<script setup lang="ts"></script>

<template>
    <p class="informational">
        <slot></slot>
    </p>
</template>

<style lang="scss" scoped>
.informational {
    align-self: center;
    background: var(--component-color-background-base);
    border-radius: 16px;
    color: var(--text-color-default);
    font-weight: 600;
    padding: 30px;
    text-align: left;
    width: 100%;

    @include respond-above('md') {
        width: var(--size-container);
    }
}
</style>
