<script setup lang="ts">
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import { computed, onMounted, PropType, watch, nextTick } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import Translations from '@/Services/translations.service';
import AppInputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';
import { SecurityDevice } from '@/Interfaces/SecurityDeviceInterface';
import { InputOption } from '@/Interfaces/InputOptionInterface';

const props = defineProps({
    securityDevices: { type: Array as PropType<SecurityDevice[]>, default: () => [] },
    formField: { type: Object as PropType<FormField<boolean>>, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
});

const emit = defineEmits(['change']);

const { translate } = useTranslate();

const form: Form = new Form();

onMounted(() => {
    setupForm();
});

const securityDeviceOptions = computed(() => {
    const result: InputOption[] = [];
    const translations: Translations = Translations.getInstance();
    if (translations.ready()) {
        result.push(
            new (class implements InputOption {
                public name: string = translations.localized('btar_yes');
                public value: string | number | boolean = true;
            })(),
        );
        result.push(
            new (class implements InputOption {
                public name: string = translations.localized('btar_no');
                public value: string | number | boolean = false;
            })(),
        );
    }

    return result;
});

function securityDevicesTabChange(value: boolean): void {
    form.field('security-device-consent-radio').patch(value);
    props.formField.patch(value);
    form.validate();
    props.formField.validate();
    emitChange();
}

const emitChange = (currentValue?: any, valueBefore?: any) => {
    emit('change', form.field('security-device-consent-radio').value);
};

watch(
    () => props.formField.value,
    (value: boolean) => {
        applyFormFieldValues(value);
    },
);

function applyFormFieldValues(value: boolean): void {
    form.field('security-device-consent-radio').patch(value);
    props.formField.patch(value);
    nextTick(() => {
        form.validate();
        props.formField.validate();
        emit('change');
    });
}

function setupForm(): void {
    form.addField(new FormField('security-device-consent-radio', ''));
    form.setReady();
    props.formField.onClear.subscribe(() => {
        form.field('security-device-consent-radio').clear();
        form.validate();
        props.formField.validate();
        emit('change');
    });
    props.formField.onPatch.subscribe((value: boolean) => {
        form.field('security-device-consent-radio').patch(value);
        form.validate();
        props.formField.validate();
        emit('change');
    });
    form.field('security-device-consent-radio').patch(props.formField.value);
    nextTick(() => {
        props.formField.addValidators({
            validation: () => {
                return !props.formField.isEmpty();
            },
        });
    });
}
</script>
<template>
    <div class="security-device-consent-container input full-width">
        <div
            :id="formField.name"
            class="security-device-consent"
            :class="{ ...formField.classes(), disabled: props.disabled }"
            :data-store="dataStoreDisabled ? '' : formField.name"
            :data-store-value="dataStoreDisabled ? '' : formField.value"
        >
            <div class="key small icon-devices-list">
                <div class="devices-approve-text">{{ translate('btar_security_devices_approve_text') }}</div>
                <div
                    v-for="device in securityDevices"
                    :id="formField.name + '-device-' + device.deviceIc.toString().toLocaleLowerCase()"
                    :key="formField.name + '-device-' + device.deviceIc.toString().toLowerCase()"
                    class="security-devices"
                >
                    {{ translate('btar_security_devices_ic_' + device.deviceIc.toString().toLocaleLowerCase()) }}
                </div>
            </div>
            <div class="value">
                <app-input-radio
                    :id="formField.name + '-securityDeviceOption'"
                    :form-field="form.field('security-device-consent-radio')"
                    :options="securityDeviceOptions"
                    :size="'large'"
                    :type="'sparse'"
                    :data-store-disabled="true"
                    @change="securityDevicesTabChange"
                >
                </app-input-radio>
            </div>
        </div>
        <div class="panel icon" :class="{ ...formField.classes() }">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                    stroke="#E30613"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path d="M11 7V11" stroke="#E30613" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M11 15H11.01"
                    stroke="#E30613"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <span>{{ translate('security_please_choose_one') }}</span>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.security-device-consent-container {
    width: 100%;

    .security-device-consent {
        align-items: center;
        background-color: var(--component-color-background-base);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: var(--size-big);
        border-top: var(--system-color-error-default) solid 2px;
        min-width: 50px;

        &.error {
            border-radius: 3px;
            border: var(--system-color-error-default) solid 2px;
        }

        @include respond-below('lg') {
            flex-direction: column;

            button {
                width: auto !important;
                margin-left: 0;
                margin-right: 0;
                min-width: 104px;
            }
        }

        .value {
            width: 45%;
            align-self: flex-end;
        }

        @include respond-below('lg') {
            .value {
                padding-top: var(--size-nano);
                width: 100%;
            }

            .buttons {
                padding-top: var(--size-nano);
                min-width: 104px;

                @include respond-below('lg') {
                    justify-content: space-between;
                }
            }
        }

        .error.untouched .button::before {
            border: 1px solid var(--black-500);
        }

        .error.touched .button::before {
            border-color: 2px solid var(--system-color-error-default);
        }

        .icon-devices-list {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .devices-approve-text {
                font-weight: 600;
                margin-bottom: 22px;
            }

            .security-devices {
                font-weight: 200;

                &::before {
                    content: '·';
                    width: 20px;
                    position: relative;
                    display: inline-block;
                    padding-left: 10px;
                }
            }

            @include respond-below('lg') {
                align-self: flex-start;
            }
        }
    }

    > .icon {
        padding: 22px;
        background: #fbebec;
        height: 64px;
        margin-top: var(--size-femto);
        display: none;

        &.error {
            display: flex;
        }

        svg {
            margin-right: 14px;
        }

        span {
            font-size: var(--font-size-nano);
            font-weight: 200;
        }
    }
}
</style>
