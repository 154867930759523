import PopupBase from '@/Assets/Libraries/Popups/PopupBase';
import PopupType from '@/Enums/PopupTypeEnum';
import { VueApp } from '@/Types/VueAppType';
import { InputOption } from '@/Interfaces/InputOptionInterface';

export default class Confirm extends PopupBase {
    public constructor() {
        super();
        this.applyType(PopupType.Confirm);
    }

    public withTitle(value: string): Confirm {
        this.applyTitle(value);

        return this;
    }

    public withSecondaryTitle(value: string): Confirm {
        this.applySecondaryTitle(value);

        return this;
    }

    public withDescription(value: string): Confirm {
        this.applyDescription(value);

        return this;
    }

    public withSecondaryDescription(value: string): Confirm {
        this.applySecondaryDescription(value);

        return this;
    }

    public withIcon(value: string): Confirm {
        this.applyIcon(value);

        return this;
    }

    public withSecondaryIcon(value: string): Confirm {
        this.applySecondaryIcon(value);

        return this;
    }

    public withInput(value: string): Confirm {
        this.applyInput(value);

        return this;
    }

    public withCancelButtonText(value: string): Confirm {
        this.applyCancelButtonText(value);

        return this;
    }

    public withConfirmButtonText(value: string): Confirm {
        this.applyConfirmButtonText(value);

        return this;
    }

    public withConfirmButtonColor(value: string): Confirm {
        this.applyConfirmButtonColor(value);

        return this;
    }

    public withConfirmCallback(model: string | Function, context: VueApp | null = null): Confirm {
        this.applyOnConfirmCallback(model, context);

        return this;
    }

    public withCancelCallback(model: string | Function, context: VueApp | null = null): Confirm {
        this.applyOnCancelCallback(model, context);

        return this;
    }

    public withClassForInput(value: string): Confirm {
        this.applyInputClass(value);

        return this;
    }

    public withLabelForCalendar(value: string): Confirm {
        this.applyDateInputLabel(value);

        return this;
    }

    public withStartDateForCalendar(value: Date): Confirm {
        this.applyStartDateForCalendar(value);

        return this;
    }

    public withMinDateForCalendarInput(value: Date): Confirm {
        this.applyMinDateForCalendarInput(value);

        return this;
    }

    public withMaxDateForCalendarInput(value: Date): Confirm {
        this.applyMaxDateForCalendarInput(value);

        return this;
    }

    public withOptionsInput(value: InputOption[]): Confirm {
        this.applyOptionsInput(value);

        return this;
    }

    public withoutCancelButton(): Confirm {
        this.applyWithoutCancelButton();

        return this;
    }
}
