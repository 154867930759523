<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import { useRouter, Router } from 'vue-router';
import RouteStepperStep from '@/Components/Routing/RouteStepper/RouteStepperStepInterface';
import { Subscription } from 'rxjs';
import EventBus from '@/Services/event.bus.service';
import { useMobileApp } from '@/Apps/MobileApp/Composables/MobileApp';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const router: Router = useRouter();

        const Facility: string = 'mobile-app';

        const viewIsReady: Ref<boolean> = ref(false);
        const viewFeedback: Ref<string> = ref('');

        const { fallbackEvent } = useMobileApp();

        const steps: RouteStepperStep[] = (router.options.routes || []).map((routeConfig): RouteStepperStep => {
            return {
                routeConfig: routeConfig,
                label: routeConfig.meta?.title as string,
            };
        });

        function init(): void {
            subscribeToFeedback();
        }

        function subscribeToFeedback(): void {
            EventBus.getInstance().subscribe(fallbackEvent(), (params: { message: string }): void => {
                viewFeedback.value = params.message;
            });
        }

        return {
            ...btaBase,
            ...{
                Facility,
                init,
                steps,
                viewIsReady,
                viewFeedback,
            },
        };
    },
    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.setStorageUsage(false);
        this.setFacility(this.Facility);
        this.init();

        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.viewIsReady = true;
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
