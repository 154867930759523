import Debounce from '@/Services/debounce.service';

export default class IdleTimer {
    private readonly callback: CallableFunction;
    private readonly delaySeconds: number;
    private readonly MaxSplitter: number = 60;
    private splitter: number = 0;
    private reset: Function = Debounce.getInstance().applyDebounce(this.resetDebounceCallback, this);
    private timerHandle: null | NodeJS.Timeout = null;

    public constructor(callback: CallableFunction, delaySeconds: number) {
        this.callback = callback;
        this.delaySeconds = delaySeconds;
    }

    public start(): void {
        window.addEventListener('mousemove', this.mouseMoveResetReceiver.bind(this));
        window.addEventListener('keypress', this.keyPressResetReceiver.bind(this));
        this.reset();
    }

    public startWithNoUserInteractions(): void {
        this.reset();
    }

    public clear(): void {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
        }
    }

    private resetDebounceCallback(): void {
        this.clear();
        const MilliSeconds: number = 1000;
        this.timerHandle = setTimeout(() => {
            if (this.callback) {
                this.callback();
            }
        }, this.delaySeconds * MilliSeconds);
    }

    private mouseMoveResetReceiver(): void {
        this.intervalReset();
        this.reset();
    }

    private keyPressResetReceiver(): void {
        this.intervalReset();
        this.reset();
    }

    private intervalReset(): void {
        this.splitter++;
        if (this.splitter >= this.MaxSplitter) {
            this.splitter = 0;
            this.resetDebounceCallback();
        }
    }
}
