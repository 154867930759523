import PolicyState from '@/Enums/OnePolicy/PolicyStateEnum';
import InvoiceDocumentBlock from '@/Interfaces/OnePolicy/invoice.document.block.interface';
import { DateTimeImmutable } from '@/Interfaces/date.time.immutable.interface';
import PaymentWidget from '@/Interfaces/OnePolicy/payment.widget.interface';
import PolicyDetailsInvoices from '@/Components/Policies/PolicyDetailsInvoices/Interfaces/PolicyDetailsInvoicesInterface';
import PolicyDetailsInvoicesContent from '@/Components/Policies/PolicyDetailsInvoices/Interfaces/PolicyDetailsInvoicesContentInterface';
import Clickable from '@/Components/Policies/PolicyDetailsInvoices/Enums/ClickableEnum';
import Invoice from '@/Components/Policies/PolicyBlock/Interfaces/InvoiceInterface';
import OneDate from '@/Assets/Libraries/Date/OneDate';

export default class PolicyDetailsInvoicesBuilder {
    private language: string = '';
    protected policyDetailsDocuments: PolicyDetailsInvoices = new (class implements PolicyDetailsInvoices {
        public component: string = 'AppPolicyDetailsInvoices';
        public content: PolicyDetailsInvoicesContent = new (class implements PolicyDetailsInvoicesContent {
            public title: string = '';
            public useBadge: boolean = true;
            public documentBlocks: InvoiceDocumentBlock[] = [];
            public paymentWidget: PaymentWidget = new (class implements PaymentWidget {
                public enabled: boolean = false;
                public additionalButtonText: string = '';
                public invoiceCount: number = 0;
                public totalAmount: string = '';
            })();
        })();
    })();
    private currentBlockIndex: number = -1;
    private currentDocumentIndex: number = -1;
    private showZeroAmount: boolean = true;

    public withTitle(title: string): PolicyDetailsInvoicesBuilder {
        this.policyDetailsDocuments.content.title = title;

        return this;
    }

    public withUseBadge(useBadge: boolean): PolicyDetailsInvoicesBuilder {
        this.policyDetailsDocuments.content.useBadge = useBadge;

        return this;
    }

    public withShowZeroAmount(show: boolean): PolicyDetailsInvoicesBuilder {
        this.showZeroAmount = show;

        return this;
    }

    public resetDocumentsBlocks(): PolicyDetailsInvoicesBuilder {
        this.currentBlockIndex = -1;
        this.currentDocumentIndex = -1;
        this.policyDetailsDocuments.content.documentBlocks = [];

        return this;
    }

    public withLanguage(language: string): PolicyDetailsInvoicesBuilder {
        this.language = language === 'ee' ? 'et' : language;

        return this;
    }

    public withPaymentWidget(): PolicyDetailsInvoicesBuilder {
        this.policyDetailsDocuments.content.paymentWidget.enabled = true;

        return this;
    }

    public withoutShowMoreButton(): PolicyDetailsInvoicesBuilder {
        this.policyDetailsDocuments.content.showMoreDisabled = true;

        return this;
    }

    public startNewDocumentBlock(
        dateFrom: string = '',
        category: string = '',
        categoryId: string = '',
    ): PolicyDetailsInvoicesBuilder {
        this.currentBlockIndex++;
        this.currentDocumentIndex = -1;
        const transformedDateFrom: string = dateFrom !== '' ? OneDate.medium(dateFrom) : '';
        this.policyDetailsDocuments.content.documentBlocks.push(
            new (class implements InvoiceDocumentBlock {
                public dateFrom: string = transformedDateFrom;
                public category: string = category;
                public categoryId: string = categoryId;
                public documents: Invoice[] = [];
                public opened: boolean = false;
            })(),
        );

        return this;
    }

    public startNewDocument(): PolicyDetailsInvoicesBuilder {
        if (this.hasNoBlocks()) {
            this.startNewDocumentBlock();
        }
        this.currentDocumentIndex++;
        const showZeroAmount: boolean = this.showZeroAmount;
        this.policyDetailsDocuments.content.documentBlocks[this.currentBlockIndex].documents[
            this.currentDocumentIndex
        ] = new (class implements Invoice {
            public id: string = '';
            public type: string = 'invoices';
            public status: PolicyState = PolicyState.Paid;
            public paymentNumber: string = '';
            public agreementNumber: string = '';
            public agreementId: string = '';
            public isAdjustment: boolean = false;
            public agreementTypeIc: string = '';
            public agreementType: string = '';
            public objectName: string = '';
            public currency: string = '';
            public amount: number = 0;
            public uncoveredAmount: number = 0;
            public acceptedCoveredAmount: number = 0;
            public dueDate: DateTimeImmutable = { date: '', timezone: '' };
            public showZeroAmount: boolean = showZeroAmount;
            public selected: boolean = false;
            public disabled: boolean = false;
            public hovered: boolean = false;
            public title: string = '';
            public policyPeriod: string = '';
            public dueTo: string = '';
            public clickableType: Clickable = '';
            public partial: boolean = false;
            public isIndividual: boolean = false;
        })();

        return this;
    }

    public withInvoiceId(value: string): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.id = value;

        return this;
    }

    public withInvoiceNumber(value: string): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.paymentNumber = value;

        return this;
    }

    public withInvoiceVisibility(value: boolean): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.visible = value;

        return this;
    }

    public withDocumentId(value: string): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.agreementId = value;

        return this;
    }

    public withAgreementTypeIc(value: string): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.agreementTypeIc = value;

        return this;
    }

    public asAdjustment(): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.isAdjustment = true;

        return this;
    }

    public withDocumentAgreement(value: string): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.agreementNumber = value;

        return this;
    }

    public withDocumentTitle(value: string): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.title = value;

        return this;
    }

    public asPartialPayment(): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.partial = true;

        return this;
    }

    public withDocumentPolicyPeriod(value: string): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.policyPeriod = String(value).replace('::', ' - ');

        return this;
    }

    public withDocumentDueTo(value: string): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.dueTo = value;

        return this;
    }

    public withDocumentAmount(value: number): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.amount = value;

        return this;
    }

    public withDocumentPayDate(value: string): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.dueDate = { date: value, timezone: value };

        return this;
    }

    public withDocumentPayType(value: PolicyState): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.status = value;

        return this;
    }

    public withPaymentScheduleTypeAsIndividual(value: boolean): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.isIndividual = value;

        return this;
    }

    public withDocumentSelected(value: boolean): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.selected = value;

        return this;
    }

    public withClickableType(value: Clickable): PolicyDetailsInvoicesBuilder {
        this.validateBlock();
        this.currentDocument.clickableType = value;

        return this;
    }

    public resetDocuments(): PolicyDetailsInvoicesBuilder {
        this.policyDetailsDocuments.content.documentBlocks = [];

        return this;
    }

    public elementsCount(): number {
        return this.policyDetailsDocuments.content.documentBlocks.length;
    }

    public build(): PolicyDetailsInvoices {
        this.validateDocuments();

        return this.policyDetailsDocuments;
    }

    public get elements(): InvoiceDocumentBlock[] {
        return this.policyDetailsDocuments.content.documentBlocks;
    }

    private get currentDocument(): Invoice {
        return this.policyDetailsDocuments.content.documentBlocks[this.currentBlockIndex].documents[
            this.currentDocumentIndex
        ];
    }

    private validateBlock(): void {
        if (this.hasNoBlocks()) {
            this.startNewDocumentBlock();
            this.startNewDocument();
        }
    }

    private hasNoBlocks(): boolean {
        return this.currentBlockIndex < 0;
    }

    private validateDocuments(): void {
        this.policyDetailsDocuments.content.documentBlocks.forEach((block: InvoiceDocumentBlock): void => {
            block.documents.forEach((document: Invoice): void => {
                if (document.agreementNumber === '') {
                    console.debug('document has no agreement!', document);
                }
            });
        });
    }
}
