import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

export const useSorting = (): SortingParams => {
    /**
     * @param value can be any simple object; nested objects & arrays are not sorted
     * @param sortKeys optional array of keys that defines the order in which the object's keys should be sorted
     * @return original object with keys sorted alphabetically (by default) or according to the provided array of keys
     */
    const sortedObject = (value: DynamicDictionary, sortKeys?: string[]): DynamicDictionary => {
        return Object.keys(value)
            .sort((a: string, b: string): number => {
                if (sortKeys) {
                    return sortKeys.indexOf(a) - sortKeys.indexOf(b);
                }
                return a.localeCompare(b);
            })
            .reduce((copy: DynamicDictionary, key: string): DynamicDictionary => {
                copy[key] = value[key];
                return copy;
            }, {});
    };

    /**
     * @param value array of alphanumeric strings
     * @return sorted array of alphanumeric strings using IntlCollator
     */
    const alphaNumericSort = (value: string[]): string[] => {
        return value.sort(new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare);
    };

    return {
        sortedObject,
        alphaNumericSort,
    };
};

export interface SortingParams {
    sortedObject: (value: DynamicDictionary, sortKeys?: string[]) => DynamicDictionary;
    alphaNumericSort: (value: string[]) => string[];
}
