<script setup lang="ts">
import { PropType } from 'vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import { InputOption } from '@/Interfaces/InputOptionInterface';

const props = defineProps({
    formField: { type: FormField<string | undefined>, default: () => new FormField('') },
    options: { type: Array as PropType<InputOption<string>[]>, default: () => [] },
    dataStoreDisabled: { type: Boolean, default: false },
    omitRedBorders: { type: Boolean, default: true },
});

const emit = defineEmits(['change']);

function onClick(option: InputOption<string>): void {
    props.formField.setValue(props.formField.value !== option.value ? option.value : undefined);
    props.formField.markAsTouched();
    props.formField.markAsDirty();
    emit('change');
}
</script>

<template>
    <div
        :id="formField.name"
        class="input input-radio-list"
        :class="{ ...formField.classes(), 'red-borders': !omitRedBorders }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <button
            v-for="(option, index) in options"
            :id="'list-option-' + index"
            :key="index"
            class="option block"
            :class="{ selected: props.formField.value === option.value }"
            @click="onClick(option)"
        >
            <span class="radio-icon"></span>
            <span class="title">{{ option.name }}</span>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.input-radio-list {
    gap: var(--size-normal);
    display: flex;
    flex-direction: column;

    .option {
        background: var(--white);
        width: 100%;
        border-radius: var(--size-pico);
        padding: var(--size-small);
        border: 2px solid transparent;
        position: relative;
        font-size: var(--font-size-tiny);

        &.block {
            display: block;
        }

        &.selected {
            border-color: var(--component-color-border-active);
            background: linear-gradient(
                    0deg,
                    var(--system-color-success-light) 0%,
                    var(--system-color-success-light) 100%
                ),
                var(--white);

            .radio-icon {
                display: block;
            }

            &::before {
                background-color: var(--system-color-success-dark);
                border-color: var(--system-color-success-dark);
            }

            .title {
                color: var(--text-color-default);
            }
        }

        .title {
            padding: 0 var(--size-small);
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -12px;
            margin-left: 14px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid var(--component-color-border-default);
            background-color: var(--white);
        }

        .radio-icon {
            display: none;
            position: absolute;
            top: 50%;
            margin-top: -4px;
            left: 22px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--white);
        }
    }
}

.input-radio-list.red-borders.touched.invalid {
    .option {
        border: 2px solid var(--component-color-border-error);
    }
}
</style>
