import StringDictionary from '@/Interfaces/string.dictionary.interface';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import SettingsService from '@/Services/settings.service';

export default class DataLayer {
    private static instance: DataLayer;
    private readonly Checkout: string = 'checkout';
    private readonly Purchase: string = 'purchase';
    private target: string = '';
    private rootParams: StringDictionary = {};
    private actionParams: StringDictionary = {};
    private productParams: StringDictionary[] = [];
    private productIndex: number = 0;
    private layer: any = {};

    private constructor() {
        this.reset();
    }

    public static getInstance(): DataLayer {
        if (!DataLayer.instance) {
            DataLayer.instance = new DataLayer();
        }

        return DataLayer.instance;
    }

    public reset(): DataLayer {
        this.rootParams = new (class implements StringDictionary {
            [key: string]: string;
        })();
        this.actionParams = new (class implements StringDictionary {
            [key: string]: string;
        })();
        this.productParams = [];
        this.productIndex = 0;
        this.target = '';
        this.layer = {};

        return this;
    }

    public useCheckout(): DataLayer {
        this.target = this.Checkout;

        return this;
    }

    public usePurchase(): DataLayer {
        this.target = this.Purchase;

        return this;
    }

    public addRootParam(name: string, value: any): DataLayer {
        this.rootParams[name] = value;

        return this;
    }

    public addActionParam(name: string, value: any): DataLayer {
        this.actionParams[name] = value;

        return this;
    }

    public newProduct(): DataLayer {
        if (this.productParams.length > 0) {
            this.productIndex++;
        }
        this.productParams[this.productIndex] = new (class implements StringDictionary {
            [key: string]: string;
        })();

        return this;
    }

    public addProductParam(name: string, value: any): DataLayer {
        this.productParams[this.productIndex][name] = value;

        return this;
    }

    public buildAndPush(): void {
        if (this.target !== '') {
            this.rootParams.event = this.target;
        }
        this.buildRootParams();
        if (this.target !== '') {
            this.layer.ecommerce = {};
            this.layer.ecommerce[this.target] = {};
            this.buildActionParams();
            this.buildProductParams();
        }
        window.dataLayer = window.dataLayer || [];
        if (SettingsService.getInstance().value('DATA_LAYER_ENABLED')) {
            window.dataLayer.push(this.layer);
        }
        this.reset();
    }

    private buildRootParams(): void {
        let eventIsFound: boolean = false;
        for (const o in this.rootParams) {
            this.layer[o] = this.rootParams[o];
            if (o === 'event') {
                eventIsFound = true;
            }
        }
        if (!eventIsFound) {
            Error.log(ErrorType.Warning, 'DataLayer::buildRootParams', '"event" param is missed!');
        }
    }

    private buildActionParams(): void {
        this.layer.ecommerce[this.target].actionField = {};
        for (const o in this.actionParams) {
            this.layer.ecommerce[this.target].actionField[o] = this.actionParams[o];
        }
    }

    private buildProductParams(): void {
        this.layer.ecommerce[this.target].products = [];
        this.productParams.forEach((value: StringDictionary, index: number) => {
            this.layer.ecommerce[this.target].products[index] = {};
            for (const o in value) {
                this.layer.ecommerce[this.target].products[index][o] = value[o];
            }
        });
    }
}

declare global {
    interface Window {
        dataLayer: any;
        parentIFrame: any;
    }
}
