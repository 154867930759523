import VueEvent from '@/Classes/VueEventClass';
import { nextTick } from 'vue';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';

export default class Video {
    public readonly Container: string = '.video-popup';
    private videoUrl: string = '';

    public get url(): string {
        return this.videoUrl;
    }

    public onVideoPopupOpenClick(event: VueEvent): void {
        this.videoUrl = event.params['url'];
        PopupService.getInstance().show(new OnePopup().withType().videoPopup);
        nextTick(() => {
            $(this.Container).find('iframe').focus();
        });
    }
}
