import AjaxAgreement from '@/Components/Policies/PolicyBlock/Interfaces/AjaxAgreementInterface';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import { AjaxAgreementBuilder } from '@/Components/Policies/PolicyBlock/Builders/AjaxAgreementBuilder';
import PolicyDetailsPopup from '@/Pages/OneDashboard/Insurances/PolicyDetailsPopup';
import OneBaseService from '@/Services/OneBaseService';
import PolicyDetailsContent from '@/Components/Policies/PolicyDetails/Interfaces/PolicyDetailsContentInterface';
import PolicyDetails from '@/Components/Policies/PolicyDetails/Services/PolicyDetailsService';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import EventBus from '@/Services/event.bus.service';

export const useMobileApp = (): MobileAppParams => {
    const FallbackEvent: string = 'mobile-view-fallback';
    /**
     * TODO @kvecvagars If we need some sort of fallback, can implement translations here
     */
    const FallbackEventMessage: string = 'agreement does not exist';

    const ajaxAgreement = (): AjaxAgreement => {
        return new AjaxAgreementBuilder().fromAgreement(storedAgreement()).build();
    };

    const agreementView = (): PolicyDetailsPopup => {
        const agreement: Agreement = storedAgreement();

        return new PolicyDetailsPopup(agreement, 'dashboard', OneBaseService.getInstance().user.current)
            .applySubscriptionHistory((agreement.subscriptionHistory as Agreement[]) ?? [])
            .applyUpcomingAgreements((agreement.upcomingAgreements as Agreement[]) ?? []);
    };

    const hasStoredAgreement = (): boolean => {
        return !!storedAgreement();
    };

    const fallbackEvent = (): string => {
        return FallbackEvent;
    };

    const emitFallbackEvent = (): void => {
        EventBus.getInstance().emit(FallbackEvent, { message: FallbackEventMessage });
    };

    const viewContent = (tab: string): DynamicDictionary => {
        const agreementDetailsContent: PolicyDetailsContent = PolicyDetails.getInstance().getContent();

        return agreementDetailsContent.tabs.elements.filter((element) => element.id === tab)[0].content;
    };

    const storedAgreement = (): Agreement => {
        return TransferStateService.getInstance().get('agreement') as Agreement;
    };

    return {
        ajaxAgreement,
        agreementView,
        hasStoredAgreement,
        viewContent,
        emitFallbackEvent,
        fallbackEvent,
    };
};

export interface MobileAppParams {
    ajaxAgreement: () => AjaxAgreement;
    agreementView: () => PolicyDetailsPopup;
    hasStoredAgreement: () => boolean;
    viewContent: (tab: string) => DynamicDictionary;
    emitFallbackEvent: () => void;
    fallbackEvent: () => string;
}
