import { reactive, UnwrapNestedRefs } from 'vue';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelFormFields';
import ClaimsTravelObject from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelObject';
import ClaimsTravelStorageFields from '@/Apps/ClaimsTravel/Interfaces/ClaimsTravelStorageFields';
import SpaFormStorageService from '@/Services/SpaFormStorageService';
import Form from '@/Assets/Libraries/Form/Form';
import TravelType from '@/Apps/ClaimsTravel/Interfaces/TravelType';
import TransferStateService from '@/Core/ServerState/TransferStateService';

interface FormStorage {
    storageFields: ClaimsTravelStorageFields;
    fields: Partial<ClaimsTravelFormFields>;
}

export default class ClaimsTravelService {
    public storageFields: UnwrapNestedRefs<ClaimsTravelStorageFields> = reactive(
        new (class implements ClaimsTravelStorageFields {
            public allInsuredObjects: ClaimsTravelObject[] = [];
            public personInsuredObjects: ClaimsTravelObject[] = [];
            public formVisibility: Partial<{ [FormKey in keyof ClaimsTravelFormFields]: boolean }> = {};
            public isSubFlowVisible: boolean = false;
        })(),
    );
    public fields!: UnwrapNestedRefs<Partial<ClaimsTravelFormFields>>;
    public initialStepUrl: string = '';
    public thankYouStepUrl: string = '';
    public tripCancellationRiskIc: string = '';
    public tripInterruptionReasons: string[] = [];
    public travelTypes: TravelType[] = [];

    private static instance: ClaimsTravelService;
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();
    private transferStateService: TransferStateService = TransferStateService.getInstance();

    public static getInstance(): ClaimsTravelService {
        if (!ClaimsTravelService.instance) {
            ClaimsTravelService.instance = new ClaimsTravelService();
        }

        return ClaimsTravelService.instance;
    }

    public init(): void {
        this.restoreStorage();
        this.applyFormStorageProviderCallback();
        this.tripInterruptionReasons = this.transferStateService.get('tripInterruptionReasons');
    }

    public storeForm<FormKey extends keyof ClaimsTravelFormFields>(
        formName: FormKey,
        form: Form<ClaimsTravelFormFields[FormKey]>,
    ): void {
        this.fields[formName] = form.fields().reduce((accumulator: Partial<ClaimsTravelFormFields[FormKey]>, field) => {
            accumulator[field.name as keyof ClaimsTravelFormFields[FormKey]] = field.value;

            return accumulator;
        }, {}) as ClaimsTravelFormFields[FormKey];
    }

    public restoreForms(
        forms: Partial<{
            [FormKey in keyof ClaimsTravelFormFields]: Form<ClaimsTravelFormFields[FormKey]>;
        }>,
    ): void {
        Object.entries(forms).forEach(([formName, form]): void => {
            this.restoreForm(formName as keyof ClaimsTravelFormFields, form);
        });
    }

    private restoreForm<
        FormKey extends keyof ClaimsTravelFormFields,
        FieldKey extends keyof ClaimsTravelFormFields[FormKey],
    >(formName: FormKey, form: Form<ClaimsTravelFormFields[FormKey]>): void {
        const formFields = this.fields[formName] as ClaimsTravelFormFields[FormKey];

        if (formFields !== undefined) {
            form.fields()
                .map((field) => field.name)
                .forEach((fieldName) => {
                    form.field(fieldName as FieldKey).setValue(formFields[fieldName as FieldKey], true);
                });
        }
    }

    private restoreStorage(): void {
        const storage: Partial<FormStorage> = this.spaFormStorageService.formStorageFields<Partial<FormStorage>>();
        this.fields = storage.fields ?? {};
        this.storageFields = storage.storageFields ?? this.storageFields;
    }

    private applyFormStorageProviderCallback(): void {
        this.spaFormStorageService.applyDataProviderCallback((): FormStorage => {
            return {
                fields: this.fields,
                storageFields: this.storageFields,
            };
        });
    }
}
