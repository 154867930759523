<script lang="ts">
import { computed, defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Form from '@/Assets/Libraries/Form/Form';
import SubmitterUrls from '@/Services/SubmitterUrls.service';
import { useTranslate } from '@/Composables/Translate';
import { useNavigate } from '@/Composables/Navigate';
import VueEvent from '@/Classes/VueEventClass';
import CssClass from '@/Enums/CssClassEnum';
import FormField from '@/Assets/Libraries/Form/FormField';
import moment from 'moment';
import Validation from '@/Services/validation.service';
import User from '@/Services/user.service';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import Value from '@/Assets/Libraries/Form/Value';
import Sanitizer from '@/Services/sanitizer.service';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { take } from 'rxjs/operators';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import { useScroll } from '@/Composables/Scroll';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import { CoveredPopupBuilder } from '@/Pages/Bicycle/Classes/CoveredPopupBuilder';
import CoveredPopupTypes from '@/Components/Popups/CoveredPopup/Enums/CoveredPopupTypes';
import UserStorage from '@/Services/user.storage.service';
import { Bicycle } from '@/Interfaces/Resources/Bicycles/BicycleInterface';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import SettingsService from '@/Services/settings.service';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import { CountryComponentParams } from '@/Components/Inputs/InputCountry/CountryComponentParams';
import DateRange from '@/Interfaces/date.range.interface';
import PhoneField from '@/Interfaces/phone.field.interface';
import { useDefine } from '@/Composables/Define';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const user: User = User.getInstance();
        const { translate } = useTranslate();
        const { translateForType } = useTranslate();
        const translationType: string = 'bicycle_policy';
        const stepsSubmitter = useStepsSubmitter();
        const CurrentStep: number = 3;
        const Facility: string = 'bicycle_policy';
        const ConsentsGlue: string = ':';
        const form: Form<{
            policyStartDate: DateRange;
            policyHolderIsResident: boolean;
            policyHolderFirstName: string;
            policyHolderLastName: string;
            policyHolderIdentityNumber: string;
            policyHolderCountry: CountryComponentParams;
            policyHolderBirthDate: Date | string;
            policyHolderEmail: string;
            policyHolderPhone: PhoneField;
            policyHolderSameAsInsured: boolean;
            agreeTerms: boolean;
            marketingConsents: DynamicDictionary;
        }> = new Form({ useValidationV2: true });
        const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
        const policyStartMinDate: Date = moment().add(1, 'days').toDate();
        const policyStartMaxDate: Date = moment().add(30, 'days').toDate();
        const bankLinkId: Ref<number> = ref(0);
        const detailsBirthDate: Ref<string> = ref('');
        const informationText: Ref<string | null> = ref(null);
        const showMarketingConsents: Ref<boolean> = computed(() => !btaBase.user.current.receiveMarketing);
        const isAuthenticatedUser: Ref<boolean> = computed(() => user.isLogged());
        const isPaymentMethodSelected: Ref<boolean> = computed(() => bankLinkId.value !== 0);
        const isLithuanian: Ref<boolean> = computed(() => form.field('policyHolderCountry').value.iso === 'LT');
        const isEstonian: Ref<boolean> = computed(() => form.field('policyHolderCountry').value.iso === 'EE');
        const policyHolderIsNonResident: Ref<boolean> = computed(() => !policyHolderIsResident.value);
        const policyHolderSameAsInsured: Ref<boolean> = computed(() => form.field('policyHolderSameAsInsured').value);
        const policyHolderBirthDateRequired: Ref<boolean> = computed(
            () => policyHolderIsNonResident.value || policyHolderCodeIsAnonymous.value,
        );
        const policyHolderCodeIsAnonymous: Ref<boolean> = computed(() => {
            const holderCodeField: FormField = form.field('policyHolderIdentityNumber');
            const holderCode: string = String(holderCodeField.value);

            return holderCodeField.isNotEmpty() && Validation.isValidPersonCodeWithoutDate(holderCode);
        });
        const policyStartDate: Ref<string> = computed(() =>
            OneDate.short(moment(form.field('policyStartDate').value.startDate).toDate()),
        );
        const policyEndDate: Ref<string> = computed(() => {
            const startDate: Date = form.field('policyStartDate').value.startDate as unknown as Date;
            const result: Date = moment(startDate).add(1, 'year').subtract(1, 'day').toDate();

            return OneDate.short(result);
        });

        const policyHolderIsFromBalticCountries: Ref<boolean> = computed(() => {
            let result: boolean = false;
            const holder: DynamicDictionary = btaBase.userStorage.stepStorageData.insuredPerson;
            if (holder && useDefine().isSet(holder.country)) {
                result = ['EE', 'LV', 'LT'].includes(holder.country.iso);
            }

            return result;
        });

        const policyHolderIsResident: Ref<boolean> = computed(() => {
            return (
                (form.field('policyHolderIsResident').value && !policyHolderSameAsInsured.value) ||
                (policyHolderSameAsInsured.value && policyHolderIsFromBalticCountries.value)
            );
        });

        function marketingConsents(): string[] {
            const selectedConsents: string = form.field('marketingConsents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ? selectedConsents.split(ConsentsGlue) : [];
        }

        function patchDefaultValues(): void {
            form.field('policyStartDate').patch({
                startDate: minPolicyStartDate() as unknown as string,
                endDate: '',
            });
            isAuthenticatedUser.value ? patchUserFields() : patchGuestFields();
        }

        function patchUserFields(): void {
            const currentUser: UserCredentials = btaBase.user.current;
            if (form.field('policyHolderFirstName').isEmpty()) {
                form.field('policyHolderFirstName').patch(currentUser.firstname);
            }
            if (form.field('policyHolderLastName').isEmpty()) {
                form.field('policyHolderLastName').patch(currentUser.lastname);
            }
            if (form.field('policyHolderIdentityNumber').isEmpty()) {
                form.field('policyHolderIdentityNumber').patch(currentUser.personCode);
            }
            if (form.field('policyHolderEmail').isEmpty()) {
                form.field('policyHolderEmail').patch(currentUser.email);
            }
            if (form.field('policyHolderPhone').isEmpty()) {
                form.field('policyHolderPhone').patch({
                    code: currentUser.code,
                    iso: currentUser.code,
                    country: currentUser.phoneCode,
                    phone: currentUser.phone,
                } as PhoneField);
            }
        }

        function patchGuestFields(): void {
            patchHolderAsInsured();
        }

        function onSameAsInsuredClick(): void {
            if (policyHolderSameAsInsured.value) {
                patchHolderAsInsured();
            } else {
                patchHolderAsGuest();
            }
        }

        function onIsResidentClick(): void {
            const settingsInstance: SettingsService = SettingsService.getInstance();
            const residentCountry: CountryComponentParams = {
                ic: '100000000',
                iso: settingsInstance.localeIso(),
                phoneCode: settingsInstance.phoneCode(),
            };
            form.field('policyHolderIdentityNumber').setValue('');
            form.field('policyHolderPhone').setValue({
                code: '',
                iso: settingsInstance.localeIso(),
                country: '',
                phone: '',
            } as PhoneField);
            if (form.field('policyHolderIsResident').value) {
                form.field('policyHolderCountry').setValue(residentCountry);
            } else {
                form.field('policyHolderCountry').setValue({ ic: '', iso: '', phoneCode: '' });
            }
        }

        function patchHolderAsInsured(): void {
            const holder: DynamicDictionary = btaBase.userStorage.stepStorageData.insuredPerson;
            form.field('policyHolderSameAsInsured').setValue(true);
            form.field('policyHolderIsResident').setValue(holder.isResident);
            form.field('policyHolderFirstName').setValue(holder.firstName);
            form.field('policyHolderLastName').setValue(holder.lastName);
            form.field('policyHolderIdentityNumber').setValue(holder.personCode);
            form.field('policyHolderBirthDate').setValue(holder.birthDate);
            form.field('policyHolderCountry').setValue(holder.country);
            if (holder.country.phoneCode) {
                if (!holder.isResident) {
                    form.field('policyHolderPhone').setValue({
                        code: holder.country.ic,
                        iso: holder.country.iso,
                        country: holder.country.phoneCode,
                        phone: '',
                    } as PhoneField);
                } else {
                    form.field('policyHolderPhone').setValue({
                        code: '',
                        iso: form.field('policyHolderIsResident').value ? new AppCountry().iso() : holder.country.iso,
                        country: '',
                        phone: '',
                    } as PhoneField);
                }
            }
        }

        function patchHolderAsGuest(): void {
            const holder: DynamicDictionary = btaBase.userStorage.stepStorageData.insuredPerson;
            form.field('policyHolderSameAsInsured').setValue(false);
            form.field('policyHolderIsResident').setValue(true);
            form.field('policyHolderFirstName').setValue('');
            form.field('policyHolderLastName').setValue('');
            form.field('policyHolderIdentityNumber').setValue('');
            form.field('policyHolderCountry').clear();
            form.field('policyHolderBirthDate').clear();
            form.field('policyHolderPhone').setValue({
                code: '',
                iso: form.field('policyHolderIsResident').value ? new AppCountry().iso() : holder.country.iso,
                country: '',
                phone: '',
            } as PhoneField);
        }

        function isVisibleInformationalText(): boolean {
            return false;
        }

        function requiresBirthDate(): boolean {
            return (
                notFromBaltic() &&
                (policyHolderIsNonResident.value || policyHolderCodeIsAnonymous.value) &&
                !isAuthenticatedUser.value
            );
        }

        function notFromBaltic(): boolean {
            return !(isLithuanian.value || isEstonian.value);
        }

        function onCoveredClick(): void {
            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        function onFormSubmit(): void {
            form.submitAttempt().then((): void => {
                if (form.isValid()) {
                    if (isPaymentMethodSelected.value) {
                        prepareSubmit();
                        stepsSubmitter.submitMethod(Method.Post);
                        stepsSubmitter.submitStep(Url.Ajax.bicyclePolicyPurchase);
                    } else {
                        scrollToBanksListAndMark();
                    }
                } else {
                    useScroll().scrollInvalidFieldToView(form);
                }
            });
        }

        function scrollToBanksListAndMark(): void {
            $('.payments > .title').addClass(CssClass.NotFilled);
            useScroll().scrollToLast('[data-alias="banks-list"]').then();
        }

        function redirectToBank(): void {
            useNavigate().navigate(Url.PaymentsApi.paymentsPay);
        }

        function policyHolder(): DynamicDictionary {
            return {
                firstName: form.field('policyHolderFirstName').value,
                lastName: form.field('policyHolderLastName').value,
                identityNumber: form.field('policyHolderIdentityNumber').value,
                country: country(),
                birthDate: birthDate(),
                email: form.field('policyHolderEmail').value,
                phone: form.field('policyHolderPhone').value,
                isResident: btaBase.user.isLogged() ? true : form.field('policyHolderIsResident').value,
                marketingConsents: marketingConsents(),
            };
        }

        function birthDate(): string {
            const currentUser: User = btaBase.user;
            let result: string = '';
            if (currentUser.isLogged()) {
                result = OneDate.iris(currentUser.current.birthDate);
            } else {
                if (requiresBirthDate()) {
                    result = OneDate.iris(new Date(form.field('policyHolderBirthDate').value));
                }
            }

            return result;
        }

        function country(): CountryComponentParams {
            const settingsInstance: SettingsService = SettingsService.getInstance();
            let source: CountryComponentParams = {
                ic: '100000000',
                iso: settingsInstance.localeIso(),
                phoneCode: settingsInstance.phoneCode(),
            };
            if (policyHolderIsNonResident.value) {
                source = form.field('policyHolderCountry').value;
            }

            return source;
        }

        function choosePayment(event: VueEvent): void {
            const newBankLinkId: number = event.params.bankLinkId;
            if (newBankLinkId > 0 && bankLinkId.value !== newBankLinkId) {
                const newInformationText: string = event.params.informationText;
                event.sender.siblings('.' + CssClass.Active).removeClass(CssClass.Active);
                event.sender.toggleClass(CssClass.Active);
                bankLinkId.value = newBankLinkId;
                informationText.value = newInformationText ? translate(String(newInformationText).toLowerCase()) : null;
                $('.payments > .title').removeClass(CssClass.NotFilled);
            }
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', stepsSubmitter.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitParam('policyStartDate', policyDate(), false);
            stepsSubmitter.addSubmitParam('policyHolder', policyHolder(), false);
            stepsSubmitter.addSubmitParam('bankLinkId', bankLinkId.value, false);
            stepsSubmitter.addSubmitParam('paymentFailRedirectUrl', SubmitterUrls.getInstance().currentStep(), false);
            stepsSubmitter.addAjaxResponseCallbackAfterStepsStorage(redirectToBank);
        }

        function policyDate(): string {
            const date: Date = moment(form.field('policyStartDate').value.startDate).toDate();

            return OneDate.iris(date);
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function minPolicyStartDate(): Date {
            const offset: number = btaBase.settings.movablePropertySettings().minPolicyStartDateOffset;

            return moment().add(offset, 'day').toDate();
        }

        function onBeforeFormRestore(): void {
            patchDefaultValues();
        }

        function onAppReady(): void {
            buildCoverageOptions();
            applyDetailsBirthDate();
        }

        function coveredButtonParams(): ButtonWithCallbackParams {
            return {
                title: translateForType('what_covered_bicycle', translationType),
                textColor: ButtonTextColor.Black,
                backgroundColor: ButtonBackground.White,
                backgroundColorHover: ButtonBackground.White,
                icon: ButtonIcon.Covered,
                iconColor: ButtonIconColor.Green,
                borderColor: ButtonBorder.Pale,
            };
        }

        function setupForm(): void {
            form.addField(new FormField('policyStartDate').addValidators({ required: Validation.required }));
            form.addField(new FormField('policyHolderIsResident', true));
            form.addField(
                new FormField('policyHolderFirstName')
                    .addValidators({
                        required: Validation.required,
                        validCaption: Validation.isValidCaption,
                    })
                    .addSanitizer(Sanitizer.cleanUppercaseName),
            );
            form.addField(
                new FormField('policyHolderLastName')
                    .addValidators({
                        required: Validation.required,
                        validCaption: Validation.isValidCaption,
                    })
                    .addSanitizer(Sanitizer.cleanUppercaseName),
            );
            form.addField(new FormField('policyHolderIdentityNumber').addValidators({ required: Validation.required }));
            form.addField(
                new FormField('policyHolderCountry').addValidators({
                    required: Validation.requiredIf(() => policyHolderIsNonResident.value),
                }),
            );
            form.addField(
                new FormField('policyHolderBirthDate').addValidators({
                    required: Validation.requiredIf(() => requiresBirthDate()),
                }),
            );
            form.addField(new FormField('policyHolderEmail').addValidators({ required: Validation.required }));
            form.addField(new FormField('policyHolderPhone').addValidators({ required: Validation.required }));
            form.addField(new FormField('policyHolderSameAsInsured', false));
            form.addField(new FormField('agreeTerms', false).addValidators({ required: Validation.required }));
            form.addField(new FormField('marketingConsents'));
            form.setReady();
        }

        function buildCoverageOptions(): void {
            const productFromStorage: Bicycle | undefined = selectedProduct();
            if (productFromStorage) {
                coveredPopup
                    .withCoveredTitle('what_covered_bicycle')
                    .withTranslationKey('bicycle_policy')
                    .withCoveredType(CoveredPopupTypes.CoveredPopupBicycleSingle)
                    .withContent(productFromStorage)
                    .build();
            }
        }

        function selectedProduct(): Bicycle | undefined {
            const storage: UserStorage = UserStorage.getInstance();
            const storedProductId: string = selectedProductId();
            return (storage.storageData as Bicycle[]).find(
                (product: Bicycle): boolean => product.id === storedProductId,
            );
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function applyDetailsBirthDate(): void {
            if (btaBase.userStorage.stepStorageData.insuredPerson.birthDate) {
                const date: Date = moment(btaBase.userStorage.stepStorageData.insuredPerson.birthDate).toDate();
                detailsBirthDate.value = OneDate.short(date);
            }
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                onBackClick,
                onBeforeFormRestore,
                onAppReady,
                onCoveredClick,
                coveredButtonParams,
                isVisibleInformationalText,
                choosePayment,
                detailsBirthDate,
                selectedProductId,
                showMarketingConsents,
                policyStartDate,
                policyEndDate,
                policyStartMinDate,
                policyStartMaxDate,
                policyHolderIsResident,
                policyHolderIsNonResident,
                policyHolderCodeIsAnonymous,
                policyHolderBirthDateRequired,
                isAuthenticatedUser,
                policyHolderSameAsInsured,
                isPaymentMethodSelected,
                isLithuanian,
                isEstonian,
                informationText,
                coveredPopup,
                setupForm,
                requiresBirthDate,
                onFormSubmit,
                onSameAsInsuredClick,
                onIsResidentClick,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        this.dynamicStepper.enableAll();
        this.userStorage.onBeforeFormStorageDataIsRestored.pipe(take(1)).subscribe((): void => {
            this.onBeforeFormRestore();
        });
        this.onAppIsPreparedAndReady.pipe(take(1)).subscribe((): void => {
            this.onAppReady();
            this.onBeforeFormRestore();
        });
    },
});
</script>
