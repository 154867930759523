import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import FetchParams from '@/Components/Maps/MapWithPlaces/FetchParams';
import MapPlace from '@/Interfaces/map.place.interface';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { AxiosResponse } from 'axios';
import { useAxios } from '@/Composables/Axios';

export default class RepairShopService {
    private static instance: RepairShopService;

    public static getInstance(): RepairShopService {
        if (!RepairShopService.instance) {
            RepairShopService.instance = new RepairShopService();
        }

        return RepairShopService.instance;
    }

    public fetchPlaces(places: MapPlace[], fetchParams: FetchParams, fetchUrl?: string): Promise<void> {
        const url: string = fetchUrl === '' ? Url.Ajax.repairServices : (fetchUrl ?? '');
        const params: Record<string, DynamicDictionary> = {
            fetchParams: fetchParams,
        };

        return useAxios()
            .get(url, { params })
            .then((value: AxiosResponse): void => {
                value.data.data.body.places.forEach((place: MapPlace): void => {
                    if (this.hasAnyFetchParamSet(fetchParams)) {
                        places.push(place as MapPlace);
                    }
                });
            })
            .catch((reason: DynamicDictionary): void => {
                Error.log(ErrorType.Error, this.fetchPlaces.name, reason);
            });
    }

    private hasAnyFetchParamSet(params: FetchParams): boolean {
        return Object.values(params).some((value): boolean => value !== undefined);
    }
}
