import BreakPoints from '@/Enums/BreakPointsEnum';
import CssClass from '@/Enums/CssClassEnum';
import VueEvent from '@/Classes/VueEventClass';
import OneBaseService from '@/Services/OneBaseService';
import OneBase from '@/Interfaces/OneBaseInterface';
import { useDefine } from '@/Composables/Define';

export default class MegaMenu {
    private isVisible: boolean = false;
    private openedMenuId: number = 0;
    private headerPhone: string = '';
    private headerAccount: string = '';
    private btaBase!: OneBase;

    public init(): void {
        this.btaBase = OneBaseService.getInstance();
        this.getHeaderPhoneElements();
    }

    public onOpenedMenuIdChange() {
        const easeSpeed: number = 400;
        const wrappersToClose: JQuery = $('.toggle-wrapper-device');
        wrappersToClose.stop(true, false).slideUp(easeSpeed, () => {
            const wrapperToOpen: JQuery = $('.toggle-wrapper-device[data-id="' + this.openedMenuId + '"]');
            wrapperToOpen.stop(true, false).slideDown();
        });
    }

    public get menuIsVisible(): boolean {
        return this.isVisible;
    }

    public get burgerIsVisible(): boolean {
        return !this.menuIsVisible;
    }

    public get burgerCloseIsVisible(): boolean {
        return this.menuIsVisible && this.btaBase.isLessThan(BreakPoints.Lp);
    }

    public get deviceMenuIsVisible(): boolean {
        const width: number = this.windowWidth();
        return this.menuIsVisible && width > 0 && this.btaBase.isLessThan(BreakPoints.Lg);
    }

    public get desktopMenuIsVisible(): boolean {
        return this.menuIsVisible && !this.btaBase.isLessThan(BreakPoints.Lp) && this.openedMenuId !== 0;
    }

    public isLessThan(breakPoint: number): boolean {
        return this.btaBase.windowWidth.value < breakPoint;
    }

    public checkHashAndScrollToAlias(event: VueEvent): void {
        const anchorHash: string = event.sender.prop('hash');
        if (anchorHash && String(anchorHash).length > 1) {
            event.event.preventDefault();
            const alias: string = String(anchorHash).substr(1);
            history.pushState(null, '', location.pathname + '#' + alias);
            this.btaBase.scrollToAliasByName(alias);
        }
    }

    public menuItemIsVisible(id: number): boolean {
        return this.menuIsVisible && this.openedMenuId === id;
    }

    public anchorExternalUrl(url: string): string {
        let result: string = '';
        const pattern: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
        const trimmedUrl: string = url.trim();
        if (pattern.test(trimmedUrl)) {
            result = trimmedUrl.includes('http:') || trimmedUrl.includes('https:') ? trimmedUrl : '//' + trimmedUrl;
        } else {
            result = 'javascript:app.error.log("Error", "Invalid url", "' + trimmedUrl + '");';
        }

        return result;
    }

    public menuToggleClick(event: VueEvent): void {
        const id: number = event.params['id'];
        if (this.openedMenuId === id) {
            this.openedMenuId = 0;
        } else {
            this.openedMenuId = id;
        }
        this.onOpenedMenuIdChange();
    }

    public menuOpenClick(event: VueEvent): void {
        this.openedMenuId = event.params['id'];
        this.isVisible = true;
        this.onOpenedMenuIdChange();
        this.btaBase.changeBodyVerticalScrollState();
        this.hidePhoneProfileIcons();
        if (this.megaMenuItem(event) && this.mainMenuItem(event)) {
            this.closeMenu();
        }
    }

    public menuCloseClick(event: VueEvent): void {
        this.closeMenu();
    }

    public closeMenu(): void {
        if (this.openedMenuId !== 0) {
            this.openedMenuId = 0;
            this.isVisible = false;
            this.onOpenedMenuIdChange();
            this.btaBase.changeBodyVerticalScrollState();
            this.showPhoneProfileIcons();
        }
    }

    public burgerClick(event: VueEvent): void {
        this.isVisible = event.params['open'];
        if (!this.menuIsVisible) {
            this.openedMenuId = 0;
            this.onOpenedMenuIdChange();
            this.showPhoneProfileIcons();
        } else {
            this.hidePhoneProfileIcons();
        }
        this.btaBase.changeBodyVerticalScrollState();
    }

    public menuButtonClick(event: VueEvent): void {
        const targetUrl: string = event.params['url'];
        const currentUrl: string = window.location.href;
        if (currentUrl.includes(targetUrl) && useDefine().endsWith(currentUrl, targetUrl)) {
            window.location.reload();
        } else {
            window.location.href = '/' + this.btaBase.language + '/' + targetUrl;
        }
    }

    public menuSeparatorClick(event: VueEvent): void {
        const id: number = event.params['id'];
        const buttonHeight: number = 95;
        $('.separator-button').removeClass('active');
        $('.separator-content').removeClass('active');
        $('.separator-button[data-id="' + id + '"]').addClass('active');
        $('.separator-content[data-id="' + id + '"]').addClass('active');
        const wrapperToChange: JQuery = $('.toggle-wrapper-device[data-id="' + this.openedMenuId + '"]');
        const newHeight: number | undefined = $('.items-list[data-id="' + id + '"]').height();
        wrapperToChange.height(newHeight! + buttonHeight);
    }

    private getHeaderPhoneElements(): void {
        this.headerPhone = '.header-phone-icon';
        this.headerAccount = '#header-container-account';
    }

    private hidePhoneProfileIcons(): void {
        $(this.headerPhone).addClass(CssClass.Hidden);
        $(this.headerAccount).addClass(CssClass.Hidden);
    }

    private showPhoneProfileIcons(): void {
        $(this.headerPhone).removeClass(CssClass.Hidden);
        $(this.headerAccount).removeClass(CssClass.Hidden);
    }

    private mainMenuItem(event: VueEvent): boolean {
        return event.sender.hasClass('line');
    }

    private megaMenuItem(event: VueEvent): boolean {
        return !event.destinationSender[0].hasAttribute('data-id');
    }

    private windowWidth(): number {
        const widthElementDom: number | undefined = $(window).width();
        if (!widthElementDom || typeof widthElementDom === 'undefined') {
            return 0;
        }

        return widthElementDom;
    }
}
