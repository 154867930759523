import moment from 'moment';
import DateFormat from '@/Enums/DateFormatEnum';
import PolicySchedule from '@/Interfaces/policy.schedule.interface';
import UserPolicy from '@/Interfaces/user.policy.interface';
import UserPolicyCoverage from '@/Interfaces/user.policy.coverage.interface';

export default class AgreementPayment {
    private readonly localAgreement: UserPolicy;
    private readonly localSchedule: PolicySchedule;
    private localCoverages: UserPolicyCoverage[] = [];

    public constructor(agreement: UserPolicy, schedule: PolicySchedule) {
        this.localAgreement = agreement;
        this.localSchedule = schedule;
    }

    public addCoverages(coverages: UserPolicyCoverage[]): void {
        this.localCoverages = this.localCoverages.concat(coverages);
        this.localCoverages.sort((a: UserPolicyCoverage, b: UserPolicyCoverage) => {
            return this.stringAsDate(b.date).getTime() - this.stringAsDate(a.date).getTime();
        });
    }

    public addCoverage(coverage: UserPolicyCoverage): void {
        this.localCoverages.push(coverage);
        this.localCoverages.sort((a: UserPolicyCoverage, b: UserPolicyCoverage) => {
            return this.stringAsDate(b.date).getTime() - this.stringAsDate(a.date).getTime();
        });
    }

    public scheduleId(): string {
        return this.localSchedule.payment_schedule_id;
    }

    public scheduleNumber(): string {
        return this.localSchedule.payment_schedule_num;
    }

    public isPaid(): boolean {
        return this.localSchedule.payment_schedule_status === 'paid';
    }

    public isPartlyPaid(): boolean {
        return !this.isPaid() && parseFloat(this.localSchedule.payment_schedule_amount_paid) > 0;
    }

    public isInProgress(): boolean {
        return this.localSchedule.payment_schedule_status === 'in_progress';
    }

    public isLate(): boolean {
        return !this.isPaid() && !this.isInProgress() && this.term().getTime() < new Date().getTime();
    }

    public term(): Date {
        return this.stringAsDate(this.localSchedule.payment_schedule_term);
    }

    public paidDate(): Date | null {
        return this.localCoverages.length > 0 ? this.stringAsDate(this.localCoverages[0].date) : null;
    }

    public isCovered(): boolean {
        return this.coveredSum() >= this.totalSum();
    }

    public agreement(): UserPolicy {
        return this.localAgreement;
    }

    public hasAgreement(): boolean {
        return Object.keys(this.agreement()).length > 0;
    }

    public totalSum(): number {
        return parseFloat(this.localSchedule.payment_schedule_amount);
    }

    public coveredSum(): number {
        return this.localCoverages.reduce(
            (totalSum: number, coverage: UserPolicyCoverage) => totalSum + coverage.amount,
            0,
        );
    }

    public currency(): 'EUR' {
        return 'EUR';
    }

    private stringAsDate(dateString: string): Date {
        return moment(dateString, DateFormat.Default.Short).toDate();
    }
}
