import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

export default class ChatService {
    public addBitrixChat(): void {
        const timeDivider: number = 60000;
        const bitrixUrl: string = 'https://bitrix.bta.lv/upload/crm/site_button/';
        const urls: string[] = ((window as DynamicDictionary)['chat_url'] as string[]) || [];
        urls.forEach((url) => {
            const scriptElement: HTMLScriptElement = document.createElement('script');
            scriptElement.async = true;
            scriptElement.setAttribute('src', bitrixUrl + url + '?' + ((Date.now() / timeDivider) | 0));
            document.head.appendChild(scriptElement);
        });
    }
}
