<script setup lang="ts">
import { computed, ComputedRef, PropType } from 'vue';
import AppSpinner from '@/Components/Loaders/Spinner/Spinner.vue';
import { useUrl } from '@/Composables/Url';
import { Color, Fill } from '@/Components/Buttons/Button/ButtonEnums';
import SharedProps from '@/Components/Buttons/Button/SharedProps';

const props = defineProps({
    color: { type: String as PropType<Color>, default: Color.Primary },
    fill: { type: String as PropType<Fill>, default: Fill.Solid },
    ...SharedProps,
});

const emit = defineEmits(['click']);

const { isExternal } = useUrl();

const isLink: ComputedRef<boolean> = computed((): boolean => Boolean(props.href));
const isButton: ComputedRef<boolean> = computed((): boolean => !isLink.value);
const target: ComputedRef<string | null> = computed((): string | null =>
    props.href && isExternal(props.href, window.location.href) ? '_blank' : null,
);

const classes: ComputedRef<string> = computed((): string =>
    Object.entries({
        color: props.color,
        fill: props.fill,
        size: props.size,
        expand: props.expand ? 'full' : 'block',
    })
        .map(([key, value]) => `${key}-${value}`)
        .concat(props.loading ? ['loading'] : [])
        .join(' '),
);

function onClick(event: MouseEvent): void {
    if (!props.disabled && !props.loading) {
        emit('click', event);
    }
}
</script>

<template>
    <component
        :is="isLink ? 'a' : 'button'"
        :href="isLink ? href : null"
        :target="isLink ? target : null"
        class="one-button"
        :class="classes"
        :disabled="isButton ? disabled : null"
        @click="onClick"
    >
        <app-spinner v-if="loading" class="loader"></app-spinner>
        <div v-if="$slots.start" class="start-slot">
            <slot name="start"></slot>
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <div v-if="$slots.end" class="end-slot">
            <slot name="end"></slot>
        </div>
    </component>
</template>
