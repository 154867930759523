<script lang="ts">
import { computed, defineComponent, reactive, ref, Ref, nextTick, UnwrapNestedRefs } from 'vue';
import TravelDataLayer from '@/Pages/Travel/TravelDataLayer';
import ExtractDataService from '@/Services/extract.data.service';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import TravellerValidators from '@/Pages/Travel/Traveller/TravellerValidators';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Form from '@/Assets/Libraries/Form/Form';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { TranslateParams, useTranslate } from '@/Composables/Translate';
import moment from 'moment/moment';
import DateFormat from '@/Enums/DateFormatEnum';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import { StepsSubmitterParams, useStepsSubmitter } from '@/Composables/StepsSubmitter';
import FormField from '@/Assets/Libraries/Form/FormField';
import Sanitizer from '@/Services/sanitizer.service';
import TravellerSanitizers from '@/Pages/Travel/Traveller/TravellerSanitizers';
import Traveler from '@/Interfaces/traveler.interface';
import CssClass from '@/Enums/CssClassEnum';
import { DefineParams, useDefine } from '@/Composables/Define';
import OneBase from '@/Interfaces/OneBaseInterface';
import OneBaseService from '@/Services/OneBaseService';
import { useDate } from '@/Composables/Date';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useCore } from '@/Composables/Core';
import { useUrl } from '@/Composables/Url';
import { Subscription } from 'rxjs';
import UserStorage from '@/Services/user.storage.service';
import TravelSteps from '@/Pages/Travel/Classes/TravelSteps';
import Validation from '@/Services/validation.service';
import SettingsService from '@/Services/settings.service';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();
        const { translate }: TranslateParams = useTranslate();
        const { isSet }: DefineParams = useDefine();
        const extractDataService: ExtractDataService = new ExtractDataService();
        const stepsSubmitter: StepsSubmitterParams = useStepsSubmitter();
        const CurrentStep: number = 4;
        const validators: TravellerValidators = new TravellerValidators();
        const dataLayer: TravelDataLayer = new TravelDataLayer();
        const appCountry: AppCountry = new AppCountry();
        const travelers: Ref<DynamicDictionary[]> = ref([]);
        const mustCheckAges: Ref<boolean> = ref(false);

        const travelerData: UnwrapNestedRefs<DynamicDictionary> = reactive({});
        const ageGroupsFromForm: Ref<DynamicDictionary[]> = ref([]);
        const travelersFromStepsStorage: Ref<DynamicDictionary[]> = ref([]);
        const form: Form = new Form();
        const needToRecalculatePrice: Ref<boolean> = ref(false);
        const canBeInsuredTogether: Ref<boolean> = ref(false);
        const userAgeIsWithinRange: Ref<boolean> = ref(false);
        const showLoginPage: Ref<boolean> = ref(false);
        const ageGroupCountExceedsLimit: Ref<boolean> = ref(false);
        const travelersTotalCount: Ref<number> = ref(0);
        const ehicTotalCount: Ref<number> = ref(0);
        const travelDestination: Ref<string> = ref('');
        const policyPlanId: Ref<string> = ref('');
        const ageRange: Ref<string> = ref('');

        const hasInvalidAges: Ref<boolean> = computed((): boolean => {
            return travelers.value.filter((traveler: DynamicDictionary) => traveler.ageLimitExceeded).length > 0;
        });

        const hasInvalidCountries: Ref<boolean> = computed((): boolean => {
            let result: boolean = false;
            for (let fieldIndex: number = 0; fieldIndex < travelersTotalCount.value; fieldIndex++) {
                const countryIso: string = form.field('travelerCountry' + fieldIndex).value.iso ?? '';
                if (!form.field('isResident' + fieldIndex).value && countryIso === '') {
                    result = true;
                }
            }

            return result;
        });

        function fillUserFields(): void {
            form.field('isResident0').patch(true);
            updateResident(0);
            form.field('isAuthenticated0').value ? patchUserFields() : patchEmptyFields();
            mustCheckAges.value = false;
        }

        function updateResident(fieldIndex: number): void {
            const isAuthenticated: boolean = form.field('isAuthenticated0').value;
            const isResident: boolean =
                isAuthenticated && fieldIndex === 0 ? true : form.field('isResident' + fieldIndex).value;
            const countryIso: string = form.field('travelerCountry' + fieldIndex).value.iso;
            if (isResident) {
                form.field('travelerBirthDate' + fieldIndex)
                    .clear()
                    .then();
                form.field('travelerCountry' + fieldIndex).value = defaultCountry();
                travelerData[fieldIndex] = {
                    isResidentForOneCountries: true,
                    isRequiredBirthDate: false,
                };
            } else {
                if (countryIso === defaultCountry().iso) {
                    form.field('travelerCountry' + fieldIndex).clear();
                }
                travelerData[fieldIndex] = {
                    isResidentForOneCountries: false,
                    isRequiredBirthDate: true,
                };
            }
            form.field('travelerBirthDate' + fieldIndex).markAsUntouched();
            mustCheckAges.value = true;
            updateTraveler(fieldIndex);
        }

        function applyTravelerParams(fieldIndex: number, value: DynamicDictionary): void {
            if (travelers.value[fieldIndex]) {
                Object.keys(value).forEach((key: string): void => {
                    travelers.value[fieldIndex][key] = value[key];
                });
            }
        }

        function onBirthDateChange(fieldIndex: number): void {
            updateAgeLimitRequirement(fieldIndex).then();
        }

        function onPersonalCodeChange(fieldIndex: number): void {
            updateAgeLimitRequirement(fieldIndex).then();
        }

        function updateTraveler(fieldIndex: number): void {
            updateBirthDateRequirement(fieldIndex).then();
            mustCheckAges.value = false;
        }

        function updateAgeLimitRequirement(fieldIndex: number): Promise<void> {
            return new Promise((resolve) => {
                applyTravelerParams(fieldIndex, {
                    ageLimitExceeded: ageLimitExceeded(fieldIndex),
                });
                resolve();
            });
        }

        function updateBirthDateRequirement(fieldIndex: number): Promise<void> {
            return new Promise((resolve) => {
                if (fieldIndex !== 0 || (fieldIndex === 0 && !form.field('isAuthenticated' + fieldIndex).value)) {
                    let requiredBirthDate: boolean = true;
                    const isResidentForOneCountries: boolean = appCountry.isResidentForOneCountries(
                        form.field('travelerCountry' + fieldIndex).value.iso,
                    );
                    if (isResidentForOneCountries) {
                        requiredBirthDate = validators.isValidPersonCodeWithoutDate(
                            form.field('travelerIdentityNumber' + fieldIndex).value,
                            form.field('travelerCountry' + fieldIndex).value.iso,
                        );
                    }
                    applyTravelerParams(fieldIndex, {
                        isRequiredBirthDate: requiredBirthDate,
                    });
                }
                resolve();
            });
        }

        function isRequiredBirthDate(fieldIndex: number): boolean {
            return travelers.value[fieldIndex].isRequiredBirthDate;
        }

        function validateBeforeSubmit(): void {
            mustCheckAges.value = true;
            validateAgeGroups();
            checkMaxAgeMessage();
            form.submitAttempt().then((): void => {
                if (travelDestination.value === 'LATVIA' && residentsFromForm() > 0) {
                    btaBase.error.show(
                        ErrorType.Error,
                        btaBase.facility(),
                        translate('one_travel_cannot_choose_territory'),
                    );
                } else if (!canBeInsuredTogether.value) {
                    btaBase.error.show(ErrorType.Error, btaBase.facility(), translate('cant_be_insured_together'));
                } else if (ageGroupCountExceedsLimit.value) {
                    btaBase.error.show(
                        ErrorType.Error,
                        btaBase.facility(),
                        translate('one_travel_age_group_limit_exceeded'),
                    );
                } else if (!hasInvalidAges.value && form.isValid()) {
                    dataLayer.pushDataLayer(policyPlanId.value);
                    nextTick(() => {
                        prepareAndSubmit();
                    });
                }
            });
        }

        function applyTravelersCount(count: string): void {
            travelersTotalCount.value = Number(count);
        }

        function applyEhicCount(count: string): void {
            ehicTotalCount.value = Number(count);
        }

        function applyShowLoginPage(newShowLoginPage: string): void {
            newShowLoginPage === 'true' ? (showLoginPage.value = true) : (showLoginPage.value = false);
        }

        function ageLimitExceeded(fieldIndex: number): boolean {
            let result: boolean = false;
            const birthDate: string = travelerBirthDate(fieldIndex);
            if (birthDate !== '' && moment(birthDate).isValid()) {
                const age: number = useDate().age(birthDate);
                result = age > maxAge();
            }

            return result;
        }

        function resetOutOfAgeLimits(): void {
            travelers.value.forEach((traveler: DynamicDictionary): void => {
                traveler.ageLimitExceeded = false;
            });
        }

        function maxAge(): number {
            const defaultMaxAge: number = 75;

            return (
                travelersFromStepsStorage.value
                    .filter((ageGroup: DynamicDictionary): boolean => !(ageGroup.isRestricted ?? false))
                    .toSorted(
                        (ageGroup1: DynamicDictionary, ageGroup2: DynamicDictionary): number =>
                            ageGroup1.maxAge - ageGroup2.maxAge,
                    )
                    .at(-1)?.maxAge ?? defaultMaxAge
            );
        }

        function maxAgeMessage(): string {
            return translate('insure_older_person', { '%max_age%': maxAge() });
        }

        function travelerBirthDate(fieldIndex: number): string {
            const formField: FormField = form.field('travelerBirthDate' + fieldIndex);
            const identyNumber: string = form.field('travelerIdentityNumber' + fieldIndex).value;
            const countryIso: string = form.field('travelerCountry' + fieldIndex).value.iso;
            let result: string = '';
            if (isRequiredBirthDate(fieldIndex)) {
                result = extractDataService.dateWithCustomFormat(formField.value);
            } else {
                if (form.field('isAuthenticated0').value && fieldIndex === 0) {
                    result = extractDataService.dateWithCustomFormat(
                        moment(btaBase.user.current.birthDate, DateFormat.Default.Short).toDate(),
                    );
                } else {
                    const extractedBirthDate: Date | undefined = extractDataService.birthDateFromPersonCode(
                        identyNumber,
                        countryIso,
                    );
                    if (extractedBirthDate) {
                        result = extractDataService.dateWithCustomFormat(extractedBirthDate);
                    }
                }
            }

            return result;
        }

        function assembledTravelers(): string {
            const result: DynamicDictionary[] = [];
            for (let fieldIndex: number = 0; fieldIndex < travelersTotalCount.value; fieldIndex++) {
                const iso: string =
                    form.field('travelerCountry' + fieldIndex).value.iso || SettingsService.getInstance().localeIso();
                const phoneCode: string =
                    form.field('travelerCountry' + fieldIndex).value.phoneCode ||
                    SettingsService.getInstance().phoneCode();
                result.push({
                    firstName: String(form.field('travelerFirstName' + fieldIndex).value).trim(),
                    lastName: String(form.field('travelerLastName' + fieldIndex).value).trim(),
                    personCode: form.field('travelerIdentityNumber' + fieldIndex).value,
                    ehicCardNo: form.field('ehicCardNumber' + fieldIndex).value,
                    isResident: form.field('isResident' + fieldIndex).value,
                    birthDate: travelerBirthDate(fieldIndex),
                    address: {
                        countryCodeIso3: form.field('travelerCountry' + fieldIndex).value.ic,
                        countryCodeIso: iso,
                        phoneCode: phoneCode,
                    },
                });
            }

            return JSON.stringify(result);
        }

        function updatedStorage(): DynamicDictionary {
            const stepsStorage: DynamicDictionary = btaBase.userStorage.stepStorageData;
            ageGroupsFromForm.value.forEach((group: Record<string, number>): void => {
                group.count = group.countInForm;
                group.ehicCount = group.ehicCountInForm;
                delete group.countInForm;
                delete group.ehicCountInForm;
            });
            stepsStorage.travelers = JSON.stringify(ageGroupsFromForm.value);

            return stepsStorage;
        }

        function prepareAndSubmit(): void {
            if (!needToRecalculatePrice.value) {
                stepsSubmitter.addSubmitParam('facility', btaBase.facility(), false);
                stepsSubmitter.addSubmitParam('nextStep', btaBase.nextStep(), false);
                stepsSubmitter.addSubmitCustomParam('ageRange', ageRange.value);
                stepsSubmitter.addSubmitCustomParam('travelersFromForm', assembledTravelers());
                stepsSubmitter.addSubmitCustomParam('travelerRecalculation', false);
                stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
                stepsSubmitter.proceedStep('', 0);
            } else {
                const currentUpdatedStorage: DynamicDictionary = updatedStorage();
                if (isSet(currentUpdatedStorage.travelTarget)) {
                    stepsSubmitter.addSubmitParam('travelTarget', currentUpdatedStorage.travelTarget, true);
                }
                if (isSet(currentUpdatedStorage.allTripsDuration)) {
                    stepsSubmitter.addSubmitParam('allTripsDuration', currentUpdatedStorage.allTripsDuration, true);
                }
                stepsSubmitter.addSubmitParam('facility', btaBase.facility(), false);
                stepsSubmitter.addSubmitParam('nextStep', btaBase.nextStep(), false);
                stepsSubmitter.addSubmitParam('policyPlanId', currentUpdatedStorage.policyPlanId, true);
                stepsSubmitter.addSubmitParam('travelStartDate', currentUpdatedStorage.travelStartDate, true);
                stepsSubmitter.addSubmitParam('travelers', currentUpdatedStorage.travelers, true);
                stepsSubmitter.addSubmitParam('multipleTrips', currentUpdatedStorage.multipleTrips, true);
                stepsSubmitter.addSubmitParam('isSingleTrip', currentUpdatedStorage.isSingleTrip, true);
                stepsSubmitter.addSubmitParam('activityType', currentUpdatedStorage.activityType, true);
                stepsSubmitter.addSubmitParam('activityName', currentUpdatedStorage.activityName, true);
                stepsSubmitter.addSubmitParam('activityStartDate', currentUpdatedStorage.activityStartDate, true);
                stepsSubmitter.addSubmitParam('activityEndDate', currentUpdatedStorage.activityEndDate, true);
                stepsSubmitter.addSubmitParam('tripDuration', currentUpdatedStorage.tripDuration, true);
                stepsSubmitter.addSubmitParam('travelEndDate', currentUpdatedStorage.travelEndDate, true);
                stepsSubmitter.addSubmitCustomParam('ageRange', ageRange.value);
                stepsSubmitter.addSubmitCustomParam('customSums', btaBase.userStorage.stepStorageData.customSums);
                stepsSubmitter.addSubmitCustomParam('travelersFromForm', assembledTravelers());
                stepsSubmitter.addSubmitCustomParam('travelerRecalculation', true);
                stepsSubmitter.addAjaxResponseCallbackBeforeStepsStorage(applyPolicyPrices);
                stepsSubmitter.addSubmitParam('destination', currentUpdatedStorage.destination, true);
                stepsSubmitter.addSubmitCustomParam(
                    'risks',
                    JSON.stringify(btaBase.userStorage.stepStorageData.additionalOptions),
                );
                stepsSubmitter.addSubmitCustomParam('product', currentUpdatedStorage.policyPlanId);
                if (new AppCountry().isLT()) {
                    stepsSubmitter.addSubmitParam('destinationType', currentUpdatedStorage.destinationType, true);
                    stepsSubmitter.addSubmitParam(
                        'territoryOfOperation',
                        currentUpdatedStorage.territoryOfOperation,
                        true,
                    );
                }
                stepsSubmitter.submitMethod(Method.Post);
                stepsSubmitter.submitStep(Url.Ajax.travelTraveller);
            }
        }

        function applyPolicyPrices(value: DynamicDictionary): void {
            const policyPlansFromAjaxResponse: DynamicDictionary = value.body.data;
            let policyPriceForUser: number = 0;
            let policyPriceForGuest: number = 0;
            policyPlansFromAjaxResponse.forEach(
                (policyPlan: { id: string; authenticatedPrice: number; guestPrice: number }) => {
                    if (policyPlan.id === policyPlanId.value) {
                        policyPriceForUser = policyPlan.authenticatedPrice;
                        policyPriceForGuest = policyPlan.guestPrice;
                    }
                },
            );
            stepsSubmitter.addSubmitCustomParam(
                'policyPrices',
                JSON.stringify({
                    priceAuthenticated: policyPriceForUser,
                    priceGuest: policyPriceForGuest,
                }),
            );
        }

        function setupForm(): void {
            for (let fieldIndex: number = 0; fieldIndex < travelersTotalCount.value; fieldIndex++) {
                form.addField(new FormField('isAuthenticated' + fieldIndex, false));
                form.addField(new FormField('isResident' + fieldIndex, true));
                form.addField(new FormField('travelerBirthDate' + fieldIndex, ''));
                form.addField(new FormField('travelerCountry' + fieldIndex, defaultCountry()));
                form.addField(new FormField('travelerFirstName' + fieldIndex, '', '', Sanitizer.cleanName));
                form.addField(new FormField('travelerLastName' + fieldIndex, '', '', Sanitizer.cleanName));
                form.addField(
                    new FormField('travelerIdentityNumber' + fieldIndex, '', { required: Validation.required }),
                );
                if (ehicTotalCount.value !== 0) {
                    form.addField(
                        new FormField('ehicCardNumber' + fieldIndex, '', '', TravellerSanitizers.cleanEhicNumber),
                    );
                }
                travelerData[fieldIndex] = [];
                travelerData[fieldIndex].push({
                    isResidentForOneCountries: false,
                    isRequiredBirthDate: false,
                });
            }
            form.setReady();
        }

        function applyValidators(): void {
            for (let fieldIndex: number = 0; fieldIndex < travelersTotalCount.value; fieldIndex++) {
                form.field('travelerBirthDate' + fieldIndex).addValidators(
                    validators.birthDateValidator('travelerBirthDate' + fieldIndex, fieldIndex),
                );
                form.field('travelerCountry' + fieldIndex).addValidators(
                    validators.countryValidator('travelerCountry' + fieldIndex, 'isResident' + fieldIndex),
                );
                form.field('travelerFirstName' + fieldIndex).addValidators('required');
                form.field('travelerLastName' + fieldIndex).addValidators('required');
                if (ehicTotalCount.value !== 0) {
                    form.field('ehicCardNumber' + fieldIndex).addValidators(
                        validators.ehicNumberValidator('ehicCardNumber' + fieldIndex),
                    );
                }
            }
        }

        function resetFormValidators(): void {
            resetOutOfAgeLimits();
            mustCheckAges.value = false;
            nextTick((): void => {
                useCore().forceUpdate();
                form.validate().then();
            });
        }

        function init(): void {
            setupForm();
            applyValidators();
            applyTravelers();
            travelersFromStepsStorage.value = JSON.parse(btaBase.userStorage.stepStorageData.travelers);
            applyTravelDestination();
            applyPolicyPlanId();
            applyUserAgeGroup();
            for (let fieldIndex: number = 0; fieldIndex < travelersTotalCount.value; fieldIndex++) {
                if (!form.field('travelerIdentityNumber' + fieldIndex).isEmpty()) {
                    form.field('travelerIdentityNumber' + fieldIndex).touch();
                }
            }
        }

        function checkMaxAgeMessage(): void {
            travelers.value.forEach((traveler: DynamicDictionary, index: number): void => {
                updateAgeLimitRequirement(index).then();
            });
        }

        function applyTravelDestination(): void {
            travelDestination.value = btaBase.userStorage.stepStorageData.destination;
        }

        function applyPolicyPlanId(): void {
            policyPlanId.value = btaBase.userStorage.stepStorageData.policyPlanId;
        }

        function validateAgeGroups(): void {
            applyTravelerAgeGroups();
            applyAgeGroupParamsFromForm();
            resetAgeGroupChecks();
            checkAgeGroupParams();
        }

        function applyTravelerAgeGroups(): void {
            ageGroupsFromForm.value = [];
            travelersFromStepsStorage.value.forEach((traveler: DynamicDictionary) => {
                ageGroupsFromForm.value.push({
                    ic: traveler.ic,
                    minAge: traveler.minAge,
                    maxAge: traveler.maxAge,
                    count: traveler.count,
                    maxCount: traveler.maxCount,
                    ehicCount: traveler.ehicCount,
                    countInForm: 0,
                    ehicCountInForm: 0,
                });
            });
        }

        function applyAgeGroupParamsFromForm(): void {
            travelersWithAgeAndEhic().forEach((traveler: Traveler) => {
                ageGroupsFromForm.value.forEach((group: DynamicDictionary) => {
                    if (traveler.age >= group.minAge && traveler.age <= group.maxAge) {
                        group.countInForm++;
                        if (traveler.ehicCardNumber !== '') {
                            group.ehicCountInForm++;
                        }
                    }
                });
            });
        }

        function resetAgeGroupChecks(): void {
            needToRecalculatePrice.value = false;
            canBeInsuredTogether.value = true;
            ageGroupCountExceedsLimit.value = false;
        }

        function checkAgeGroupParams(): void {
            ageGroupsFromForm.value.forEach((group: Record<string, number>) => {
                if (group.count !== group.countInForm || group.ehicCount !== group.ehicCountInForm) {
                    needToRecalculatePrice.value = true;
                }
                if (group.countInForm > group.maxCount) {
                    ageGroupCountExceedsLimit.value = true;
                }
            });
            if (!new AppCountry().isLT()) {
                const ageGroupsFromFormCopy: DynamicDictionary[] = JSON.parse(JSON.stringify(ageGroupsFromForm.value));
                const insuredSeparatelyGroup: DynamicDictionary = ageGroupsFromFormCopy.pop() as DynamicDictionary;
                const insuredSeparately: number = insuredSeparatelyGroup.countInForm;
                const insuredTogether: number = ageGroupsFromFormCopy.reduce(
                    (insuredTogetherCount: number, ageGroup: DynamicDictionary) =>
                        insuredTogetherCount + ageGroup.countInForm,
                    0,
                );
                if (insuredSeparately > 0 && insuredTogether > 0) {
                    canBeInsuredTogether.value = false;
                }
                if (insuredSeparately > 0) {
                    ageRange.value = insuredSeparatelyGroup.ic;
                } else {
                    ageRange.value = ageGroupsFromFormCopy[0].ic;
                }
            } else {
                ageRange.value = ageGroupsFromForm.value[0].ic;
            }
        }

        function travelersWithAgeAndEhic(): Traveler[] {
            const travelersInForm: Traveler[] = [];
            for (let fieldIndex: number = 0; fieldIndex < travelersTotalCount.value; fieldIndex++) {
                const traveler: Traveler = { age: 0, ehicCardNumber: '' };
                traveler.age = isRequiredBirthDate(fieldIndex)
                    ? moment().diff(form.field('travelerBirthDate' + fieldIndex).value, 'years')
                    : moment().diff(moment(travelerBirthDate(fieldIndex)).toDate(), 'years');
                traveler.ehicCardNumber = form.field('ehicCardNumber' + fieldIndex).value;
                travelersInForm.push(traveler);
            }

            return travelersInForm;
        }

        function userNotFilled(): boolean {
            return (
                form.field('travelerFirstName0').isEmpty() &&
                form.field('travelerLastName0').isEmpty() &&
                form.field('travelerIdentityNumber0').isEmpty() &&
                form.field('travelerBirthDate0').isEmpty()
            );
        }

        function patchUserFields(): void {
            form.field('travelerFirstName0').patch(btaBase.user.current.firstname);
            form.field('travelerLastName0').patch(btaBase.user.current.lastname);
            form.field('travelerIdentityNumber0').patch(btaBase.user.current.personCode);
            form.field('travelerBirthDate0').patch(
                moment(btaBase.user.current.birthDate, DateFormat.Default.Short).toDate(),
            );
        }

        function patchEmptyFields(): void {
            form.field('travelerFirstName0').patch('');
            form.field('travelerFirstName0').markAsUntouched();
            form.field('travelerLastName0').patch('');
            form.field('travelerLastName0').markAsUntouched();
            form.field('travelerIdentityNumber0').patch('');
            form.field('travelerIdentityNumber0').markAsUntouched();
            form.field('travelerBirthDate0').patch('');
        }

        function residentsFromForm(): number {
            const residentFieldPattern: string = 'isResident';
            const countryFieldPattern: string = 'travelerCountry';
            let allResidents: number = 0;
            form.fields().forEach((field: FormField) => {
                if (field.name.includes(residentFieldPattern) && field.value) {
                    allResidents++;
                }
                if (field.name.includes(countryFieldPattern) && !field.isEmpty()) {
                    if (field.value.iso === appCountry.iso()) {
                        allResidents++;
                    }
                }
            });

            return allResidents;
        }

        function offerToLogin(): void {
            if (
                !btaBase.user.isLogged() &&
                !btaBase.user.isGuestOnly &&
                !userHasSelectGuestMode() &&
                showLoginPage.value
            ) {
                PopupService.getInstance().show(new OnePopup().withType().login.withoutCloseButton());
                nextTick(() => {
                    guestButtonVisibility();
                });
            }
        }

        function guestButtonVisibility(): void {
            $('.continue-as-guest-button').removeClass(CssClass.Hidden);
        }

        function userHasSelectGuestMode(): boolean {
            return useUrl().guestModeEnabled();
        }

        function userAge(): number {
            return extractDataService.ageFromBirthDate(btaBase.user.current.birthDate);
        }

        function applyUserAgeGroup(): void {
            if (btaBase.user.isLogged()) {
                const age: number = userAge();
                travelersFromStepsStorage.value.forEach((traveler: Record<string, number>) => {
                    if (traveler.count > 0 && age >= traveler.minAge && age <= traveler.maxAge) {
                        userAgeIsWithinRange.value = true;
                    }
                });
            }
        }

        function applyTravelers(): void {
            for (let index: number = 0; index < travelersTotalCount.value; index++) {
                travelers.value.push({
                    isRequiredBirthDate: false,
                    ageLimitExceeded: false,
                });
            }
        }

        function onUserStorageReady(): void {
            init();
        }

        function onAfterFormRestored(): void {
            form.validate().then();
            if (!btaBase.iframe.isEnabled()) {
                offerToLogin();
            }
            if (btaBase.user.isLogged() && userNotFilled()) {
                if (userAgeIsWithinRange.value) {
                    form.field('isAuthenticated0').value = true;
                    form.field('isResident0').value = true;
                    patchUserFields();
                }
            }
            for (let index: number = 0; index < travelersTotalCount.value; index++) {
                updateTraveler(index);
                touchIfHasValue(index);
            }
        }

        function onAppReady(): void {
            if (hasAdditionalOptions()) {
                btaBase.dynamicStepper.applyEnabled(TravelSteps.additional());
            } else {
                btaBase.dynamicStepper.applyEnabled(TravelSteps.default());
            }
        }

        function touchIfHasValue(index: number): void {
            if (!form.field('travelerFirstName' + index).isEmpty()) {
                form.field('travelerFirstName' + index).touch();
            }
            if (!form.field('travelerLastName' + index).isEmpty()) {
                form.field('travelerLastName' + index).touch();
            }
            if (!form.field('travelerIdentityNumber' + index).isEmpty()) {
                form.field('travelerIdentityNumber' + index).touch();
            }
        }

        function additionalOptions(): DynamicDictionary[] | undefined {
            return UserStorage.getInstance().stepStorageData.additionalOptions;
        }

        function hasAdditionalOptions(): boolean {
            return !!additionalOptions();
        }

        function defaultCountry(): { iso: string } {
            return { iso: appCountry.iso() };
        }

        return {
            ...btaBase,
            ...{
                form,
                travelerData,
                fillUserFields,
                updateResident,
                updateTraveler,
                validateBeforeSubmit,
                applyTravelersCount,
                applyEhicCount,
                applyShowLoginPage,
                resetFormValidators,
                hasInvalidAges,
                hasInvalidCountries,
                travelers,
                ehicTotalCount,
                maxAgeMessage,
                maxAge,
                onBirthDateChange,
                onPersonalCodeChange,
                mustCheckAges,
                CurrentStep,
                validators,
                onUserStorageReady,
                onAfterFormRestored,
                onAppReady,
                translate,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility('one-travel');
        this.setStorageUsage(true);
        this.validators.init(this.form, this.travelers);
        const onStorageReadySubscription = this.userStorage.onStorageDataIsReady.subscribe(() => {
            this.onUserStorageReady();
            onStorageReadySubscription.unsubscribe();
        });
        const onAfterFormRestoredSubscription = this.userStorage.onFormStorageDataIsReady.subscribe(() => {
            this.onAfterFormRestored();
            onAfterFormRestoredSubscription.unsubscribe();
        });
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
