<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import { PropType, watch } from 'vue';
import { InputOption } from '@/Interfaces/InputOptionInterface';

type RadioTypes = 'button' | 'radio' | 'sparse' | 'badge' | 'digits' | 'clear' | 'with-circle';
type Size = 'small' | 'large';

const props = defineProps({
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    formField: { type: Object as PropType<FormField<string>>, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    options: { type: Array as PropType<InputOption[]>, default: () => [] },
    size: { type: String as PropType<Size>, default: 'large' },
    type: { type: String as PropType<RadioTypes>, default: 'button' },
    vertical: { type: Boolean, default: false },
    customBorderRadius: { type: Boolean, default: false },
    mobileIcon: { type: String, default: '' },
});

const emit = defineEmits(['change']);

watch(
    () => props.formField.value,
    (after: string, before: string) => {
        if (after !== before) {
            emitChange();
        }
    },
);

function iconSrc(iconName: string): string {
    return `images/one/components/input-radio/${iconName}.svg`;
}

function select(option: InputOption): void {
    props.formField.markAsTouched();
    if (option.value !== props.formField.value) {
        props.formField.setValue(option.value as string);
        props.formField.markAsDirty();
    }
}

function emitChange(): void {
    emit('change', props.formField.value);
}
</script>

<template>
    <div
        class="input input-radio"
        :data-type="formField.name !== '' ? formField.name : 'radioButtons'"
        :class="{ ...formField.classes(), disabled: disabled, vertical: vertical }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : `${formField.value}`"
    >
        <div v-if="label" class="label informative">
            <p>{{ label }}</p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div
            class="buttons"
            :class="{
                'button-sparse': type === 'sparse',
                'custom-button-sparse': mobileIcon !== '',
                'button-badge': type === 'badge' || type === 'digits' || type === 'clear',
                'button-digits': type === 'digits',
            }"
        >
            <button
                v-for="(option, index) in options"
                :key="index"
                class="button outside white"
                :class="{
                    'active': formField.value === option.value && !(option.disabled || disabled),
                    'hidden': option.hidden,
                    'size-large': size === 'large' && type !== 'badge' && type !== 'digits' && type !== 'clear',
                    'size-small': size === 'small',
                    'type-with-circle': type === 'with-circle',
                    'type-radio': type === 'radio',
                    'type-sparse': type === 'sparse',
                    'type-badge': type === 'badge' || type === 'digits',
                    'type-digits': type === 'digits',
                    'type-clear': type === 'clear',
                    'custom-type-sparse': mobileIcon !== '',
                    'custom-border-radius': customBorderRadius,
                }"
                :disabled="option.disabled || disabled"
                :data-type="(formField.name !== '' ? formField.name : 'radioButton') + '-' + index"
                :data-index="index"
                @click="select(option)"
            >
                <span v-if="type === 'with-circle'" class="with-circle-and-border"></span>
                <img
                    v-if="option.icon"
                    class="option-icon"
                    :class="{ active: formField.value === option.value }"
                    :src="iconSrc(option.icon)"
                    width="32"
                    height="32"
                    alt="option-icon"
                />
                <span
                    :class="{
                        'option-text-outside': mobileIcon !== '',
                    }"
                    class="button-text"
                    v-html="option.name"
                ></span>
                <img
                    v-if="mobileIcon !== ''"
                    class="mobile-icon"
                    :class="{ active: formField.value === option.value }"
                    :src="mobileIcon"
                    width="32"
                    height="32"
                    alt="bricks"
                />
                <div v-if="type === 'digits' && option.custom !== '' && option.custom !== 0" class="digits">
                    {{ option.custom }}
                </div>
            </button>
            <span class="title">
                <slot></slot>
            </span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-radio {
    width: 100%;
    scroll-margin-top: 4em;

    .buttons {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .button {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-shrink: 1;

            .option-icon {
                margin-right: var(--size-nano);
                filter: contrast(1%);

                &.active {
                    filter: var(--to-color-green);
                }
            }

            &.custom-border-radius {
                border-radius: 8px;

                &::before {
                    border-radius: 8px;
                }
            }

            &.size-large {
                height: 52px;
                padding: 0 10px;

                &:disabled {
                    box-shadow: none;

                    &::before {
                        opacity: 0.16;
                    }
                }
            }

            &.size-small {
                height: 32px;
                padding: 0 12px;
                min-width: 50px;
                width: auto !important;
            }

            &.type-with-circle {
                justify-content: flex-start;
                padding-left: 55px;

                .button-text {
                    text-align: left;
                }

                .with-circle-and-border {
                    $diameter: 1.5rem;

                    &::before {
                        content: '';
                        position: absolute;
                        border: solid 2px var(--component-color-border-default);
                        border-radius: 50%;
                        display: flex;
                        height: $diameter;
                        width: $diameter;
                        left: 20px;
                        top: 50%;
                        margin-top: -12px;
                    }
                }

                &.active {
                    background: linear-gradient(
                            0deg,
                            var(--system-color-success-light) 0%,
                            var(--system-color-success-light) 100%
                        ),
                        var(--white);

                    &::before {
                        border: 2px solid var(--component-color-border-active);
                    }

                    .with-circle-and-border {
                        &::before {
                            border-color: var(--button-color-background-primary-green-default);
                        }

                        &:active {
                            &::before {
                                outline: none;
                                border-color: var(--button-color-background-primary-green-default);
                            }
                        }

                        &::after {
                            $diameter: 0.75rem;

                            background-color: var(--button-color-background-primary-green-default);
                            border-radius: 50%;
                            content: '';
                            height: $diameter;
                            position: absolute;
                            width: $diameter;
                            left: 26px;
                            top: 50%;
                            margin-top: -6px;
                        }
                    }
                }
            }

            &.type-radio {
                $diameter: 1.5rem;

                background-color: transparent;
                line-height: $diameter;
                width: auto !important;

                &:disabled {
                    cursor: default;
                }

                &.size-large,
                &.size-small {
                    height: auto;
                    padding-left: $diameter + 1rem;
                }

                &::before {
                    border: solid 2px var(--component-color-border-default);
                    border-radius: 50%;
                    display: flex;
                    height: $diameter;
                    width: $diameter;
                }

                &:hover {
                    box-shadow: none;
                }

                &.active {
                    &::before {
                        border-color: var(--brand-teal);
                        left: 0;
                        top: 0;
                    }

                    &:active {
                        &::before {
                            outline: none;
                            border-color: var(--brand-teal);
                        }
                    }

                    &::after {
                        $diameter: 0.75rem;
                        $offset: 0.375rem;

                        background-color: var(--brand-teal);
                        border-radius: 50%;
                        content: '';
                        height: $diameter;
                        left: $offset;
                        position: absolute;
                        top: $offset;
                        width: $diameter;
                    }
                }
            }
        }

        .type-sparse {
            margin-left: 5px;
            margin-right: 5px;
            max-width: 73px;
        }

        .custom-type-sparse {
            margin: 0;
            max-width: 100%;

            @include respond-below('lg') {
                max-width: 103px;
            }

            .option-text-outside {
                @include respond-below('lg') {
                    position: absolute;
                    top: 110%;
                }
            }

            .mobile-icon {
                display: block;

                img {
                    &.active {
                        filter: brightness(10%);
                    }
                }

                @include respond-above('sm') {
                    display: none;
                }
            }

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        .type-badge {
            position: relative;
            height: 32px;
            font-size: var(--font-size-pico);
            padding-left: var(--size-tiny);
            padding-right: 28px;
            flex-grow: 0;
            border-radius: 16px;
            margin-right: var(--size-tiny);
            background-color: transparent;
            color: var(--component-color-text-value);

            &:last-child {
                margin-right: 0;
            }

            &::before {
                display: none;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 4px;
                right: 14px;
                top: 50%;
                margin-top: -2px;
                background-color: var(--black-200);
            }

            &.active {
                background-color: var(--component-color-background-base);

                &::after {
                    background-color: var(--brand-teal);
                }
            }

            &:disabled {
                color: var(--component-color-text-disabled);
            }
        }

        .type-digits {
            padding-right: var(--size-tiny);
            flex-shrink: 1;

            &::after {
                display: none;
            }

            .digits {
                font-size: var(--font-size-femto);
                color: var(--black-400);
                margin-left: var(--size-femto);
            }

            &.active {
                .digits {
                    color: var(--brand-red);
                }
            }
        }

        .type-clear {
            position: relative;
            height: 32px;
            font-size: var(--font-size-pico);
            padding: 0 16px;
            flex-grow: 0;
            border-radius: 16px;
            margin-right: var(--size-tiny);
            background-color: transparent;
            color: var(--component-color-text-value);

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }

            &.active {
                background-color: var(--component-color-background-base);
            }
        }
    }

    .button-sparse {
        justify-content: flex-end;

        .button:not(.active) {
            &::before {
                display: none;
            }

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                border: 1px solid var(--component-color-border-default);
                border-radius: 3px;
                opacity: 0.48;
                transition: all 0.3s;
            }
        }
    }

    .custom-button-sparse {
        @include respond-below('lg') {
            justify-content: space-between;
        }
    }

    &.invalid:not(.untouched) {
        .buttons {
            .button {
                &::before {
                    border-color: var(--brand-red);
                    opacity: 1;
                }
            }
        }
    }

    .title {
        display: flex;
        flex-direction: row;
        margin-top: 2px;
        margin-left: var(--size-nano);
        color: var(--black-200);
        font-weight: 500;
        font-size: var(--font-size-nano);
        align-items: center;

        &:empty {
            display: none;
        }
    }

    &.vertical {
        width: auto;

        .buttons {
            flex-direction: column;

            button {
                flex-direction: unset;
                justify-content: left;
                margin: 0.5rem 0;
            }
        }
    }
}
</style>
