import CoveredContent from '@/Components/Other/ProductBlock/Interfaces/CoveredContentInterface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import PolicyAdapter from '@/Components/Other/ProductBlock/Interfaces/PolicyAdapterInterface';
import CoveredContentPolicyPlans from '@/Components/Other/ProductBlock/Interfaces/CoveredContentPolicyPlansInterface';
import CoveredContentPolicyRisks from '@/Components/Other/ProductBlock/Interfaces/CoveredContentPolicyRisksInterface';
import User from '@/Services/user.service';
import Translations from '@/Services/translations.service';
import { LimitedVariant } from '@/Types/LimitedVariantType';

export default class OneDayPolicyAdapter implements PolicyAdapter {
    public transformed(
        data: DynamicDictionary[],
        title: string = '',
        description: string = '',
        translationType: string = '',
    ): CoveredContent {
        const coveredContent: CoveredContent = new (class implements CoveredContent {
            public translationType: string = translationType;
            public title: string = title;
            public description: string = description;
            public policyPlans: CoveredContentPolicyPlans[] = [];
            public risks: CoveredContentPolicyRisks[] = [];
            public selected: string = '';
        })();
        const translations: Translations = Translations.getInstance();
        data.forEach((policy: DynamicDictionary) => {
            const id: string = policy.id;
            const productName: string = translations.localized('one_day_product_' + id);
            coveredContent.policyPlans.push({
                id: id,
                price: this.price(policy.paymentTerms[0].prices),
                title: productName,
            });
        });
        const risksCount: number = data[0].paymentTerms[0].risks.length;
        for (let i: number = 0; i < risksCount; i++) {
            const risks: DynamicDictionary = data[0].paymentTerms[0].risks;
            const travelRisk: CoveredContentPolicyRisks = new (class implements CoveredContentPolicyRisks {
                public id: string = risks[i].id;
                public title: string = risks[i].id;
                public prices: number[] = [];
            })();
            const maxProducts: number = data.length;
            for (let j: number = 0; j < maxProducts; j++) {
                if (this.isSet(data[j].paymentTerms[0].risks[i])) {
                    const risk: DynamicDictionary = data[j].paymentTerms[0].risks[i];
                    if (!risk.isAdditional) {
                        travelRisk.prices.push(risk.insuredSum);
                    }
                }
            }
            coveredContent.risks.push(travelRisk);
        }

        return coveredContent;
    }

    private price(prices: DynamicDictionary[]): number {
        let result: number = 0;
        prices.forEach((priceItem: DynamicDictionary) => {
            if (User.getInstance().isLogged() && priceItem.type === 'authenticated') {
                result = priceItem.price;
            } else if (!User.getInstance().isLogged() && priceItem.type === 'guest') {
                result = priceItem.price;
            }
        });

        return result;
    }

    public isSet(property: LimitedVariant | undefined): boolean {
        return typeof property !== 'undefined' && property !== null;
    }
}
