import { CSSLoader } from '@/Assets/Libraries/CssLoader/CssLoader';
import { JSLoader } from '@/Assets/Libraries/JsLoader/JsLoader';
import { Subject } from 'rxjs';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Library from '@/Enums/LibraryEnum';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

export default class Libraries {
    private version: string = '1.2.5';

    public static ready: boolean = false;
    public static onLoadComplete: Subject<boolean> = new Subject<boolean>();

    public init(): Promise<void> {
        return Promise.all([
            new CSSLoader(Library.Plugins.css.map((file: string) => Library.Path + file + '?' + this.version)).load(),
            new JSLoader(Library.Plugins.js.map((file: string) => Library.Path + file + '?' + this.version)).load(),
        ])
            .then(() => {
                Libraries.ready = true;
                Libraries.onLoadComplete.next(true);
            })
            .catch((reason: DynamicDictionary) => {
                Libraries.onLoadComplete.next(false);
                Error.log(ErrorType.Error, 'Libraries', reason);
            })
            .finally(() => {
                const easingSpeed: number = 1000;
                $('.tooltipster, .tooltipster-popup').css('visibility', 'visible').animate({ opacity: 1 }, easingSpeed);
            });
    }
}
