<script setup lang="ts">
import PolicyLimitGroup from '@/Enums/PolicyLimitGroupEnum';
import { useTranslate } from '@/Composables/Translate';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/Interfaces/image.tag.attributes';
import { usePrice } from '@/Composables/Price';
import { PropType } from 'vue';
import PolicyLimit from '@/Interfaces/OnePolicy/PolicyLimit';
import PolicyLimitGroupMapper from '@/Components/Policies/PolicyLimits/Classifiers/PolicyLimitGroupMapperClassifier';

const { imgTag } = useHtml();
const { translate } = useTranslate();

const props = defineProps({
    hideFraction: { type: Boolean, default: true },
    content: {
        type: Array as PropType<PolicyLimit[]>,
        default: () => {
            return [];
        },
    },
});

function isLimitVisible(limit: PolicyLimit): boolean {
    return limit.isVisible === undefined || limit.isVisible;
}

function limitIcon(limit: PolicyLimit): string {
    const group: PolicyLimitGroup = new PolicyLimitGroupMapper().byLimitId(limit.id);
    return imgTag({
        class: '',
        src: 'images/one/limits/limit-icon-' + group + '.svg',
        width: 24,
        height: 24,
    });
}

function openerAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/limits/opener.svg',
        width: 12,
        height: 12,
    };
}

function sumLeft(left: number): string {
    return translate('btar_euro') + ' ' + (props.hideFraction ? left : usePrice().price(left, false));
}

function sumTotal(total: number): string {
    return (
        translate('of') +
        ' ' +
        translate('btar_euro') +
        ' ' +
        (props.hideFraction ? total : usePrice().price(total, false))
    );
}

function onLimitClick(index: number): void {
    $('.limit-opener[data-id="' + index + '"]').toggleClass('opened');
    $('.opener-icon[data-id="' + index + '"]').toggleClass('opened');
}
</script>

<template>
    <div class="policy-limits">
        <template v-for="(limit, index) in content">
            <div v-if="isLimitVisible(limit)" :key="index" class="policy-limit">
                <div class="limit-header" @click="onLimitClick(index)">
                    <div class="limit-header-left">
                        <div class="limit-icon" v-html="limitIcon(limit)"></div>
                        <div class="limit-name">{{ limit.name }}</div>
                    </div>
                    <div class="header-right">
                        <div class="limit-header-right">
                            <div class="sum-left" v-html="sumLeft(limit.sumLeft || 0)"></div>
                            <div class="sum-total" v-html="sumTotal(limit.sumTotal || 0)"></div>
                        </div>
                        <div class="opener-icon" :data-id="index" v-html="imgTag(openerAttributes())"></div>
                    </div>
                </div>
                <div class="limit-opener" :data-id="index" @click="onLimitClick(index)">
                    <div class="opener-content first-level">
                        <div v-for="(item, itemIndex) in limit.description" :key="itemIndex" class="description-row">
                            {{ item }}
                        </div>
                        <ul v-if="limit.limits?.length! > 0" class="second-level">
                            <template v-for="secondLevelLimit in limit.limits">
                                <li
                                    v-if="isLimitVisible(secondLevelLimit)"
                                    :key="secondLevelLimit.id"
                                    class="item-container"
                                >
                                    <div class="item">
                                        <div class="name">{{ secondLevelLimit.name }}</div>
                                        <div
                                            v-if="secondLevelLimit.sumLeft || secondLevelLimit.sumLeft === 0"
                                            class="sum"
                                            v-html="sumLeft(secondLevelLimit.sumLeft)"
                                        ></div>
                                    </div>
                                    <div
                                        v-for="(item, itemIndex) in secondLevelLimit.description"
                                        :key="itemIndex"
                                        class="description-row"
                                    >
                                        {{ item }}
                                    </div>
                                    <div v-if="!(secondLevelLimit.limits?.length! > 0)" class="third-level">
                                        <template v-for="thirdLevelLimit in secondLevelLimit.limits">
                                            <div
                                                v-if="isLimitVisible(thirdLevelLimit)"
                                                :key="thirdLevelLimit.id"
                                                class="item-container"
                                            >
                                                <div class="item">
                                                    <div class="name">{{ thirdLevelLimit.name }}</div>
                                                    <div
                                                        v-if="thirdLevelLimit.sumLeft || thirdLevelLimit.sumLeft === 0"
                                                        class="sum"
                                                        v-html="sumLeft(thirdLevelLimit.sumLeft)"
                                                    ></div>
                                                </div>
                                                <div
                                                    v-for="(item, itemIndex) in thirdLevelLimit.description"
                                                    :key="itemIndex"
                                                    class="description-row"
                                                >
                                                    {{ item }}
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div class="separator"></div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.policy-limits {
    .policy-limit {
        &:not(:last-child) {
            .opener-content {
                margin-bottom: 15px;
            }
        }

        .limit-header {
            display: flex;
            max-height: 70px;
            justify-content: space-between;
            min-height: 70px;
            align-items: center;
            cursor: pointer;

            .limit-header-left {
                display: flex;
                gap: 12px;
                align-items: center;

                @include respond-below('md') {
                    max-width: min-content;
                }

                .limit-name {
                    font-weight: 600;
                    font-size: var(--font-size-tiny);
                }
            }

            .header-right {
                display: flex;
                align-items: center;

                .opener-icon {
                    height: 12px;
                    width: 12px;
                    display: inline-block;
                    margin-left: var(--size-nano);
                    filter: var(--to-color-green);

                    &.opened {
                        transform: rotateX(180deg);
                        filter: var(--to-color-color-grey);
                    }
                }

                .limit-header-right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    gap: 6px;

                    .sum-left {
                        font-weight: 600;
                        font-size: var(--font-size-nano);
                    }

                    .sum-total {
                        font-size: var(--font-size-pico);
                        line-height: 12px;
                        color: var(--black-500);
                    }
                }
            }
        }

        .limit-opener {
            height: auto;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-out;

            &.opened {
                max-height: 2000px;
            }

            .opener-content {
                font-size: var(--font-size-nano);
                line-height: 20px;
                padding-left: 36px;

                .item-container {
                    display: flex;
                    flex-direction: column;

                    & > .item {
                        position: relative;
                        display: flex;
                        flex-direction: row;

                        .name {
                            flex-grow: 1;
                            flex-shrink: 1;
                        }

                        .sum {
                            white-space: nowrap;
                            color: var(--black-500);
                        }
                    }

                    & > .description-row {
                        color: var(--black-500);
                    }
                }

                &.first-level {
                    & > .description-row {
                        color: var(--black-500);

                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }
                    }
                }

                .second-level {
                    & > .item-container {
                        margin-right: var(--size-normal);

                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }

                        & > .item {
                            padding-left: 13px;

                            &::before {
                                content: '';
                                position: absolute;
                                width: 4px;
                                height: 4px;
                                left: 0;
                                background: var(--brand-black);
                                border-radius: 50%;
                                top: 8px;
                            }
                        }

                        & > .description-row {
                            padding-left: 13px;
                        }
                    }
                }

                .third-level {
                    border-left: 1px solid var(--black-50);
                    margin-left: var(--size-tiny);
                    margin-top: 15px;

                    & > .item-container {
                        & > .item {
                            padding-left: var(--size-tiny);
                        }

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .separator {
            border-bottom: 1px solid var(--black-50);
        }

        &:last-child {
            .separator {
                border-bottom: none;
            }
        }
    }
}
</style>
