<script setup lang="ts">
import { computed, ComputedRef, ref, Ref, PropType } from 'vue';

type TitleSize = 'small' | 'medium';

const props = defineProps({
    title: { type: String, default: '' },
    titleSize: { type: String as PropType<TitleSize>, default: 'small' },
});

const titleSizeMapping: Ref<Record<string, string>> = ref({ small: 'heading-5', medium: 'heading-4' });
const titleClass: ComputedRef<string> = computed((): string => titleSizeMapping.value[props.titleSize]);
</script>

<template>
    <div class="whiteboard">
        <div class="wrapper">
            <div class="header">
                <h2 v-if="title !== ''" class="title" :class="titleClass">{{ title }}</h2>
                <div v-if="$slots['right-top']" class="right-top">
                    <slot name="right-top"></slot>
                </div>
            </div>
            <div class="whiteboard-content">
                <slot></slot>
            </div>
            <div v-if="$slots['after-content']" class="after-content">
                <slot name="after-content"></slot>
            </div>
        </div>
        <div v-if="$slots['footer']" class="footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
.whiteboard {
    display: grid;
    gap: var(--size-medium);
    width: 100%;
    background: var(--white);
    padding: var(--size-small);
    border-radius: var(--size-tiny);

    @include respond-above('sm') {
        padding: var(--size-big);
        gap: var(--size-big);
    }

    .wrapper {
        display: grid;
        gap: var(--size-small);

        .header {
            display: flex;
            width: 100%;

            &:empty {
                display: none;
            }

            .title {
                flex-grow: 1;
                flex-shrink: 1;
            }

            .right-top {
                flex-shrink: 0;
                margin-left: var(--size-small);
            }
        }

        .whiteboard-content {
            width: 100%;
        }

        .after-content {
            width: 100%;
        }
    }
}
</style>
