import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import LegalModalTabFileReceiver from '@/Pages/LegalPerson/Workbench/Interfaces/LegalModalTabFileReceiverInterface';
import BusinessModal from '@/Pages/LegalPerson/Workbench/Common/BusinessModal';

export default class ModalTab implements LegalModalTabFileReceiver {
    private context!: BusinessModal;

    public addContext(context: BusinessModal): void {
        this.context = context;
    }

    public init(): void {}

    public destroy(): void {}

    public onPolicyChanged(policy: string): void {}

    public fileUploadReceiver(rows: DynamicDictionary): void {}

    protected get modal(): BusinessModal {
        return this.context;
    }
}
