<script setup lang="ts">
import { computed, ComputedRef } from 'vue';
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppInputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';

const titleSizeOptions: ComputedRef<InputOption<string>[]> = computed((): InputOption<string>[] => {
    return [
        new InputOptionBuilder<string>().setValue('small').setName('Small').build(),
        new InputOptionBuilder<string>().setValue('medium').setName('Medium').build(),
    ];
});

const form: Form<{ titleSize: string }> = new Form();
form.addField(new FormField('titleSize', 'small'));
form.setReady();
</script>

<template>
    <app-whiteboard :title="'Whiteboard title'" :title-size="form.field('titleSize').value as any">
        <app-custom-form v-if="form.isReady()" :form="form" class="slot-content">
            <div>#default</div>
            <app-input-radio
                :label="'Change title size:'"
                :type="'badge'"
                :form-field="form.field('titleSize')"
                :options="titleSizeOptions"
            ></app-input-radio>
        </app-custom-form>
        <template #right-top>
            <div class="slot-content">#right-top</div>
        </template>
        <template #after-content>
            <div class="slot-content">#after-content</div>
        </template>
        <template #footer>
            <div class="slot-content">#footer</div>
        </template>
    </app-whiteboard>
</template>

<style lang="scss" scoped>
.slot-content {
    display: flex;
    align-items: flex-start;
    background-color: #fcfcfc;
    border: dashed 1px lightgrey;
    padding: 10px;
    border-radius: 5px;
}
</style>
