<script setup lang="ts"></script>

<template>
    <div class="page-container">
        <div v-if="$slots.title || $slots.description" class="header">
            <h2 v-if="$slots.title" class="heading-3">
                <slot name="title"></slot>
            </h2>
            <div v-if="$slots.description" class="description">
                <slot name="description"></slot>
            </div>
        </div>
        <slot></slot>
        <div v-if="$slots.footer" class="footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    align-items: center;

    @include respond-above('sm') {
        width: 800px;
    }

    .header {
        display: grid;
        width: 100%;
        text-align: center;
        gap: var(--size-nano);
        margin-bottom: var(--size-medium);

        @include respond-above('sm') {
            margin-bottom: var(--size-big);
        }

        .description {
            font-size: var(--size-tiny);
            font-weight: 500;
            color: var(--text-color-subtle);
            text-align: center;
            line-height: 140%;
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        gap: var(--size-small);
        text-align: center;
        margin-top: var(--size-small);

        @include respond-above('sm') {
            text-align: left;
            justify-content: flex-start;
        }

        .continue-hint {
            font-size: var(--font-size-nano);
            color: var(--text-color-subtlest);
        }
    }
}
</style>
