<script lang="ts">
import { computed, defineComponent, reactive, ref, Ref, nextTick, UnwrapNestedRefs } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Translations from '@/Services/translations.service';
import { useTranslate } from '@/Composables/Translate';
import { AutoForms, useAutoForms } from '@/Pages/Auto/Composables/AutoForms';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import SubmitterUrls from '@/Services/SubmitterUrls.service';
import FormField from '@/Assets/Libraries/Form/FormField';
import Validation from '@/Services/validation.service';
import { AxiosResponse } from 'axios';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import moment from 'moment/moment';
import PopupType from '@/Enums/PopupTypeEnum';
import VueEvent from '@/Classes/VueEventClass';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import SimpleError from '@/Assets/Libraries/Popups/Types/SimpleError';
import Url from '@/Enums/UrlEnum';
import ResponseCode from '@/Enums/ResponseCodeEnum';
import { useDefine } from '@/Composables/Define';
import { useNavigate } from '@/Composables/Navigate';
import SpecificError from '@/Enums/SpecificErrorEnum';
import Sanitizer from '@/Services/sanitizer.service';
import DataLayer from '@/Services/data.layer.service';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import OneBase from '@/Interfaces/OneBaseInterface';
import { VehicleSumInsuredLimits } from '@/Pages/Auto/Classes/VehicleSumInsuredLimits';
import AutoUrls from '@/Pages/Auto/Enums/AutoUrlsEnum';
import RequestService from '@/Services/request.service';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const { isSet } = useDefine();
        const request: AxiosParams = useAxios();
        const forms: AutoForms = useAutoForms();

        const CurrentStep: number = 1;
        const NextStep: number = 2;
        const Facility: string = 'auto-policy';
        const taxiSpecialUsageOption: string = 'TAXI';

        const plateNumber: Ref<string | null> = ref('');
        const code: Ref<string | null> = ref('');
        const encodedRef: Ref<string | null> = ref('');
        const specialCarUsageTypeOptions: Ref<InputOption[]> = ref([]);
        const administrativeCodeOptions: Ref<InputOption[]> = ref([]);
        const fetchAdministrativeRegionsInProgress: Ref<boolean> = ref(false);
        const driverLicenceDateDisabled: Ref<boolean> = ref(false);
        const leasingCompanies: Ref<string[]> = ref([]);
        const errorCodeSuffix: Ref<string> = ref('');
        const appCountry: AppCountry = new AppCountry();
        const restrictedByGeoIp: Ref<boolean> = ref(false);
        const vehicleNotOwnedPopupEnabled: Ref<boolean> = ref(false);
        const vehicleNotOwnedPopupTitle: Ref<string> = ref('');
        const vehicleNotOwnedPopupDescription: Ref<string> = ref('');
        const vehicleNotOwnedPopupCheckbox: Ref<string> = ref('');
        const vehicleSumInsuredLimits: UnwrapNestedRefs<VehicleSumInsuredLimits> = reactive(
            new VehicleSumInsuredLimits(),
        );
        let appliedMissingParams: UnwrapNestedRefs<DynamicDictionary> = reactive({});

        const encodedReference: Ref<string> = computed(() => {
            return encodedRef.value ? encodedRef.value : '';
        });

        const isDirectLinkMode: Ref<boolean> = computed(() => {
            return plateNumber.value !== '';
        });

        const userPersonCodeOrRegNum: Ref<string> = computed(() => {
            return code.value ? code.value : '';
        });

        const userPlateNumber: Ref<string> = computed(() => {
            return plateNumber.value ? plateNumber.value : '';
        });

        const isLeasingCompanyEntered: Ref<boolean> = computed(() => {
            const field: FormField = forms.form.field('vehicleOwnerIdentification');
            return (
                !field.isEmpty() &&
                leasingCompanies.value.length > 0 &&
                leasingCompanies.value.some((value: string): boolean => value === field.value)
            );
        });

        function init(): void {
            const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
            encodedRef.value = urlParams.get('d');
            if (encodedRef.value) {
                const decoded: string = atob(encodedRef.value);
                const decodedUrlParams: URLSearchParams = new URLSearchParams(decoded);
                plateNumber.value = decodedUrlParams.get('t');
                code.value = decodedUrlParams.get('p');
            }
            if (isDirectLinkMode.value) {
                btaBase.showPopup('loading-wait');
            }
        }

        function setupForm(): void {
            forms.form.addField(new FormField('plateNumber', ''));
            if (btaBase.cmsFieldIsEnabled('vehicleOwnerIdentification')) {
                forms.form.addField(
                    new FormField(
                        'vehicleOwnerIdentification',
                        '',
                        vehicleOwnerValidator(),
                        Sanitizer.cleanVehicleOwnerIdentificationNumber,
                    ),
                );
            }
            if (btaBase.cmsFieldIsEnabled('holdingDisabilityCertificate')) {
                forms.form.addField(new FormField('holdingDisabilityCertificate', false));
            }
            if (btaBase.cmsFieldIsEnabled('disabilityCertificateNumber')) {
                forms.form.addField(
                    new FormField(
                        'disabilityCertificateNumber',
                        '',
                        disabilityCertificateValidator(),
                        Sanitizer.cleanDisabilityCertificateNumber,
                    ),
                );
            }
            if (btaBase.cmsFieldIsEnabled('specialCarUsage')) {
                forms.form.addField(new FormField('specialCarUsage', false));
            }
            if (btaBase.cmsFieldIsEnabled('specialCarUsageType')) {
                forms.form.addField(new FormField('specialCarUsageType', '', specialCarUsageValidator()));
            }
            if (btaBase.cmsFieldIsEnabled('keylessCar')) {
                forms.form.addField(new FormField('keylessCar', false));
            }
            if (btaBase.cmsFieldIsEnabled('leasingContract')) {
                forms.form.addField(new FormField('leasingContractIsValid', false));
            }
            if (btaBase.cmsFieldIsEnabled('agreePrivacyPolicy') && !btaBase.user.isLogged()) {
                forms.form.addField(new FormField('agreePrivacyPolicy', false, 'checkboxChecked'));
            }
            forms.form.setReady();
            forms.formsAreReady.value = true;
        }

        const specialCarUsageValidator = (): object => {
            return {
                validate: () =>
                    !forms.form.field('specialCarUsage').value || !!forms.form.field('specialCarUsageType').value,
            };
        };

        const vehicleOwnerValidator = (): object => {
            return {
                validate: (value: string) =>
                    vehicleOwnerValidationResults(value).some((result) => result.valid) &&
                    !isLeasingCompanyEntered.value,
            };
        };

        const disabilityCertificateValidator = (): object => {
            return {
                validate: () =>
                    Validation.isValidDisabilityCertificateNumber(
                        forms.form.field('disabilityCertificateNumber').value,
                    ) || !forms.form.field('holdingDisabilityCertificate').value,
            };
        };

        const onAfterFormRestored = (): void => {
            if (isDirectLinkMode.value) {
                directModeApply();
            }
            forms.form.markAsUntouched().then((): void => {
                if (isDirectLinkMode.value) {
                    directModeApply();
                }
                if (new AppCountry().isLT()) {
                    checkRestrictedPurchase();
                }
            });
        };

        const applyErrorCodeSuffix = (value: string): void => {
            errorCodeSuffix.value = value;
        };

        const applyLeasingCompanies = (value: string[]): void => {
            leasingCompanies.value = value;
        };

        const applyGeoIpRestriction = (isRestricted: boolean): void => {
            restrictedByGeoIp.value = isRestricted;
        };

        const onPlateNumberChange = (): void => {
            appliedMissingParams = {};
            if (appCountry.isLT()) {
                autoFillSpecialCarUsageForTaxi();
            }
        };

        const onSpecialCarUsageChange = (): void => {
            const usageField: FormField = forms.form.field('specialCarUsageType');
            if (usageField) {
                usageField.clear().then();
                usageField.touch();
            }
        };

        const noDriversLicenceChange = (): void => {
            driverLicenceDateDisabled.value = forms.missingParamsForm.field('noDriversLicence').value;
        };

        const driverHasLicenceChange = (): void => {
            const hasLicenceField: FormField = forms.missingParamsForm.field('driverHasLicense');
            driverLicenceDateDisabled.value = !!hasLicenceField.value;
        };

        const hasMultipleMissingParams = (): boolean => {
            let result: boolean = false;
            const validFieldsLength: number = 2;
            if (
                forms.missingParamsForm.fields().length === validFieldsLength &&
                forms.missingParamsForm.exists('ownerDrivingLicenceDate') &&
                forms.missingParamsForm.exists('noDriversLicence')
            ) {
                result = false;
            } else if (forms.missingParamsForm.fields().length > 1) {
                result = true;
            }

            return result;
        };

        const autoFillSpecialCarUsageForTaxi = (): void => {
            const plate: string = forms.form.field('plateNumber').value;
            if (Validation.isValidTaxiPlateNumber(plate)) {
                applySpecialUsageForTaxi();
            } else {
                clearSpecialUsageFields();
            }
        };

        const applySpecialUsageForTaxi = (): void => {
            if (forms.form.exists('specialCarUsage')) {
                forms.form.field('specialCarUsage').patch(true);
            }
            if (forms.form.exists('specialCarUsageType')) {
                forms.form.field('specialCarUsageType').patch(taxiSpecialUsageOption);
            }
        };

        const clearSpecialUsageFields = (): void => {
            forms.form.field('specialCarUsage').patch(false);
            forms.form.field('specialCarUsageType').clear().then();
        };

        const fetchAdministrativeRegions = (): void => {
            fetchAdministrativeRegionsInProgress.value = true;
            RequestService.getInstance()
                .get({
                    uri: AutoUrls.administrativeCodes,
                    content: {},
                    withCache: false,
                })
                .then((response: AxiosResponse<DynamicDictionary>): void => {
                    if (useDefine().validResponse(response)) {
                        administrativeCodeOptions.value = response.data.data.body.codes.map((item: any) => {
                            return new InputOptionBuilder().setName(item.label).setValue(item.value).build();
                        });
                    } else {
                        throw response;
                    }
                })
                .catch((reason: DynamicDictionary) => {
                    Error.log(ErrorType.Error, 'fetchAdministrativeRegions', reason);
                })
                .finally((): void => {
                    fetchAdministrativeRegionsInProgress.value = false;
                });
        };

        const applyMissingParams = (): void => {
            forms.missingParamsForm.touch().then();
            if (forms.missingParamsForm.isValid()) {
                forms.missingParamsForm.fields().forEach((field: FormField): void => {
                    let value: string = field.value;
                    if (field.name === 'ownerDrivingLicenceDate') {
                        value = moment(value).format('YYYY-MM-DD');
                    }
                    if (field.name === 'noDriversLicence') {
                        value = value ? 'Y' : 'N';
                    }
                    appliedMissingParams[field.name] = value;
                });
                processDriverLicenseFields();
                btaBase.showPopup(PopupType.None);
                quoteAndBuy();
            } else {
                btaBase.showPopup(PopupType.VehicleMissingParams);
            }
        };

        const applyVehicleNotOwnedEnabled = (value: boolean): void => {
            vehicleNotOwnedPopupEnabled.value = value;
        };

        const applyVehicleNotOwnedTitle = (value: string): void => {
            vehicleNotOwnedPopupTitle.value = value;
        };

        const applyVehicleNotOwnedDescription = (value: string): void => {
            vehicleNotOwnedPopupDescription.value = value;
        };

        const applyVehicleNotOwnedCheckbox = (value: string): void => {
            vehicleNotOwnedPopupCheckbox.value = value;
        };

        const vehicleNotOwnedPopupCloseBack = (): void => {
            btaBase.closePopup();
            btaBase.captcha.resetCaptcha();
            forms.missingParamsForm.clear().then();
            forms.form.unlockInput();
        };

        const vehicleNotOwnedPopupCloseContinue = (): void => {
            saveStepStorageAndContinue();
        };

        const quoteAndBuy = (event: VueEvent | null = null): void => {
            if (!navigator.cookieEnabled) {
                btaBase.showPopup(PopupType.CookiesDisabled);
            } else {
                forms.form.touch().then();
                if (!forms.missingParamsForm.isValid()) {
                    btaBase.showPopup(PopupType.VehicleMissingParams);
                    btaBase.captcha.resetCaptcha();
                } else if (forms.form.isValid()) {
                    if (
                        btaBase.cmsFieldIsEnabled('specialCarUsage') &&
                        forms.form.field('specialCarUsage').value &&
                        new AppCountry().isEE()
                    ) {
                        btaBase.popup.applyErrorTitle(
                            useTranslate().translate('special_car_usage_unavailable_popup_title'),
                        );
                        btaBase.popup.applyErrorDetails(
                            useTranslate().translate('special_car_usage_unavailable_popup_description'),
                        );
                        btaBase.popup.applyErrorButtonText(useTranslate().translate('btar_error_close'));
                        PopupService.getInstance().show(new OnePopup().withType().error);
                    } else {
                        btaBase.userStorage.saveForms().then((): void => {
                            pushDataLayer();
                            submit();
                        });
                    }
                }
            }
        };

        const showPurchaseRestrictedPopup = (): void => {
            let description: string = 'vehicle_purchase_restriction_wrong_user';
            if (Validation.isValidCompanyRegistrationNumber(forms.form.field('vehicleOwnerIdentification').value)) {
                description = 'vehicle_purchase_restriction_corporate_vehicle';
            }
            const simpleError: SimpleError = new OnePopup()
                .withType()
                .simpleError.withAutoWidth()
                .withDescription('')
                .withSecondaryDescription(useTranslate().translate(description));
            PopupService.getInstance().show(simpleError);
        };

        const submit = (): void => {
            if (isDirectLinkMode.value) {
                if (btaBase.settings.metaApiEnabled()) {
                    sendViewContentEvent();
                }
                submitStep1('');
            } else {
                if (btaBase.settings.metaApiEnabled()) {
                    sendViewContentEvent();
                }
                btaBase.captcha
                    .executeCaptcha(submitStep1)
                    .then()
                    .catch((reason: string): void => {
                        Error.log(ErrorType.Error, 'quoteAndBuy', reason, true);
                    });
            }
        };

        const assembleStep1 = (token: string): Record<string, unknown> => {
            const params: Record<string, unknown> = {};
            const plate: string = forms.form.field('plateNumber').value;
            params['id'] = plate;
            params['facility'] = Facility;
            params['g-recaptcha-response'] = token;
            if (isDirectLinkMode.value) {
                params['d-link'] = btoa(plate);
            }
            const customParams: Record<string, unknown> = assembleStep1CustomParams();
            Object.keys(customParams).forEach(
                (key: string) => (params['customParam[' + key + ']'] = customParams[key]),
            );

            return params;
        };

        const assembleStep1CustomParams = (): Record<string, unknown> => {
            const specialCarUsageChecked: boolean =
                forms.form.exists('specialCarUsage') && forms.form.field('specialCarUsage').value;
            const holdingDisabilityCertificateChecked: boolean =
                forms.form.exists('holdingDisabilityCertificate') &&
                forms.form.field('holdingDisabilityCertificate').value;
            const params: Record<string, unknown> = {};
            params['special'] = specialCarUsageChecked;
            if (forms.form.exists('specialCarUsageType') && specialCarUsageChecked) {
                params['specialUseType'] = forms.form.field('specialCarUsageType').value;
            }
            if (forms.form.exists('vehicleOwnerIdentification')) {
                const value: string = forms.form.field('vehicleOwnerIdentification').value;
                const firstFoundResult: { fieldName: string; valid: boolean } | undefined =
                    vehicleOwnerValidationResults(value).find(
                        (result: { fieldName: string; valid: boolean }) => result.valid,
                    );
                params[firstFoundResult ? firstFoundResult.fieldName : ''] = value;
            }
            if (forms.form.exists('disabilityCertificateNumber') && holdingDisabilityCertificateChecked) {
                params['disabilityCertificateNumber'] = forms.form.field('disabilityCertificateNumber').value;
            }
            if (forms.form.exists('keylessCar')) {
                params['keylessCar'] = forms.form.field('keylessCar').value;
            }
            if (forms.form.exists('leasingContract')) {
                params['leasing'] = forms.form.field('leasingContractIsValid').value;
            }
            if (forms.form.exists('agreePrivacyPolicy')) {
                params['privacyPolicy'] = forms.form.field('agreePrivacyPolicy').value ? 'Y' : 'N';
            } else {
                if (btaBase.cmsFieldIsEnabled('agreePrivacyPolicy') && btaBase.user.isLogged()) {
                    params['privacyPolicy'] = 'Y';
                }
            }
            if (new AppCountry().isEE()) {
                params['territory'] = btaBase.settings.cascoTerritoryIc();
            }

            return {
                ...params,
                ...appliedMissingParams,
            };
        };

        const vehicleOwnerValidationResults = (value: string): { fieldName: string; valid: boolean }[] => {
            const validators = [
                { fieldName: 'ownerRegistrationCode', valid: Validation.isValidCompanyRegistrationNumber(value) },
                { fieldName: 'ownerPersonCode', valid: Validation.isValidNaturalPersonCode(value) },
            ];
            if (new AppCountry().isLV()) {
                validators.push({
                    fieldName: 'vehicleRegCertificateNumber',
                    valid: Validation.isValidVehicleRegistrationNumber(value),
                });
            }

            return validators;
        };

        const submitStep1 = (token: string): void => {
            if (forms.form.isInputLocked()) {
                return;
            }
            forms.form.lockInput();
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            const params: any = assembleStep1(token);
            useAxios()
                .get(AutoUrls.autoApiPrimary, { params })
                .then((value: AxiosResponse<any>): void => {
                    forms.form.unlockInput();
                    PopupService.getInstance().hide();
                    if (useDefine().validResponse(value)) {
                        if (vehicleNotOwnedPopupEnabled.value && isPersonNotOwnerCase(value.data)) {
                            if (isCorporateVehicle()) {
                                showPurchaseRestrictedPopup();
                            } else {
                                btaBase.popup
                                    .applyTextTitle(useTranslate().translate(vehicleNotOwnedPopupTitle.value))
                                    .applyTextDescription(
                                        useTranslate().translate(vehicleNotOwnedPopupDescription.value, {
                                            '%MAKER%': value.data.data.body.data.attributes.vehicleMake,
                                            '%MODEL%': value.data.data.body.data.attributes.modelModification,
                                            '%PLATE%': value.data.data.body.data.id,
                                        }),
                                    )
                                    .applyTextAgree(useTranslate().translate(vehicleNotOwnedPopupCheckbox.value))
                                    .showPopup(PopupType.PersonNotVehicleOwner);
                            }
                        } else {
                            saveStepStorageAndContinue();
                        }
                    } else {
                        if (isRequestMissingParams(value.data)) {
                            btaBase.captcha.resetCaptcha();
                            const errorDetail: any = JSON.parse(value.data.errors.detail);
                            vehicleSumInsuredLimits.setVehicleType(errorDetail.vehicleType);
                            setupMissingFieldsForm(Object.values(errorDetail.requiredFields));
                            btaBase.showPopup(PopupType.VehicleMissingParams);
                        } else if (isInvalidTaxiCase(value.data)) {
                            btaBase.popup.applyCallbackModel(clearSpecialCarUsageAndResubmit);
                            throw value;
                        } else {
                            throw value;
                        }
                        btaBase.captcha.resetCaptcha();
                    }
                })
                .catch((reason: any): void => {
                    PopupService.getInstance().hide();
                    btaBase.captcha.resetCaptcha();
                    forms.missingParamsForm.clear();
                    forms.form.unlockInput();
                    if (reason.data.errors.status === ResponseCode.Unprocessable && errorCodeSuffix.value) {
                        reason.data.errors.code += errorCodeSuffix.value;
                    }
                    btaBase.error.show(ErrorType.Error, 'submitStep1', reason);
                });
        };

        const isCorporateVehicle = (): boolean => {
            return Validation.isValidCompanyRegistrationNumber(forms.form.field('vehicleOwnerIdentification').value);
        };

        const saveStepStorageAndContinue = (): void => {
            forms.form.lockInput();
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            const params: Record<string, Record<string, unknown>> = {
                data: {
                    uid: btaBase.userStorage.uUid,
                    json: JSON.stringify({ data: assembleStep1CustomParams() }),
                    facility: Facility,
                    step: NextStep,
                },
            };
            request
                .post(Url.Ajax.stepsStore, params)
                .then((): void => {
                    useNavigate().navigate(SubmitterUrls.getInstance().nextStep());
                })
                .catch((reason: DynamicDictionary): void => {
                    forms.form.unlockInput();
                    btaBase.error.show(ErrorType.Error, 'saveStepStorageAndContinue', reason);
                });
        };

        const isRequestMissingParams = (json: any): boolean => {
            return (
                isSet(json.errors) && isSet(json.errors.code) && json.errors.code === SpecificError.MissingVehicleParams
            );
        };

        const isInvalidTaxiCase = (json: any): boolean => {
            return (
                isSet(json.errors) &&
                isSet(json.errors.code) &&
                json.errors.code === SpecificError.MtplNotAvailableForTaxi
            );
        };

        const isPersonNotOwnerCase = (json: any): boolean => {
            return (
                isSet(json.data.body.data.attributes.showMessage) &&
                json.data.body.data.attributes.showMessage === SpecificError.VehicleNotOwned
            );
        };

        const clearSpecialCarUsageAndResubmit = (): void => {
            forms.form.field('specialCarUsage').patch(false);
            forms.form.field('specialCarUsageType').clear().then();
            submit();
        };

        const setupMissingFieldsForm = (missingParams: string[]): void => {
            if (missingParams.indexOf('SUM_INSURED') !== -1) {
                forms.missingParamsForm.addField(
                    new FormField(
                        'sumInsured',
                        '',
                        sumInsuredValidator(vehicleSumInsuredLimits.limit()),
                        Sanitizer.cleanSumInsured,
                    ),
                );
            }
            if (missingParams.indexOf('ENGINE_POWER') !== -1) {
                forms.missingParamsForm.addField(
                    new FormField('enginePower', '', 'required', Sanitizer.cleanInputNumber),
                );
            }
            if (missingParams.indexOf('ENGINE_CAPACITY') !== -1) {
                forms.missingParamsForm.addField(
                    new FormField('engineCapacity', '', engineCapacityValidator(), Sanitizer.cleanInputNumber),
                );
            }
            if (missingParams.indexOf('FULL_WEIGHT') !== -1) {
                forms.missingParamsForm.addField(
                    new FormField('fullWeight', '', 'required', Sanitizer.cleanInputInteger),
                );
            }
            if (missingParams.indexOf('YEAR_OF_MANUFACTURE') !== -1) {
                forms.missingParamsForm.addField(
                    new FormField('yearOfManufacture', '', 'required', Sanitizer.cleanInputNumber),
                );
            }
            if (missingParams.indexOf('ADMINISTRATIVE_CODE') !== -1) {
                fetchAdministrativeRegions();
                forms.missingParamsForm.addField(new FormField('administrativeCode', '', 'required'));
            }
            if (missingParams.indexOf('DRIVER_LICENCE_DATE') !== -1) {
                forms.missingParamsForm.addField(
                    new FormField('ownerDrivingLicenceDate', '', driverLicenseValidator()),
                );
                forms.missingParamsForm.addField(new FormField('noDriversLicence', false));
                noDriversLicenceChange();
            }
        };

        const sumInsuredValidator = (limit: any): object => {
            return {
                validate: (value: string) =>
                    Validation.isSumInsured(value) &&
                    ((limit && Validation.inRange(value, limit.minSum, limit.maxSum)) || !limit),
            };
        };

        const engineCapacityValidator = (): object => {
            return {
                validate: () => Validation.isValidEngineCapacity(forms.missingParamsForm.field('engineCapacity').value),
            };
        };

        const driverLicenseValidator = (): object => {
            return {
                validate: () => {
                    return (
                        !forms.missingParamsForm.field('ownerDrivingLicenceDate').isEmpty() ||
                        forms.missingParamsForm.field('noDriversLicence').value
                    );
                },
            };
        };

        const makeSpecialCarUsageTypeOptions = (): void => {
            specialCarUsageTypeOptions.value = btaBase.cmsFields.specialCarUsageType.items.map(
                (item: { name: string; value: string }) => {
                    return new InputOptionBuilder().setName(item.name).setValue(item.value).build();
                },
            );
        };

        const getCookie = (name: string): string => {
            const nameLenPlus: number = name.length + 1;
            return document.cookie
                .split(';')
                .map((c: string) => c.trim())
                .filter((cookie: string): boolean => {
                    return cookie.substring(0, nameLenPlus) === `${name}=`;
                })
                .map((cookie: string) => {
                    return decodeURIComponent(cookie.substring(nameLenPlus));
                })[0] as string;
        };

        const processDriverLicenseFields = (): void => {
            if (isSet(appliedMissingParams.ownerDrivingLicenceDate) && isSet(appliedMissingParams.noDriversLicence)) {
                appliedMissingParams.noDriversLicence === 'Y'
                    ? delete appliedMissingParams.ownerDrivingLicenceDate
                    : delete appliedMissingParams.noDriversLicence;
            }
        };

        const pushDataLayer = (): void => {
            DataLayer.getInstance()
                .useCheckout()
                .addActionParam('step', CurrentStep)
                .addActionParam('option', DataLayerType.ProductTypeVehicle)
                .newProduct()
                .addProductParam('name', DataLayerType.AutoInsurance)
                .addProductParam('id', DataLayerType.AutoProductFakeId)
                .addProductParam('price', '')
                .addProductParam('category', DataLayerType.AutoInsurance)
                .addProductParam('quantity', 1)
                .addProductParam('variant', '')
                .addProductParam('coupon', '')
                .addProductParam('dimension2', '')
                .addProductParam('dimension8', '')
                .addProductParam('dimension9', '')
                .addProductParam('dimension10', '')
                .addProductParam('dimension11', '')
                .buildAndPush();
        };

        const directModeApply = (): void => {
            forms.form.field('agreePrivacyPolicy').patch(true);
            forms.form.field('plateNumber').patch(userPlateNumber.value);
            if (btaBase.cmsFieldIsEnabled('vehicleOwnerIdentification')) {
                forms.form.field('vehicleOwnerIdentification').patch(userPersonCodeOrRegNum.value);
            }
            forms.form
                .validate()
                .then((): void => {
                    if (forms.form.isValid()) {
                        btaBase.userStorage.saveForms(true).then((): void => {
                            pushDataLayer();
                            submit();
                        });
                    } else {
                        btaBase.error.show(
                            ErrorType.Error,
                            'directModeApply',
                            useTranslate().translateForType('error_invalid_form_parameters', 'errors'),
                        );
                    }
                })
                .catch((reason: any): void => {
                    btaBase.error.show(
                        ErrorType.Error,
                        'directModeApply',
                        useTranslate().translateForType('error_invalid_form_parameters', 'errors'),
                    );
                });
        };

        const checkRestrictedPurchase = (): void => {
            const params: URLSearchParams = new URLSearchParams(window.location.search);
            const purchaseRestricted: string | null = params.get('purchaseRestricted');
            if (purchaseRestricted) {
                btaBase.clearGet();
                const simpleError: SimpleError = new OnePopup()
                    .withType()
                    .simpleError.withConfirmButtonColor(ButtonIconColor.Green)
                    .withTitle(useTranslate().translate('vehicle_purchase_restriction_no_data_match'))
                    .withDescription('')
                    .withAutoWidth()
                    .withSecondaryDescription(useTranslate().translate('vehicle_purchase_restriction_wrong_user'));
                PopupService.getInstance().show(simpleError);
            }
        };

        const sendViewContentEvent = (): void => {
            request
                .post(Url.Ajax.viewContentEvent, {
                    productCategory: 'AutoInsurance',
                })
                .then();
        };

        const patchValuesFromGetParams = (): void => {
            const params: URLSearchParams = new URLSearchParams(window.location.search);
            const plate: string | null = params.get('plateNumber');
            const registrationNumber: string | null = params.get('registrationNumber');
            if (plate) {
                forms.form.field('plateNumber').patch(plate);
            }
            if (registrationNumber) {
                forms.form.field('vehicleOwnerIdentification').patch(registrationNumber);
            }
        };

        return {
            ...btaBase,
            ...forms,
            ...{
                CurrentStep,
                Facility,
                isLeasingCompanyEntered,
                userPlateNumber,
                userPersonCodeOrRegNum,
                isDirectLinkMode,
                encodedReference,
                restrictedByGeoIp,
                plateNumber,
                code,
                encodedRef,
                specialCarUsageTypeOptions,
                administrativeCodeOptions,
                fetchAdministrativeRegionsInProgress,
                driverLicenceDateDisabled,
                leasingCompanies,
                errorCodeSuffix,
                appCountry,
                vehicleNotOwnedPopupEnabled,
                vehicleNotOwnedPopupTitle,
                vehicleNotOwnedPopupDescription,
                vehicleNotOwnedPopupCheckbox,
                vehicleSumInsuredLimits,
                appliedMissingParams,
                init,
                setupForm,
                onAfterFormRestored,
                applyErrorCodeSuffix,
                applyLeasingCompanies,
                applyGeoIpRestriction,
                onPlateNumberChange,
                onSpecialCarUsageChange,
                noDriversLicenceChange,
                driverHasLicenceChange,
                hasMultipleMissingParams,
                autoFillSpecialCarUsageForTaxi,
                applySpecialUsageForTaxi,
                clearSpecialUsageFields,
                fetchAdministrativeRegions,
                applyMissingParams,
                applyVehicleNotOwnedEnabled,
                applyVehicleNotOwnedTitle,
                applyVehicleNotOwnedDescription,
                applyVehicleNotOwnedCheckbox,
                vehicleNotOwnedPopupCloseBack,
                vehicleNotOwnedPopupCloseContinue,
                quoteAndBuy,
                showPurchaseRestrictedPopup,
                submit,
                assembleStep1,
                assembleStep1CustomParams,
                vehicleOwnerValidationResults,
                submitStep1,
                isCorporateVehicle,
                saveStepStorageAndContinue,
                isRequestMissingParams,
                isPersonNotOwnerCase,
                clearSpecialCarUsageAndResubmit,
                setupMissingFieldsForm,
                sumInsuredValidator,
                engineCapacityValidator,
                driverLicenseValidator,
                makeSpecialCarUsageTypeOptions,
                getCookie,
                processDriverLicenseFields,
                pushDataLayer,
                directModeApply,
                checkRestrictedPurchase,
                sendViewContentEvent,
                patchValuesFromGetParams,
            },
        };
    },

    mounted() {
        if (!this.restrictedByGeoIp) {
            this.applyApp(this);
            this.initBtaBase();
            this.initReCaptcha();

            this.setStep(this.CurrentStep);
            this.setFacility(this.Facility);
            this.setStorageUsage(true);
            Translations.getInstance().addType('auto-policy');
            this.init();
            const onAfterFormRestoredSubscription = this.userStorage.onFormStorageDataIsReady.subscribe(() => {
                this.patchValuesFromGetParams();
                this.onAfterFormRestored();
                onAfterFormRestoredSubscription.unsubscribe();
            });

            nextTick((): void => {
                this.setupForm();
                if (this.cmsFieldIsEnabled('specialCarUsageType')) {
                    this.makeSpecialCarUsageTypeOptions();
                }
            });
        }
    },
});
</script>
