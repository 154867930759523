<script lang="ts">
import { defineComponent, onMounted, ref, Ref, nextTick } from 'vue';
import VueEvent from '@/Classes/VueEventClass';
import PopupBase from '@/Assets/Libraries/Popups/PopupBase';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import PopupService from '@/Services/custom.popup.service';
import { AxiosResponse } from 'axios';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Url from '@/Enums/UrlEnum';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import OneBase from '@/Interfaces/OneBaseInterface';
import OneBaseService from '@/Services/OneBaseService';
import { useDefine } from '@/Composables/Define';
import { useNavigate } from '@/Composables/Navigate';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const request: AxiosParams = useAxios();

        let currentItemId: number = 0;
        let businessDashboardUrl: string = '';
        let maxSlidesCount: number = 0;
        const activePinId: Ref<number> = ref(0);

        onMounted(() => {
            btaBase.initBtaBase();
        });

        function slideIsActive(pinId: number): boolean {
            return currentItemId === pinId;
        }

        function pinIsActive(pinId: number): boolean {
            return activePinId.value === pinId;
        }

        function applyMaxSlides(slides: number): void {
            maxSlidesCount = slides;
        }

        function prevTip(): void {
            currentItemId--;
            if (currentItemId < 0) {
                currentItemId = 0;
                openSelectAccountPopup();
            }
            updatePins();
        }

        function nextTip(): void {
            currentItemId++;
            if (currentItemId >= maxSlidesCount) {
                currentItemId = maxSlidesCount - 1;
                btaBase.lockInput(true);
                closeAndProceed();
            }
            updatePins();
        }

        function applyBusinessDashboardUrl(url: string): void {
            businessDashboardUrl = url;
        }

        function updatePins(): void {
            nextTick(() => {
                activePinId.value = currentItemId;
            });
        }

        function openSelectAccountPopup(): void {
            const selectAccountPopup: PopupBase = new OnePopup()
                .withType()
                .selectAccountPopup.withoutHeader()
                .withClickCallback(selectEntity)
                .withCloseButton();
            PopupService.getInstance().show(selectAccountPopup);
        }

        function closeAndProceed(): void {
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);
            request
                .get(Url.Ajax.disableLegalOnBoarding)
                .then((value: AxiosResponse): void => {
                    if (useDefine().validResponse(value)) {
                        useNavigate().navigate(businessDashboardUrl);
                    } else {
                        btaBase.lockInput(false);
                        btaBase.error.show(ErrorType.Error, 'disableOnBoarding', value);
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    btaBase.lockInput(false);
                    btaBase.error.show(ErrorType.Error, 'disableOnBoarding', reason);
                });
        }

        function selectEntity(event: VueEvent): void {
            btaBase.lockInput(true);
            request
                .post(Url.Ajax.selectEntity, event)
                .then((response: AxiosResponse): void => {
                    if (useDefine().validResponse(response)) {
                        useNavigate().navigate(response.data.data.body.redirectUrl);
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    btaBase.error.show(ErrorType.Error, 'selectEntity', reason);
                });
        }

        return {
            ...btaBase,
            ...{
                slideIsActive,
                pinIsActive,
                applyMaxSlides,
                prevTip,
                nextTip,
                applyBusinessDashboardUrl,
            },
        };
    },
});
</script>
