export enum FormNames {
    // 1st
    WhatHappenedToTransport = 'whatHappenedToTransport',
    HasCarriersTripCancellationConfirmation = 'hasCarriersTripCancellationConfirmation',
    // 2nd
    ReasonOfTripCancellation = 'reasonOfTripCancellation',
    HowWasTripPlanned = 'howWasTripPlanned',
    HadOtherInsuredPersonsWhoDidNotGo = 'hadOtherInsuredPersonsWhoDidNotGo',
    HadIndemnificationOfLoses = 'hadIndemnificationOfLoses',
}
