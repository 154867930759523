import InsuredRisk from '@/Interfaces/OnePolicy/insured.risk.interface';

export default class InsuredRisksBuilder {
    private currentRiskIndex: number = -1;
    private risks: InsuredRisk[] = [];

    public startNewRisk(): this {
        this.currentRiskIndex++;
        this.risks.push(
            new (class implements InsuredRisk {
                public currency: string = 'EUR';
                public id: string = '';
                public name: string = '';
                public sum: number = 0;
            })(),
        );

        return this;
    }

    public withRiskIc(ic: string): this {
        this.checkRisk();
        this.risks[this.currentRiskIndex].id = ic;

        return this;
    }

    public withRiskName(name: string): this {
        this.checkRisk();
        this.risks[this.currentRiskIndex].name = name;

        return this;
    }

    public withRiskInsuredSum(insuredSum: number): this {
        this.checkRisk();
        this.risks[this.currentRiskIndex].sum = insuredSum;

        return this;
    }

    public build(): InsuredRisk[] {
        return this.risks;
    }

    private checkRisk(): this {
        if (this.currentRiskIndex === -1) {
            this.currentRiskIndex = 0;
        }

        return this;
    }
}
