<script setup lang="ts">
import { computed, PropType } from 'vue';
import AppInputDate from '@/Components/Inputs/InputDate/InputDate.vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import { useTranslate } from '@/Composables/Translate';

const { translateForType } = useTranslate();

const props = defineProps({
    formField: { type: Object as PropType<FormField<string>>, default: () => new FormField('') },
    label: { type: String, default: '' },
    delimiter: { type: String, default: '.' },
    validateBirthDate: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    allowInvalidValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
});

const dateFormField = computed(() => {
    return props.formField as unknown as FormField<Date | string>;
});

const emit = defineEmits(['change']);
</script>
<template>
    <div class="input-birthdate">
        <app-input-date
            class="date"
            :label="label"
            :delimiter="delimiter"
            :disabled="disabled"
            :allow-invalid-value="allowInvalidValue"
            :form-field="dateFormField"
            :data-store-disabled="dataStoreDisabled"
            @change="emit('change')"
        >
        </app-input-date>
        <span class="date-hint">{{ translateForType('day_month_year', 'components') }}</span>
    </div>
</template>

<style lang="scss" scoped>
.input-birthdate {
    .date {
        width: 100%;
        margin-bottom: var(--size-pico);

        :deep(.wrapper) {
            height: 52px;
            justify-content: flex-start;
            padding: 0 30px 0 20px;
        }
    }

    .date-hint {
        color: var(--text-color-subtlest);
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
    }
}
</style>
