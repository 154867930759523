<script setup lang="ts">
import { computed, onMounted, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import RecurringPaymentCard from '@/Interfaces/recurring.payment.card.interface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import OptionWithToken from '@/Interfaces/option.with.token.interface';
import { useHtml } from '@/Composables/Html';
import { useDefine } from '@/Composables/Define';
import ImageTagAttributes from '@/Interfaces/image.tag.attributes';
import RecurringPaymentCardStatus from '@/Enums/RecurringPaymentCardStatusEnum';
import { useTranslate } from '@/Composables/Translate';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import RequestService from '@/Services/request.service';

const props = defineProps({
    showAllCards: { type: Boolean, default: false },
});

const emit = defineEmits(['card-status-widget-update']);

const request: AxiosParams = useAxios();
const { translate } = useTranslate();
const { isSet } = useDefine();
const { imgTag } = useHtml();

const segmentMask: string = '&nbsp;&bull;&bull;&bull;&bull;&nbsp;';
const defaultStatusMessage = 'active';

const paymentCards: Ref<RecurringPaymentCard[]> = ref([]);
const axiosToken: UnwrapNestedRefs<OptionWithToken> = reactive({
    options: [],
    cancelToken: null,
    fetchIsInProgress: false,
});

const renderComponent: Ref<boolean> = computed(() => {
    return props.showAllCards
        ? paymentCards.value.length > 0
        : paymentCards.value.some((card: RecurringPaymentCard): boolean => card.isExpired || card.hasInsufficientFunds);
});

const warningIcon: Ref<string> = computed(() => {
    return imgTag(warningIconAttributes.value);
});

const warningIconAttributes: Ref<ImageTagAttributes> = computed(() => {
    const source: string = 'images/one/circle-exclamation-mark-background.svg';
    return {
        class: '',
        src: source,
        width: 44,
        height: 44,
    };
});

const cardStatusMessages: Ref<DynamicDictionary> = computed(() => {
    return {
        [RecurringPaymentCardStatus.Expired]: {
            title: translate('credit_card_status_widget_title_expired'),
            description: translate('credit_card_status_widget_description_expired'),
        },
        [RecurringPaymentCardStatus.Inactive]: {
            title: translate('credit_card_status_widget_title_inactive'),
            description: translate('credit_card_status_widget_description_inactive'),
        },
        [RecurringPaymentCardStatus.InsufficientFunds]: {
            title: translate('credit_card_status_widget_title_insufficient'),
            description: translate('credit_card_status_widget_description_insufficient'),
        },
    };
});

onMounted((): void => {
    fetchRecurringPaymentCards();
});

function numberMask(cardNumbers: string): string {
    return (
        '<div class="card-mask">' +
        '<div class="masked-numbers">' +
        '<img class="icon" src="images/one/bank/generic_card.svg" alt="card-icon"/>' +
        '<span class="mask">' +
        segmentMask +
        '</span>' +
        cardNumbers +
        '</div></div>'
    );
}

function cardStatusMessage(item: RecurringPaymentCard, key: string): string {
    let result: string;
    switch (true) {
        case item.isExpired:
            result = cardStatusMessages.value[RecurringPaymentCardStatus.Expired][key];
            break;
        case item.isInactive:
            result = cardStatusMessages.value[RecurringPaymentCardStatus.Inactive][key];
            break;
        case item.hasInsufficientFunds:
            result = cardStatusMessages.value[RecurringPaymentCardStatus.InsufficientFunds][key];
            break;
        default:
            result = translate(defaultStatusMessage);
            break;
    }

    return result;
}

function onUpdateCardClick(): void {
    emit('card-status-widget-update');
}

function transformedCards(cards: DynamicDictionary): RecurringPaymentCard[] {
    let result: RecurringPaymentCard[] = [];
    if (isSet(cards.active) && Array.isArray(cards.active) && cards.active.length > 0) {
        result = cards.active;
    }
    if (isSet(cards.inactive) && Array.isArray(cards.inactive) && cards.inactive.length > 0) {
        result = [...result, ...cards.inactive];
    }

    return result;
}

function fetchRecurringPaymentCards() {
    if (axiosToken.fetchIsInProgress) {
        axiosToken.cancelToken!.cancel();
    }
    axiosToken.fetchIsInProgress = true;
    axiosToken.cancelToken = RequestService.getInstance().cancelTokenSource();
    request
        .get(Url.Ajax.fetchRecurringPaymentCards, { cancelToken: axiosToken.cancelToken.token })
        .then((response: AxiosResponse) => {
            paymentCards.value = transformedCards(response.data.data.body.paymentsByCard);
            axiosToken.fetchIsInProgress = false;
        })
        .catch(() => {
            axiosToken.fetchIsInProgress = false;
        });
}
</script>

<template>
    <div v-if="renderComponent" :id="'credit-card-status-widget'" class="credit-card-status-widget">
        <ul>
            <li v-for="(item, index) in paymentCards" :key="index" class="flex mobile-column">
                <div class="flex">
                    <div class="icon" v-html="warningIcon"></div>
                    <div class="status-messages flex">
                        <p class="tiny black-text">{{ cardStatusMessage(item, 'title') }}</p>
                        <p class="nano">{{ cardStatusMessage(item, 'description') }}</p>
                    </div>
                </div>
                <div class="flex mobile-column margin-top-mobile">
                    <div class="mask" v-html="numberMask(item.cardNumberPart)"></div>
                    <button class="button red border-radius-small margin-left-top-desktop" @click="onUpdateCardClick">
                        {{ translate('credit_card_status_widget_button_text') }}
                    </button>
                </div>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
.credit-card-status-widget {
    width: 100%;
    padding: var(--size-normal);
    border-radius: 16px;
    scroll-margin-top: 4em;
    background-color: var(--component-color-background-base);

    @include respond-above('sm') {
        width: 800px;
    }

    ul {
        li {
            justify-content: space-between;

            .status-messages {
                flex-direction: column;
                justify-content: center;

                p {
                    line-height: var(--line-height-accented);
                }
            }

            :deep(.card-mask) {
                .masked-numbers {
                    .mask {
                        font-size: var(--font-size-medium);
                        vertical-align: sub;
                    }
                }
            }

            &:not(:first-child) {
                margin-top: var(--size-normal);
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--black-300);
                padding-bottom: var(--size-normal);
            }
        }
    }

    .mobile-column {
        align-items: center;
        flex-direction: column;
        width: 100%;

        @include respond-above('sm') {
            width: auto;
            flex-direction: row;
        }
    }

    .icon {
        margin-right: var(--size-tiny);
    }

    .flex {
        display: flex;
    }

    .black-text {
        color: var(--text-color-default);
    }

    .tiny {
        font-size: var(--font-size-tiny);
    }

    .nano {
        font-size: var(--font-size-nano);
    }

    .border-radius-small {
        border-radius: 8px;
    }

    .margin-left-top-desktop {
        margin-left: 0;
        margin-top: var(--size-normal);

        @include respond-above('sm') {
            margin-left: var(--size-normal);
            margin-top: 0;
        }
    }

    .margin-top-mobile {
        margin-top: var(--size-normal);

        @include respond-above('sm') {
            margin-top: 0;
        }
    }
}
</style>
