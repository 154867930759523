import VehicleType from '@/Interfaces/Vehicle/type.interface';
import VehicleDamageType from '@/Interfaces/Vehicle/damage.interface';
import VehicleAge from '@/Interfaces/Vehicle/age.interface';

export default class PartnerVehicle {
    public static Type: VehicleType = new (class implements VehicleType {
        public Light: string = 'A';
        public Cargo: string = 'C';
        public Motorcycle: string = 'M';
    })();
    public static DamageType: VehicleDamageType = new (class implements VehicleDamageType {
        public Glass: string = 'glass_repair';
        public Other: string = 'other_repair';
    })();
    public static Age: VehicleAge = new (class implements VehicleAge {
        public AboveThreshold: string = 'ABOVE';
        public BelowThreshold: string = 'BELOW';
    })();
}
