export enum Color {
    Primary = 'primary',
    PrimaryAlternative = 'primary-alternative',
    Secondary = 'secondary',
}

export enum Fill {
    Solid = 'solid',
    Outline = 'outline',
    Clear = 'clear',
}

export enum Size {
    Small = 'small', // size small is not by design
    Medium = 'medium', // size medium is not by design
    Large = 'large',
}
