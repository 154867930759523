import ChatService from '@/Services/chat.service';
import SettingsService from '@/Services/settings.service';
import CookiesNotifications from '@/Services/cookies.notifications.service';

export const useChat = (): ChatParams => {
    const addChat = (): void => {
        const settings: SettingsService = SettingsService.getInstance();
        const cookiesNotifications: CookiesNotifications = new CookiesNotifications();
        if (settings.chatEnabled() && settings.value('VIEW_MODE') !== 'embed' && cookiesNotifications.isSetCookie) {
            new ChatService().addBitrixChat();
        }
    };

    return {
        addChat,
    };
};

export interface ChatParams {
    addChat: () => void;
}
