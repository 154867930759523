import Attachment from '@/Components/TwoWayCommunication/Message/Interfaces/Attachment';
import Decision from '@/Components/Claims/ClaimOverview/Interfaces/Decision';
import UploadedFile from '@/Interfaces/uploaded.file.interface';

export default class DecisionBuilder {
    private claimDecision: Decision = this.cleanValues();

    public withBlockTitle(value: string): this {
        this.claimDecision.title = value;

        return this;
    }

    public withBlockDescription(value: string): this {
        this.claimDecision.description = value;

        return this;
    }

    public withDocumentTitle(value: string): this {
        this.claimDecision.documentsTitle = value;

        return this;
    }

    public withDocuments(documents: Attachment[] | UploadedFile[]): this {
        this.claimDecision.documents = documents;

        return this;
    }

    public withDecisionOpened(): this {
        this.claimDecision.opened = true;

        return this;
    }

    public build(): Decision {
        return this.claimDecision;
    }

    private cleanValues(): Decision {
        return new (class implements Decision {
            public title: string = '';
            public description: string = '';
            public documentsTitle: string = '';
            public documents: Attachment[] | UploadedFile[] = [];
            public opened: boolean = false;
        })();
    }
}
