import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import AppProcessing from '@/Components/Loaders/Processing/Processing.vue';
import Spinner from '@/Components/Loaders/Spinner/Spinner.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppContentLoader: AppContentLoader,
    AppProcessing: AppProcessing,
    AppSpinner: Spinner,
} as Components;
