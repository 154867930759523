import CacheHash from '@/Interfaces/cache.hash.interface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useGenerator } from '@/Composables/Generator';

export default class AxiosCache {
    private static instance: AxiosCache;
    private cachedRequests: CacheHash = {};

    public static getInstance(): AxiosCache {
        if (!AxiosCache.instance) {
            AxiosCache.instance = new AxiosCache();
        }
        return AxiosCache.instance;
    }

    public fetchCache(params: DynamicDictionary | string): DynamicDictionary | null {
        let result: DynamicDictionary | null = null;
        const hash: string = useGenerator().generateHash(params as DynamicDictionary);
        if (typeof this.cachedRequests[hash] !== 'undefined' && this.cachedRequests[hash] !== null) {
            result = this.cachedRequests[hash];
        }

        return result;
    }

    public storeCache(params: DynamicDictionary | string, value: DynamicDictionary): void {
        this.cachedRequests[useGenerator().generateHash(params as DynamicDictionary)] = value;
    }

    public clearCache(params: DynamicDictionary | string): void {
        this.cachedRequests[useGenerator().generateHash(params as DynamicDictionary)] = null;
    }

    public destroy(): void {
        this.cachedRequests = {};
    }
}
