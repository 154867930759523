<script setup lang="ts">
import { PropType } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import { useDefine } from '@/Composables/Define';
import { useHtml } from '@/Composables/Html';
import { usePrice } from '@/Composables/Price';
import ImageTagAttributes from '@/Interfaces/image.tag.attributes';
import { Bicycle } from '@/Interfaces/Resources/Bicycles/BicycleInterface';
import { CoverageRisk } from '@/Interfaces/Resources/Bicycles/CoverageRiskInterface';
import IconSide from '@/Components/Tooltips/TextWithTip/IconSide.enum';
import CoveredPopupAutomationIterator from '@/Components/Popups/CoveredPopup/Classes/CoveredPopupAutomationIterator';

const props = defineProps({
    title: { type: String, default: '' },
    policies: {
        type: Object as PropType<Bicycle>,
        default: () => {},
    },
    excludedRisks: { type: Array, default: () => [] },
    translationType: { type: String, default: '' },
});

const emit = defineEmits(['selected', 'blur', 'close']);

const { translate, translateForType } = useTranslate();
const { isSet } = useDefine();
const { imgTag } = useHtml();
const { price } = usePrice();

const DefaultInsuranceTerritory: string = 'EUROPE';

function riskStatus(risk: CoverageRisk): string {
    return !risk.isCovered ? risk.insuredSum + ' &euro;' : `${iconContent(checkmarkIconAttributes())}`;
}

function insuranceTerritoryStatus(territory: string): string {
    return territory === DefaultInsuranceTerritory
        ? `${iconContent(checkmarkIconAttributes())}`
        : `${iconContent(crossIconAttributes())}`;
}

function checkmarkIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon checkmark',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    };
}

function crossIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon',
        src: 'images/one/cross-icon.svg',
        width: 14,
        height: 14,
    };
}

function iconContent(iconAttributes: ImageTagAttributes): string {
    return useHtml().imgTag(iconAttributes);
}

function close(): void {
    emit('close');
}
</script>

<template>
    <div class="covered-popup-multiple">
        <div class="coverage-title">{{ translateForType(title, translationType) }}</div>
        <div class="no-plans"></div>
        <div class="covered-information">
            <div class="coverage-text">
                <div class="title">{{ translateForType('insurance_coverage_title', translationType) }}</div>
                <div class="description">{{ translateForType('insurance_coverage_description', translationType) }}</div>
            </div>
            <div
                v-for="(plan, index) in policies.coveragePlans"
                :id="'covered-popup-bicycle-' + CoveredPopupAutomationIterator.blockIndexIterate()"
                :key="index"
            >
                <div class="title grey-background">
                    <p class="coverage-risk-title">
                        {{ translateForType(plan.ic + '_TITLE', translationType) }}
                    </p>
                </div>
                <div
                    :id="'covered-block-bicycle-item-' + CoveredPopupAutomationIterator.blockIndex() + '-0'"
                    class="risk with-price"
                >
                    <div class="sub-title bold">{{ translateForType('insurance_total_sum', translationType) }}</div>
                    <div class="status">
                        <div class="item">
                            <div v-html="plan.sumInsured + ' &euro;'"></div>
                        </div>
                    </div>
                </div>
                <div v-for="(risk, riskIndex) in plan.risks" :key="riskIndex">
                    <div :id="'covered-popup-bicycle-risk-' + index + '-' + (riskIndex + 1)" class="risk">
                        <app-text-with-tip
                            class="sub-title"
                            :info-icon-side="IconSide.Before"
                            :title="translateForType(risk.id + '_MAIN', translationType)"
                            :tip-description="translateForType(risk.id + '_MAIN_TOOLTIP', translationType)"
                            :value="riskStatus(risk)"
                        >
                        </app-text-with-tip>
                    </div>
                </div>
            </div>
            <div :id="'covered-popup-bicycle-' + CoveredPopupAutomationIterator.blockIndexIterate()">
                <div class="title grey-background">
                    <p class="coverage-risk-title">
                        {{ translateForType('INSURANCE_TERRITORY_TITLE', translationType) }}
                    </p>
                </div>
                <div
                    :id="'covered-block-bicycle-item-' + CoveredPopupAutomationIterator.blockIndex() + '-0'"
                    class="risk"
                >
                    <app-text-with-tip
                        class="sub-title"
                        :info-icon-side="IconSide.Before"
                        :title="translateForType('TERRITORY_EUROPE_MAIN', translationType)"
                        :tip-description="translateForType('TERRITORY_EUROPE_MAIN_TOOLTIP', translationType)"
                        :value="insuranceTerritoryStatus(policies.params.insuranceTerritoryIc)"
                    >
                    </app-text-with-tip>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.covered-popup-multiple {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .no-plans {
        background-color: var(--background-light);
        min-height: 98px;
        border-top-left-radius: var(--size-tiny);
        border-top-right-radius: var(--size-tiny);

        @include respond-above('sm') {
            height: 120px;
        }
    }

    .covered-information {
        display: grid;
        padding: 0 var(--size-small) var(--size-small);
        width: 100%;
        overflow-y: auto;
        max-height: 650px;

        @include respond-above('sm') {
            padding: var(--size-small) var(--size-small) 0 var(--size-big);
        }

        .coverage-text {
            .title {
                font-size: var(--font-size-medium);
                margin-bottom: var(--size-nano) !important;
            }

            .description {
                text-align: left;
            }
        }

        .coverage-amount {
            .amount {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .risk {
            border-bottom: 1px solid var(--black-100);

            .status {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .icon {
                    .checkmark {
                        height: 11px;
                        width: 16px;
                    }
                }

                .item:last-child {
                    border-right: none;
                }
            }
        }

        .with-price {
            display: grid;
            grid-template-columns: 60% 40%;

            @include respond-above('sm') {
                grid-template-columns: 75% 25%;
            }
        }

        .title {
            border-bottom: none;
            padding-bottom: 0;

            .coverage-risk-title {
                color: var(--text-color-default);
                font-size: var(--font-size-small);
                padding: var(--size-tiny) var(--size-normal);
                font-weight: 700;
            }
        }
    }

    .popup-title {
        position: absolute;
        padding-bottom: var(--size-small);
        border-bottom: 1px solid var(--black-100);
    }

    .coverage-title {
        position: absolute;
        max-width: 200px;
        top: 30px;
        left: 25px;
        font-size: var(--font-size-medium);
        font-weight: 700;

        @include respond-above('sm') {
            top: 50px;
            left: var(--size-big);
            max-width: 600px;
        }
    }

    .title {
        margin: var(--size-small) 0 0 !important;
        border-bottom: 1px solid var(--black-100);
        padding-bottom: var(--size-small);
        font-size: var(--font-size-small);
        font-weight: 700;
    }

    .text-with-tip {
        :deep(.sub-title) {
            font-weight: 500 !important;
        }
    }

    .sub-title {
        font-size: var(--font-size-tiny);
        margin: var(--size-tiny) 0 !important;
        font-weight: 500;
    }

    .bold {
        font-weight: 600;
    }

    .grey-background {
        background-color: var(--teal-50);
    }
}
</style>
