<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/Services/OneBaseService';
import { computed, getCurrentInstance, onMounted, ref, Ref, shallowReactive, ShallowUnwrapRef, watch } from 'vue';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import GuardsService from '@/Apps/ClaimsTravel/Services/GuardsService';
import ClaimsTravelStepUid from '@/Apps/ClaimsTravel/Enums/ClaimsTravelStepUid';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import { useClaimsTravelHtml } from '@/Apps/ClaimsTravel/Composables/ClaimsTravelHtml';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import { FormNames as UploadFilesForms } from '@/Apps/ClaimsTravel/Enums/Steps/UploadFiles/FormNames';
import { FilesUploadedFields, Forms } from '@/Apps/ClaimsTravel/Interfaces/Forms/UploadFiles/Forms';
import { FieldNames } from '@/Apps/ClaimsTravel/Enums/Steps/UploadFiles/FieldNames';
import Translations from '@/Services/translations.service';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useAxios } from '@/Composables/Axios';
import { AxiosResponse } from 'axios';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { ClaimsTravelAjaxRoutes } from '@/Apps/ClaimsTravel/Enums/ClaimsTravelAjaxRoutes';
import OneBase from '@/Interfaces/OneBaseInterface';
import { useDefine } from '@/Composables/Define';
import ClaimsTravelFormFields from '@/Apps/ClaimsTravel/Classes/ClaimsTravelFormFields';
import ClaimPage from '@/Components/Other/Page/ClaimPage.vue';

type FormNames = `${UploadFilesForms}`;

const { translateForType, applyTranslationType, type } = useTranslate();
const router: Router = useRouter();
const { scrollToPanel } = useClaimsTravelHtml();
const { validResponse } = useDefine();

const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();
const btaBase: OneBase = OneBaseService.getInstance();
const Uploader: string = 'claims-travel';
const UploaderId: string = 'uploader';
const Step: number = 3;

const filesUploader: Ref<DynamicDictionary | null> = ref(null);
const renew: Ref<boolean> = ref(false);
const uploadCategories: Ref<string[]> = ref([]);
const necessaryDocumentKeys: Ref<string[]> = ref([]);

const forms: ShallowUnwrapRef<{ [FormKey in keyof Forms]: Form<Forms[FormKey]> }> = shallowReactive(
    {},
) as ShallowUnwrapRef<{
    [FormKey in keyof Forms]: Form<Forms[FormKey]>;
}>;

const uploadInProgress: Ref<boolean> = computed(() => {
    return filesUploader.value ? !filesUploader.value.uploadsSuccessful : true;
});

const uploaderIsReady: Ref<boolean> = computed(() => {
    return filesUploader.value ? filesUploader.value.componentIsReady : false;
});

const isUploaded: Ref<boolean> = computed(() => {
    return filesUploader.value ? !filesUploader.value.filesNotUploaded && filesUploader.value.uploadsSuccessful : false;
});

function setupForm(): void {
    const uploadedFilesForm: Form<FilesUploadedFields> = new Form({ useValidationV2: true });
    uploadedFilesForm.addField(new FormField(FieldNames.FilesUploaded, false, ''));
    forms[UploadFilesForms.FilesUploaded] = uploadedFilesForm;
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function storeFormToService(): void {
    forms[UploadFilesForms.FilesUploaded].field(FieldNames.FilesUploaded).patch(Boolean(isUploaded.value));
    storeForm(UploadFilesForms.FilesUploaded);
}

function fetchUploadCategories(): void {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    //TODO implement params when steps before ready
    const params: DynamicDictionary = {
        accidentType: claimsTravelService.fields.accidentType!.accidentType.selected,
    };
    useAxios()
        .get(ClaimsTravelAjaxRoutes.UploadCategories, { params })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (validResponse(response)) {
                uploadCategories.value = response.data.data.body.uploadCategories;
                necessaryDocumentKeys.value = response.data.data.body.documentKeys ?? [];
            }
        })
        .catch((reason: LimitedVariant): void => {
            btaBase.error.show(ErrorType.Error, 'uploadCategories', reason as DynamicDictionary);
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
        });
}

function onProceed(): void {
    storeFormToService();
    GuardsService.getInstance().applyStepValidity(Step, true, true);
    router.push({ name: ClaimsTravelStepUid.RecipientData });
}

function proceedButton(): DynamicDictionary {
    return {
        title: isUploaded.value ? translated('proceed') : translated('upload_later'),
        textColor: isUploaded.value ? ButtonTextColor.White : ButtonTextColor.Black,
        backgroundColor: isUploaded.value ? ButtonBackground.Red : ButtonBackground.Transparent,
        backgroundColorHover: isUploaded.value ? ButtonBackground.Red : ButtonBackground.Transparent,
        icon: isUploaded.value ? ButtonIcon.LongArrowRight : '',
        iconPosition: ButtonIconPosition.Right,
    };
}

function markFormsAsReady(): void {
    Object.values(forms).forEach((form) => form.setReady());
}

function storeForm(formName: FormNames): void {
    return claimsTravelService.storeForm(
        formName as keyof ClaimsTravelFormFields,
        forms[formName as keyof ClaimsTravelFormFields],
    );
}

function onFilesUploaded(): void {
    GuardsService.getInstance().applyStepValidity(Step, false);
}

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('claims_travel');
    setupForm();
    fetchUploadCategories();
    claimsTravelService.restoreForms(forms);
    setTimeout(() => {
        markFormsAsReady();
    });
});

defineExpose({});
</script>

<template>
    <claim-page class="horizontal-spacing">
        <template #title>
            <h3>{{ translated('upload_files') }}</h3>
        </template>
        <app-custom-form
            v-if="forms[UploadFilesForms.FilesUploaded]?.isReady()"
            :form="forms[UploadFilesForms.FilesUploaded]"
            class="form"
        >
            <div class="whiteboard-panel whiteboard-panel-margin">
                <div class="whiteboard" :data-scroll="UploaderId">
                    <div class="documents-descriptions">
                        {{ translated('description_upload_documents') }}
                        <ul>
                            <li
                                v-for="(document, index) in necessaryDocumentKeys"
                                :key="index"
                                v-html="translated(document)"
                            ></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="uploader">
                <app-files-uploader
                    ref="filesUploader"
                    :uploader="Uploader"
                    :renew="renew"
                    :translation-type="Translations.getInstance().type"
                    :upload-categories="uploadCategories"
                    @file-change="onFilesUploaded()"
                >
                </app-files-uploader>
            </div>
            <div class="input">
                <app-button-with-callback
                    v-if="uploaderIsReady"
                    class="button"
                    data-type="upload-submit"
                    :disabled="uploadInProgress"
                    v-bind="proceedButton()"
                    @button-callback-click="onProceed()"
                >
                </app-button-with-callback>
            </div>
        </app-custom-form>
    </claim-page>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .input:not(:last-child) {
        margin-bottom: 24px;
    }

    .documents-descriptions {
        list-style-position: inside;
        color: var(--text-color-subtlest);
        font-weight: 600;

        ul {
            list-style: disc inside;
            font-weight: 500;

            li {
                margin-top: var(--size-pico);
                font-size: var(--font-size-nano);
            }
        }
    }

    .button {
        margin-top: 32px;
    }

    .uploader {
        margin-top: var(--size-small);

        :deep(.files-uploader) {
            .category {
                padding: var(--size-small);

                @include respond-above('sm') {
                    padding: var(--size-big);
                }

                .category-header {
                    font-weight: 600;
                }

                .files-uploader-area {
                    .upload-container {
                        width: 100%;

                        @include respond-above('sm') {
                            width: 75%;
                        }
                    }

                    .qr {
                        width: 0;

                        @include respond-above('sm') {
                            width: 25%;
                        }
                    }
                }
            }
        }
    }

    .input {
        width: 100%;
        margin-bottom: var(--size-big);

        .button-with-callback {
            max-width: fit-content;
            height: 52px;
            margin-top: var(--size-tiny);
            padding: 0 var(--button-horizontal-padding);
        }
    }
}
</style>
