import MultiCountersValue from '@/Interfaces/multi.counters.value.interface';
import CounterValue from '@/Interfaces/counter.value.interface';

export default class InsuranceSanitizers {
    public static travellersSanitizer(counters: MultiCountersValue): MultiCountersValue {
        if (counters) {
            counters.counters[1].counters.forEach((counter: CounterValue, index: number) => {
                const baseValue: number = counters.counters[0].counters[index].value;
                if (baseValue < counter.value) {
                    counters.counters[1].counters[index].value = baseValue;
                }
            });
        }

        return counters;
    }
}
