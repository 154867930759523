<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import { computed, PropType, Ref } from 'vue';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

const props = defineProps({
    label: { type: String, default: '' },
    rowElementsCount: { type: Number, default: 5 },
    buttonLabel: { type: String, default: '' },
    items: {
        type: Array as PropType<string[]>,
        default: () => {
            return [];
        },
    },
    formField: { type: Object as PropType<FormField<string>>, default: () => new FormField('') },
    disabled: { type: Boolean, default: false },
});
const emit = defineEmits(['action']);

function onActionClick(): void {
    emit('action');
}

const showItems: Ref<boolean> = computed(() => {
    return transformedItems.value.length > 0;
});

const transformedItems: Ref<DynamicDictionary[]> = computed(() => {
    const result: DynamicDictionary[] = [];
    for (let index: number = 0; index < props.items.length; index += props.rowElementsCount) {
        const temp: string[] = props.items.slice(index, index + props.rowElementsCount);
        result.push(temp);
    }
    return result;
});
</script>

<template>
    <div class="input items-button" :class="{ ...formField.classes(), disabled: disabled }">
        <div v-if="label" class="label informative">
            <p>{{ label }}</p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper">
            <div v-if="showItems" class="list">
                <ul v-for="(transformedItem, itemIndex) in transformedItems" :key="itemIndex">
                    <li v-for="(item, index) in transformedItem" :key="index" class="item">
                        <span v-html="item"></span>
                    </li>
                </ul>
                <button class="item-action" @click="onActionClick">{{ buttonLabel }}</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.items-button {
    width: 100%;

    .label.informative {
        display: flex;
        justify-content: center;
    }

    .wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        height: auto;

        .list {
            position: relative;
            height: 100%;
            border-radius: 3px;
            padding-left: var(--size-small);
            padding-right: var(--size-small);
            border: 1px solid rgb(146 151 160 / 0.46);
            min-width: 300px;
            display: flex;
            flex-direction: column;

            ul {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                @include respond-above('sm') {
                    flex-direction: row;
                }

                .item {
                    font-size: var(--font-size-nano);
                    height: 52px;
                    margin: 0 10px;

                    span {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .item-action {
                height: 100%;
                color: var(--brand-red);
                font-size: var(--font-size-nano);
                margin-bottom: 10px;

                &:hover {
                    color: var(--text-color-default);
                }
            }
        }
    }
}
</style>
