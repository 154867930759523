<script setup lang="ts">
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import { computed, ComputedRef, getCurrentInstance, markRaw, onMounted, Ref, ref } from 'vue';
import Storage from '@/Apps/ActivePlus/Services/Storage';
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import StepsGuard from '@/Apps/ActivePlus/Services/StepsGuard';
import OneBaseService from '@/Services/OneBaseService';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppInputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { ObjectType } from '@/Apps/ActivePlus/Interfaces/ObjectTypeInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Validation from '@/Services/validation.service';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import AppRepeatable from '@/Components/Other/Repeatable/Repeatable.vue';
import AppRepeatableWhiteboardItem from '@/Components/Other/Repeatable/RepeatableWhiteboardItem.vue';
import AppObjectModel from '@/Apps/ActivePlus/Components/ObjectModel.vue';
import AppPage from '@/Apps/ActivePlus/Components/Page.vue';
import { StoredSelectedObject } from '@/Apps/ActivePlus/Interfaces/StoredSelectedObject';
import { FormHelperParams, useFormHelper } from '@/Composables/FormHelper';
import Steps from '@/Apps/ActivePlus/Enums/Steps';

interface ObjectForm {
    objectType: string;
    model: string;
}

interface ObjectItem {
    id: string;
    form: Form<ObjectForm>;
}

const { translate, translateForType } = useTranslate();
const router: Router = useRouter();
const formHelper: FormHelperParams = useFormHelper();
const storage: Storage = Storage.getInstance();
const TranslationType: string = 'velo_plus';
const maxObjects: number = 5;
const isPageReady: Ref<boolean> = ref(false);
const objectTypesOptions: ComputedRef<InputOption<string>[]> = computed((): InputOption<string>[] => {
    return storage.selectedProduct().objectTypes.map((objectType: ObjectType): InputOption<string> => {
        return new InputOptionBuilder<string>().setName(objectType.ic).setValue(objectType.ic).build();
    });
});
const objects: Ref<ObjectItem[]> = ref([]);

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    storage.fetchProducts().then(() => {
        restoreValues();
        isPageReady.value = true;
    });
});

function restoreValues(): void {
    objects.value = storage.fields.selectedObjects.map((storedItem: StoredSelectedObject): ObjectItem => {
        const item: ObjectItem = createObjectFactory(storedItem.formName);
        item.form.restoreValues({
            objectType: storedItem.objectType.ic,
            model: storedItem.model,
        });
        return item;
    });
}

function storeValues(): void {
    storage.fields.selectedObjects = objects.value.map((item: ObjectItem): StoredSelectedObject => {
        return {
            formName: item.form.name,
            model: item.form.field('model').value,
            objectType: objectTypeByIc(item.form.field('objectType').value),
        };
    });
}

function createObjectFactory(id: string): ObjectItem {
    const form: Form<ObjectForm> = new Form(id, { useValidationV2: true });
    form.addField(new FormField('objectType').addValidators({ required: Validation.required }));
    form.addField(
        new FormField('model').addValidators({
            required: Validation.requiredIf(() => form.field('objectType').isNotEmpty()),
        }),
    );
    form.setReady();
    return { id: id, form: markRaw(form) };
}

function onRemove(item: ObjectItem): void {
    item.form.destroy();
}

function onSubmitStep(): void {
    const forms: Form<ObjectForm>[] = objects.value.map((item: ObjectItem): Form<ObjectForm> => item.form);
    formHelper.submitAttemptMultiple(forms).then(() => {
        if (formHelper.isMultipleFormsValid(forms)) {
            storeValues();
            router.push({ name: Steps.InsuredPersons });
        }
    });
}

function objectTypeByIc(ic: string): ObjectType {
    const foundType: ObjectType | undefined = storage
        .selectedProduct()
        .objectTypes.find((objectType: ObjectType): boolean => objectType.ic === ic);
    if (!foundType) {
        throw new Error(`ObjectType "${ic}" not found!`);
    }
    return foundType;
}
</script>

<template>
    <app-page v-if="isPageReady">
        <template #title>{{ translateForType('title_insured_objects', TranslationType) }}</template>
        <template #description>{{
            translateForType('subtitle_insured_objects_description', TranslationType)
        }}</template>
        <app-repeatable :items="objects" :min="1" :max="maxObjects" :factory="createObjectFactory" @remove="onRemove">
            <template #default="{ item, index, repeatable }">
                <app-repeatable-whiteboard-item
                    class="object-item"
                    :item="item"
                    :repeatable="repeatable"
                    :title="
                        translateForType('label_object_details', TranslationType, {
                            number: index + 1,
                        })
                    "
                    :add-button-text="translateForType('button_add_object', TranslationType)"
                >
                    <app-custom-form class="grid-utility grid-columns-1 sm:grid-columns-2" :form="item.form">
                        <app-input-select
                            :label="translateForType('label_object_type', TranslationType)"
                            :options="objectTypesOptions"
                            :form-field="item.form.field('objectType')"
                        ></app-input-select>
                        <app-object-model
                            v-if="item.form.field('objectType').value"
                            :form-field="item.form.field('model')"
                            :object-type="item.form.field('objectType').value"
                            :transaltion-type="TranslationType"
                        ></app-object-model>
                    </app-custom-form>
                </app-repeatable-whiteboard-item>
            </template>
        </app-repeatable>
        <template #footer>
            <app-button-primary @click="onSubmitStep()">
                {{ translateForType('button_object_continue', TranslationType) }}
                <template #end>
                    <app-svg src="images/one/arrow-right.svg" />
                </template>
            </app-button-primary>
        </template>
    </app-page>
</template>
