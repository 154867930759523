import Router from '@/Core/Routing/Router';
import VueApp from '@/Core/Routing/VueApp';
import { App } from 'vue';
import AppComponent from '@/Apps/ClaimsTravel/App.vue';
import GlobalDirectives from '@/Directives/Directives';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import SelectAccountDropdown from '@/Components/Widgets/SelectAccountDropdown/SelectAccountDropdown.vue';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/Routing/RouteStepper/RouteStepper.vue';
import CustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import LanguageSwitcher from '@/Components/Widgets/LanguageSwitcher/LanguageSwitcher.vue';
import Popup from '@/Components/Popups/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import Drawer from '@/Components/Modals/Drawer/Drawer.vue';
import { ComponentPublicInstance } from 'vue';
import PersonListWithSearch from '@/Components/Lists/PersonListWithSearch/PersonListWithSearch.vue';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import InputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';
import InputTextarea from '@/Components/Inputs/InputTextarea/InputTextarea.vue';
import ContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import FilesUploader from '@/Components/Files/FilesUploader/FilesUploader.vue';
import AccidentData from '@/Apps/ClaimsTravel/Pages/AccidentData.vue';
import AccidentDetails from '@/Apps/ClaimsTravel/Pages/AccidentDetails.vue';
import UploadFiles from '@/Apps/ClaimsTravel/Pages/UploadFiles.vue';
import RecipientData from '@/Apps/ClaimsTravel/Pages/RecipientData.vue';
import InsuredObjectsBlocks from '@/Components/Lists/InsuredObjectsBlocks/InsuredObjectsBlocks.vue';
import InputText from '@/Components/Inputs/InputText/InputText.vue';
import InputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import InputMultiCheckbox from '@/Components/Inputs/InputMultiCheckbox/InputMultiCheckbox.vue';
import InputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import PhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import InputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import InputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import InputDateWithDropdown from '@/Components/Inputs/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputNumber from '@/Components/Inputs/InputNumber/InputNumber.vue';
import OptionsSmartList from '@/Components/Options/OptionsSmartList/OptionsSmartList.vue';
import InputTime from '@/Components/Inputs/InputTime/InputTime.vue';
import InputPlateNumber from '@/Components/Inputs/InputPlateNumber/InputPlateNumber.vue';
import ObjectList from '@/Components/Lists/ObjectList/ObjectList.vue';
import InputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import InputDate from '@/Components/Inputs/InputDate/InputDate.vue';
import MapWithAddress from '@/Components/Maps/MapWithAddress/MapWithAddress.vue';
import OptionsList from '@/Components/Options/OptionsList/OptionsList.vue';
import MapWithPlaces from '@/Components/Maps/MapWithPlaces/MapWithPlaces.vue';
import InputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import InputCompanyType from '@/Components/Inputs/InputCompanyType/InputCompanyType.vue';
import InputBankAccount from '@/Components/Inputs/InputBankAccount/InputBankAccount.vue';
import ResultMessage from '@/Components/Other/ResultMessage/ResultMessage.vue';
import HumanBodyParts from '@/Components/Widgets/HumanBodyParts/HumanBodyParts.vue';
import CommunicationNotificator from '@/Components/TwoWayCommunication/CommunicationNotificator/CommunicationNotificator.vue';
import Notification from '@/Components/Widgets/Notification/Notification.vue';
import Whiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import InputError from '@/Components/Inputs/InputError/InputError.vue';
import ClaimLayout from '@/Layouts/ClaimLayout.vue';

const pages: Components = {
    AccidentData: AccidentData,
    AccidentDetails: AccidentDetails,
    UploadFiles: UploadFiles,
    RecipientData: RecipientData,
};

const components: Components = {
    AppClaimLayout: ClaimLayout,
    AppStepperLayout: StepperLayout,
    AppRouteStepper: RouteStepper,
    AppCustomForm: CustomForm,
    AppLanguageSwitcher: LanguageSwitcher,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppDrawer: Drawer,
    AppSelectAccountDropdown: SelectAccountDropdown,
    AppInsuredObjectsBlocks: InsuredObjectsBlocks,
    AppContentLoader: ContentLoader,
    AppInputRadio: InputRadio,
    AppInputText: InputText,
    AppInputEmail: InputEmail,
    AppInputMultiCheckbox: InputMultiCheckbox,
    AppButtonWithCallback: ButtonWithCallback,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppPersonListWithSearch: PersonListWithSearch,
    AppPhoneWithCountry: PhoneWithCountry,
    AppInputCheckbox: InputCheckbox,
    AppInputSelect: InputSelect,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputNumber: InputNumber,
    AppFilesUploader: FilesUploader,
    AppOptionsSmartList: OptionsSmartList,
    AppInputTime: InputTime,
    AppInputPlateNumber: InputPlateNumber,
    AppObjectList: ObjectList,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppInputDate: InputDate,
    AppInputTextarea: InputTextarea,
    AppMapWithAddress: MapWithAddress,
    AppOptionsList: OptionsList,
    AppMapWithPlaces: MapWithPlaces,
    AppInputCountry: InputCountry,
    AppInputCompanyType: InputCompanyType,
    AppInputBankAccount: InputBankAccount,
    AppResultMessage: ResultMessage,
    AppHumanBodyParts: HumanBodyParts,
    AppCommunicationNotificator: CommunicationNotificator,
    AppInputError: InputError,
    AppNotification: Notification,
    AppWhiteboard: Whiteboard,
};

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({ ...pages, ...components })
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());

        return app.mount(selector);
    }
}
