import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { AgGridLocaleBase } from '@/Components/Lists/SmartGrid/Localization/BaseLocalization';

export default class GridLocalization {
    private static instance: GridLocalization;
    private overrides: DynamicDictionary = {};

    private constructor() {}

    public static getInstance(): GridLocalization {
        if (!GridLocalization.instance) {
            GridLocalization.instance = new GridLocalization();
        }

        return GridLocalization.instance;
    }

    public withOverride(key: string, value: string): GridLocalization {
        this.overrides[key] = value;

        return this;
    }

    public build(): DynamicDictionary {
        const result: { [key: string]: string } = AgGridLocaleBase;
        Object.keys(this.overrides).forEach((key: string): void => {
            if (typeof AgGridLocaleBase[key] !== 'undefined') {
                result[key] = this.overrides[key];
            }
        });

        return result;
    }
}
