import PolicyDetailsContent from '@/Components/Policies/PolicyDetails/Interfaces/PolicyDetailsContentInterface';
import PolicyDetailsContentTabs from '@/Components/Policies/PolicyDetails/Interfaces/PolicyDetailsContentTabsInterface';
import PolicyDetailsContentTab from '@/Components/Policies/PolicyDetails/Interfaces/PolicyDetailsContentTabInterface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Method from '@/Enums/MethodEnum';
import PolicyDetailsTabs from '@/Components/Policies/PolicyDetails/Enums/PolicyDetailsTabsEnum';
import InvoiceDocumentBlock from '@/Interfaces/OnePolicy/invoice.document.block.interface';
import PolicyDetailsInvoicesContent from '@/Components/Policies/PolicyDetailsInvoices/Interfaces/PolicyDetailsInvoicesContentInterface';
import AgreementTypes from '@/Components/Policies/PolicyBlock/Enums/AgreementTypesEnum';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import Invoice from '@/Components/Policies/PolicyBlock/Interfaces/InvoiceInterface';
import PolicyState from '@/Enums/OnePolicy/PolicyStateEnum';
import UrlBuilder from '@/Assets/Libraries/Url/UrlBuilder';
import Translations from '@/Services/translations.service';
import { Ref, ref } from 'vue';

export default class PolicyDetails {
    private static instance: PolicyDetails;
    private content: PolicyDetailsContent = new (class implements PolicyDetailsContent {
        public type: AgreementTypes = '';
        public title: string = '';
        public description: string = '';
        public agreement!: Agreement;
        public invoices: Invoice[] = [];
        public tabs: PolicyDetailsContentTabs = new (class implements PolicyDetailsContentTabs {
            public active: Ref<number> = ref(0);
            public elements: PolicyDetailsContentTab[] = [];
        })();
    })();

    public static getInstance(): PolicyDetails {
        if (!PolicyDetails.instance) {
            PolicyDetails.instance = new PolicyDetails();
        }

        return PolicyDetails.instance;
    }

    public addContent(content: PolicyDetailsContent): PolicyDetails {
        this.content = content;

        return this;
    }

    public getContent(): PolicyDetailsContent {
        return this.content;
    }

    public processPayment(bankLinkId: number, isRecurringPaymentAction: boolean = false): void {
        const paymentForm: HTMLFormElement = this.paymentForm();
        const paymentDetails: DynamicDictionary = isRecurringPaymentAction
            ? this.recurringPaymentDetails()
            : this.paymentDetails();
        paymentDetails.bank = bankLinkId;
        Object.keys(paymentDetails).forEach((key: string): void => {
            if (paymentDetails[key] !== '') {
                const inputElement: HTMLInputElement = document.createElement('input');
                inputElement.type = 'hidden';
                inputElement.name = key;
                inputElement.value = paymentDetails[key];
                paymentForm.append(inputElement);
            }
        });
        $('body').append(paymentForm);
        paymentForm.submit();
    }

    public activateTab(tabId: string | number): void {
        this.content.tabs.active.value = this.tabIndex(tabId);
    }

    public applyTabId(tabId: string | number, id: string): void {
        if (this.tabIsPresent(tabId)) {
            this.content.tabs.elements[this.tabIndex(tabId)].id = id;
        }
    }

    public applyTabTitle(tabId: string | number, title: string): void {
        if (this.tabIsPresent(tabId)) {
            this.content.tabs.elements[this.tabIndex(tabId)].title = title;
        }
    }

    public applyTabBadge(tabId: string | number, badgeValue: number): void {
        if (this.tabIsPresent(tabId)) {
            this.content.tabs.elements[this.tabIndex(tabId)].badge = badgeValue;
        }
    }

    public tabContent(tabId: string | number): DynamicDictionary {
        let result: DynamicDictionary = {};
        if (this.tabIsPresent(tabId)) {
            result = this.content.tabs.elements[this.tabIndex(tabId)].content;
        }

        return result;
    }

    public get currentAgreement(): Agreement {
        return this.content.agreement;
    }

    public get agreementInvoices(): Invoice[] {
        return this.content.invoices;
    }

    public isValueCurrentActiveTab(value: string | number): boolean {
        return this.tabIndex(value) === this.content.tabs.active.value;
    }

    public get activeTab(): string {
        return this.content.tabs.elements[this.content.tabs.active.value].id;
    }

    public get policyType(): AgreementTypes {
        return this.content.type;
    }

    public set policyType(type: AgreementTypes) {
        this.content.type = type;
    }

    public get policyTitle(): string {
        return this.content.title;
    }

    public set policyTitle(title: string) {
        this.content.title = title;
    }

    public get descriptionTitle(): string {
        return this.content.description;
    }

    public set descriptionTitle(title: string) {
        this.content.description = title;
    }

    public get hasTabs(): boolean {
        return this.content.tabs.elements.length > 0;
    }

    private get invoicesTabContentDocuments(): InvoiceDocumentBlock[] {
        return (PolicyDetails.getInstance().tabContent(PolicyDetailsTabs.Invoices) as PolicyDetailsInvoicesContent)
            .documentBlocks;
    }

    private get firstInvoice(): Invoice | undefined {
        let result: Invoice | undefined;
        if (this.hasLateInvoices()) {
            result = this.agreementInvoices.filter(
                (invoice: Invoice): boolean => invoice.status === PolicyState.Late,
            )[0];
        } else if (this.hasUnpaidInvoices()) {
            result = this.agreementInvoices.filter(
                (invoice: Invoice): boolean => invoice.status === PolicyState.Unpaid,
            )[0];
        }

        return result;
    }

    private hasLateInvoices(): boolean {
        return !!this.agreementInvoices.find((invoice: Invoice): boolean => invoice.status === PolicyState.Late);
    }

    private hasUnpaidInvoices(): boolean {
        return !!this.agreementInvoices.find((invoice: Invoice): boolean => invoice.status === PolicyState.Unpaid);
    }

    private tabIndex(tabId: string | number): number {
        let tabIndex: number = -1;
        const searchTab: string = String(tabId);
        this.content.tabs.elements.forEach((tab: PolicyDetailsContentTab, index: number): void => {
            if (tab.id === searchTab) {
                tabIndex = index;
            }
        });

        return tabIndex;
    }

    private tabIsPresent(tabId: string | number): boolean {
        return this.content.tabs.elements.length > 0 && this.tabIndex(tabId) >= 0;
    }

    private paymentForm(): HTMLFormElement {
        const formElement: HTMLFormElement = document.createElement('form');
        formElement.action = new UrlBuilder()
            .withLanguage(Translations.getInstance().language)
            .withUri('/Policies/pay')
            .build();
        formElement.method = Method.Post;

        return formElement;
    }

    private paymentDetails(): DynamicDictionary {
        return {
            total_amount: this.selectedInvoicesPaymentAmount(),
            policy_id: this.currentAgreement.id,
            policy_nr: this.currentAgreement.agreementNumber,
            pay_type: this.payType(),
            policy_code: this.currentAgreement.typeId,
            date_from: this.currentAgreement.validFrom.date,
            timezone: this.currentAgreement.validFrom.timezone,
            schedules_to_pay: JSON.stringify(this.schedulesToPay()),
            schedule_items: JSON.stringify(this.scheduleItems()),
            _token: this.csrfToken(),
        };
    }

    private recurringPaymentDetails(): DynamicDictionary {
        return {
            total_amount: this.firstUnpaidInvoicePaymentAmount(),
            policy_id: this.currentAgreement.id,
            policy_nr: this.currentAgreement.agreementNumber,
            pay_type: this.payType(),
            policy_code: this.currentAgreement.typeId,
            date_from: this.currentAgreement.validFrom.date,
            timezone: this.currentAgreement.validFrom.timezone,
            schedules_to_pay: JSON.stringify(this.recurringSchedulesToPay()),
            schedule_items: JSON.stringify(this.recurringScheduleItems()),
            card_number_part: this.currentAgreement.cardLastDigits,
            is_recurring: true,
            _token: this.csrfToken(),
        };
    }

    private csrfToken(): string {
        return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? '';
    }

    private payType(): string {
        return this.currentAgreement.type === 'policies' ? 'schedule' : 'offer';
    }

    private scheduleItems(): DynamicDictionary[] {
        const result: DynamicDictionary[] = [];
        this.invoicesTabContentDocuments.forEach((block: InvoiceDocumentBlock): void => {
            block.documents.forEach((invoice: Invoice): void => {
                if (invoice.selected) {
                    result.push({
                        id: invoice.id,
                        number: invoice.paymentNumber,
                        price: invoice.amount,
                        isIndividual: invoice.isIndividual,
                    });
                }
            });
        });

        return result;
    }

    private recurringScheduleItems(): DynamicDictionary[] {
        const result: DynamicDictionary[] = [];
        const firstInvoice: Invoice | undefined = this.firstInvoice;
        if (firstInvoice) {
            result.push({
                id: firstInvoice.id,
                number: firstInvoice.paymentNumber,
                price: firstInvoice.amount,
            });
        }

        return result;
    }

    private schedulesToPay(): DynamicDictionary {
        const result: DynamicDictionary = {};
        this.invoicesTabContentDocuments.forEach((block: InvoiceDocumentBlock): void => {
            block.documents.forEach((invoice: Invoice): void => {
                if (invoice.selected) {
                    const key: string = this.payType() === 'offer' ? invoice.paymentNumber : invoice.id;
                    result[key] = invoice.amount;
                }
            });
        });

        return result;
    }

    private recurringSchedulesToPay(): DynamicDictionary {
        const payment: DynamicDictionary = {};
        const firstInvoice: Invoice | undefined = this.firstInvoice;
        if (firstInvoice) {
            if (this.payType() === 'offer') {
                payment[this.currentAgreement.isSubscription ? '1' : firstInvoice.agreementNumber] =
                    firstInvoice.amount;
            } else {
                payment[firstInvoice.id] = firstInvoice.amount;
            }
        }

        return payment;
    }

    private firstUnpaidInvoicePaymentAmount(): number {
        let result: number = 0;
        const firstInvoice: Invoice | undefined = this.firstInvoice;
        if (firstInvoice) {
            result = firstInvoice.amount;
        }

        return result;
    }

    private selectedInvoicesPaymentAmount(): number {
        let result: number = 0;
        this.invoicesTabContentDocuments.forEach((block: InvoiceDocumentBlock): void => {
            block.documents.forEach((invoice: Invoice): void => {
                if (invoice.selected) {
                    result += invoice.amount;
                }
            });
        });

        return result;
    }
}
