import { useDefine } from '@/Composables/Define';

export default class Value {
    private readonly localValue: any;

    public constructor(value: any) {
        this.localValue = value;
    }

    public isEmpty(): boolean {
        return typeof this.localValue === 'object'
            ? this.isDateObject(this.localValue)
                ? false
                : this.isEmptyObject(this.localValue)
            : this.isEmptyNullOrUndefined(this.localValue);
    }

    public isNotEmpty(): boolean {
        return !this.isEmpty();
    }

    public get value(): any {
        return this.localValue;
    }

    private isEmptyNullOrUndefined(value: any): boolean {
        return this.isUndefined(value) || this.isNull(value) || this.isEmptyString(value);
    }

    private isDateObject(value: any): boolean {
        return value instanceof Date;
    }

    private isEmptyObject(value: any): boolean {
        let isObjectEmpty: boolean = true;
        if (Array.isArray(value) && value.length > 0) {
            isObjectEmpty = false;
        }
        if (isObjectEmpty) {
            if (useDefine().isSet(value.phone)) {
                isObjectEmpty = this.isEmptyString(value.phone);
            } else {
                const objectKeys: any[] = Object.keys(value);
                if (objectKeys.length > 0) {
                    objectKeys.some((key: any): void => {
                        if (!this.isNull(value[key]) && !this.isUndefined(value[key])) {
                            if (!this.isEmptyString(value[key])) {
                                isObjectEmpty = false;
                            }
                        }
                    });
                }
            }
        }

        return isObjectEmpty;
    }

    private isEmptyString(value: any): boolean {
        return value.toString() === '';
    }

    private isNull(value: any): boolean {
        return value === null;
    }

    private isUndefined(value: any): boolean {
        return value === undefined;
    }
}
