<script setup lang="ts">
import { computed, ComputedRef, PropType, ref, Ref } from 'vue';
import FormField from '@/Assets/Libraries/Form/FormField';

const props = defineProps({
    formField: { type: Object as PropType<FormField<string>>, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    rows: { type: Number, default: 5 },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
});
const emit = defineEmits(['change']);
const fieldId: ComputedRef<string> = computed(() => props.formField.name + '-textArea');
const userMadeInput: Ref<boolean> = ref(false);

function onKeyUp(): void {
    userMadeInput.value = true;
    props.formField.markAsDirty();
    props.formField.markAsTouched();
    emitChange();
}

function onChange(): void {
    emitChange();
}

function onBlur(): void {
    if (userMadeInput.value) {
        props.formField.markAsDirty();
    }
    props.formField.markAsTouched();
}

function emitChange(): void {
    emit('change', props.formField.value);
}
</script>
<template>
    <div
        :id="formField.name"
        class="input input-textarea"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : `${formField.value}`"
    >
        <div v-if="label" class="label informative">
            <label :for="fieldId"> {{ label }}<span v-if="required" class="asterisk">&#42;</span> </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper">
            <textarea
                :id="fieldId"
                v-model="formField.value"
                :name="formField.name"
                :disabled="disabled"
                :rows="rows"
                :placeholder="placeholder"
                @change="onChange"
                @keyup="onKeyUp"
                @blur="onBlur"
            >
            </textarea>
            <div v-if="formField.classes().error" class="error-container">
                <slot name="error"></slot>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.input-textarea {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .wrapper {
        display: flex;
        height: auto;

        textarea {
            width: 100%;
            padding: var(--size-small) 30px var(--size-small) var(--size-small);
            background: var(--white);
            border: 1px solid var(--black-200);
            border-radius: 3px;
            font-weight: 500;
            font-size: var(--font-size-tiny);
            color: var(--text-color-default);
            mix-blend-mode: normal;
            font-family: var(--text-font-stack);
            resize: none;

            &::placeholder {
                opacity: 0.56;
                color: var(--black-500);
            }

            &:focus {
                @include input-focus;
            }
        }

        .error-container {
            margin-top: 4px;

            &:empty {
                display: none;
            }
        }
    }

    &.disabled {
        > .wrapper::before {
            content: '';
            position: absolute;
            inset: 0;
            opacity: 0.08;
            background: var(--black-500);
            border-radius: 3px;
        }

        textarea {
            -webkit-text-fill-color: var(--black-500);
            opacity: 1;
            border-color: transparent;
        }
    }

    &.error textarea {
        border-color: var(--system-color-error-default);
        outline-color: var(--system-color-error-default);
        background-color: var(--red-50);

        &:focus {
            outline-color: var(--system-color-error-default);
            border-color: transparent;
            background-color: var(--white);
        }
    }

    &:hover {
        &.error textarea {
            border-color: var(--system-color-error-default);
        }
    }
}
</style>
