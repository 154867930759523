import Sanitizer from '@/Services/sanitizer.service';

export default class IMEIValidator {
    private readonly length: number = 15;
    private readonly fragmentMultiplier: number = 2;
    private readonly evenModuloDivisor: number = 2;
    private readonly checksumDivisor: number = 10;
    private readonly zeroValue: number = 0;
    private readonly tempValueSplitThreshold: number = 1;

    public isValidIMEI(value: string): boolean {
        const cleanValue: string = Sanitizer.cleanIMEINumber(value);
        const result: boolean = cleanValue.length === this.length;
        let checksum: number = this.zeroValue;
        if (result) {
            cleanValue
                .split('')
                .reverse()
                .map((splitValue: string) => Number(splitValue))
                .forEach((fragment: number, index: number): void => {
                    if (index > this.zeroValue && index % this.evenModuloDivisor !== this.zeroValue) {
                        const tempValue: number = fragment * this.fragmentMultiplier;
                        if (String(tempValue).length > this.tempValueSplitThreshold) {
                            String(tempValue)
                                .split('')
                                .forEach((tempFragment: string): void => {
                                    checksum += Number(tempFragment);
                                });
                        } else {
                            checksum += Number(tempValue);
                        }
                    } else {
                        checksum += Number(fragment);
                    }
                });
        }
        return result && this.isValidChecksum(checksum);
    }

    private isValidChecksum(value: number): boolean {
        return value > this.zeroValue && value % this.checksumDivisor === this.zeroValue;
    }
}
