import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { DirectiveBinding, nextTick } from 'vue';

class VisibilityState {
    private readonly el: Element | null = null;
    private observer: IntersectionObserver | null = null;
    private frozen: boolean = false;
    private options: DynamicDictionary = {};
    private oldResult: boolean | undefined = undefined;
    private callback: Function | null = null;

    public constructor(el: Element, options: DynamicDictionary) {
        this.el = el;
        this.createObserver(options);
    }

    public createObserver(options: DynamicDictionary) {
        if (this.observer) {
            this.destroyObserver();
        }
        if (this.frozen) {
            return;
        }
        this.options = this.processOptions(options);
        this.callback = (result: DynamicDictionary, entry: DynamicDictionary) => {
            this.options.callback(result, entry);
            if (result && this.options.once) {
                this.frozen = true;
                this.destroyObserver();
            }
        };
        if (this.callback && this.options.throttle) {
            const { leading } = this.options.throttleOptions || {};
            this.callback = this.throttle(this.callback, this.options.throttle, {
                leading: (state: DynamicDictionary) => {
                    return leading === 'both' || (leading === 'visible' && state) || (leading === 'hidden' && !state);
                },
            });
        }
        this.oldResult = undefined;
        this.observer = new IntersectionObserver((entries) => {
            let entry = entries[0];
            if (entries.length > 1) {
                const intersectingEntry = entries.find((e) => e.isIntersecting);
                if (intersectingEntry) {
                    entry = intersectingEntry;
                }
            }
            if (this.callback) {
                const result: boolean = entry.isIntersecting && entry.intersectionRatio >= this.threshold;
                if (result === this.oldResult) {
                    return;
                }
                this.oldResult = result;
                this.callback(result, entry);
            }
        }, this.options.intersection);
        nextTick(() => {
            if (this.observer) {
                this.observer.observe(this.el as Element);
            }
        });
    }

    public destroyObserver() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
        if (this.callback && (this.callback as DynamicDictionary)._clear) {
            (this.callback as DynamicDictionary)._clear();
            this.callback = null;
        }
    }

    private get threshold(): number {
        return this.options.intersection && typeof this.options.intersection.threshold === 'number'
            ? this.options.intersection.threshold
            : 0;
    }

    private processOptions(value: DynamicDictionary): DynamicDictionary {
        let options: DynamicDictionary = {};
        if (typeof value === 'function') {
            options = {
                callback: value,
            };
        } else {
            options = value;
        }

        return options;
    }

    private throttle(callback: Function, delay: number, options: DynamicDictionary = {}) {
        let timeout: NodeJS.Timeout | undefined;
        let lastState: DynamicDictionary;
        let currentArgs: DynamicDictionary[];
        const throttled = (state: DynamicDictionary, ...args: DynamicDictionary[]) => {
            currentArgs = args;
            if (timeout && state === lastState) {
                return;
            }
            let leading = options.leading;
            if (typeof leading === 'function') {
                leading = leading(state, lastState);
            }
            if ((!timeout || state !== lastState) && leading) {
                callback(state, ...currentArgs);
            }
            lastState = state;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                callback(state, ...currentArgs);
            }, delay);
        };
        throttled._clear = () => {
            clearTimeout(timeout);
        };

        return throttled;
    }
}

export default {
    beforeMount: (el: HTMLElement, { value }: DirectiveBinding): void => {
        if (!value) {
            return;
        }
        if (typeof IntersectionObserver !== 'undefined') {
            (el as DynamicDictionary)._vue_visibilityState = new VisibilityState(el, value);
        }
    },
    updated(el: HTMLElement, { value, oldValue }: DirectiveBinding): void {
        if (VisibilityStateCompare.deepEqual(value, oldValue)) {
            return;
        }
        const state: DynamicDictionary = (el as DynamicDictionary)._vue_visibilityState;
        if (!value) {
            this.unmounted(el);
            return;
        }
        if (state) {
            state.createObserver(value);
        } else {
            this.beforeMount(el, { value } as DirectiveBinding);
        }
    },
    unmounted: (el: HTMLElement): void => {
        const state: DynamicDictionary = (el as DynamicDictionary)._vue_visibilityState;
        if (state) {
            state.destroyObserver();
            delete (el as DynamicDictionary)._vue_visibilityState;
        }
    },
};

class VisibilityStateCompare {
    public static deepEqual(val1: DynamicDictionary, val2: DynamicDictionary): boolean {
        let result: boolean = false;
        if (val1 === val2) {
            result = true;
        } else if (typeof val1 === 'object') {
            result = true;
            for (const key in val1) {
                if (!VisibilityStateCompare.deepEqual(val1[key], val2[key])) {
                    result = false;
                }
            }
        }

        return result;
    }
}
