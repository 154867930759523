import FormField from '@/Assets/Libraries/Form/FormField';

export default class SelectorItem {
    public field!: FormField;
    public type!: string;
    public name!: string;
    public risk_ic!: string;
    public subrisk!: string;
    public selected: boolean = false;
}
