import { AxiosResponse } from 'axios';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Popup from '@/Services/popup.service';
import PopupType from '@/Enums/PopupTypeEnum';
import Url from '@/Enums/UrlEnum';
import User from '@/Services/user.service';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useAxios } from '@/Composables/Axios';
import { useTranslate } from '@/Composables/Translate';
import { useDefine } from '@/Composables/Define';
import OneBaseService from '@/Services/OneBaseService';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';

const { translateForType } = useTranslate();
const { validResponse } = useDefine();

export default class Terms {
    private static instance: Terms;

    public static getInstance(): Terms {
        if (!Terms.instance) {
            Terms.instance = new Terms();
        }

        return Terms.instance;
    }

    public checkAgreeStatus(): void {
        const user: User = User.getInstance();
        if (user.isLogged()) {
            if (user.current.agreeVersion !== translateForType('terms1_version', '')) {
                this.fetchTerms();
            }
        }
    }

    private fetchTerms(): void {
        useAxios()
            .get(Url.Ajax.fetchAgreeTerms)
            .then((value: AxiosResponse<DynamicDictionary>) => {
                if (validResponse(value)) {
                    this.showTerms(value.data.data.body);
                }
            })
            .catch((reason: DynamicDictionary) => {
                Error.log(ErrorType.Error, 'checkAgreeStatus', reason);
            });
    }

    private showTerms(data: DynamicDictionary): void {
        Popup.getInstance()
            .applyTextTitle(data.title)
            .applyTextDescription(data.descriptionHtml)
            .applyTextAgree(data.agreeText)
            .applyCallbackModel('agreeToTerms', this)
            .applyOnCloseCallback('logout', this)
            .showPopup(PopupType.TextAgree);
    }

    public logout(): void {
        PopupService.getInstance().show(new OnePopup().withType().loading);
        window.location.href = OneBaseService.getInstance().formattedUrl(Url.Api.logout);
    }

    public agreeToTerms(): void {
        const user: User = User.getInstance();
        if (user.isLogged()) {
            user.current.agreeVersion = translateForType('terms1_version', '');
            OneBaseService.getInstance().lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);
            useAxios()
                .get(Url.Ajax.agreeToTerms)
                .then((value: AxiosResponse) => {
                    if (validResponse(value)) {
                        PopupService.getInstance().hide();
                    } else {
                        Error.getInstance().show(ErrorType.Error, 'agreeToTerms', value);
                    }
                })
                .catch((reason: DynamicDictionary) => {
                    Error.getInstance().show(ErrorType.Error, 'agreeToTerms', reason);
                })
                .finally(() => {
                    OneBaseService.getInstance().lockInput(false);
                });
        }
    }
}
