import BusinessTab from '@/Pages/LegalPerson/Workbench/Common/Business.tab';

export default class BusinessHelp extends BusinessTab {
    private static instance: BusinessHelp;

    public static getInstance(): BusinessHelp {
        if (!BusinessHelp.instance) {
            BusinessHelp.instance = new BusinessHelp();
        }

        return BusinessHelp.instance;
    }
}
