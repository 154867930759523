import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import MovableProperty from '@/Pages/ItemsAndBelongings/Enums/MovableProperty';

export default class MovablePropertySteps {
    private static readonly mapping: DynamicDictionary = {
        [MovableProperty.SingleDevice]: [
            MovableProperty.InsuranceStep,
            MovableProperty.CoverageStep,
            MovableProperty.AdditionalOptionsStep,
            MovableProperty.DeviceDetailsStep,
            MovableProperty.SummaryStep,
        ],
        [MovableProperty.AllDevices]: [
            MovableProperty.InsuranceStep,
            MovableProperty.CoverageStep,
            MovableProperty.AdditionalOptionsStep,
            MovableProperty.SummaryStep,
        ],
    };

    public static byProductId(productId: string): number[] {
        return MovablePropertySteps.mapping[productId];
    }
}
