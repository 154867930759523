import Translations from '@/Services/translations.service';
import UrlBuilder from '@/Assets/Libraries/Url/UrlBuilder';
import { usePrice } from '@/Composables/Price';

export const useFormatter = (): FormatterParams => {
    const formattedUrl = (url: string): string => {
        return new UrlBuilder()
            .withLanguage(Translations.getInstance().language)
            .withForcedLanguage()
            .withUri(url)
            .build();
    };

    const sparsePrice = (price: number, noCents: boolean): string => {
        const hasFraction: boolean = Number(price) % 1 > 0;

        return usePrice().sparse(price, noCents && !hasFraction);
    };

    const numberToFixedNoRounding = (number: number, fixed: number): string => {
        const stringNumber: string = number.toFixed(2);
        let baseNumber: string;
        let fractionalNumber: string;
        if (stringNumber.includes('.')) {
            const splitParts: string[] = stringNumber.split('.');
            baseNumber = splitParts[0];
            fractionalNumber = splitParts[1].slice(0, fixed);
        } else {
            baseNumber = stringNumber;
            fractionalNumber = '';
        }
        let result: string = baseNumber;
        if (fractionalNumber !== '') {
            result = result + '.' + fractionalNumber;
        }

        return result;
    };

    return {
        formattedUrl,
        sparsePrice,
        numberToFixedNoRounding,
    };
};

export interface FormatterParams {
    formattedUrl: (url: string) => string;
    sparsePrice: (price: number, noCents: boolean) => string;
    numberToFixedNoRounding: (number: number, fixed: number) => string;
}
