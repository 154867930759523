export default class ButtonTextColor {
    public static readonly Grey: string = 'grey';
    public static readonly Green: string = 'green';
    public static readonly Red: string = 'red';
    public static readonly White: string = 'white';
    public static readonly Black: string = 'black';
    public static readonly Disabled: string = 'disabled';
    public static readonly Blue: string = 'blue';
    public static readonly Link: string = 'link';
    public static readonly LinkHover: string = 'link';
}
