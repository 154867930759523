import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { LimitedVariant } from '@/Types/LimitedVariantType';

export const useDebounce = (): DebounceInterface => {
    const debounceFactory = (callback: Function, wait: number = 500): Function => {
        const maxWait: number = 0;
        const maxing: boolean = false;
        const trailing: boolean = true;

        let lastArgs: DynamicDictionary | undefined;
        let lastThis: DynamicDictionary | undefined;
        let result: Function;
        let timerId: NodeJS.Timeout | number | undefined;
        let lastCallTime: number | undefined = 0;
        let lastInvokeTime: number = 0;

        if (typeof callback !== 'function') {
            throw new TypeError('Expected a function');
        }

        const invokeFunc = (time: number): Function => {
            const args: DynamicDictionary = lastArgs as DynamicDictionary;
            const thisArg: DynamicDictionary = lastThis as DynamicDictionary;
            lastArgs = lastThis = undefined;
            lastInvokeTime = time;
            result = callback.apply(thisArg, args);

            return result;
        };

        const startTimer = (pendingFunc: Function, waitTime: number): number => {
            return setTimeout(pendingFunc, waitTime);
        };

        const cancelTimer = (id: NodeJS.Timeout): void => {
            clearTimeout(id);
        };

        const leadingEdge = (time: number): Function => {
            lastInvokeTime = time;
            timerId = startTimer(timerExpired, wait);

            return result;
        };

        const remainingWait = (time: number): number => {
            const timeSinceLastCall = time - (lastCallTime ? lastCallTime : 0);

            return wait - timeSinceLastCall;
        };

        const shouldInvoke = (time: number): boolean => {
            const timeSinceLastCall: number = time - (lastCallTime ? lastCallTime : 0);
            const timeSinceLastInvoke: number = time - lastInvokeTime;

            return (
                lastCallTime === undefined ||
                timeSinceLastCall >= wait ||
                timeSinceLastCall < 0 ||
                (maxing && timeSinceLastInvoke >= maxWait)
            );
        };

        const timerExpired = (): DynamicDictionary => {
            const time: number = Date.now();
            if (shouldInvoke(time)) {
                return trailingEdge(time);
            }
            timerId = startTimer(timerExpired, remainingWait(time));

            return {};
        };

        const trailingEdge = (time: number): Function => {
            timerId = undefined;
            if (trailing && lastArgs) {
                return invokeFunc(time);
            } else {
                lastArgs = lastThis = undefined;
            }

            return result;
        };

        const cancel = (): void => {
            if (timerId !== undefined) {
                cancelTimer(timerId as NodeJS.Timeout);
            }
            lastInvokeTime = 0;
            lastArgs = lastCallTime = lastThis = timerId = undefined;
        };

        const flush = (): Function => {
            return timerId === undefined ? result : trailingEdge(Date.now());
        };

        const pending = (): boolean => {
            return timerId !== undefined;
        };

        const debounced = (...args: DynamicDictionary[]): Function => {
            const time: number = Date.now();
            const isInvoking: boolean = shouldInvoke(time);
            lastArgs = args;
            // eslint-disable-next-line '@typescript-eslint/no-this-alias'
            lastThis = this;
            lastCallTime = time;
            if (isInvoking) {
                if (timerId === undefined) {
                    return leadingEdge(lastCallTime);
                }
                if (maxing) {
                    timerId = startTimer(timerExpired, wait);
                    return invokeFunc(lastCallTime);
                }
            }
            if (timerId === undefined) {
                timerId = startTimer(timerExpired, wait);
            }

            return result;
        };
        debounced.cancel = cancel;
        debounced.flush = flush;
        debounced.pending = pending;

        return debounced;
    };

    const debounceFactoryPromise = (callback: Function, wait: number = 500): Function => {
        let timer: NodeJS.Timeout;
        let resolves: Function[] = [];

        return (...args: LimitedVariant[]) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                const result: DynamicDictionary = callback(args);
                resolves.forEach((r) => r(result));
                resolves = [];
            }, wait);

            return new Promise((r) => resolves.push(r));
        };
    };

    return {
        debounce: debounceFactory,
        debouncePromise: debounceFactoryPromise,
    };
};

export interface DebounceInterface {
    debounce: (callback: Function, wait: number) => Function;
    debouncePromise: (callback: Function, wait: number) => Function;
}
