import PopupBase from '@/Assets/Libraries/Popups/PopupBase';
import PopupType from '@/Enums/PopupTypeEnum';

export default class Login extends PopupBase {
    public constructor() {
        super();
        this.applyType(PopupType.Login);
        this.withCloseButton();
    }

    public withCloseButton(): Login {
        this.applyCloseButtonVisibility(true);

        return this;
    }

    public withoutCloseButton(): Login {
        this.applyCloseButtonVisibility(false);

        return this;
    }
}
