import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { LimitedVariant } from '@/Types/LimitedVariantType';

export default class Stepper {
    private static instance: Stepper;
    private steps: boolean[] = [];
    private currentStep: number = 0;

    public static getInstance(): Stepper {
        if (!Stepper.instance) {
            Stepper.instance = new Stepper();
        }
        return Stepper.instance;
    }

    public applySteps(steps: string, currentStep: number): void {
        try {
            this.steps = JSON.parse(steps);
            this.currentStep = currentStep;
        } catch (e) {
            Error.log(ErrorType.Error, 'Stepper::applySteps', e as LimitedVariant);
        }
    }

    public clickIsEnabled(step: number): boolean {
        return this.steps[step - 1] || this.currentStep > step;
    }

    public stepClass(step: number): string {
        let result: string = ' ';
        if (step > this.currentStep) {
            result += 'next ';
        } else if (step === this.currentStep) {
            result += 'current ';
        } else {
            result += 'done ';
        }
        if (this.clickIsEnabled(step)) {
            result += 'enabled ';
        }

        return result;
    }

    public disableAhead(fromStep: number): void {
        this.steps = this.steps.map((value: boolean, index: number) => {
            return index <= fromStep;
        });
    }
}
