<script setup lang="ts">
import Repeatable from '@/Components/Other/Repeatable/Repeatable.vue';
import AppButtonTertiary from '@/Components/Buttons/ButtonTertiary/ButtonTertiary.vue';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import { PropType, ComponentInternalInstance, computed, Ref } from 'vue';
import type { ComponentExposed } from 'vue-component-type-helpers';

const props = defineProps({
    item: { type: Object as PropType<{ id: string }>, default: () => ({}) },
    index: { type: Number, default: 0 },
    addButtonText: { type: String, default: '' },
    removeButtonText: { type: String, default: '' },
    actionsVisible: { type: Boolean, default: true },
    repeatable: {
        type: Object as PropType<(ComponentInternalInstance & { exposed: ComponentExposed<typeof Repeatable> }) | null>,
        default: null,
    },
});

const lastIndex = computed(() => {
    return (props.repeatable?.exposed.items as unknown as Ref<{ id: string }[]>).value.length - 1;
});
</script>

<template>
    <div :id="'repeatable-item-' + item.id" class="repeatable-item">
        <slot></slot>
        <hr v-if="index < lastIndex" />
        <div v-if="index === lastIndex && actionsVisible" class="buttons">
            <app-button-tertiary
                v-if="repeatable?.exposed.isAddButtonVisible(item)"
                @click="repeatable?.exposed.addItem()"
            >
                <template #start>
                    <app-svg src="/images/one/components/button-with-callback/add.svg"></app-svg>
                </template>
                {{ addButtonText }}
            </app-button-tertiary>
            <app-button-tertiary
                v-if="repeatable?.exposed.isRemoveButtonVisible(item)"
                class="remove-item"
                @click="repeatable?.exposed.removeItem(item)"
            >
                <template #start>
                    <app-svg src="/images/icons/trashcan.svg"></app-svg>
                </template>
                {{ removeButtonText }}
            </app-button-tertiary>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.repeatable-item {
    display: grid;
    gap: var(--size-small);
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

hr {
    width: 100%;
    height: 1px;
    background-color: var(--black-100);
}
</style>
