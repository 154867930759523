<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { computed } from 'vue';

const { translateForType } = useTranslate();

const props = defineProps({
    title: { type: String, default: '' },
    translationType: { type: String, default: '' },
});

const isTitleVisible = computed(() => {
    return props.title.toLowerCase() !== 'badge.blank';
});
</script>
<template>
    <div
        class="badge"
        :class="{
            blank: !isTitleVisible,
        }"
    >
        <span class="radio-icon"></span>
        <span v-if="isTitleVisible" class="title">{{ translateForType(title, translationType) }}</span>
    </div>
</template>

<style lang="scss" scoped>
.badge {
    display: flex;
    align-self: center;
    align-items: center;
    gap: var(--size-femto);
    padding: 0 var(--size-pico) 0 var(--size-femto);
    background: var(--red-50);
    border-radius: 8px;
    border: 1px solid var(--red-50);
    height: var(--size-small);
    width: fit-content;

    &.blank {
        padding: 0 var(--size-femto);
    }

    &.green {
        background: var(--teal-50);
        border-color: var(--teal-50);

        .radio-icon {
            background-color: var(--brand-teal);

            &::before {
                background-color: var(--teal-50);
            }
        }

        .title {
            color: var(--text-color-link);
            display: flex;
        }
    }

    .radio-icon {
        position: relative;
        width: 4px;
        height: 4px;
        background-color: var(--brand-red);
        border-radius: 50%;
        margin: 3px;

        &::before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: var(--red-50);
            border-radius: 50%;
            top: -100%;
            left: -100%;
        }
    }

    .title {
        font-size: var(--font-size-pico);
        color: var(--text-color-attention);
    }
}
</style>
