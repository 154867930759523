<script setup lang="ts">
import { onMounted, PropType, watch, Ref, ref, computed } from 'vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';

type ValueType = string | number | boolean;

const props = defineProps({
    options: { type: Array as PropType<InputOption[]>, default: () => [] },
    formField: { type: Object as PropType<FormField<ValueType>>, default: () => new FormField('') },
    disabled: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
});

const emit = defineEmits(['change']);

const defaultOptions: InputOption[] = [
    new InputOptionBuilder().setValue(false).build(),
    new InputOptionBuilder().setValue(true).build(),
];
const availableOptions: Ref<InputOption[]> = computed(() => {
    return props.options.length === 2 ? props.options : defaultOptions;
});
const firstOption: Ref<InputOption> = ref(availableOptions.value[0]);
const secondOption: Ref<InputOption> = ref(availableOptions.value[1]);
const firstOptionSelected: Ref<boolean> = computed(() => {
    return String(props.formField.value) === String(firstOption.value.value);
});
const secondOptionSelected: Ref<boolean> = computed(() => {
    return String(props.formField.value) === String(secondOption.value.value);
});

watch(
    () => props.formField.value,
    (value: ValueType, valueBefore: ValueType) => {
        if (value !== valueBefore) {
            emit('change');
        }
    },
);

onMounted(() => {
    if (props.formField.isEmpty()) {
        props.formField.setValue(firstOption.value.value);
    }
});

function toggle(): void {
    if (!props.disabled) {
        firstOptionSelected.value ? switchSecond() : switchFirst();
    }
}

function switchFirst(): void {
    if (!props.disabled) {
        props.formField.setValue(firstOption.value.value);
    }
}

function switchSecond(): void {
    if (!props.disabled) {
        props.formField.setValue(secondOption.value.value);
    }
}
</script>

<template>
    <span
        :id="formField.name"
        class="input input-radio-switch"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : `${formField.value}`"
    >
        <label
            class="left-side-label"
            :class="{ selected: firstOptionSelected }"
            @click="switchFirst()"
            v-html="firstOption.name"
        >
        </label>
        <div class="switch-container">
            <div
                :id="formField.name + '-toggle'"
                class="switch-control"
                :class="{ 'second-option-selected': secondOptionSelected }"
                @click="toggle()"
            >
                <div class="control"></div>
            </div>
        </div>
        <label
            :id="formField.name + '-switchSecond'"
            class="right-side-label"
            :class="{ selected: secondOptionSelected }"
            @click="switchSecond()"
            v-html="secondOption.name"
        >
        </label>
    </span>
</template>

<style lang="scss" scoped>
.input-radio-switch {
    $control-size: 24px;
    $border-width: 2px;
    $control-container-width: 54px;
    $control-container-height: calc(#{$control-size} + (#{$border-width} * 2));
    $background-color: var(--brand-teal);
    $control-color: var(--white);
    $switch-transition: all 0.3s ease-in-out;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;

    .left-side-label {
        margin-right: var(--size-small);
    }

    .right-side-label {
        margin-left: var(--size-small);
    }

    .left-side-label,
    .right-side-label {
        color: var(--black-500);
        font-size: var(--font-size-tiny);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 120px;

        @include respond-above('sm') {
            width: auto;
            max-width: calc((100% - #{$control-container-width}) / 2);
        }

        &.selected {
            color: var(--text-color-default);
        }

        &:empty {
            display: none;
        }
    }

    .switch-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .switch-control {
            width: $control-container-width;
            height: $control-container-height;
            border: $border-width solid $background-color;
            background-color: $background-color;
            border-radius: $control-container-height;
            transition: $switch-transition;
            cursor: pointer;

            .control {
                height: $control-size;
                width: $control-size;
                border-radius: 50%;
                background: $control-color;
                transition: $switch-transition;
            }

            &.second-option-selected {
                .control {
                    transform: translateX(
                        calc(#{$control-container-width} - (#{$control-size} + (#{$border-width} * 2)))
                    );
                }
            }
        }
    }
}
</style>
