<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import { onMounted, PropType } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import MtplOfferOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/MtplOfferOptionInterface';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/Interfaces/image.tag.attributes';
import { usePrice } from '@/Composables/Price';
import MtplOfferOptionEmitters from '@/Components/Lists/AdditionalOptionsList/Enums/MtplOfferOptionEmitters';
import AppInputSwitch from '@/Components/Inputs/InputSwitch/InputSwitch.vue';
import AppTextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';
import AgreementPeriodMap from '@/Apps/PayByLink/Classes/AgreementPeriodMap';

const props = defineProps({
    formField: { type: FormField, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    option: {
        type: Object as PropType<MtplOfferOption>,
        default: () => {},
    },
});
const translationType: string = 'mtpl_renewal';
const { translateForType } = useTranslate();
const { sparse } = usePrice();
const emit = defineEmits([MtplOfferOptionEmitters.Toggle, MtplOfferOptionEmitters.Mounted]);

onMounted((): void => {
    emit(MtplOfferOptionEmitters.Mounted, {
        [props.option.risk.id]: props.option.enabled.value,
    });
});

function onAdditionalOptionChange(optionState: boolean): void {
    emit(MtplOfferOptionEmitters.Toggle, {
        [props.option.risk.id]: optionState,
    });
}

function offerPeriod(periodIc: string): string {
    return [new AgreementPeriodMap().monthsByIc(periodIc), new AgreementPeriodMap().monthsLabelByIc(periodIc)].join(
        ' ',
    );
}

function optionSubtitle(option: MtplOfferOption): string {
    let result: string[] = [translateForType(option.risk.id + '_SWITCHOFF', translationType)];
    if (option.enabled.value) {
        result = [useHtml().imgTag(successIconAttributes())];
        result.push(
            [
                translateForType('included_for_price', translationType, {
                    price: String(sparse(option.risk.price, false)),
                }),
                '&nbsp;&euro;',
            ].join(''),
        );
    }

    return result.join('&nbsp;');
}

function successIconAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    };
}
</script>

<template>
    <div
        class="mtpl-offer-option"
        :class="{ ...formField.classes(), enabled: option.enabled.value }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div class="title flex column">
            <div class="switch flex space-between align-center">
                <span>{{ option.name }}</span>
                <app-input-switch
                    v-if="option.risk.price > 0"
                    :class="{ off: !option.enabled.value }"
                    :form-field="option.enabled"
                    :data-store-disabled="true"
                    @change="onAdditionalOptionChange($event)"
                ></app-input-switch>
            </div>
            <div class="subtitle default-color">
                <app-text-with-tip
                    class="title-tooltip"
                    :title="translateForType(option.risk.id + '_SWITCHOFF', translationType)"
                    :mode="'popup'"
                    :tip-description="translateForType(option.risk.id + '_DESCRIPTION', translationType)"
                ></app-text-with-tip>
            </div>
            <div v-if="option.enabled.value" class="subtitle" v-html="optionSubtitle(option)"></div>
        </div>
        <div v-if="option.risk.price > 0 && !option.enabled.value" class="details flex column">
            <div class="price flex space-between align-center">
                <span v-if="option.value === ''">{{ translateForType('price', translationType) }}</span>
                <span v-else>{{ option.value }}&nbsp;{{ offerPeriod(option.periodIc) }}</span>
                <span class="green">
                    <span class="amount">{{ '+&nbsp;' + sparse(option.risk.price, false) }}</span>
                    <span class="amount-text" v-html="'&nbsp;&euro;'"></span>
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.mtpl-offer-option {
    background: var(--white);
    width: 100%;
    border-radius: var(--size-pico);
    padding: var(--size-small);
    border: 2px solid transparent;

    &.enabled {
        border-color: var(--component-color-border-active);
        background: linear-gradient(0deg, var(--system-color-success-light) 0%, var(--system-color-success-light) 100%),
            var(--white);

        .subtitle {
            color: var(--text-color-link);
        }
    }

    .title {
        gap: var(--size-pico);
        font-weight: 700;
        font-size: var(--font-size-small);

        .subtitle {
            font-size: var(--font-size-nano);
            font-weight: 500;

            &.default-color {
                color: var(--text-color-default);
            }

            .title-tooltip {
                :deep(.sub-title) {
                    font-size: var(--font-size-nano);
                    font-weight: 500;
                    color: var(--text-color-subtle);
                }

                :deep(svg) {
                    rect {
                        fill: var(--system-color-success-light);
                    }
                }
            }
        }
    }

    .details {
        gap: var(--size-tiny);
        margin-top: var(--size-small);
        font-size: var(--font-size-nano);
        color: var(--text-color-subtle);

        .price {
            font-size: var(--font-size-nano);
            font-weight: 500;

            .amount {
                font-size: var(--font-size-medium);
                font-weight: 700;
            }

            .amount-text {
                font-size: var(--font-size-tiny);
            }
        }

        .coverage {
            .amount {
                font-size: var(--font-size-tiny);
                font-weight: 700;
            }
        }
    }

    .green {
        color: var(--text-color-link);
    }

    .flex {
        display: flex;

        &.column {
            flex-direction: column;
        }

        &.space-between {
            justify-content: space-between;
        }

        &.align-center {
            align-items: center;
        }
    }

    @include respond-above('sm') {
        width: 440px;
    }

    hr {
        border-top: 1px solid var(--black-100);
    }
}
</style>
