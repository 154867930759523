<script setup lang="ts">
import Error from '@/Services/error.service';
import FilesUploader from '@/Enums/FileUploadEnum';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import SettingsService from '@/Services/settings.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useDefine } from '@/Composables/Define';
import { useTranslate } from '@/Composables/Translate';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import ResponseCode from '@/Enums/ResponseCodeEnum';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import Sanitizer from '@/Services/sanitizer.service';

const props = defineProps({
    translationType: { type: String, default: 'components' },
    label: { type: String, default: '' },
    allowedExtensions: { type: String, default: '.doc' },
    uploadUrl: { type: String, default: '' },
    action: { type: String, default: '' },
    customLoader: { type: Boolean, default: false },
});
const emit = defineEmits(['upload-callback', 'trigger-loader', 'before-upload']);
const { isSet } = useDefine();
const { translateForType } = useTranslate();
const request: AxiosParams = useAxios();

function emitUploadCallback(param: DynamicDictionary): void {
    emit('upload-callback', param);
}

function emitTriggerLoadingState(loading: boolean): void {
    emit('trigger-loader', loading);
}

function emitTriggerBeforeUpload(file: File): void {
    emit('before-upload', file);
}

function uploadSubmit(selectedFiles: FileList): void {
    const uploadFile: File = selectedFiles[0];
    postFile(uploadFile);
}

function onFileInputChange(event: Event): void {
    if (event.target) {
        uploadSubmit((event.target as unknown as { files: FileList }).files);
        (event.target as unknown as { value: string }).value = '';
    }
}

function postFile(file: File): void {
    if (!isAllowedFileSize(file)) {
        errorService().show(
            ErrorType.Error,
            file.name,
            translateForType(FilesUploader.Error.UploadWrongFileSize, props.translationType),
        );
    } else {
        if (props.customLoader) {
            emitTriggerLoadingState(true);
        } else {
            popupService().show(new OnePopup().withType().loading);
        }
        emitTriggerBeforeUpload(file);
        const formData: FormData = new FormData();
        formData.append('document', file);
        if (props.action) {
            formData.append('action', props.action);
        }
        request
            .post(props.uploadUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((value: DynamicDictionary) => {
                if (validResponse(value)) {
                    value.data.data.body.parsed.name = Sanitizer.cleanFileName(value.data.data.body.parsed.name);
                    emitUploadCallback(value.data.data.body);
                } else {
                    emitUploadCallback(value.data);
                }
            })
            .finally(() => {
                if (props.customLoader) {
                    emitTriggerLoadingState(false);
                } else {
                    popupService().hide();
                }
            });
    }
}

function validResponse(response: DynamicDictionary): boolean {
    return (
        isSet(response.status) &&
        response.status === ResponseCode.Ok &&
        isSet(response.data) &&
        isSet(response.data.data)
    );
}

function isAllowedFileSize(file: File): boolean {
    return file.size <= Number(settingsService().value(FilesUploader.Settings.FileSizeMax));
}

function onDragOver(event: DragEvent): void {
    event.dataTransfer!.dropEffect = 'copy';
}

function onFilesDrop(event: DragEvent): void {
    uploadSubmit(event.dataTransfer!.files);
}

function popupService(): PopupService {
    return PopupService.getInstance();
}

function errorService(): Error {
    return Error.getInstance();
}

function settingsService(): SettingsService {
    return SettingsService.getInstance();
}
</script>
<template>
    <div
        ref="container"
        class="document-upload"
        data-type="file-upload"
        @dragover="onDragOver"
        @dragover.prevent
        @drop="onFilesDrop"
        @drop.prevent
    >
        <div class="drop-area">
            <svg class="icon" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M24 1H5C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5V37C1 38.0609 1.42143 39.0783 2.17157 39.8284C2.92172 40.5786 3.93913 41 5 41H29C30.0609 41 31.0783 40.5786 31.8284 39.8284C32.5786 39.0783 33 38.0609 33 37V10L24 1Z"
                    stroke="#00B4AD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M24 1V10H33"
                    stroke="#00B4AD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M17 29V17"
                    stroke="#00B4AD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11 23H23"
                    stroke="#00B4AD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <div class="description">{{ label }}</div>
            <input class="upload-input" type="file" :accept="allowedExtensions" @change="onFileInputChange($event)" />
        </div>
    </div>
</template>
<style lang="scss" scoped>
.document-upload {
    width: 100%;

    .drop-area {
        position: relative;
        height: 141px;
        width: 100%;
        padding: 32px;
        background-color: var(--teal-50);
        border: 2px dashed var(--teal-400);
        border-radius: 8px;
        text-align: center;

        .icon {
            width: 32px;
            height: 40px;
            margin-bottom: var(--size-tiny);
        }

        .description {
            color: var(--text-color-default);
            font-weight: 600;
        }

        .upload-input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            font-size: 0;
            opacity: 0;
        }
    }
}
</style>
