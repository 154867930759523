<script setup lang="ts">
import { onMounted, ref, Ref, nextTick, watch } from 'vue';
import Map from '@/Enums/MapEnum';
import MapControlsOptions from '@/Components/Maps/MapControls/MapControlsOptions';
import MapPlace from '@/Interfaces/map.place.interface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useMaps } from '@/Composables/Maps';
import { useDefine } from '@/Composables/Define';
import { useTranslate } from '@/Composables/Translate';
import AppMapControls from '@/Components/Maps/MapControls/MapControls.vue';

const props = defineProps({
    componentName: { type: String, default: 'MapPlaces' },
    translationType: { type: String, default: 'components' },
    dataStoreDisabled: { type: Boolean, default: false },
    showSelectPlaceButton: { type: Boolean, default: true },
    startWithAllPlaces: { type: Boolean, default: false },
    places: { type: String, default: '' },
    city: { type: String, default: '' },
});
const emit = defineEmits(['change', 'list-select', 'map-ready']);
const { mapLoader, createMap, placeIcon, changeBasemap, mapSettings } = useMaps();
const { isSet } = useDefine();
const { translate, translateForType } = useTranslate();

const options: MapControlsOptions = new MapControlsOptions();
options.zoomControl = true;
options.homeControl = false;
options.geolocateControl = true;
options.basemapControl = false;
const mapPlaces: Ref<MapPlace[]> = ref([]);

let map!: google.maps.Map;
let locationCoordinates: google.maps.LatLng | null = null;
const emptyMapPlace: MapPlace = {
    id: '',
    latitude: 0,
    longitude: 0,
    distance: 0,
    expert_contract_id: '',
    title: '',
    city: '',
    city_title: '',
    address: '',
    region: '',
    email: '',
    phone: '',
    working_hours: '',
    working_days: '',
    special: '',
    infoWindow: null,
    marker: null,
};
const selectedPlace: Ref<MapPlace> = ref(emptyMapPlace);

watch(
    () => props.city,
    () => {
        const areaPlaces: MapPlace[] = mapPlaces.value.filter(
            (place: MapPlace): boolean => place.city_title === props.city,
        );
        fitMap(areaPlaces);
    },
);

onMounted((): void => {
    Object.entries(props.places).forEach(([_id, place]) => {
        const mapPlace: MapPlace = Object(place) as MapPlace;
        mapPlaces.value.push(mapPlace);
    });

    initMap();
});

function initMap(): void {
    const mapId: string = 'map-places';
    mapLoader()
        .load()
        .then(() => {
            map! = createMap(mapId);
            map.addListener('zoom_changed', () => {
                unsetPlace(false);
            });
            setupMap();
            emit('map-ready', true);
        })
        .catch(() => {
            showError(Map.Error.MapCreationFail, initMap.name);
        });
}

function setupMap(): void {
    setupControls();
    addPlacesToMap();
}

function setupControls(): void {
    const mapControls: HTMLElement = document.getElementById('map-places-controls') as HTMLElement;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(mapControls);
}

function addPlacesToMap(): void {
    mapPlaces.value.forEach((mapPlace: MapPlace) => {
        const marker = new google.maps.Marker({
            position: { lat: Number(mapPlace.latitude), lng: Number(mapPlace.longitude) },
            title: mapPlace.address + ', ' + mapPlace.city_title,
            map: map,
        });
        const placeInfo: HTMLElement = document.getElementById('place-info') as HTMLElement;
        const infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow({
            minWidth: 300,
            maxWidth: 350,
            content: placeInfo,
            disableAutoPan: false,
        });
        marker.addListener('click', () => {
            selectPlace(mapPlace.id);
            selectedPlace.value.infoWindow = infoWindow;
            infoWindow.open({
                map: map,
                anchor: marker,
                shouldFocus: true,
            });
        });
    });
}

function currentLocation(): google.maps.LatLng {
    let coordinates!: google.maps.LatLng;
    if (isSet(locationCoordinates)) {
        coordinates = locationCoordinates!;
    } else {
        coordinates = mapSettings.defaultMapCenter;
    }

    return coordinates;
}

function selectPlace(id: string): void {
    const mapPlace: MapPlace = mapPlaces.value.find((value) => value.id === id) as MapPlace;
    if (isSet(mapPlace)) {
        selectedPlace.value = mapPlace;
        map.setCenter({ lat: Number(mapPlace.latitude), lng: Number(mapPlace.longitude) });
        map.setZoom(mapSettings.maxZoom);
        nextTick(() => {
            google.maps.event.trigger(mapPlace.marker as google.maps.Marker, 'open');
            emit('list-select');
        });
    }
}

function unsetPlace(cleanId: boolean = false): void {
    mapPlaces.value.forEach((mapPlace: MapPlace) => {
        if (isSet(mapPlace.infoWindow)) {
            mapPlace.infoWindow!.close();
        }
        if (isSet(mapPlace.marker)) {
            placeIcon(mapPlace.marker!, '', mapPlace.special);
        }
    });
    if (cleanId) {
        selectedPlace.value.id = '';
    }
}

function fitMap(areaPlaces: MapPlace[]): void {
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < areaPlaces.length; i++) {
        const coordinates: google.maps.LatLng = new google.maps.LatLng(areaPlaces[i].latitude, areaPlaces[i].longitude);
        bounds.extend(coordinates);
    }
    if (!bounds.isEmpty()) {
        map!.fitBounds(bounds);
    }
}

function recalculateDistances(mapCenter: google.maps.LatLng): void {
    mapPlaces.value.forEach((mapPlace: MapPlace) => {
        mapPlace.distance = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(mapPlace.latitude, mapPlace.longitude),
            mapCenter,
        );
    });
}

function onCloseInfoWindow(): void {
    unsetPlace(true);
}

function onLocatePlacesNearMe(): void {
    onMapHome();
    if (isSet(navigator.geolocation)) {
        navigator.geolocation.getCurrentPosition((position: DynamicDictionary) => {
            const coordinates: google.maps.LatLng = new google.maps.LatLng(
                position.coords!.latitude,
                position.coords!.longitude,
            );
            locationCoordinates = coordinates;
            map!.setCenter(coordinates);
        });
    } else {
        showError(Map.Error.MapUnsuportGeolocations, onLocatePlacesNearMe.name);
    }
}

function onZoomIn(): void {
    map!.setZoom(Number(map!.getZoom()) + 1);
}

function onZoomOut(): void {
    map!.setZoom(Number(map!.getZoom()) - 1);
}

function onMapHome(): void {
    unsetPlace();
    map!.setCenter(mapSettings.defaultMapCenter);
    recalculateDistances(map!.getCenter() as google.maps.LatLng);
}

function onChangeBasemap(): void {
    changeBasemap(map!);
}

function trimNearMeResults(): void {
    mapPlaces.value = mapPlaces.value.filter(
        (mapPlace: MapPlace) => mapPlace.distance < mapSettings.nearMeRadiusMeters,
    );
}

function showError(errorType: string, functionName: string): void {
    errorService().show(
        ErrorType.Error,
        props.componentName + '::' + functionName,
        translateForType(errorType, props.translationType),
    );
}

function errorService(): Error {
    return Error.getInstance();
}

function transformedWorkingTime(value: string): string {
    const workingHours: DynamicDictionary[] = Object(value);
    let result: string = '';
    workingHours.forEach((day) => {
        switch (day.id) {
            case 'workdays':
                result = result + 'I - V: ';
                break;
            case 'saturday':
                result = result + ', VI: ';
                break;
            case 'sunday':
                result = result + ', VII: ';
                break;
        }
        result += day.from === '' && day.to === '' ? translate('office_closed') : day.from + ' - ' + day.to;
    });
    return result;
}
</script>
<template>
    <div class="map-places">
        <div class="map-container">
            <div class="map-with-slot">
                <slot name="map-above"></slot>
                <div id="map-places" data-scroll="map-places" class="map"></div>
            </div>
            <div class="hidden">
                <app-map-controls
                    id="map-places-controls"
                    :options="options"
                    @map-zoom-in="onZoomIn"
                    @map-zoom-out="onZoomOut"
                    @map-home="onMapHome"
                    @map-geolocate="onLocatePlacesNearMe"
                    @map-basemap="onChangeBasemap"
                >
                </app-map-controls>
                <div id="place-info" class="place-info">
                    <div class="label title-row">
                        <div class="title" v-text="selectedPlace.title"></div>
                        <button class="close-button" @click="onCloseInfoWindow()">
                            <svg
                                width="22"
                                height="22"
                                viewBox="-1 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="23" height="23" rx="12" fill="#F5F6F8" />
                                <path
                                    d="M9 9L15 15M15 9L9 15"
                                    stroke="#9297A0"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                    <div class="label address">
                        <svg
                            class="icon"
                            width="23"
                            height="23"
                            viewBox="2 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                                stroke="#00B4AD"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                                stroke="#00B4AD"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        {{ selectedPlace.address + ', ' + selectedPlace.city_title }}
                    </div>
                    <div class="contacts">
                        <div v-if="selectedPlace.phone !== ''" class="label phone">
                            <svg
                                class="icon"
                                width="23"
                                height="23"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.278 4.90918C15.2547 5.09975 16.1523 5.57744 16.856 6.28112C17.5597 6.98479 18.0374 7.88244 18.228 8.85918M14.278 0.90918C16.3072 1.13462 18.1996 2.04335 19.6442 3.48619C21.0889 4.92902 22 6.82019 22.228 8.84918M21.228 16.8292V19.8292C21.2291 20.1077 21.172 20.3833 21.0605 20.6385C20.9489 20.8937 20.7853 21.1228 20.58 21.311C20.3748 21.4993 20.1325 21.6427 19.8687 21.7319C19.6049 21.8211 19.3253 21.8542 19.048 21.8292C15.9708 21.4948 13.015 20.4433 10.418 18.7592C8.00179 17.2238 5.9533 15.1754 4.41796 12.7592C2.72794 10.1504 1.6762 7.18017 1.34796 4.08918C1.32297 3.81265 1.35584 3.53394 1.44446 3.2708C1.53309 3.00767 1.67553 2.76587 1.86273 2.5608C2.04992 2.35573 2.27777 2.19189 2.53175 2.0797C2.78574 1.96751 3.0603 1.90944 3.33796 1.90918H6.33796C6.82327 1.9044 7.29375 2.07626 7.66172 2.39271C8.02969 2.70917 8.27004 3.14863 8.33796 3.62918C8.46458 4.58925 8.69941 5.53191 9.03796 6.43918C9.1725 6.7971 9.20162 7.18609 9.12187 7.56006C9.04211 7.93403 8.85682 8.27729 8.58796 8.54918L7.31796 9.81918C8.74152 12.3227 10.8144 14.3956 13.318 15.8192L14.588 14.5492C14.8598 14.2803 15.2031 14.095 15.5771 14.0153C15.951 13.9355 16.34 13.9646 16.698 14.0992C17.6052 14.4377 18.5479 14.6726 19.508 14.7992C19.9937 14.8677 20.4374 15.1124 20.7545 15.4867C21.0716 15.861 21.2401 16.3388 21.228 16.8292Z"
                                    stroke="#00B4AD"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            {{ selectedPlace.phone }}
                        </div>
                        <div v-if="selectedPlace.email !== ''" class="label email">
                            <svg
                                class="icon"
                                width="23"
                                height="23"
                                viewBox="1 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                                    stroke="#00B4AD"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M22 6L12 13L2 6"
                                    stroke="#00B4AD"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            {{ selectedPlace.email }}
                        </div>
                    </div>
                    <div v-if="selectedPlace.working_hours !== ''" class="label working-hours">
                        <svg
                            class="icon"
                            width="23"
                            height="23"
                            viewBox="1 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                stroke="#00B4AD"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12 6V12L16 14"
                                stroke="#00B4AD"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        {{ transformedWorkingTime(selectedPlace.working_days) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.map-places {
    width: 100%;

    .map-with-slot {
        width: 100%;
    }

    .map-container {
        display: flex;
        flex-wrap: wrap;

        .map {
            width: 100%;
            height: 424px;
            border-radius: 8px;
        }
    }

    .google-info-box-close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background-color: white;
        z-index: 2;
    }

    .place-info {
        flex-direction: column;
        margin: 10px;

        .title-row {
            display: flex;
            margin-top: 9px;
            align-items: flex-start !important;

            .title {
                font-size: var(--font-size-small);
                font-weight: bolder;
                width: 100%;
                align-items: center;
            }

            .close-button {
                width: 24px;
                height: 24px;

                &:hover {
                    path {
                        stroke: var(--text-color-default);
                    }
                }
            }
        }

        .label {
            display: flex;
            font-size: var(--font-size-nano);
            line-height: 28px;
            margin-bottom: 20px;
            align-items: center;

            .icon {
                margin-right: var(--size-pico);
            }
        }

        .contacts {
            div:first-child {
                margin-right: var(--size-nano);
            }

            @include respond-above('lg') {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .working-hours {
            display: none;
            margin-bottom: 0;

            @include respond-above('lg') {
                display: flex;
            }
        }

        .choose-button {
            font-size: var(--font-size-nano);
            border: 1px solid var(--brand-teal);
            width: 100%;
            border-radius: 10px;
            background-color: var(--brand-teal);
            color: var(--white);
            padding: 0 10px;

            .truncate {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @include respond-above('lg') {
                font-size: var(--font-size-tiny);
            }
        }
    }
}
</style>
<style lang="scss">
.gm-style-iw div {
    overflow: hidden !important;
}

.gm-style-iw-chr {
    display: none !important;
}

.gm-style-iw-d,
.gm-style-iw {
    max-height: initial !important;
}
</style>
