<script lang="ts">
import { defineComponent, ref, Ref, nextTick } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import { Router, RouteRecordRaw, useRouter } from 'vue-router';
import GuardsService from '@/Apps/ClaimsMtpl/Services/GuardsService';
import RouteStepperStep from '@/Components/Routing/RouteStepper/RouteStepperStepInterface';
import { Subscription } from 'rxjs';
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Translations from '@/Services/translations.service';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import EventBus from '@/Services/event.bus.service';
import TransferStateService from '@/Core/ServerState/TransferStateService';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();
        const guardService: GuardsService = GuardsService.getInstance();
        const transferService: TransferStateService = TransferStateService.getInstance();

        const router: Router = useRouter();

        const CurrentStep: number = 1;
        const Facility: string = 'one-claims-mtpl';
        const TranslationType: string = 'claims_mtpl';
        const landingPageRoute: string = transferService.get('landingPageRoute');
        const landingPageTitle: string = transferService.get('landingPageTitle');

        const appPreparedAndReady: Ref<boolean> = ref(false);

        const steps: RouteStepperStep[] = [
            {
                path: landingPageRoute,
                meta: { stepLabel: landingPageTitle, title: landingPageTitle },
                redirect: '',
            } as RouteRecordRaw,
        ]
            .concat(router.options.routes || [])
            .map((routeConfig: RouteRecordRaw, index: number): RouteStepperStep => {
                return {
                    routeConfig: routeConfig,
                    label: routeConfig.meta?.title as string,
                    rowIndex: index + 1,
                    visible: true,
                };
            });

        function applyInitialStepUrl(initialStepUrl: string): void {
            claimsMtplService.initialStepUrl = initialStepUrl;
        }

        function applyTypesOfClaim(types: string[]): void {
            claimsMtplService.claimTypes = types;
        }

        function applyDamageTypeFilterData(data: DynamicDictionary[]): void {
            claimsMtplService.damageTypeFilterData = data;
        }

        function applyThankYouStepUrl(url: string): void {
            claimsMtplService.thankYouStepUrl = url;
        }

        function updateStepVisibility(stepsToUpdate: { stepName: string; isVisible: boolean }[]): void {
            stepsToUpdate.forEach(({ stepName, isVisible }) => {
                const stepToUpdate: RouteStepperStep | undefined = steps.find(
                    (step: RouteStepperStep) => step.routeConfig.name === stepName,
                );
                if (stepToUpdate) {
                    stepToUpdate.visible = isVisible;
                }
            });
        }

        function recalculateStepperIndexes(): void {
            let enabledIndex: number = 1;
            steps.forEach((route) => {
                route.rowIndex = route.visible ? enabledIndex++ : enabledIndex;
            });
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                TranslationType,
                steps,
                appPreparedAndReady,
                applyInitialStepUrl,
                applyTypesOfClaim,
                applyDamageTypeFilterData,
                applyThankYouStepUrl,
                claimsMtplService,
                guardService,
                recalculateStepperIndexes,
                updateStepVisibility,
            },
        };
    },
    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.setStorageUsage(false);
        Translations.getInstance().addType(this.TranslationType);
        EventBus.getInstance().subscribe('updateStepVisibility', (data: { stepName: string; isVisible: boolean }[]) => {
            this.updateStepVisibility(data);
            this.recalculateStepperIndexes();
        });
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.appPreparedAndReady = true;
            this.claimsMtplService.init();
            this.guardService.applyInitialStepUrl(this.claimsMtplService.initialStepUrl);
            this.guardService.init();
            nextTick((): void => this.sticky.init());
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
