<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/Services/OneBaseService';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import Form from '@/Assets/Libraries/Form/Form';
import { computed, getCurrentInstance, onMounted, Ref } from 'vue';
import TwoWayFormFields from '@/Apps/TwoWayCommunication/Classes/TwoWayFormFields';
import TwoWayGuardsService from '@/Apps/TwoWayCommunication/Services/TwoWayGuardsService';
import TwoWaySteps from '@/Apps/TwoWayCommunication/Enums/TwoWayStepsEnum';
import SpaUserStorage from '@/Services/SpaUserStorageService';
import TwoWayCommunicationsBlock from '@/Apps/TwoWayCommunication/Interfaces/TwoWayCommunicationsBlockInterface';
import { useDefine } from '@/Composables/Define';
import TwoWaySupportType from '@/Apps/TwoWayCommunication/Enums/TwoWaySupportTypeEnum';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import PropertyDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/PropertyDamageNames';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import { MessageAction } from '@/Components/TwoWayCommunication/Message/Enums/MessageActionEnum';

const { translateForType, applyTranslationType, type } = useTranslate();
const { validResponse } = useDefine();
const router: Router = useRouter();

const twoWayCommunicationService: TwoWayCommunication = TwoWayCommunication.getInstance();
const spaUserStorage: SpaUserStorage = SpaUserStorage.getInstance();

const Step: number = 1;

const form: Form = new Form();

const canProceedToNextStep: Ref<boolean> = computed(() => {
    return true;
});

const communications: Ref<TwoWayCommunicationsBlock[]> = computed(() => {
    return twoWayCommunicationService.communicationsById(twoWayCommunicationService.storageFields.communicationId);
});

const noRepliesForCurrentCommunication: Ref<boolean> = computed(() => {
    let result: boolean = true;
    communications.value.forEach((communication: TwoWayCommunicationsBlock) => {
        if (!communication.isAuthenticatedPersonAuthor) {
            result = false;
        }
    });

    return result;
});

function messageAction(item: TwoWayCommunicationsBlock): string {
    return !item.isAuthenticatedPersonAuthor ? MessageAction.Reply : MessageAction.Add;
}

function setupForm(): void {
    spaUserStorage.setupForm();
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function onBackClick(): void {
    twoWayCommunicationService.storageFields.communicationId = '';
    router.push({ name: TwoWaySteps.Communication });
}

function prepareCommunicationId(): void {
    const parts: string[] = String(window.location.href).split('/');
    twoWayCommunicationService.storageFields.communicationId = parts.length > 1 ? parts[parts.length - 1] : '';
    if (
        twoWayCommunicationService.storageFields.communicationId === '' ||
        twoWayCommunicationService.storageFields.communicationId === TwoWaySteps.Communication
    ) {
        router.push({ name: TwoWaySteps.Communication });
    } else {
        twoWayCommunicationService.markAsRead();
    }
}

function onClick(item: TwoWayCommunicationsBlock): void {
    const action: string = messageAction(item);
    if (action === MessageAction.Add) {
        addNewMessage();
    } else {
        replyOnMessage(item);
    }
}

function addNewMessage(): void {
    if (communications.value.length > 0) {
        twoWayCommunicationService.storageFields.addOrReplyType = twoWayCommunicationService.typeByIc(
            communications.value[0].type,
        );
    }
    twoWayCommunicationService.storageFields.isAddNewMessage = true;
    router.push({ name: TwoWaySteps.CommunicationNew });
}

function replyOnMessage(item: TwoWayCommunicationsBlock): void {
    twoWayCommunicationService.storageFields.newMessageResponseId = item.id;
    twoWayCommunicationService.storageFields.supportType = TwoWaySupportType.Reply;
    twoWayCommunicationService.storageFields.addOrReplyType = twoWayCommunicationService.typeByIc(item.type);
    twoWayCommunicationService.storageFields.isReply = true;
    router.push({ name: TwoWaySteps.CommunicationNew });
}

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('communications');
    spaUserStorage.init(TwoWayFormFields, twoWayCommunicationService.storageFields);
    setupForm();
    if (twoWayCommunicationService.storageFields.allCommunications.length === 0) {
        twoWayCommunicationService.fetchCommunicationsList();
    }
    prepareCommunicationId();
    form.validate().then((): void => {
        TwoWayGuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
    });
});

defineExpose({
    twoWayCommunicationService,
});
</script>

<template>
    <div class="two-way-communication full-width horizontal-spacing horizontal-spacing-desktop">
        <div class="wrapper full-width">
            <app-custom-form
                v-if="spaUserStorage.isReady()"
                :form="spaUserStorage.newForm('two-way-new')"
                class="form full-width"
                @change="spaUserStorage.storeForm()"
            >
                <div class="single-communication full-width">
                    <div class="communication-header">
                        <button
                            data-v-ff6cde2a=""
                            class="back back-margin full-width block-wide"
                            @click="onBackClick()"
                        >
                            <img data-v-ff6cde2a="" src="images/one/arrow-left.svg" alt="back" />
                            <span data-v-ff6cde2a="">{{ translated('back') }}</span>
                        </button>
                        <div class="title full-width block-wide">{{ translated('communication_details') }}</div>
                    </div>
                    <div class="content">
                        <div class="full-width block-wide">
                            <app-message
                                v-for="(item, index) in communications"
                                :id="item.id"
                                :key="index"
                                :index="index"
                                :author="twoWayCommunicationService.communicationAuthor(item)"
                                :date="item.createdAt"
                                :title="item.title"
                                :body="twoWayCommunicationService.communicationBody(item)"
                                :is-new="false"
                                :is-bta="twoWayCommunicationService.communicationIsBta(item)"
                                :attachments="item.attachments"
                                :action="messageAction(item)"
                                @click="onClick(item)"
                            >
                            </app-message>
                        </div>
                    </div>
                </div>
            </app-custom-form>
        </div>
    </div>
</template>

<style scoped lang="scss">
.two-way-communication {
    .wrapper {
        .form {
            .single-communication {
                .content {
                    margin-bottom: 90px;

                    .message {
                        margin-bottom: var(--size-nano);
                    }

                    .need-more-block {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: var(--size-normal);
                        font-size: var(--font-size-small);
                        font-weight: 700;
                        @include respond-below('xs') {
                            flex-direction: column;
                            gap: var(--size-nano);
                            align-items: start;
                            padding: var(--size-normal);
                        }

                        span {
                            font-size: var(--font-size-tiny);
                            font-weight: 600;
                            color: var(--text-color-link);
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            gap: var(--size-nano);

                            &:hover {
                                color: var(--text-color-link-hover);

                                .icon {
                                    path {
                                        stroke: var(--text-color-link-hover);
                                    }

                                    rect {
                                        fill: var(--text-color-link-hover);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
