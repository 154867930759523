import { AutoStorage, useAutoStorage } from '@/Pages/Auto/Composables/AutoStorage';

export default class AutoStorageService {
    private static instance: AutoStorage;

    private constructor() {}

    public static getInstance(): AutoStorage {
        if (!AutoStorageService.instance) {
            AutoStorageService.instance = useAutoStorage();
        }

        return AutoStorageService.instance;
    }
}
