import ActiveElement from '@/Assets/Libraries/Dom/ActiveElement';

export default {
    beforeMount: (element: HTMLElement): void => {
        element.addEventListener('mouseleave', (): void => {
            if (ActiveElement.is(element)) {
                ActiveElement.blur();
            }
        });
    },
};
