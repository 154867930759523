import Confirm from '@/Assets/Libraries/Popups/Types/Confirm';
import Popups from '@/Assets/Libraries/Popups/Types/PopupsInterface';
import SelectAccount from '@/Assets/Libraries/Popups/Types/SelectAccount';
import SimpleError from '@/Assets/Libraries/Popups/Types/SimpleError';
import CoveredProperty from '@/Assets/Libraries/Popups/Types/CoveredProperty';
import CoveredTravel from '@/Assets/Libraries/Popups/Types/CoveredTravel';
import Covered from '@/Assets/Libraries/Popups/Types/Covered';
import Login from '@/Assets/Libraries/Popups/Types/Login';
import LoadingWait from '@/Assets/Libraries/Popups/Types/LoadingWait';
import Loading from '@/Assets/Libraries/Popups/Types/Loading';
import ClaimSuccess from '@/Assets/Libraries/Popups/Types/ClaimSuccess';
import Error from '@/Assets/Libraries/Popups/Types/Error';
import VideoPopup from '@/Assets/Libraries/Popups/Types/VideoPopup';
import CoveredProduct from '@/Assets/Libraries/Popups/Types/CoveredProduct';
import SessionEnd from '@/Assets/Libraries/Popups/Types/SessionEnd';
import OneCovered from '@/Assets/Libraries/Popups/Types/OneCovered';
import TravelRentalCarSecurity from '@/Assets/Libraries/Popups/Types/TravelRentalCarSecurity';
import Info from '@/Assets/Libraries/Popups/Types/Info';
import OneConfirm from '@/Assets/Libraries/Popups/Types/OneConfirm';
import NextBestAction from '@/Assets/Libraries/Popups/Types/NextBestAction';
import YoungestDriverAge from '@/Assets/Libraries/Popups/Types/YoungestDriverAge';

export default class OnePopup {
    public withType(): Popups {
        return this.availablePopups();
    }

    private availablePopups(): Popups {
        return {
            confirmPopup: new Confirm(),
            selectAccountPopup: new SelectAccount(),
            simpleError: new SimpleError(),
            coveredProperty: new CoveredProperty(),
            coveredTravel: new CoveredTravel(),
            covered: new Covered(),
            login: new Login(),
            loadingWait: new LoadingWait(),
            loading: new Loading(),
            claimSuccess: new ClaimSuccess(),
            error: new Error(),
            videoPopup: new VideoPopup(),
            coveredProduct: new CoveredProduct(),
            sessionEnd: new SessionEnd(),
            oneCovered: new OneCovered(),
            travelRentalCarSecurity: new TravelRentalCarSecurity(),
            youngestDriverAge: new YoungestDriverAge(),
            info: new Info(),
            oneConfirm: new OneConfirm(),
            nextBestAction: new NextBestAction(),
        } as Popups;
    }
}
