import { Loader } from '@googlemaps/js-api-loader';
import MapSettings from '@/Interfaces/map.settings.interface';
import Translations from '@/Services/translations.service';
import { MarkerClusterer, Renderer } from '@googlemaps/markerclusterer';
import SettingsService from '@/Services/settings.service';

export const useMaps = (): MapDispatcher => {
    const settingsService: SettingsService = SettingsService.getInstance();
    const apiKey: string = settingsService.value('mapApiKey') as string;
    const showTopPlaces: number = settingsService.value('mapTopPlacesCount') as number;
    const mapSettings: MapSettings = JSON.parse(settingsService.value('mapSettings') as string) as MapSettings;
    const mapLoader = (): Loader => {
        const region: string = Translations.getInstance().countryIso;
        const language: string = Translations.getInstance().language;

        return new Loader({
            apiKey: apiKey,
            region: region,
            language: language,
            version: 'weekly',
            libraries: ['places', 'geometry'],
        });
    };

    const createMap = (mapId: string): google.maps.Map => {
        return new google.maps.Map(document.getElementById(mapId) as HTMLElement, {
            center: mapSettings.defaultMapCenter,
            disableDefaultUI: true,
            mapTypeControl: false,
            streetViewControl: false,
            scrollwheel: true,
            scaleControl: true,
            fullscreenControl: false,
            zoomControl: false,
            zoom: mapSettings.defaultZoom,
            minZoom: mapSettings.minZoom,
            maxZoom: mapSettings.maxZoom,
        }) as google.maps.Map;
    };

    const createClusterer = (map: google.maps.Map): MarkerClusterer => {
        const clustererPlacesIcon: string = 'images/one/components/map/clusterer.svg';
        const renderer: Renderer = {
            render: ({ count, position }) => {
                return new google.maps.Marker({
                    label: { text: String(count), color: '#00807B', fontSize: '11px' },
                    position: position,
                    icon: clustererPlacesIcon,
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                });
            },
        };

        return new MarkerClusterer({ map, renderer });
    };

    const placeIcon = (marker: google.maps.Marker, type: string, special: string): void => {
        let icon: string;
        switch (type) {
            case 'selected':
                icon =
                    special === 'true'
                        ? 'images/one/components/map/place-special-selected.svg'
                        : 'images/one/components/map/place-selected.svg';
                break;
            default:
                icon =
                    special === 'true'
                        ? 'images/one/components/map/place-special.svg'
                        : 'images/one/components/map/place.svg';
        }
        marker.setIcon(icon);
    };

    const locationIcon = (marker: google.maps.Marker): void => {
        const icon: string = 'images/one/components/map/location.svg';
        marker.setIcon(icon);
    };

    const changeBasemap = (map: google.maps.Map): void => {
        const mapTypeId: google.maps.MapTypeId =
            map.getMapTypeId() === String(google.maps.MapTypeId.ROADMAP)
                ? google.maps.MapTypeId.SATELLITE
                : google.maps.MapTypeId.ROADMAP;
        map.setMapTypeId(mapTypeId);
    };

    return {
        mapLoader,
        createMap,
        createClusterer,
        placeIcon,
        locationIcon,
        showTopPlaces,
        changeBasemap,
        mapSettings,
    };
};

export interface MapDispatcher {
    mapLoader: () => Loader;
    createMap: (mapId: string) => google.maps.Map;
    createClusterer: (map: google.maps.Map) => MarkerClusterer;
    placeIcon: (marker: google.maps.Marker, type: string, special: string) => void;
    locationIcon: (marker: google.maps.Marker) => void;
    showTopPlaces: number;
    changeBasemap: (map: google.maps.Map) => void;
    mapSettings: MapSettings;
}
