<script setup lang="ts">
import { PropType } from 'vue';
import ProductBlockItem from '@/Components/Other/ProductBlock/Interfaces/ProductBlockItemInterface';
import CssClass from '@/Enums/CssClassEnum';
import ProductBlockEmitters from '@/Components/Other/ProductBlock/Enums/ProductBlockEmittersEnum';
import ProductBlockSumType from '@/Components/Other/ProductBlock/Enums/ProductBlockSumTypeEnum';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import { usePrice } from '@/Composables/Price';

const { sparse } = usePrice();
const props = defineProps({
    componentName: { type: String, default: 'OneDayMini' },
    products: { type: Array as PropType<ProductBlockItem[]>, default: () => [] },
    selectedProduct: {
        type: Object as PropType<DynamicDictionary>,
        default: () => {
            return {
                selectedProductId: '',
                miniSelectedProductId: '',
                width: 0,
            };
        },
    },
    productSumType: { type: String, default: ProductBlockSumType.None },
});
const emit = defineEmits([ProductBlockEmitters.MiniPlanSelected]);
const { translateForType } = useTranslate();
const TranslationType: string = 'product_block';

function miniPlanClasses(product: ProductBlockItem): string {
    const result: string[] = [];
    if (props.selectedProduct.miniSelectedProductId === product.id) {
        result.push(CssClass.Active);
    }

    return result.join(' ');
}

function onMiniPlanClick(product: ProductBlockItem): void {
    emit(ProductBlockEmitters.MiniPlanSelected, product.id);
}

function discountedSumPostfix(product: ProductBlockItem): string {
    return isProductDiscountAvailable(product) ? product.discountSumPostfix : '&euro;' + productSumLabel();
}

function isProductDiscountAvailable(product: ProductBlockItem): boolean {
    return product.discountSavedSum > 0;
}

function printSparsePolicyPrice(product: ProductBlockItem): string {
    return sparse(product.discountSum, false);
}

function productSumLabel(): string {
    let result: string = '';
    if (props.productSumType !== '') {
        result = '/' + translateForType(props.productSumType, TranslationType);
    }
    return result;
}
</script>

<template>
    <div class="products-block-mini">
        <button
            v-for="(product, index) in products"
            :key="index"
            class="mini-plan"
            :class="miniPlanClasses(product)"
            @click="onMiniPlanClick(product)"
        >
            <span class="mini-plan-header">{{ product.productName }}</span>
            <span class="mini-plan-price">
                <span class="price">
                    <span class="num">{{ printSparsePolicyPrice(product) }}</span>
                    <span class="text-currency" v-html="discountedSumPostfix(product)"></span>
                </span>
                <span v-if="isProductDiscountAvailable(product)" class="price-and-discount">
                    <span class="strike"
                        ><span class="base-price">{{ product.discountFullSum }} &euro;</span></span
                    >
                    <span class="base-discount">{{ product.discountSavedSumText }}</span>
                </span>
                <svg
                    class="check"
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 7.4L6.72727 13L19 1"
                        stroke="#00B4AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </span>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.products-block-mini {
    padding-left: var(--size-normal);
    padding-right: var(--size-normal);
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: var(--size-small);

    @media (max-width: 350px) {
        padding-left: var(--size-pico);
        padding-right: var(--size-pico);
    }

    @include respond-above('sm') {
        display: none;
    }

    .mini-plan {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 128px;
        width: 100%;
        max-width: 131px;
        border-radius: 8px;
        border: 1px solid var(--black-100);
        background: var(--white);

        .mini-plan-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            color: var(--text-color-default);
            font-size: var(--font-size-pico);

            &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: var(--black-500);
                opacity: 0.24;
            }
        }

        .mini-plan-price {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            height: 76%;
            color: var(--text-color-default);
            font-size: var(--font-size-tiny);

            .price {
                font-weight: 600;

                .text-currency {
                    padding-left: var(--size-pico);
                }
            }

            .check {
                display: none;
            }

            .price-and-discount {
                font-size: var(--font-size-nano);
                color: var(--black-500);
                display: flex;
                flex-direction: column;

                .strike {
                    color: var(--brand-red);

                    .base-price {
                        color: var(--black-500) !important;
                    }
                }
            }
        }

        .mini-plan-header,
        .mini-plan-price {
            width: 100%;
        }

        &.active {
            border: 2px solid var(--teal-800);
            background-color: var(--teal-50);

            .mini-plan-price {
                .check {
                    display: block;
                }
            }

            .mini-plan-header {
                background-color: var(--teal-800);
                color: var(--white);

                &::after {
                    display: none;
                }
            }
        }
    }
}
</style>
