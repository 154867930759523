import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

export class CellRenderer {
    private eGui!: HTMLSpanElement;

    public init(params: DynamicDictionary) {
        this.eGui = document.createElement('span');
        const data: DynamicDictionary = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
        this.eGui.innerHTML = this.htmlTemplate(params, data);
    }

    public getGui() {
        return this.eGui;
    }

    public refresh(params: DynamicDictionary): boolean {
        return false;
    }

    public htmlTemplate(params: DynamicDictionary, data: DynamicDictionary): string {
        return params.value;
    }
}
