<script setup lang="ts">
import moment from 'moment';
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import AppInputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import SettingsService from '@/Services/settings.service';
import { computed, ComputedRef, onMounted, PropType, Ref, ref, watch, nextTick } from 'vue';
import InputDateLayout from '@/Components/Inputs/InputDate/InputDateLayout';
import KeyPressEvent = JQuery.KeyPressEvent;
import { useTranslate } from '@/Composables/Translate';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

const props = defineProps({
    layout: { type: String, default: InputDateLayout.dayMonthYear },
    formField: { type: Object as PropType<FormField<Date | ''>>, default: () => new FormField('') },
    label: { type: String, default: '' },
    feedbackMessage: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    minDate: { type: Date, default: () => moment('1900-01-01').toDate() },
    maxDate: { type: Date, default: () => moment().toDate() },
    useCustomPlaceholders: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
});

const emit = defineEmits(['change']);

const { translate } = useTranslate();

const form: Form = new Form();
const daysInputOptions: Ref<InputOption[]> = ref([]);
const monthsInputOptions: Ref<InputOption[]> = ref([]);
const yearsInputOptions: Ref<InputOption[]> = ref([]);
const settings: SettingsService = SettingsService.getInstance();
const dayFocused: Ref<boolean> = ref(false);
const monthFocused: Ref<boolean> = ref(false);
const yearFocused: Ref<boolean> = ref(false);
const lastDayValues: Ref<string[]> = ref([]);
const lastMonthValues: Ref<string[]> = ref([]);
const lastYearValues: Ref<string[]> = ref([]);
const lastKeypressKey: Ref<string> = ref('');
const lastKeypressTime: Ref<number> = ref(0);
const daySelectElement: Ref<typeof AppInputSelect | null> = ref(null);
const monthSelectElement: Ref<typeof AppInputSelect | null> = ref(null);
const yearSelectElement: Ref<typeof AppInputSelect | null> = ref(null);

const daysPlaceholder: ComputedRef<string> = computed(() => {
    return props.useCustomPlaceholders ? translate('btar_day_custom') : translate('btar_day');
});
const monthsPlaceholder: ComputedRef<string> = computed(() => {
    return props.useCustomPlaceholders ? translate('btar_month_custom') : translate('btar_month');
});
const yearsPlaceholder: ComputedRef<string> = computed(() => {
    return props.useCustomPlaceholders ? translate('btar_year_custom') : translate('btar_year');
});

const fieldValueIsValidDate: ComputedRef<boolean> = computed(() => {
    return props.formField.value instanceof Date && props.formField.value.toString() !== 'Invalid Date';
});

const chainKeypressKey: ComputedRef<boolean> = computed(() => {
    return $.now() - lastKeypressTime.value < parseInt(settings.inputDateChainDelay(), 10);
});

const fieldsValidators: ComputedRef<object> = computed(() => {
    return {
        isRequired: () => {
            return (
                (form.field('day').value !== '' &&
                    form.field('month').value !== '' &&
                    form.field('year').value !== '') ||
                (form.field('day').value === '' && form.field('month').value === '' && form.field('year').value === '')
            );
        },
    };
});

watch(
    () => props.formField.value,
    (value, previousValue) => {
        if (value.toString() === 'Invalid Date') {
            props.formField.value = '';
        }
        if (value.toString() !== '' && value !== previousValue) {
            patchChildForm(resetTime(value));
        }
    },
    { deep: true },
);

onMounted(() => {
    setupForm();
    makeInputOptions();
    props.formField.value = resetTime(props.formField.value);
    patchChildForm(props.formField.value);
    props.formField.addValidators({
        childFormIsValid: () => props.formField.isValid || form.isValid(),
        dateBeforeMaxDate: () => {
            let result: boolean = true;
            if (fieldValueIsValidDate.value) {
                result = props.formField.value <= props.maxDate;
            }
            return result;
        },
    });
    form.validate();
    initKeyListening();
});

function onChildChange(): void {
    form.validate().then(() => {
        patchValue();
    });
}

function onChildClose(element: string): void {
    if (element === 'day') {
        dayFocused.value = false;
    }
    if (element === 'month') {
        monthFocused.value = false;
    }
    if (element === 'year') {
        yearFocused.value = false;
    }
    props.formField.touch().validate();
}

function onChildOpen(element: string): void {
    switch (element) {
        case 'day':
            openDay();
            break;
        case 'month':
            openMonth();
            break;
        case 'year':
            openYear();
            break;
        default:
            break;
    }
}

function openDay(): void {
    applyFocus('month');
    (monthSelectElement.value as DynamicDictionary).close();
    (yearSelectElement.value as DynamicDictionary).close();
}

function openMonth(): void {
    applyFocus('month');
    (daySelectElement.value as DynamicDictionary).close();
    (yearSelectElement.value as DynamicDictionary).close();
}

function openYear(): void {
    applyFocus('year');
    (daySelectElement.value as DynamicDictionary).close();
    (monthSelectElement.value as DynamicDictionary).close();
}

function applyFocus(element: string): void {
    dayFocused.value = element === 'day';
    monthFocused.value = element === 'month';
    yearFocused.value = element === 'year';
}

function initKeyListening(): void {
    $(document).on('keypress', (event: KeyPressEvent) => {
        const key: string = event.key;
        if (dayFocused.value) {
            lastKeypressKey.value = keyUpDay(key);
            lastMonthValues.value = [];
            lastYearValues.value = [];
            nextTick(() => {
                (daySelectElement.value as DynamicDictionary).scrollToSelectedElement();
            });
        }
        if (monthFocused.value) {
            lastKeypressKey.value = keyUpMonth(key);
            lastDayValues.value = [];
            lastYearValues.value = [];
            nextTick(() => {
                (monthSelectElement.value as DynamicDictionary).scrollToSelectedElement();
            });
        }
        if (yearFocused.value) {
            lastKeypressKey.value = keyUpYear(key);
            lastDayValues.value = [];
            lastMonthValues.value = [];
            nextTick(() => {
                (yearSelectElement.value as DynamicDictionary).scrollToSelectedElement();
            });
        }
        lastKeypressTime.value = $.now();
    });
}

function keyUpDay(key: string): string {
    const chained: boolean = chainKeypressKey.value && !matchesPrevious(key);
    if (chained) {
        key = lastKeypressKey.value + key;
    }
    const keypressFoundInputOption: InputOption | undefined = daysInputOptions.value.find((item: InputOption) => {
        let found: boolean = false;
        if (item.value === key) {
            found = true;
        }
        if (String(item.value).toLowerCase().startsWith(key.toLowerCase())) {
            found = true;
        }
        if (lastDayValues.value.length && lastDayValues.value.includes(String(item.value))) {
            found = false;
        }
        return found;
    });

    if (keypressFoundInputOption !== undefined) {
        lastDayValues.value.push(String(keypressFoundInputOption.value));
        form.field('day').patch(keypressFoundInputOption.value);
    } else {
        lastDayValues.value = [];
    }
    if (chained) {
        lastDayValues.value = [];
    }
    if (!chained && !matchesPrevious(key)) {
        lastDayValues.value = [];
    }
    if (key.length >= 2) {
        key = '';
    }
    return key;
}

function keyUpMonth(key: string): string {
    const chained: boolean = chainKeypressKey.value && !matchesPrevious(key);
    if (chained) {
        key = lastKeypressKey.value + key;
    }
    const keypressFoundInputOption: InputOption | undefined = monthsInputOptions.value.find((item: InputOption) => {
        let found: boolean = false;
        if (item.name.toLowerCase().startsWith(key.toLowerCase())) {
            found = true;
        }
        if (lastMonthValues.value.length && lastMonthValues.value.includes(String(item.value))) {
            found = false;
        }
        return found;
    });
    if (keypressFoundInputOption !== undefined) {
        lastMonthValues.value.push(String(keypressFoundInputOption.value));
        form.field('month').patch(keypressFoundInputOption.value);
    } else {
        lastMonthValues.value = [];
    }
    if (chained) {
        lastMonthValues.value = [];
    } else if (!matchesPrevious(key)) {
        lastMonthValues.value = [];
    }
    return key;
}

function keyUpYear(key: string): string {
    const chained: boolean = chainKeypressKey.value && !matchesPrevious(key);
    if (chained) {
        key = lastKeypressKey.value + key;
    }
    const keypressFoundInputOption: InputOption | undefined = yearsInputOptions.value.find((item: InputOption) => {
        let found: boolean = false;
        if (item.value === key) {
            found = true;
        }
        if (String(item.value).toLowerCase().startsWith(key.toLowerCase())) {
            found = true;
        }
        if (lastYearValues.value.length && lastYearValues.value.includes(String(item.value))) {
            found = false;
        }
        return found;
    });

    if (keypressFoundInputOption !== undefined) {
        lastYearValues.value.push(String(keypressFoundInputOption.value));
        form.field('year').patch(keypressFoundInputOption.value);
    } else {
        lastYearValues.value = [];
    }
    if (chained) {
        lastYearValues.value = [];
    } else if (!matchesPrevious(key)) {
        lastYearValues.value = [];
    }
    if (key.length >= 4) {
        key = '';
    }
    return key;
}

function matchesPrevious(key: string): boolean {
    return lastKeypressKey.value === key;
}

function patchValue(): void {
    if (form.isValid()) {
        const dayField = form.field('day');
        const monthField = form.field('month');
        const yearField = form.field('year');
        const value = moment()
            .set('year', Number(yearField.value))
            .set('month', Number(monthField.value) - 1)
            .set('date', Number(dayField.value))
            .toDate();
        if (value !== props.formField.value) {
            props.formField.value = resetTime(value);
            props.formField.validate();
            emit('change', props.formField.value);
        }
    }
}

function patchChildForm(value: Date): void {
    const date = moment(value);
    if (date.isValid()) {
        const day = date.date().toString();
        const month = (date.month() + 1).toString();
        const year = date.year().toString();
        const dayField = form.field('day');
        const monthField = form.field('month');
        const yearField = form.field('year');
        if (dayField.value !== day) {
            dayField.patch(day);
        }
        if (monthField.value !== month) {
            monthField.patch(month);
        }
        if (yearField.value !== year) {
            yearField.patch(year);
        }
        form.validate();
    }
}

function resetTime(date: Date | string): Date {
    return moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
}

function setupForm(): void {
    form.addField(new FormField('day', '', fieldsValidators.value));
    form.addField(new FormField('month', '', fieldsValidators.value));
    form.addField(new FormField('year', '', fieldsValidators.value));
}

function makeInputOptions(): void {
    const minDates: moment.Moment = moment(props.minDate);
    const maxDates: moment.Moment = moment(props.maxDate);
    daysInputOptions.value = new Array(31).fill('').map((value, index) => {
        const dayNumber = index + 1;
        return new InputOptionBuilder().setValue(dayNumber.toString()).build();
    });
    monthsInputOptions.value = new Array(12).fill('').map((value, index) => {
        const monthNumber = index + 1;
        const monthName = translatedMonths()[index];
        return new InputOptionBuilder().setValue(monthNumber.toString()).setName(monthName).build();
    });
    yearsInputOptions.value = new Array(maxDates.year() - minDates.year() + 1).fill('').map((value, index) => {
        const yearNumber = maxDates.year() - index;
        return new InputOptionBuilder().setValue(yearNumber.toString()).build();
    });
}

function translatedMonths(): string[] {
    return [
        translate('btar_calendar_january'),
        translate('btar_calendar_february'),
        translate('btar_calendar_march'),
        translate('btar_calendar_april'),
        translate('btar_calendar_may'),
        translate('btar_calendar_june'),
        translate('btar_calendar_july'),
        translate('btar_calendar_august'),
        translate('btar_calendar_september'),
        translate('btar_calendar_october'),
        translate('btar_calendar_november'),
        translate('btar_calendar_december'),
    ];
}
</script>
<template>
    <div
        :id="formField.name"
        ref="container"
        class="input input-date-with-dropdown"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <div v-if="label" class="label informative">
            <label>{{ label }}</label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper">
            <app-input-select
                v-if="daysInputOptions"
                ref="daySelectElement"
                class="day"
                :form-field="form.field('day')"
                :placeholder="daysPlaceholder"
                :options="daysInputOptions"
                :disabled="disabled"
                :data-store-disabled="true"
                @change="onChildChange"
                @close="onChildClose('day')"
                @open="onChildOpen('day')"
            >
            </app-input-select>
            <app-input-select
                v-if="monthsInputOptions"
                ref="monthSelectElement"
                class="month"
                :form-field="form.field('month')"
                :placeholder="monthsPlaceholder"
                :options="monthsInputOptions"
                :disabled="disabled"
                :data-store-disabled="true"
                @change="onChildChange"
                @close="onChildClose('month')"
                @open="onChildOpen('month')"
            >
            </app-input-select>
            <app-input-select
                v-if="yearsInputOptions"
                ref="yearSelectElement"
                class="year"
                :form-field="form.field('year')"
                :placeholder="yearsPlaceholder"
                :options="yearsInputOptions"
                :disabled="disabled"
                :data-store-disabled="true"
                @change="onChildChange"
                @close="onChildClose('year')"
                @open="onChildOpen('year')"
            >
            </app-input-select>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.input-date-with-dropdown {
    display: flex;
    width: 100%;

    .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    @include respond-above('xs') {
        .wrapper {
            flex-direction: row;
            max-width: 430px;

            .day {
                width: 25%;
                margin-right: -1px;

                :deep(.select .button) {
                    border-radius: 3px 0 0 3px;
                }
            }

            .month {
                width: 50%;

                :deep(.select .button) {
                    border-radius: 0;
                }
            }

            .year {
                width: 25%;
                margin-left: -1px;

                :deep(.select .button) {
                    border-radius: 0 3px 3px 0;
                }
            }
        }
    }

    :deep(.input) {
        margin-top: 0 !important;
    }

    :deep(.invalid.touched .select .button) {
        border-color: var(--component-color-border-default);
    }

    &.invalid.touched :deep(.select .button) {
        border-color: var(--component-color-border-error);
    }
}
</style>
