import moment from 'moment';
import Validation from '@/Services/validation.service';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

export default class PersonCodeGenerator {
    private existingCodes: string[] = [];
    private birthDate: string = '';
    private dateFormat: string = '';
    private personCode: string = '';
    private delimiter: string = '';
    private base: number = 0;
    private baseAsPrefix: boolean = false;

    private readonly DateFormatsByIso: DynamicDictionary = {
        LV: 'DDMMYY',
        LT: 'YYYYMMDD',
        EE: 'DDMMYY',
    };
    private readonly DelimitersByIso: DynamicDictionary = {
        LV: '-',
        LT: '',
        EE: '-',
    };
    private readonly BasesByIso: DynamicDictionary = {
        LV: 99999,
        LT: 999,
        EE: 99999,
    };

    public constructor(iso: string) {
        this.initForCountry(iso);
    }

    public withExistingCodes(value: string[]): PersonCodeGenerator {
        this.existingCodes = value.map((code: string): string => code.replace('-', ''));

        return this;
    }

    public withBirthDate(value: Date | string): PersonCodeGenerator {
        if (value instanceof Date) {
            this.birthDate = moment(value).format(this.dateFormat);
        } else {
            this.birthDate = value;
        }

        return this;
    }

    public withPersonCode(value: string): PersonCodeGenerator {
        this.personCode = value.replace('-', '');

        return this;
    }

    public generate(): string {
        const personCodeMidIndex: number = 6;
        if (this.isValidCode()) {
            return this.personCode;
        }
        let result: string = this.generatedCode();
        while (!this.isUniqueCode(result)) {
            result = this.generatedCode();
        }

        return result.substring(0, personCodeMidIndex) + this.delimiter + result.substring(personCodeMidIndex);
    }

    private initForCountry(iso: string): void {
        this.base = this.BasesByIso[iso];
        this.dateFormat = this.DateFormatsByIso[iso];
        this.delimiter = this.DelimitersByIso[iso];
        this.baseAsPrefix = iso === 'LT';
    }

    private isUniqueCode(value: string): boolean {
        return !this.existingCodes.some((code: string): boolean => code === value);
    }

    private isValidCode(): boolean {
        return Validation.isValidNaturalPersonCode(this.personCode);
    }

    private generatedCode(): string {
        const result: string = this.baseAsPrefix
            ? String(this.base) + this.birthDate
            : this.birthDate + String(this.base);
        this.base--;

        return result;
    }
}
