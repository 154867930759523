<script setup lang="ts">
import Sanitizer from '@/Services/sanitizer.service';
import { computed, ComputedRef, onMounted, reactive, PropType, UnwrapNestedRefs, ref } from 'vue';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import Validation from '@/Services/validation.service';
import PlateNumberPlaceholder from '@/Enums/PlateNumberPlaceholderEnum';
import FormField from '@/Assets/Libraries/Form/FormField';
import { useInputErrorMessage } from '@/Composables/InputErrorMessage';

const props = defineProps({
    placeholder: { type: String, default: () => PlateNumberPlaceholder.ByCountry[new AppCountry().iso()] },
    obligatory: { type: Boolean, default: true },
    formField: { type: Object as PropType<FormField<string>>, default: () => new FormField('') },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    disableErrorText: { type: Boolean, default: false },
    feedbackMessage: { type: String, default: '' },
    supportTextMessage: { type: String, default: '' },
    withHintIcon: { type: Boolean, default: false },
});

const emit = defineEmits(['change', 'keyup']);

const fieldId: ComputedRef<string> = computed(() => {
    return props.formField.name + '-with-eu';
});

const icon: ComputedRef<string> = computed(() => {
    return '/images/one/plate-no-' + new AppCountry().iso().toLowerCase() + '.svg';
});

const inputErrors = computed(() =>
    useInputErrorMessage(props.formField, props.disableErrorText, {
        supportTextMessage: props.supportTextMessage,
        feedbackMessage: props.feedbackMessage,
    }),
);

onMounted(() => {
    props.formField.addValidators(validators());
    props.formField.addSanitizer(Sanitizer.cleanPlateNumber);
});

function onKeyUp(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();
    emit('keyup', props.formField.value);
}

function onChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();
    emit('change', props.formField.value);
}

function validators(): Record<string, any> {
    return props.obligatory
        ? { isValidPlateNumber: Validation.isValidPlateNumber }
        : { skipPlateNumberValidation: () => true };
}
</script>

<template>
    <div
        :id="formField.name"
        class="input input-plate-number"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <div v-if="label" class="label informative">
            <label :for="fieldId">{{ label }}</label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper with-eu">
            <div class="eu">
                <img :src="icon" width="23" height="38" alt="" />
            </div>
            <input
                :id="fieldId"
                v-model="formField.value"
                v-uppercase
                type="text"
                :name="formField.name"
                :placeholder="placeholder"
                :disabled="disabled"
                @change="onChange()"
                @keyup="onKeyUp()"
            />
            <div v-if="inputErrors.infoMessageIsVisible() && !disableErrorText" class="feedback">
                <img v-if="withHintIcon" src="images/icons/alert-triangle.svg" alt="alert triangle" />
                <span>{{ inputErrors.infoMessage() }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.feedback {
    display: flex;
    width: 100%;
    padding-top: var(--size-pico);
    color: var(--text-color-error);
    font-size: var(--font-size-nano);
    line-height: var(--size-tiny);
    gap: var(--size-pico);

    img {
        align-self: flex-start;
        filter: brightness(0) saturate(100%) invert(16%) sepia(39%) saturate(4632%) hue-rotate(339deg) brightness(92%)
            contrast(121%);
    }
}
</style>
