import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

declare const BTA: DynamicDictionary;

export default class OneLocalization {
    public translations(): DynamicDictionary {
        return BTA.One.Localization.translations;
    }

    public clear(): void {
        delete BTA.One.Translations;
        delete BTA.One.Localization.translations;
        delete BTA.One.Localization;
    }
}
