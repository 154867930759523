<script lang="ts">
import { computed, defineComponent, nextTick, Ref, watch } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Form from '@/Assets/Libraries/Form/Form';
import SubmitterUrls from '@/Services/SubmitterUrls.service';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import { useNavigate } from '@/Composables/Navigate';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/Interfaces/image.tag.attributes';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import FormField from '@/Assets/Libraries/Form/FormField';
import User from '@/Services/user.service';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import moment from 'moment';
import Sanitizer from '@/Services/sanitizer.service';
import Validation from '@/Services/validation.service';
import ExtractDataService from '@/Services/extract.data.service';
import UserStorage from '@/Services/user.storage.service';
import SettingsService from '@/Services/settings.service';
import { CountryComponentParams } from '@/Components/Inputs/InputCountry/CountryComponentParams';
import { take } from 'rxjs/operators';
import { useScroll } from '@/Composables/Scroll';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const extractDataService: ExtractDataService = new ExtractDataService();

        const { translate } = useTranslate();
        const stepsSubmitter = useStepsSubmitter();
        const settingsService: SettingsService = SettingsService.getInstance();
        const CurrentStep: number = 2;
        const Facility: string = 'bicycle_policy';
        const form: Form<{
            insuredObject: string;
            policyHolderIsAuthenticated: boolean;
            policyHolderIsResident: boolean;
            policyHolderFirstName: string;
            policyHolderLastName: string;
            policyHolderIdentityNumber: string;
            policyHolderCountry: CountryComponentParams;
            policyHolderBirthDate: Date | string;
        }> = new Form({ useValidationV2: true });
        const isAuthenticated: Ref<boolean> = computed(() => form.field('policyHolderIsAuthenticated').value);
        const isResident: Ref<boolean> = computed(() => form.field('policyHolderIsResident').value);
        const isLithuanian: Ref<boolean> = computed(() => form.field('policyHolderCountry').value.iso === 'LT');
        const isEstonian: Ref<boolean> = computed(() => form.field('policyHolderCountry').value.iso === 'EE');
        const birthDateRequired: Ref<boolean> = computed(() => {
            return (isAnonymousNumber.value || requiresBirthDate()) && !isAuthenticated.value;
        });
        const isAnonymousNumber: Ref<boolean> = computed(() => {
            return (
                isResident.value &&
                Validation.isValidPersonCodeWithoutDate(form.field('policyHolderIdentityNumber').value)
            );
        });

        function setupForm(): void {
            form.addField(
                new FormField('insuredObject')
                    .addValidators({ required: Validation.required })
                    .addSanitizer(Sanitizer.cleanBicycleModel),
            );
            form.addField(new FormField('policyHolderIsAuthenticated', ''));
            form.addField(new FormField('policyHolderIsResident', ''));
            form.addField(
                new FormField('policyHolderFirstName')
                    .addValidators({
                        required: Validation.requiredIf(() => !isAuthenticated.value),
                        validCaption: Validation.isValidCaption,
                    })
                    .addSanitizer(Sanitizer.cleanUppercaseName),
            );
            form.addField(
                new FormField('policyHolderLastName')
                    .addValidators({
                        required: Validation.requiredIf(() => !isAuthenticated.value),
                        validCaption: Validation.isValidCaption,
                    })
                    .addSanitizer(Sanitizer.cleanUppercaseName),
            );
            form.addField(
                new FormField('policyHolderIdentityNumber').addValidators({
                    required: Validation.requiredIf(() => !isAuthenticated.value),
                }),
            );
            form.addField(
                new FormField('policyHolderCountry').addValidators({
                    required: Validation.requiredIf(() => !isAuthenticated.value && !isResident.value),
                }),
            );
            form.addField(
                new FormField('policyHolderBirthDate').addValidators({
                    required: Validation.requiredIf(() => birthDateRequired.value),
                }),
            );
            form.setReady();
        }

        function onAppReady(): void {
            if (isAuthenticatedUser() && form.field('policyHolderIsAuthenticated').value) {
                patchUserFields();
            }
        }

        function isAuthenticatedUser(): boolean {
            return User.getInstance().isLogged();
        }

        function requiresBirthDate(): boolean {
            return !isResident.value && !isLithuanian.value && !isEstonian.value;
        }

        function onAuthenticatedClick(): void {
            if (isAuthenticated.value) {
                patchUserFields();
            } else {
                patchGuestFields();
            }
        }

        function patchUserFields(): void {
            const currentUser: UserCredentials = btaBase.user.current;
            form.field('policyHolderFirstName').setValue(currentUser.firstname);
            form.field('policyHolderLastName').setValue(currentUser.lastname);
            form.field('policyHolderIdentityNumber').setValue(currentUser.personCode);
            patchDefaultHolderCountry();
        }

        function patchGuestFields(): void {
            form.field('policyHolderFirstName').setValue('');
            form.field('policyHolderLastName').setValue('');
            form.field('policyHolderIdentityNumber').setValue('');
        }

        function patchDefaultHolderCountry(): void {
            form.field('policyHolderCountry').setValue({
                ic: '100000000',
                iso: settingsService.localeIso(),
                phoneCode: settingsService.phoneCode(),
            });
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function insuredPerson(): DynamicDictionary {
            const holderBirthDateField: FormField = form.field('policyHolderBirthDate');

            return {
                firstName: form.field('policyHolderFirstName').value,
                lastName: form.field('policyHolderLastName').value,
                personCode: form.field('policyHolderIdentityNumber').value,
                country: form.field('policyHolderCountry').value,
                birthDate: holderBirthDateField.isNotEmpty()
                    ? OneDate.iris(new Date(holderBirthDateField.value))
                    : holderBirthDateField.value,
                isResident: form.field('policyHolderIsResident').value,
            };
        }

        function birthDate(): string {
            return isAuthenticated.value ? userDate() : guestDate();
        }

        function userDate(): string {
            return OneDate.iris(btaBase.user.current.birthDate);
        }

        function guestDate(): string {
            return birthDateRequired.value ? formFieldDate() : identityNumberDate();
        }

        function formFieldDate(): string {
            return OneDate.iris(moment(form.field('policyHolderBirthDate').value).toDate());
        }

        function identityNumberDate(): string {
            const extractedBirthDate: Date | undefined = extractDataService.birthDateFromPersonCode(
                form.field('policyHolderIdentityNumber').value,
                form.field('policyHolderCountry').value.iso,
            );

            return extractedBirthDate ? OneDate.iris(extractedBirthDate) : '';
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function onSubmitStep(): void {
            form.submitAttempt().then((): void => {
                if (form.isValid()) {
                    stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
                    stepsSubmitter.addSubmitCustomParam('facility', Facility);
                    stepsSubmitter.addSubmitCustomParam('productId', selectedProductId());
                    stepsSubmitter.addSubmitCustomParam('model', form.field('insuredObject').value);
                    stepsSubmitter.addSubmitCustomParam('insuredPerson', insuredPerson());
                    stepsSubmitter.proceedStep('', 0);
                } else {
                    useScroll().scrollInvalidFieldToView(form);
                }
            });
        }

        function hint(key: string, value: string): string {
            return useHtml().imgTag(cautionIconAttributes()) + key + ': ' + value;
        }

        function cautionIconAttributes(): ImageTagAttributes {
            return {
                class: '',
                src: 'images/one/caution.svg',
                width: 16,
                height: 16,
            };
        }

        function continueButtonParams(): ButtonWithCallbackParams {
            return {
                title: translate('btar_continue'),
                textColor: ButtonTextColor.White,
                backgroundColor: ButtonBackground.Red,
                icon: ButtonIcon.LongArrowRight,
                iconColor: ButtonIconColor.White,
                iconPosition: ButtonIconPosition.Right,
            };
        }

        function onBeforeFormRestore(): void {
            patchDefaultValues();
        }

        function patchDefaultValues(): void {
            if (isAuthenticatedUser()) {
                patchUserFields();
            } else {
                patchDefaultHolderCountry();
            }
        }

        function onIsResidentClick(): void {
            const settingsInstance: SettingsService = SettingsService.getInstance();
            const residentCountry: CountryComponentParams = {
                ic: '100000000',
                iso: settingsInstance.localeIso(),
                phoneCode: settingsInstance.phoneCode(),
            };
            form.field('policyHolderIdentityNumber').setValue('');
            if (form.field('policyHolderIsResident').value) {
                patchDefaultHolderCountry();
                form.field('policyHolderCountry').setValue(residentCountry);
            } else {
                form.field('policyHolderCountry').setValue({ ic: '', iso: '', phoneCode: '' });
            }
        }

        function onAfterFormRestored(restoredCount: number): void {
            if (restoredCount === 0) {
                form.field('policyHolderIsAuthenticated').patch(isAuthenticatedUser());
                form.field('policyHolderIsResident').patch(true);
            }
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                isAuthenticated,
                isResident,
                isLithuanian,
                isEstonian,
                birthDateRequired,
                onSubmitStep,
                onBackClick,
                setupForm,
                hint,
                continueButtonParams,
                onBeforeFormRestore,
                onAfterFormRestored,
                onAuthenticatedClick,
                isAuthenticatedUser,
                onAppReady,
                birthDate,
                onIsResidentClick,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        this.dynamicStepper.enableAll();
        this.userStorage.onBeforeFormStorageDataIsRestored.pipe(take(1)).subscribe((): void => {
            this.onBeforeFormRestore();
        });
        this.onAppIsPreparedAndReady.pipe(take(1)).subscribe((): void => {
            this.onAppReady();
        });
        this.userStorage.onFormStorageDataIsReady.pipe(take(1)).subscribe((value: number): void => {
            this.onAfterFormRestored(value);
        });
    },
});
</script>
