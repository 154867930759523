<script lang="ts">
import { defineComponent, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Form from '@/Assets/Libraries/Form/Form';
import SubmitterUrls from '@/Services/SubmitterUrls.service';
import { useNavigate } from '@/Composables/Navigate';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import { Subscription } from 'rxjs';
import FormField from '@/Assets/Libraries/Form/FormField';
import { MovableProperty } from '@/Interfaces/Resources/MovableProperties/MovablePropertyInterface';
import UserStorage from '@/Services/user.storage.service';
import { CoveragePlan } from '@/Interfaces/Resources/MovableProperties/CoveragePlanInterface';
import AdditionalOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/MovablePropertyOptionInterface';
import { CoverageRisk } from '@/Interfaces/Resources/MovableProperties/CoverageRiskInterface';
import MovablePropertyOptionBuilder from '@/Components/Lists/AdditionalOptionsList/Builders/MovablePropertyOptionBuilder';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Value from '@/Assets/Libraries/Form/Value';
import InsurancePlan from '@/Components/Widgets/InsurancePlanWidget/Interfaces/InsurancePlanInterface';
import InsurancePlanBuilder from '@/Components/Widgets/InsurancePlanWidget/Builders/InsurancePlanBuilder';
import InsurancePlanItem from '@/Components/Widgets/InsurancePlanWidget/Interfaces/InsurancePlanItemInterface';
import InsurancePlanItemBuilder from '@/Components/Widgets/InsurancePlanWidget/Builders/InsurancePlanItemBuilder';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import MovablePropertySteps from '@/Pages/ItemsAndBelongings/Classes/MovablePropertySteps';
import { useNumbers } from '@/Composables/Numbers';
import { take } from 'rxjs/operators';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { translateForType } = useTranslate();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 3;
        const Facility: string = 'items-and-belongings';
        const ExcludedRisks: string[] = ['ALL_RISKS', 'DEDUCTIBLE'];

        const form: Form<{ 'additional-options': DynamicDictionary }> = new Form({ useValidationV2: true });
        const additionalOptions: Ref<AdditionalOption[]> = ref([]);
        const insurancePlan: UnwrapNestedRefs<InsurancePlan> = reactive({
            title: '',
            priceTitle: '',
            price: 0,
            discount: 0,
            items: [],
            paymentFrequency: '',
        });
        const insurancePlanPrice: Ref<number> = ref(0);
        const insurancePlanItems: Ref<InsurancePlanItem[]> = ref([]);

        const nextStep: Ref<number> = ref(4);

        function setupForm(): void {
            form.addField(new FormField('additional-options'));
            form.setReady();
            stepsSubmitter.addForm(form);
        }

        function onBeforeFormRestore(): void {
            setupForm();
        }

        function onAppReady(): void {
            btaBase.dynamicStepper.applyEnabled(MovablePropertySteps.byProductId(selectedProductId()));
            const coveragePlan: CoveragePlan | undefined = selectedProductCoverage();
            if (coveragePlan) {
                buildAdditionalOptions(coveragePlan);
            }
            buildInsurancePlanItems();
            calculatePrice();
            buildInsurancePlan();
        }

        function applyNextStep(value: string) {
            nextStep.value = Number(value);
        }

        function calculatePrice(): void {
            const optionsAdditional: DynamicDictionary = form.field('additional-options').value;
            const coveragePlan: CoveragePlan | undefined = selectedProductCoverage();
            const prices: number[] = [];
            if (coveragePlan) {
                prices.push(coveragePlan.price);
                if (new Value(optionsAdditional).isNotEmpty()) {
                    Object.keys(optionsAdditional).forEach((option: string): void => {
                        if (optionsAdditional[option]) {
                            const selectedRisk: CoverageRisk | undefined = coveragePlan.risks.find(
                                (risk: CoverageRisk): boolean => risk.id === option,
                            );
                            if (selectedRisk) {
                                prices.push(selectedRisk.price);
                            }
                        }
                    });
                }
            }
            insurancePlanPrice.value = useNumbers().arraySum(prices);
        }

        function buildInsurancePlan(): void {
            Object.assign(
                insurancePlan,
                new InsurancePlanBuilder()
                    .withTitle(translateForType('insurance_widget_title', 'items_and_belongings'))
                    .withPrice(insurancePlanPrice.value)
                    .withPriceTitle(translateForType('insurance_widget_price_title', 'items_and_belongings'))
                    .withItems(insurancePlanItems.value)
                    .withPaymentFrequency(translateForType('payment_frequency', 'items_and_belongings'))
                    .build(),
            );
        }

        function buildInsurancePlanItems(): void {
            insurancePlanItems.value = [];
            addSelectedProductToItems();
            activeRisks().forEach((risk: CoverageRisk): void => {
                insurancePlanItems.value.push(
                    new InsurancePlanItemBuilder()
                        .withTitle(translateForType(risk.id + '_MAIN', 'items_and_belongings'))
                        .withPrice(risk.price)
                        .build(),
                );
            });
        }

        function addSelectedProductToItems(): void {
            const storedProduct: MovableProperty | undefined = selectedProduct();
            const coveragePlan: CoveragePlan | undefined = selectedProductCoverage();
            if (storedProduct && coveragePlan) {
                insurancePlanItems.value.push(
                    new InsurancePlanItemBuilder()
                        .withTitle(translateForType(storedProduct.id, 'items_and_belongings'))
                        .withPrice(Number(coveragePlan.price))
                        .build(),
                );
            }
        }

        function buildAdditionalOptions(coveragePlan: CoveragePlan): void {
            coveragePlan.risks.forEach((risk: CoverageRisk): void => {
                if (isIncludedRisk(risk) && !ExcludedRisks.some((excluded: string): boolean => excluded === risk.id)) {
                    const storedOptions: DynamicDictionary = form.field('additional-options').value;
                    const optionState: boolean = new Value(storedOptions[risk.id]).isEmpty()
                        ? isIncludedRiskFreeOfCharge(risk)
                        : storedOptions[risk.id];
                    additionalOptions.value.push(
                        new MovablePropertyOptionBuilder()
                            .withName(translateForType(risk.id + '_MAIN', 'items_and_belongings'))
                            .withCoverageRisk(risk)
                            .withState(optionState)
                            .withPaymentFrequency(translateForType('payment_frequency', 'items_and_belongings'))
                            .build(),
                    );
                }
            });
        }

        function isIncludedRisk(risk: CoverageRisk): boolean {
            return isAdditionalRiskWithPrice(risk) || isIncludedRiskFreeOfCharge(risk);
        }

        function isAdditionalRiskWithPrice(risk: CoverageRisk): boolean {
            return risk.isAdditional && risk.price > 0;
        }

        function isIncludedRiskFreeOfCharge(risk: CoverageRisk): boolean {
            return !risk.isAdditional && risk.insuredSum > 0;
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function onSubmitStep(): void {
            stepsSubmitter.proceedStep('', btaBase.nextStep());
        }

        function selectedProductCoverage(): CoveragePlan | undefined {
            let result: CoveragePlan | undefined;
            const storedProduct: MovableProperty | undefined = selectedProduct();
            if (storedProduct) {
                result = storedProduct.coveragePlans[selectedCoverageId()];
            }

            return result;
        }

        function selectedProduct(): MovableProperty | undefined {
            const storage: UserStorage = UserStorage.getInstance();
            const storedProductId: string = selectedProductId();
            return (storage.storageData as MovableProperty[]).find(
                (product: MovableProperty): boolean => product.id === storedProductId,
            );
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function selectedCoverageId(): number {
            return Number(UserStorage.getInstance().stepStorageData.coverageId ?? 0);
        }

        function onAdditionalOptionToggle(optionEmit: DynamicDictionary): void {
            Object.keys(optionEmit).forEach((key: string): void => {
                const tempValue: DynamicDictionary = form.field('additional-options').value;
                if (new Value(tempValue).isNotEmpty()) {
                    tempValue[key] = optionEmit[key];
                    form.field('additional-options').patch(tempValue);
                } else {
                    form.field('additional-options').patch(optionEmit);
                }
            });
            calculatePrice();
            buildInsurancePlanItems();
            buildInsurancePlan();
        }

        function onInsuranceWidgetContinue(): void {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            prepareSubmit();
            stepsSubmitter.proceedStep(SubmitterUrls.getInstance().nextStep(), nextStep.value);
        }

        function activeRisks(): CoverageRisk[] {
            const storedOptions: DynamicDictionary = form.field('additional-options').value;
            const coveragePlan: CoveragePlan | undefined = selectedProductCoverage();

            return coveragePlan
                ? coveragePlan.risks.filter((risk: CoverageRisk): boolean => storedOptions[risk.id])
                : [];
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', nextStep.value);
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('selectedRisks', activeRisks());
            stepsSubmitter.addSubmitCustomParam('productId', selectedProductId());
            stepsSubmitter.addSubmitCustomParam('coverageId', selectedCoverageId());
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                onSubmitStep,
                onBackClick,
                setupForm,
                onAppReady,
                additionalOptions,
                onAdditionalOptionToggle,
                onBeforeFormRestore,
                insurancePlan,
                onInsuranceWidgetContinue,
                applyNextStep,
                activeRisks,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);

        this.userStorage.onBeforeFormStorageDataIsRestored.pipe(take(1)).subscribe((): void => {
            this.onBeforeFormRestore();
        });

        this.onAppIsPreparedAndReady.pipe(take(1)).subscribe((): void => {
            this.onAppReady();
        });
    },
});
</script>
