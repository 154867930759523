import AxiosCache from '@/Services/axios.cache.service';
import axios, {
    AxiosPromise,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosStatic,
    CancelToken,
    CancelTokenSource,
    InternalAxiosRequestConfig,
} from 'axios';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { DefineParams, useDefine } from '@/Composables/Define';
import { ref, Ref } from 'vue';
import UrlBuilder from '@/Assets/Libraries/Url/UrlBuilder';
import Translations from '@/Services/translations.service';

export default class RequestService {
    private static instance: RequestService;
    private cache!: AxiosCache;
    private define: DefineParams = useDefine();
    private pendingRequestsCount: Ref<number> = ref(0);
    private readonly FallbackLanguage: string = 'en';

    public constructor() {
        this.cache = AxiosCache.getInstance();
    }

    public static getInstance(): RequestService {
        if (!RequestService.instance) {
            RequestService.instance = new RequestService();
        }
        return RequestService.instance;
    }

    public isPending(): boolean {
        return this.pendingRequestsCount.value > 0;
    }

    public isCanceled(reason: DynamicDictionary): boolean {
        return axios.isCancel(reason);
    }

    public cancelTokenSource(): CancelTokenSource {
        return axios.CancelToken.source();
    }

    public async get(params: RequestParams): Promise<AxiosResponse> {
        const getParams: RequestParams = this.getParams(params);
        let result: Promise<AxiosResponse>;
        if (!getParams.withCache || !this.cache.fetchCache(getParams)) {
            result = this.axiosGet(getParams.uri, {
                params: getParams.content,
                headers: getParams.headers,
                cancelToken: getParams.cancelToken,
            })
                .then((response: AxiosResponse): Promise<AxiosResponse> => {
                    this.cache.storeCache(getParams, response);
                    return this.responsePromise(response, getParams);
                })
                .catch((reason: DynamicDictionary): void => {
                    if (!axios.isCancel(reason)) {
                        Error.log(ErrorType.Error, 'RequestService::get', reason);
                    }
                    throw reason;
                }) as Promise<AxiosResponse>;
        } else {
            result = new Promise((resolve) => resolve(this.cache.fetchCache(getParams) as AxiosResponse));
        }

        return result;
    }

    public async post(params: RequestParams): Promise<AxiosResponse> {
        const postParams: RequestParams = this.postParams(params);
        return this.axiosPost(postParams.uri, postParams.content || {}, {
            headers: postParams.headers,
            cancelToken: postParams.cancelToken,
        })
            .then((response: AxiosResponse): Promise<AxiosResponse> => {
                return this.responsePromise(response, postParams);
            })
            .catch((reason: DynamicDictionary): void => {
                if (!axios.isCancel(reason)) {
                    Error.log(ErrorType.Error, 'RequestService::post', reason);
                }
                throw reason;
            }) as Promise<AxiosResponse>;
    }

    private responsePromise(response: AxiosResponse, params: RequestParams): Promise<AxiosResponse> {
        return new Promise((resolve, reject): void => {
            if (params.returnRaw || this.isValidResponse(response)) {
                resolve(response);
            } else {
                Error.log(ErrorType.Error, 'RequestService::responsePromise', 'fetch_invalid_data');
                reject(response);
            }
        });
    }

    private getParams(params: RequestParams): RequestParams {
        return {
            uri: params.uri,
            content: params.content,
            headers: params.headers,
            cancelToken: params.cancelToken,
            returnRaw: params.returnRaw,
            withCache: params.withCache === undefined ? true : params.withCache,
        };
    }

    private postParams(params: RequestParams): RequestParams {
        return {
            uri: params.uri,
            content: params.content,
            headers: params.headers,
            cancelToken: params.cancelToken,
            returnRaw: params.returnRaw,
        };
    }

    private isValidResponse(response: AxiosResponse): boolean {
        return this.isValidSuccessResponse(response) || this.isValidErrorResponse(response);
    }

    private isValidSuccessResponse(response: AxiosResponse): boolean {
        return (
            this.define.isSet(response.data) &&
            this.define.isSet(response.data.data) &&
            this.define.isSet(response.data.data.body)
        );
    }

    private isValidErrorResponse(response: AxiosResponse): boolean {
        return this.define.isSet(response.data.errors);
    }

    private axiosGet(uri: string, config?: AxiosRequestConfig): AxiosPromise<DynamicDictionary> {
        return this.axiosWithDefaults().get(this.axiosUrl(uri), config);
    }

    private axiosPost(
        uri: string,
        requestContent: DynamicDictionary,
        config?: AxiosRequestConfig,
    ): AxiosPromise<DynamicDictionary> {
        return this.axiosWithDefaults().post(this.axiosUrl(uri), requestContent, config);
    }

    private axiosWithDefaults(): AxiosStatic {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['X-CSRF-TOKEN'] = (
            document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement
        ).content;
        axios.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                this.pendingRequestsCount.value++;
                return config;
            },
            (err: DynamicDictionary) => {
                return Promise.reject(err);
            },
        );
        axios.interceptors.response.use(
            (config: AxiosResponse) => {
                this.pendingRequestsCount.value--;
                return config;
            },
            (err: DynamicDictionary) => {
                this.pendingRequestsCount.value--;
                return Promise.reject(err);
            },
        );

        return axios;
    }

    private axiosUrl(uri: string): string {
        return new UrlBuilder().withLanguage(this.language()).withForcedLanguage().withUri(uri).withSessionId().build();
    }

    private language(): string {
        const lang: string = Translations.getInstance().language;

        return lang === '' ? this.FallbackLanguage : lang;
    }
}

export interface RequestParams {
    uri: string;
    headers?: DynamicDictionary;
    content?: DynamicDictionary;
    cancelToken?: CancelToken;
    /**
     * @deprecated Only to be used for direct controller or legacy requests, where proper AjaxResponse object is not returned
     */
    returnRaw?: boolean;
    withCache?: boolean;
}
