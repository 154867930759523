<script setup lang="ts">
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { CancelTokenSource } from 'axios';
import { useTranslate } from '@/Composables/Translate';
import { ref, Ref } from 'vue';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import { useHtml } from '@/Composables/Html';
import { usePrice } from '@/Composables/Price';

const request: AxiosParams = useAxios();
const { translateForType, translate } = useTranslate();
const { imgTag } = useHtml();
const { price } = usePrice();

const props = defineProps({
    translationsType: { type: String, default: 'dashboard' },
    content: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

const fetchIsInProgress: Ref<boolean> = ref(false);
const cancelToken: Ref<CancelTokenSource | null> = ref(null);

function translated(key: string, replacements?: DynamicDictionary): string {
    return translateForType(key, props.translationsType, replacements);
}
</script>

<template>
    <div class="claim-document-overview">
        <div class="overview-block"></div>
    </div>
</template>

<style lang="scss" scoped>
.claim-document-overview {
    .overview-block {
        width: 100%;
        padding: var(--size-normal);
        border-radius: 16px;
        background-color: var(--white);
        box-shadow: none;
        margin-bottom: 20px;
    }
}
</style>
