import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import Debounce from '@/Services/debounce.service';

export default class LazyLoader {
    public static updated: boolean = false;
    private static lazyImageClass: string = 'lazy-image';
    private static lazyBackgroundImageClass: string = 'lazy-background-image';

    private updateDebounce: Function = Debounce.getInstance().applyDebounce(() => this.update());

    public init(): void {
        const appId: string = TransferStateService.getInstance().get('appId');
        const targetNode: HTMLElement | null = document.getElementById(appId);
        if (targetNode) {
            const config: DynamicDictionary = { attributes: false, childList: true, subtree: true };
            const observer: MutationObserver = new MutationObserver(
                (mutationList: MutationRecord[], newObserver: MutationObserver): void => {
                    mutationList.forEach((mutation: MutationRecord): void => {
                        if (mutation.type === 'childList') {
                            this.updateDebounce();
                        }
                    });
                },
            );
            observer.observe(targetNode, config);
        }
    }

    public update(): void {
        LazyLoader.updated = true;
        this.enableLazyImageLoad();
        this.enableLazyBackgroundImageLoad();
    }

    private enableLazyImageLoad(): void {
        const observer: IntersectionObserver = new IntersectionObserver(this.onImageIntersection);
        const lazyImagesList: JQuery = $('.' + LazyLoader.lazyImageClass)!;
        lazyImagesList.each((index: number, lazyImage: HTMLElement): void => {
            observer.unobserve(lazyImage);
            observer.observe(lazyImage);
        });
    }

    private enableLazyBackgroundImageLoad(): void {
        const observer: IntersectionObserver = new IntersectionObserver(this.onBackgroundIntersection);
        const lazyBackgroundImagesList: JQuery = $('.' + LazyLoader.lazyBackgroundImageClass)!;
        lazyBackgroundImagesList.each((index: number, lazyBackgroundImage: HTMLElement): void => {
            observer.unobserve(lazyBackgroundImage);
            observer.observe(lazyBackgroundImage);
        });
    }

    private onImageIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
        entries.forEach((entry: IntersectionObserverEntry): void => {
            const imageTag: Element = entry.target;
            if (entry.isIntersecting) {
                const imageDataSource: string | null = imageTag.getAttribute('data-src')!;
                imageTag.setAttribute('src', imageDataSource);
                imageTag.classList.remove(LazyLoader.lazyImageClass);
                observer.unobserve(imageTag);
            }
        });
    }

    private onBackgroundIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
        entries.forEach((entry: IntersectionObserverEntry): void => {
            const elementWithBackground: Element = entry.target;
            if (entry.isIntersecting) {
                elementWithBackground.classList.remove(LazyLoader.lazyBackgroundImageClass);
                observer.unobserve(elementWithBackground);
            }
        });
    }
}
