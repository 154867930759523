<script lang="ts">
import { computed, defineComponent, ref, Ref, nextTick } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Form from '@/Assets/Libraries/Form/Form';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { Subscription } from 'rxjs';
import UserDetailsUpdateMode from '@/Enums/UserDetailsUpdateModeEnum';
import CssClass from '@/Enums/CssClassEnum';
import FormField from '@/Assets/Libraries/Form/FormField';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import VueEvent from '@/Classes/VueEventClass';
import Value from '@/Assets/Libraries/Form/Value';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useAxios } from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import RequestService from '@/Services/request.service';
import { AxiosResponse } from 'axios';
import { useTranslate } from '@/Composables/Translate';
import Validation from '@/Services/validation.service';
import SettingsService from '@/Services/settings.service';
import AppCountry from '@/Assets/Libraries/App/AppCountry';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const CurrentStep: number = 1;
        const Facility: string = 'my-facility';
        const ConsentsGlue: string = ':';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const contactLanguageEnabled: Ref<boolean> = ref(false);
        const contactLanguageOptions: Ref<InputOption[]> = ref([]);
        const updateMode: Ref<string> = ref('');
        const redirectUrl: Ref<string> = ref('');
        const updateInProgress: Ref<boolean> = ref(false);

        const isUpdateNameMode: Ref<boolean> = computed(() => {
            return updateMode.value === UserDetailsUpdateMode.Name;
        });

        const showMarketingContainer: Ref<boolean> = computed(() => {
            return !btaBase.user.userAgreedReceiveMarketing() && !updateInProgress.value;
        });

        const buttonOkClasses: Ref<string> = computed(() => {
            let result: string = '';
            if (!isUpdateNameMode.value && !form.isValid()) {
                result = CssClass.Disabled;
            }

            return result;
        });

        function onAppReady(): void {
            setupForm().then((): void => {
                applyValues();
            });
        }

        function setupForm(): Promise<void> {
            return new Promise((resolve) => {
                if (isUpdateNameMode.value) {
                    form.addField(new FormField('nameSurname', ''));
                    form.addField(new FormField('bankFullName', ''));
                } else {
                    form.addField(new FormField('firstname', ''));
                    form.addField(new FormField('lastname', ''));
                    form.addField(new FormField('email', '', 'required'));
                    form.addField(new FormField('phoneWithCountry', {}, 'required'));
                    if (contactLanguageEnabled.value) {
                        form.addField(new FormField('contactLanguage', ''));
                    }
                }
                form.addField(new FormField('marketingConsents'));
                form.setReady();
                formIsReady.value = true;
                resolve();
            });
        }

        function applyContactLanguageStatus(value: boolean): void {
            contactLanguageEnabled.value = value;
        }

        function applyRedirectUrl(value: string): void {
            redirectUrl.value = value;
        }

        function applyUpdateMode(value: string): void {
            updateMode.value = value;
        }

        function applyValues(): void {
            if (btaBase.user.current.logged && btaBase.settings.ready) {
                nextTick((): void => {
                    if (isUpdateNameMode.value) {
                        form.field('nameSurname').patch(btaBase.user.current.name);
                        form.field('bankFullName').patch(btaBase.user.current.bankFullName);
                    } else {
                        form.field('firstname').patch(btaBase.user.current.firstname);
                        form.field('lastname').patch(btaBase.user.current.lastname);
                        if (canPatchEmail()) {
                            form.field('email').patch(btaBase.user.current.email);
                        } else {
                            form.field('email').patch('');
                        }
                        const phoneWithCountry: DynamicDictionary = {
                            country: btaBase.user.current.phoneCode,
                            phone: btaBase.user.current.phone,
                        };
                        form.field('phoneWithCountry').patch(phoneWithCountry);
                        applyContactLanguageValues();
                        applyMarketingConsentValues();
                    }
                });
            }
        }

        function applyMarketingConsentValues(): void {
            form.field('marketingConsents').patch({
                customText: {},
                selected: btaBase.user.current.marketingConsents.join(':'),
            });
        }

        function updateDetails(event: VueEvent): void {
            if (isUpdateNameMode.value) {
                const endpoint: string = event.params['update']
                    ? Url.Ajax.acceptNameChange
                    : Url.Ajax.declineNameChange;
                saveSession(endpoint).then((): void => {
                    redirect();
                });
            } else {
                updateInProgress.value = true;
                btaBase.user.current.email = form.field('email').value;
                btaBase.user.current.phone = form.field('phoneWithCountry').value.phone;
                btaBase.user.current.phoneCode = form.field('phoneWithCountry').value.country;
                btaBase.user.current.code = form.field('phoneWithCountry').value.code;
                btaBase.user.current.receiveMarketing = marketingConsents().length > 0;
                btaBase.user.current.marketingConsents = marketingConsents();
                if (contactLanguageEnabled.value) {
                    btaBase.user.current.contactLanguage = form.field('contactLanguage').value;
                }
                if (form.isValid()) {
                    submitUserUpdate(true);
                }
                updateInProgress.value = false;
            }
        }

        function marketingConsents(): string[] {
            const selectedConsents: string = form.field('marketingConsents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ? selectedConsents.split(ConsentsGlue) : [];
        }

        function submitUserUpdate(disableFirstTimeDetailsChange: boolean = false): void {
            if (btaBase.inputIsLocked.value) {
                return;
            }
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);
            const params: DynamicDictionary = {
                [btaBase.user.current.csrf_name]: btaBase.user.current.csrf_hash,
                params: userDetailsForUpdate(),
            };
            if (disableFirstTimeDetailsChange) {
                params['disableFirstTimeDetailsChange'] = true;
            }
            if (!isUpdateNameMode.value && !btaBase.user.userHasCorrectContactData()) {
                useAxios()
                    .post(Url.Ajax.updateContactInformation, {}, { headers: {} })
                    .then((value: DynamicDictionary): void => {
                        if (typeof value.data.error !== 'undefined') {
                            btaBase.lockInput(false);
                            btaBase.error.show(ErrorType.Error, 'submitContactDataInformationUpdate', value.data.error);
                        } else {
                            const data: DynamicDictionary = value.data.data.consent;
                            if (data.error === false) {
                                btaBase.user.current.correctContactData = data.consent.correctContactData;
                                updateUser(params);
                            } else {
                                btaBase.lockInput(false);
                                btaBase.error.show(ErrorType.Error, 'submitContactDataInformationUpdate', data.error);
                            }
                        }
                    })
                    .catch((reason: DynamicDictionary): void => {
                        btaBase.lockInput(false);
                        btaBase.error.show(ErrorType.Error, 'contactDataConsent', reason);
                    });
            } else {
                updateUser(params);
            }
        }

        function saveSession(endpoint: string): Promise<void> {
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);

            return useAxios().post(endpoint, {}).then();
        }

        function updateUser(params: DynamicDictionary): void {
            RequestService.getInstance()
                .post({ uri: Url.Ajax.oneDashboardUpdateUser, content: params })
                .then((value: AxiosResponse): void => {
                    if (typeof value.data.error !== 'undefined') {
                        btaBase.lockInput(false);
                        btaBase.error.show(ErrorType.Error, 'submitUserUpdate', value.data.error);
                    } else {
                        redirect();
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    btaBase.lockInput(false);
                    btaBase.error.show(ErrorType.Error, 'submitUserUpdate', reason);
                });
        }

        function redirect(): void {
            btaBase.navigate(redirectUrl.value);
        }

        function userDetailsForUpdate(): DynamicDictionary {
            return {
                email: btaBase.user.current.email,
                phone: btaBase.user.current.phone,
                phoneCode: btaBase.user.current.phoneCode,
                code: btaBase.user.current.code,
                receiveMarketing: btaBase.user.current.receiveMarketing,
                marketingConsents: btaBase.user.current.marketingConsents,
                contactLanguage: btaBase.user.current.contactLanguage,
            };
        }

        function canPatchEmail(): boolean {
            const isValidEmail: boolean = Validation.isValidEmail(btaBase.user.current.email);
            const settings: SettingsService = SettingsService.getInstance();

            return settings.restrictedEmailsEnabled()
                ? isValidEmail && Validation.isValidRestrictedEmail(btaBase.user.current.email)
                : isValidEmail;
        }

        function applyContactLanguageValues(): void {
            if (contactLanguageOptions.value.length === 0 && contactLanguageEnabled.value) {
                switch (true) {
                    case new AppCountry().isLV():
                        contactLanguageOptions.value.push({
                            name: useTranslate().translate('btar_communication_language_lv'),
                            value: 'LV',
                        });
                        contactLanguageOptions.value.push({
                            name: useTranslate().translate('btar_communication_language_ru'),
                            value: 'RU',
                        });
                        break;
                    case new AppCountry().isEE():
                        contactLanguageOptions.value.push({
                            name: useTranslate().translate('btar_communication_language_ee'),
                            value: 'EE',
                        });
                        contactLanguageOptions.value.push({
                            name: useTranslate().translate('btar_communication_language_ru'),
                            value: 'RU',
                        });
                        break;
                    case new AppCountry().isLT():
                        contactLanguageOptions.value.push({
                            name: useTranslate().translate('btar_communication_language_lt'),
                            value: 'LT',
                        });
                        break;
                    default:
                        break;
                }
                contactLanguageOptions.value.push({
                    name: useTranslate().translate('btar_communication_language_en'),
                    value: 'EN',
                });
                const contactLanguage =
                    btaBase.user.current.contactLanguage !== ''
                        ? btaBase.user.current.contactLanguage
                        : new AppCountry().iso();
                form.field('contactLanguage').patch(contactLanguage);
            }
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                contactLanguageEnabled,
                contactLanguageOptions,
                updateMode,
                updateInProgress,
                setupForm,
                applyContactLanguageStatus,
                applyRedirectUrl,
                applyUpdateMode,
                onAppReady,
                applyValues,
                applyMarketingConsentValues,
                updateDetails,
                marketingConsents,
                submitUserUpdate,
                updateUser,
                canPatchEmail,
                applyContactLanguageValues,
                isUpdateNameMode,
                showMarketingContainer,
                buttonOkClasses,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
