<script setup lang="ts">
import { computed, onMounted, Ref } from 'vue';
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import AppWhiteboard from '@/Components/Containers/Whiteboard/Whiteboard.vue';
import AppPersonDetails from '@/Components/Widgets/PersonDetails/PersonDetails.vue';
import AppPersonContacts from '@/Components/Widgets/PersonContacts/PersonContacts.vue';
import AppPersonBankDetails from '@/Components/Widgets/PersonBankDetails/PersonBankDetails.vue';
import PersonDetails from '@/Interfaces/PersonDetailsInterface';
import PersonContacts from '@/Interfaces/PersonContactsInterface';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import PersonBankDetails from '@/Interfaces/PersonBankDetailsInterface';
import AppNotification from '@/Components/Widgets/Notification/Notification.vue';

const form: Form<{
    personDetails: PersonDetails;
    personContacts: PersonContacts;
    personBankDetails: PersonBankDetails;
    checkboxTerms: boolean;
    checkboxCertify: boolean;
}> = new Form({ useValidationV2: true });

const firstUserPersonDetails: Ref<PersonDetails> = computed(() => {
    return {
        isAuthenticated: false,
        isPolicyHolder: true,
        isResident: false,
        name: 'My Name',
        surname: 'My Surname',
        personCode: '35806240226',
        country: {
            iso: 'AF',
            phoneCode: '93',
            ic: 'AFG',
        },
        birthDate: 'Sun Nov 11 1990 00:00:00',
    };
});

onMounted((): void => {
    setupForm();
});

function setupForm(): void {
    form.addField(new FormField('personDetails'));
    form.addField(
        new FormField('personContacts', {
            email: '',
            phone: {
                code: firstUserPersonDetails.value.country.phoneCode,
                iso: firstUserPersonDetails.value.country.iso,
                country: '',
                phone: '',
            },
        }),
    );
    form.addField(new FormField('personBankDetails'));
    form.addField(new FormField('checkboxTerms'));
    form.addField(new FormField('checkboxCertify'));
    form.setReady();
}
</script>

<template>
    <app-whiteboard :title="'Test title'">
        <app-person-details
            :form-field="form.field('personDetails')"
            :provided-person="firstUserPersonDetails"
            :show-authenticated-option="true"
        >
            <template #other-person-title>
                <h2 class="heading-5">Other person test title</h2>
            </template>
            <template #contacts>
                <app-person-contacts :form-field="form.field('personContacts')"></app-person-contacts>
            </template>
            <template #above-bank>
                <app-notification
                    :variant="'warning'"
                    :with-body="false"
                    :with-close="false"
                    :title="'Pay attention! The bank account for the indemnity payment must belong to the specified person.'"
                ></app-notification>
            </template>
            <template #bank>
                <app-person-bank-details
                    :bank-account-currency="['EUR', 'USD']"
                    :form-field="form.field('personBankDetails')"
                >
                </app-person-bank-details>
            </template>
            <app-input-checkbox
                class="base column-span-12"
                :data-store-disabled="true"
                :form-field="form.field('checkboxTerms')"
                data-type="checkboxTerms"
                ><span
                    class="basic-text-formatted"
                    v-html="'I have read and agree with BTA Terms and Conditions'"
                ></span
                >{{}}
            </app-input-checkbox>
            <app-input-checkbox
                v-if="form.field('personDetails').value.isPolicyHolder"
                class="base column-span-12"
                :data-store-disabled="true"
                :form-field="form.field('checkboxCertify')"
                data-type="checkboxCertify"
                ><span
                    class="basic-text-formatted"
                    v-html="'I certify that I am authorized to receive compensation on behalf of the insured person.'"
                ></span
                >{{}}
            </app-input-checkbox>
        </app-person-details>
    </app-whiteboard>
</template>
