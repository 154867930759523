import { Subject } from 'rxjs';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import FilesUploader from '@/Enums/FileUploadEnum';
import DateFormat from '@/Assets/Libraries/Date/Interfaces/DateFormat.interface';
import ClaimsSettings from '@/Interfaces/claims.settings.interface';
import MovablePropertySettings from '@/Interfaces/MovablePropertySettingsInterface';
import TravelAdditionalSettings from '@/Interfaces/TravelAdditionalSettingsInterface';
import { Ref, ref } from 'vue';
import AccidentSingleDaySettings from '@/Interfaces/AccidentSingleDaySettingsInterface';
import TransferStateService from '@/Core/ServerState/TransferStateService';

export default class SettingsService {
    private static instance: SettingsService;
    private localSettings: DynamicDictionary = [];
    public ready: boolean = false;
    public isReady: Ref<boolean> = ref(false);
    public onExternalDataIsReady: Subject<void> = new Subject<void>();
    private transferStateService: TransferStateService = TransferStateService.getInstance();

    public static getInstance(): SettingsService {
        if (!SettingsService.instance) {
            SettingsService.instance = new SettingsService();
            SettingsService.instance.init();
        }

        return SettingsService.instance;
    }

    public localeIso(): string {
        return this.value('LOCALE_ISO') as string;
    }

    public phoneCode(): string {
        return this.value('USER_PHONE_CODE') as string;
    }

    public uploadRestrictionDays(): string {
        return this.value(FilesUploader.Settings.RestrictionDays) as string;
    }

    public uploadWsUrl(): string {
        return this.value(FilesUploader.Settings.WsUrl) as string;
    }

    public inputDateChainDelay(): string {
        return this.value('inputDateChainDelay') as string;
    }

    public sessionTimeout(): string {
        return this.value('SessionTimeout') as string;
    }

    public logoutTimeout(): string {
        return this.value('LogoutTimeout') as string;
    }

    public logoutUrl(): string {
        return this.value('logoutUrl') as string;
    }

    public businessDashboardUrl(): string {
        return this.value('businessDashboardUrl') as string;
    }

    public myAccountUrl(): string {
        return this.value('myAccountUrl') as string;
    }

    public restrictedEmailsEnabled(): boolean {
        return Boolean(this.value('RESTRICTED_EMAILS_ENABLED'));
    }

    public disableTravelNonResidents(): boolean {
        return Boolean(this.value('DISABLE_TRAVEL_NON_RESIDENTS'));
    }

    public restrictedEmailDomains(): string[] {
        return this.value('RESTRICTED_EMAIL_DOMAINS') as string[];
    }

    public chatEnabled(): boolean {
        return Boolean(this.value('chatEnabled'));
    }

    public globalReloadTimerForCsrfDelay(): number {
        return parseInt(this.value('globalReloadTimerForCsrfDelay') as string, 10);
    }

    public sentryIsEnabled(): boolean {
        return Boolean(this.value('sentryEnabled'));
    }

    public sentryDsn(): string {
        return this.value('sentryDsn') as string;
    }

    public sentryTargets(): string[] {
        return String(this.value('sentryTargets')).split(',');
    }

    public metaApiEnabled(): boolean {
        return Boolean(this.value('metaApiEnabled'));
    }

    public vehicleCanPurchaseIfPolicyHasLeftLessThanDays(): number {
        return Number(this.value('vehicleCanPurchaseIfPolicyHasLeftLessThanDays'));
    }

    public repairServiceVehicleAge(): number {
        return Number(this.value('repairServiceVehicleAge'));
    }

    public repairServiceVehicleAgeOptions(): string[] {
        return JSON.parse(this.value('repairServiceVehicleAgeOptions') as string);
    }

    public useIframeTermsAndConditions(): boolean {
        return Boolean(this.value('iframeTermsAndConditions'));
    }

    public timezone(): string {
        return String(this.value('timezone'));
    }

    public renewablePolicyTypes(): string[] {
        return this.value('renewablePolicyTypes') as string[];
    }

    public agreementsMapping(): DynamicDictionary {
        return this.value('agreementsMapping') as DynamicDictionary;
    }

    public endingSoonBadgePolicyTypes(): string[] {
        return this.value('endingSoonBadgePolicyTypes') as string[];
    }

    public renewedBadgePolicyTypes(): string[] {
        return this.value('renewedBadgePolicyTypes') as string[];
    }

    public insuredRisksExcludeSumIcs(): string[] {
        return this.value('insuredRisksExcludeSumIcs') as string[];
    }

    public civilLiabilityRisks(): string[] {
        return this.value('civilLiabilityRisks') as string[];
    }

    public marketingConsentTypes(): string[] {
        return this.value('marketingConsentTypes') as string[];
    }

    public editRenewalOfferMandatory(): boolean {
        return Boolean(this.value('editRenewalOfferMandatory'));
    }

    public value(
        key: string,
    ):
        | boolean
        | number
        | string
        | string[]
        | DateFormat
        | ClaimsSettings
        | MovablePropertySettings
        | TravelAdditionalSettings
        | AccidentSingleDaySettings {
        return this.localSettings[key];
    }

    public subscriptionCancellationReasons(): string {
        return this.value('subscriptionCancellationReasons') as string;
    }

    public selectedPoliciesMaximum(): number {
        return this.value('selectedPoliciesMaximum') as number;
    }

    public cascoTerritoryIc(): string {
        return this.value('cascoTerritoryIc') as string;
    }

    public defaultDeductible(): number {
        return this.value('defaultDeductible') as number;
    }

    public throttlerCapacity(): number {
        return this.value('throttlerCapacity') as number;
    }

    public throttlerUploadCapacity(): number {
        return this.value('throttlerUploadCapacity') as number;
    }

    public claimsSettings(): ClaimsSettings {
        return this.value('claimsSettings') as ClaimsSettings;
    }

    public movablePropertySettings(): MovablePropertySettings {
        return this.value('movablePropertySettings') as MovablePropertySettings;
    }

    public accidentSingleDaySettings(): AccidentSingleDaySettings {
        return this.value('accidentSingleDaySettings') as AccidentSingleDaySettings;
    }

    public travelAdditionalSettings(): TravelAdditionalSettings {
        return this.value('travelAdditionalSettings') as TravelAdditionalSettings;
    }

    public dateFormat(): DateFormat {
        return this.value('dateFormat') as DateFormat;
    }

    private init(): void {
        this.localSettings = this.transferStateService.get('settings', {});
        this.ready = true;
        this.isReady.value = true;
        this.onExternalDataIsReady.next();
    }
}
