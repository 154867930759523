<script setup lang="ts">
import Form from '@/Assets/Libraries/Form/Form';
import { getCurrentInstance, markRaw, onMounted, ref, Ref } from 'vue';
import { TranslateReplaceParts, useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import Storage from '@/Apps/ActivePlus/Services/Storage';
import StepsGuard from '@/Apps/ActivePlus/Services/StepsGuard';
import OneBaseService from '@/Services/OneBaseService';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppPage from '@/Apps/ActivePlus/Components/Page.vue';
import AppRepeatableWhiteboardItem from '@/Components/Other/Repeatable/RepeatableWhiteboardItem.vue';
import AppRepeatable from '@/Components/Other/Repeatable/Repeatable.vue';
import { StoredInsuredPerson } from '@/Apps/ActivePlus/Interfaces/StoredInsuredPerson';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { FormHelperParams, useFormHelper } from '@/Composables/FormHelper';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import AppSvg from '@/Components/Other/Svg/Svg.vue';
import PersonDetails from '@/Interfaces/PersonDetailsInterface';
import AppNotification from '@/Components/Widgets/Notification/Notification.vue';
import AppPersonDetails from '@/Components/Widgets/PersonDetails/PersonDetails.vue';
import AppPersonContacts from '@/Components/Widgets/PersonContacts/PersonContacts.vue';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import AppPersonBankDetails from '@/Components/Widgets/PersonBankDetails/PersonBankDetails.vue';
import Steps from '@/Apps/ActivePlus/Enums/Steps';

interface PersonForm {
    person: PersonDetails;
}

interface PersonItem {
    id: string;
    form: Form<PersonForm>;
}

const { translate, translateForType } = useTranslate();
const router: Router = useRouter();
const formHelper: FormHelperParams = useFormHelper();
const storage: Storage = Storage.getInstance();
const TranslationType: string = 'velo_plus';
const persons: Ref<PersonItem[]> = ref([]);

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    restoreValues();
});

function restoreValues(): void {
    persons.value = storage.fields.insuredPersons.map((storedItem: StoredInsuredPerson): PersonItem => {
        const item: PersonItem = createPersonFactory(storedItem.formName);
        item.form.restoreValues({
            person: storedItem.person,
        });
        return item;
    });
}

function storeValues(): void {
    storage.fields.insuredPersons = persons.value.map((item: PersonItem): StoredInsuredPerson => {
        return {
            formName: item.form.name,
            person: {
                isAuthenticated: true,
                isPolicyHolder: true,
                isResident: true,
                name: '',
                surname: '',
                personCode: '',
                birthDate: '',
                country: {
                    iso: '',
                    phoneCode: '',
                    ic: '',
                },
            }, // TODO: add correct return values
        };
    });
}

function createPersonFactory(id: string): PersonItem {
    const form: Form<PersonForm> = new Form(id, { useValidationV2: true });
    form.setReady();
    return { id: id, form: markRaw(form) };
}

function onRemove(item: PersonItem): void {
    item.form.destroy();
}

function onSubmitStep(): void {
    const forms: Form<PersonForm>[] = persons.value.map((item: PersonItem): Form<PersonForm> => item.form);
    formHelper.submitAttemptMultiple(forms).then(() => {
        if (formHelper.isMultipleFormsValid(forms)) {
            storeValues();
            router.push({ name: Steps.SummaryAndPayment });
        }
    });
}
</script>

<template>
    <app-page>
        <template #title>{{ translateForType('title_insured_persons', TranslationType) }}</template>
        <template #description>{{ translateForType('subtitle_insured_persons', TranslationType) }}</template>
        <app-repeatable
            :items="persons"
            :min="1"
            :lock-first-item="true"
            :factory="createPersonFactory"
            @remove="onRemove"
        >
            <template #default="{ item, index, repeatable }">
                <app-repeatable-whiteboard-item
                    class="person-item"
                    :item="item"
                    :repeatable="repeatable"
                    :title="translateForType('label_person_details', TranslationType, { number: index + 1 })"
                    :add-button-text="translateForType('button_add_person', TranslationType)"
                >
                    <app-custom-form :form="item.form">
                        <app-person-details :form-field="item.form.field('person')" :show-provided-option="false">
                        </app-person-details>
                    </app-custom-form>
                </app-repeatable-whiteboard-item>
            </template>
        </app-repeatable>
        <template #footer>
            <app-button-primary @click="onSubmitStep()">
                {{ translateForType('button_insured_person_continue', TranslationType) }}
                <template #end>
                    <app-svg src="images/one/arrow-right.svg" />
                </template>
            </app-button-primary>
        </template>
    </app-page>
</template>

<style lang="scss" scoped>
.person-form {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--size-small);
    padding: 0;

    @include respond-above('sm') {
        grid-template-columns: repeat(2, 1fr);
    }
}

.button-with-callback {
    height: 52px;

    @include respond-above('sm') {
        width: 240px;
    }
}
</style>
