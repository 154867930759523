<script setup lang="ts">
import { computed, nextTick, onMounted, ref, Ref } from 'vue';
import IconSide from '@/Components/Tooltips/TextWithTip/IconSide.enum';
import TooltipMode from '@/Components/Tooltips/TextWithTip/TooltipMode.enum';

const props = defineProps({
    title: { type: String, default: '' },
    tipDescription: { type: String, default: '' },
    value: { type: String, default: '' },
    infoIconSide: { type: String, default: IconSide.After },
    mode: { type: String, default: TooltipMode.Default },
});

const labelClass: string = 'label-tooltip';

const id: string = 'text-with-tip-' + Math.random();
const popupId: string = 'tip-bubble-' + Math.random();

const isOpened: Ref<boolean> = ref(false);

const infoIconBeforeTitle: Ref<boolean> = computed((): boolean => {
    return props.infoIconSide === IconSide.Before;
});

const popupModeEnabled: Ref<boolean> = computed((): boolean => {
    return props.mode === TooltipMode.Popup;
});

let labelOffsetWidth: number = 0;
let labelOffsetHeight: number = 0;
let inputOffsetWidth: number = 0;

onMounted(() => {
    applySiblingAttributes();
});

function applySiblingAttributes(): void {
    const labelSibling: HTMLLabelElement | null = document
        .getElementById(id)!
        .parentElement!.children.namedItem('form-field-label') as HTMLLabelElement;
    if (labelSibling) {
        const inputSibling: HTMLInputElement | null = document.getElementById(
            labelSibling.getAttribute('for') ?? '',
        ) as HTMLInputElement;
        labelOffsetWidth = labelSibling.offsetWidth;
        labelOffsetHeight = labelSibling.offsetHeight;
        if (inputSibling) {
            inputOffsetWidth = inputSibling.offsetWidth;
        }
    }
}

function applyTipPositionOffset(): void {
    const bubbleElement: HTMLElement | null = document.getElementById(popupId) as HTMLDivElement;
    const selfElement: HTMLElement | null = document.getElementById(id) as HTMLDivElement;
    if (bubbleElement && selfElement.classList.contains(labelClass)) {
        bubbleElement.style.left = `-${labelOffsetWidth}px`;
        bubbleElement.style.top = `-${labelOffsetHeight * 4}px`;
        bubbleElement.style.maxWidth = `${inputOffsetWidth}px`;
    }
}

function isValueSet(): boolean {
    return props.value !== '';
}

function closeTip(): void {
    isOpened.value = false;
}

function onTipIconClick(): void {
    isOpened.value = !isOpened.value;
    nextTick((): void => {
        applyTipPositionOffset();
    });
}

defineExpose({
    closeTip,
});
</script>
<template>
    <div :id="id" class="text-with-tip">
        <app-popup
            v-if="isOpened && popupModeEnabled"
            :id="popupId"
            class="tooltip-popup"
            :close-on-overlay-click="true"
            :disable-close="true"
            @close="closeTip()"
        >
            <span v-html="tipDescription"></span>
        </app-popup>
        <div v-click-outside="closeTip" class="inline">
            <div class="sub-title">
                <span
                    :class="{
                        'opened': isOpened,
                        'icon-before-title': infoIconBeforeTitle,
                        'icon-after-title': !infoIconBeforeTitle,
                    }"
                    @click="onTipIconClick()"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        class="icon"
                    >
                        <rect width="20" height="20" rx="4" fill="var(--tooltip-color-background-default)" />
                        <path
                            d="M10.0039 7.025C9.50991 7.025 9.10691 6.609 9.10691 6.128C9.10691 5.634 9.50991 5.231 10.0039 5.231C10.4979 5.231 10.9009 5.634 10.9009 6.128C10.9009 6.609 10.4979 7.025 10.0039 7.025ZM9.30191 14.5V8H10.7059V14.5H9.30191Z"
                            fill="var(--tooltip-color-icon-default)"
                        />
                    </svg>
                </span>
                {{ title }}
            </div>
            <div v-if="isValueSet()" class="value" v-html="value"></div>
        </div>
        <div v-if="isOpened && !popupModeEnabled" class="expanded-text" v-html="tipDescription"></div>
    </div>
</template>

<style lang="scss" scoped>
.text-with-tip {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    position: relative;

    .tooltip-popup {
        position: absolute;
        top: 30px;
        z-index: 1;
        background: var(--white);
        color: var(--black-700);
        font-weight: 500;
        display: block;
        font-size: var(--font-size-nano);
        padding: var(--size-tiny);
        border-radius: var(--size-tiny);
        outline: 1px solid var(--black-200);
        box-shadow: 0 10px 10px var(--black-300);
        text-align: left;
    }

    &.label-tooltip {
        .tooltip-popup {
            width: max-content;
        }
    }

    .inline {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .sub-title {
            font-size: var(--font-size-tiny);
            font-weight: 500;
            display: flex;
            align-items: center;

            span {
                cursor: pointer;
                transition: all 0.3s ease-in;

                .icon {
                    vertical-align: middle;
                }
            }

            .icon-before-title {
                order: -1;
                margin-right: var(--size-pico);
            }

            .icon-after-title {
                order: 1;
                margin-left: var(--size-pico);
            }

            .opened {
                .icon rect {
                    fill: var(--tooltip-color-background-focus);
                }

                .icon path {
                    fill: var(--tooltip-color-icon-focus);
                }
            }
        }

        .value {
            margin-left: var(--size-small);
        }
    }

    .expanded-text {
        margin-top: var(--size-small) !important;
        color: var(--black-700);
        font-weight: 500;
        display: block;
        font-size: var(--font-size-nano);

        @include respond-above('sm') {
            margin-top: var(--size-tiny) !important;
        }
    }
}
</style>
