import TravelInsuranceOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/TravelInsuranceOptionInterface';
import { CoverageRisk } from '@/Interfaces/Resources/MovableProperties/CoverageRiskInterface';
import FormField from '@/Assets/Libraries/Form/FormField';
import TravelInsuranceOptionComponents from '@/Pages/Travel/Enums/TravelInsuranceOptionComponents';
import DateRange from '@/Interfaces/date.range.interface';
import { markRaw } from 'vue';

export default class TravelInsuranceOptionBuilder {
    private option: TravelInsuranceOption = new (class implements TravelInsuranceOption {
        public name: string = '';
        public risk!: CoverageRisk;
        public component: string = 'AppTravelInsuranceOption';
        public enabled: FormField<boolean> = markRaw(new FormField('enabled', false));
        public paymentFrequency: string = '';
        public carCount: FormField<string | number> = markRaw(new FormField(''));
        public deductible: FormField<string> = markRaw(new FormField(''));
        public rentalPeriod: FormField<DateRange> = markRaw(new FormField(''));
    })();

    public withName(value: string): TravelInsuranceOptionBuilder {
        this.option.name = value;

        return this;
    }

    public withPaymentFrequency(value: string): TravelInsuranceOptionBuilder {
        this.option.paymentFrequency = value;

        return this;
    }

    public withCoverageRisk(value: CoverageRisk): TravelInsuranceOptionBuilder {
        this.option.risk = value;

        return this;
    }

    public withState(value: boolean): TravelInsuranceOptionBuilder {
        this.option.enabled.setValue(value);

        return this;
    }

    public withCarCount(value: FormField): TravelInsuranceOptionBuilder {
        this.option.carCount = value;

        return this;
    }

    public withDeductible(value: FormField): TravelInsuranceOptionBuilder {
        this.option.deductible = value;

        return this;
    }

    public withRentalPeriod(value: FormField): TravelInsuranceOptionBuilder {
        this.option.rentalPeriod = value;

        return this;
    }

    public build(): TravelInsuranceOption {
        this.applyComponent();

        return this.option;
    }

    private applyComponent(): void {
        this.option.component = TravelInsuranceOptionComponents.forOptionRiskId(this.option.risk.id);
    }
}
