import BusinessTab from '@/Pages/LegalPerson/Workbench/Common/Business.tab';

export default class BusinessDashboard extends BusinessTab {
    private static instance: BusinessDashboard;

    public static getInstance(): BusinessDashboard {
        if (!BusinessDashboard.instance) {
            BusinessDashboard.instance = new BusinessDashboard();
        }

        return BusinessDashboard.instance;
    }

    public init(): void {}

    public onPolicyChanged(policy: string): void {}
}
