<script setup lang="ts">
import { computed, PropType, Ref } from 'vue';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import Popups from '@/Assets/Libraries/Popups/Types/PopupsInterface';
import PopupType from '@/Enums/PopupTypeEnum';

const props = defineProps({
    buttonParams: {
        type: Object as PropType<ButtonWithCallbackParams>,
        default: () => {
            return {};
        },
    },
    popupType: { type: String, default: () => PopupType.Login },
});
const emit = defineEmits(['popup-opener-click']);

const buttonProps: Ref<ButtonWithCallbackParams> = computed((): ButtonWithCallbackParams => {
    return {
        title: '',
        textColor: ButtonTextColor.Link,
        iconColor: ButtonIconColor.Link,
        textColorHover: ButtonTextColor.LinkHover,
        iconPosition: ButtonIconPosition.Left,
        backgroundColor: ButtonBackground.Transparent,
        backgroundColorHover: ButtonBackground.Transparent,
        borderColor: ButtonBorder.Transparent,
        icon: ButtonIcon.User,
        ...props.buttonParams,
    };
});

function onCallbackClick(): void {
    PopupService.getInstance().show(availablePopups()[props.popupType]);
    emit('popup-opener-click');
}

function availablePopups(): Popups {
    return new OnePopup().withType();
}
</script>

<template>
    <div class="popup-opener">
        <button-with-callback v-bind="buttonProps" @button-callback-click="onCallbackClick"> </button-with-callback>
    </div>
</template>

<style lang="scss" scoped>
.popup-opener {
    width: 100%;

    .button-with-callback {
        padding: 0;
        border: none;

        &:hover {
            border: none;
        }
    }
}
</style>
