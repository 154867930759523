<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue';
import { useRouter, Router, RouteParamsGeneric } from 'vue-router';
import SpaUrl from '@/Components/Widgets/LanguageSwitcher/SpaUrl';
import SpaUrls from '@/Services/SpaUrls.service';
import { useTranslate } from '@/Composables/Translate';

const { language } = useTranslate();

const router: Router = useRouter();
const urls: Ref<SpaUrl[]> = ref([]);
const urlsService: SpaUrls = SpaUrls.getInstance();

router.afterEach(() => updateCurrentUrls());

const props = defineProps({
    activeClass: { type: String, default: 'active' },
});

onMounted((): void => {
    urlsService.onExternalDataIsReady.subscribe((): void => {
        updateCurrentUrls();
    });
});

function updateCurrentUrls(): void {
    const baseUrls: SpaUrl[] = JSON.parse(
        JSON.stringify(SpaUrls.getInstance().byName(router.currentRoute.value.name as string)),
    );
    urls.value = baseUrls.map((spaUrl: SpaUrl): SpaUrl => {
        return updateUrlWithParams(spaUrl);
    });
}

function isActive(item: SpaUrl): boolean {
    return item.language === language();
}

function href(item: SpaUrl): string {
    return [item.language, item.path].join('/');
}

function updateUrlWithParams(spaUrl: SpaUrl): SpaUrl {
    const params: RouteParamsGeneric = router.currentRoute.value.params;
    if (Object.keys(params).length > 0) {
        return {
            ...spaUrl,
            path: replaceDynamicSegments(spaUrl.path, params),
        };
    }

    return spaUrl;
}

function replaceDynamicSegments(path: string, params: RouteParamsGeneric): string {
    let updatedPath: string = path;
    Object.entries(params).forEach(([key, value]) => {
        if (updatedPath.includes(`:${key}`)) {
            updatedPath = updatedPath.replace(`:${key}`, String(value));
        }
    });

    return updatedPath;
}
</script>
<template>
    <div class="lang-switch">
        <div class="lang-buttons">
            <a
                v-for="(item, index) in urls"
                :key="index"
                class="btn"
                :href="href(item)"
                :class="{ [props.activeClass]: isActive(item) }"
            >
                {{ item.language.toUpperCase() }}
            </a>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.language-switcher {
    .lang-buttons {
        display: flex;
        margin-top: var(--size-femto);
        column-gap: 16px;
        white-space: nowrap;

        .btn {
            padding-bottom: 2px;
            font-weight: 600;
            font-size: var(--font-size-pico);
            line-height: 12px;
            color: var(--black-500);
            transition: all 0.3s;
            cursor: pointer;

            &.active-desktop {
                border-bottom: solid 2px var(--brand-red);
                color: var(--text-color-default);
            }

            &.active {
                color: var(--text-color-default);
                border-width: 1px;
                border-style: solid;
                border-color: var(--black-500);
            }

            @include respond-below('lg') {
                display: none;
            }

            &:focus,
            &:hover {
                color: var(--text-color-default);
            }

            &:not(:last-of-type) {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -12px;
                    bottom: 0;
                    width: 1px;
                    background: #e9eaec;
                }

                @include respond-above('lg') {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
