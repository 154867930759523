<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import Sanitizer from '@/Services/sanitizer.service';
import { onMounted, PropType, onBeforeUnmount, watch } from 'vue';
import { Subject, Subscription } from 'rxjs';
import Popup from '@/Services/popup.service';
import { debounceTime } from 'rxjs/operators';
import { useTranslate } from '@/Composables/Translate';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';

const props = defineProps({
    placeholder: { type: String, default: () => '' },
    formField: { type: Object as PropType<FormField<number>>, default: () => new FormField('') },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    type: { type: String, default: 'number' },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 99999 },
    maxLength: { type: Number, default: 5 },
    rangeError: { type: String, default: 'Range error' },
    allowDecimals: { type: Boolean, default: true },
});

const emit = defineEmits(['change', 'input', 'click']);

const popup: Popup = Popup.getInstance();
const { translate } = useTranslate();

let valueBeforeChange: number = 0;
const debounceInputMs: number = 1000;
let patchSubscribe: Subscription | null = null;
const onInputSubject: Subject<void> = new Subject();
let onInputSubjectSubscribe: Subscription | null = null;

onMounted((): void => {
    valueBeforeChange = props.formField.isEmpty() ? 0 : props.formField.value;
    patchSubscribe = props.formField.onPatch.subscribe((value: number) => {
        valueBeforeChange = value;
    });
    onInputSubjectSubscribe = onInputSubject.pipe(debounceTime(debounceInputMs)).subscribe(() => {
        notifyAboutChangedValue();
        valueBeforeChange = props.formField.value;
    });
});

onBeforeUnmount((): void => {
    patchSubscribe?.unsubscribe();
    onInputSubjectSubscribe?.unsubscribe();
});

watch(
    () => props.formField.value,
    () => {
        emit('input', [props.formField.value, valueBeforeChange]);
    },
);

function fieldId(): string {
    return props.formField.name + (props.type === 'plain' ? '-plain' : '') + '-text';
}

function increase(): void {
    props.formField.setValue(sanitize(Number(props.formField.value) + 1));
    onInputSubject.next();
}

function decrease(): void {
    props.formField.setValue(sanitize(Number(props.formField.value) - 1));
    onInputSubject.next();
}

function inputChange(): void {
    props.formField.touch();
    if (props.type === 'plain') {
        const inputValue: number = props.formField.value;
        if (inputValue < props.min || inputValue > props.max) {
            processRangeError();
        } else {
            onInputSubject.next();
        }
    } else {
        props.formField.setValue(sanitize(props.formField.value));
        onInputSubject.next();
    }
}

function notifyAboutChangedValue(): void {
    emit('change', [props.formField.value, valueBeforeChange]);
}

function processRangeError(): void {
    let error: string = props.rangeError;
    error = error.replace('minVal', String(props.min));
    error = error.replace('maxVal', String(props.max));
    popup.applyErrorDetails(error);
    PopupService.getInstance().show(new OnePopup().withType().error);
}

function sanitize(value: string | number): number {
    let sanitizedValue: number = Number(value);
    [cleanInputSanitizer, minSanitizer, maxSanitizer].forEach((sanitizer: (sanitizerValue: number) => number) => {
        sanitizedValue = sanitizer(sanitizedValue);
    });
    return sanitizedValue;
}

function cleanInputSanitizer(value: number): number {
    const result = props.allowDecimals
        ? Sanitizer.cleanInputNumber(String(value))
        : Sanitizer.cleanInputInteger(String(value));
    return Number(result);
}

function minSanitizer(value: number): number {
    return value < props.min ? props.min : value;
}

function maxSanitizer(value: number): number {
    return value > props.max ? props.max : value;
}

function emitClick(): void {
    emit('click', props.formField.value);
}
</script>

<template>
    <div
        :id="formField.name"
        class="input number input-number"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <div v-if="label" class="label informative">
            <label :for="fieldId()"> {{ label }}<span v-if="required" class="asterisk">&#42;</span> </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div v-if="type === 'plain'" class="wrapper">
            <input
                :id="fieldId()"
                v-model="formField.value"
                type="text"
                :name="formField.name"
                :maxlength="2"
                :disabled="disabled"
                :placeholder="placeholder"
                :range-error="translate('btar_number_not_in_range')"
                @change="inputChange()"
                @click="emitClick"
            />
        </div>
        <div v-else class="wrapper">
            <input
                :id="fieldId()"
                v-model="formField.value"
                type="text"
                :name="formField.name"
                :maxlength="maxLength"
                :disabled="disabled"
                :placeholder="placeholder"
                @change="inputChange()"
            />
            <div class="buttons">
                <button
                    :id="formField.name + '-increase'"
                    class="up"
                    :aria-label="translate('increase')"
                    @click="increase()"
                >
                    <svg
                        class="arrow-up"
                        width="12"
                        height="6"
                        viewBox="0 0 12 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.37534 0.219131C5.74056 -0.0730437 6.25951 -0.0730437 6.62473 0.219131L11.6247 4.21913C12.056 4.56414 12.1259 5.19343 11.7809 5.6247C11.4359 6.05596 10.8066 6.12588 10.3753 5.78087L6.00004 2.28062L1.62473 5.78087C1.19347 6.12588 0.564178 6.05596 0.219168 5.6247C-0.125842 5.19343 -0.0559202 4.56414 0.375342 4.21913L5.37534 0.219131Z"
                        ></path>
                    </svg>
                </button>
                <button
                    :id="formField.name + '-decrease'"
                    class="down"
                    :aria-label="translate('decrease')"
                    @click="decrease()"
                >
                    <svg
                        class="arrow-down"
                        width="12"
                        height="6"
                        viewBox="0 0 12 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.37534 5.78087C5.74056 6.07304 6.25951 6.07304 6.62473 5.78087L11.6247 1.78087C12.056 1.43586 12.1259 0.806567 11.7809 0.375305C11.4359 -0.0559573 10.8066 -0.125878 10.3753 0.219131L6.00004 3.71938L1.62473 0.219131C1.19347 -0.125878 0.564178 -0.0559573 0.219168 0.375305C-0.125842 0.806567 -0.0559202 1.43586 0.375342 1.78087L5.37534 5.78087Z"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
// Keep this for structure purposes
</style>
