import Translations from '@/Services/translations.service';

export const useTranslate = (): TranslateParams => {
    const translate = (key: string, replaceParts?: TranslateReplaceParts): string => {
        return translateForType(key, '', replaceParts);
    };

    const translateForType = (key: string, translationType: string, replaceParts?: TranslateReplaceParts): string => {
        return Translations.getInstance().localized(key, translationType, replaceParts);
    };

    const hasLocalization = (stringUid: string, translationType: string = ''): boolean => {
        return Translations.getInstance().hasLocalization(stringUid, translationType);
    };

    const language = (): string => {
        return Translations.getInstance().language;
    };

    const applyTranslationType = (newType: string): void => {
        Translations.getInstance().addType(newType);
    };

    const type = (): string => {
        return Translations.getInstance().type;
    };

    return {
        translate,
        translateForType,
        hasLocalization,
        language,
        applyTranslationType,
        type,
    };
};

export interface TranslateReplaceParts {
    [key: string]: string | number;
}

export interface TranslateParams {
    translate: (key: string, replaceParts?: TranslateReplaceParts) => string;
    translateForType: (key: string, type: string, replaceParts?: TranslateReplaceParts) => string;
    hasLocalization: (stringUid: string, type?: string) => boolean;
    language: () => string;
    applyTranslationType: (newType: string) => void;
    type: () => string;
}
