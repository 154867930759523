<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import OneBase from '@/Interfaces/OneBaseInterface';
import DataLayerFacilities from '@/Enums/DataLayerFacilitiesEnum';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        return {
            ...btaBase,
        };
    },

    mounted() {
        this.applyApp(this);
        this.setFacility(DataLayerFacilities.SpecialRisksInsurance);
        this.initBtaBase();
        this.initReCaptcha();
    },
});
</script>
