<script setup lang="ts">
import { computed, ComputedRef, onMounted, PropType, watch, nextTick } from 'vue';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import PlateNumberPlaceholder from '@/Enums/PlateNumberPlaceholderEnum';
import FormField from '@/Assets/Libraries/Form/FormField';
import Form from '@/Assets/Libraries/Form/Form';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';

type InputOptionMultiCheckbox = Omit<InputOption, 'value'> & { value: string };

const props = defineProps({
    placeholder: { type: String, default: () => PlateNumberPlaceholder.ByCountry[new AppCountry().iso()] },
    formField: { type: Object as PropType<FormField<string[]>>, default: () => new FormField('') },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    options: { type: Array as PropType<InputOptionMultiCheckbox[]>, default: () => [] },
    maxSelectionCount: { type: Number, default: 5 },
});

const emit = defineEmits(['change']);
const form: Form = new Form();
const maxAllowedSelectionCount: ComputedRef<number> = computed(() => {
    return props.maxSelectionCount || props.options.length || 0;
});

let checkedCount: number = 0;

watch(
    () => props.formField?.value,
    () => {
        emitChange();
    },
);

onMounted(() => {
    nextTick(() => {
        setupForm();
    });
});

function maxFieldsChecked(): boolean {
    return checkedCount >= maxAllowedSelectionCount.value;
}

function onCheckboxChecked(): void {
    updateCheckedCount();
    const filteredOptions: any[] = form
        .fields()
        .filter((field) => field.value)
        .map((field) => props.options.find((option) => option.value.toString() === field.name));
    if (filteredOptions !== undefined) {
        props.formField?.setValue(filteredOptions.map((option) => option.value));
    }
}

function updateCheckedCount(): void {
    checkedCount = form.fields().filter((field) => field.value).length;
}

function setupForm(): void {
    props.options.forEach((option: InputOptionMultiCheckbox) => {
        form.addField(new FormField(String(option.value), ''));
    });
    form.setReady();
    form.touch().then();
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();
    emit('change', props.formField.value);
}
</script>

<template>
    <div class="multi-checkbox">
        <template v-if="form.isReady()">
            <div v-if="label" class="label informative">
                <p>{{ label }}</p>
                <slot name="app-tooltipster"></slot>
            </div>
            <div class="list">
                <div v-for="(option, index) in options" :key="index" class="field">
                    <app-input-checkbox
                        :disabled="!form.field(option.value).value && maxFieldsChecked()"
                        :form-field="form.field(option.value)"
                        @change="onCheckboxChecked()"
                    >
                        {{ option.name }}
                    </app-input-checkbox>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.multi-checkbox {
    width: 100%;

    .list {
        flex-direction: column;
        width: 100%;

        .field {
            display: flex;
            height: 64px;
            border: 1px solid rgb(146 151 160 / 0.48);
            border-radius: 3px;
            margin-bottom: 10px;

            .checkbox {
                opacity: 1;
                margin-left: var(--size-small);
                margin-top: var(--size-small);
                margin-bottom: 20px;

                .label {
                    margin-left: var(--size-big);
                    color: var(--text-color-default);
                    font-size: var(--font-size-tiny);
                }
            }
        }
    }
}
</style>
