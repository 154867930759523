import MultiCountersValue from '@/Interfaces/multi.counters.value.interface';
import moment from 'moment';
import Form from '@/Assets/Libraries/Form/Form';
import { ref, Ref } from 'vue';

export default class InsuranceValidators {
    private form: Form = new Form();
    private isRanged: Ref<boolean> = ref(false);
    private multipleTripsIsSelected: Ref<boolean> = ref(false);
    private activitiesAreSelected: Ref<boolean> = ref(false);
    private showAllTripsDuration: Ref<boolean> = ref(false);
    private hasMultipleIcGroups: Function | null = null;

    public init(
        form: Form,
        isRanged: Ref<boolean>,
        multipleTripsIsSelected: Ref<boolean>,
        activitiesAreSelected: Ref<boolean>,
        showAllTripsDuration: Ref<boolean>,
        hasMultipleIcGroups: Function,
    ): void {
        this.form = form;
        this.isRanged = isRanged;
        this.multipleTripsIsSelected = multipleTripsIsSelected;
        this.hasMultipleIcGroups = hasMultipleIcGroups;
        this.activitiesAreSelected = activitiesAreSelected;
        this.showAllTripsDuration = showAllTripsDuration;
    }

    public targetValidators(): object {
        return {
            mustBeSelection: (): boolean => {
                return this.form.field('travelTarget').isNotEmpty();
            },
        };
    }

    public regionsValidators(): object {
        return {
            mustBeSelection: (): boolean => {
                return this.form.field('regions').isNotEmpty();
            },
        };
    }

    public travelDatesValidators(): object {
        return {
            datesMustBeSelected: (): boolean => {
                return this.isRanged.value
                    ? this.form.field('travelDates').isNotEmpty() && this.form.field('travelDates').value.endDate !== ''
                    : this.form.field('travelDates').isNotEmpty() &&
                          this.form.field('travelDates').value.startDate !== '';
            },
        };
    }

    public tripDurationValidators(): object {
        return {
            requiredIfVisible: (): boolean => {
                return this.multipleTripsIsSelected.value ? this.form.field('tripDuration').isNotEmpty() : true;
            },
        };
    }

    public travellersValidators(): object {
        return {
            mustBeGreaterThanOneAndEvakMustBeValidIfPresent: (): boolean => {
                let result: boolean = false;
                if (this.form.field('travellers').isNotEmpty()) {
                    const counters: MultiCountersValue = this.form.field('travellers').value;
                    if (counters.counters.length > 0 && this.hasMultipleIcGroups) {
                        result =
                            this.form.field('travellers').value.counters[0].total > 0 &&
                            !this.hasMultipleIcGroups(counters);
                    }
                }

                return result;
            },
        };
    }

    public travelActivitiesValidators(): object {
        return {
            mustBeSelected: (): boolean => {
                return this.form.field('travelActivities').isNotEmpty();
            },
        };
    }

    public activitiesDatesValidators(): object {
        return {
            datesMustBeSelected: (): boolean => {
                let result: boolean = true;
                if (this.activitiesAreSelected.value) {
                    const activitiesDatesIsEmpty: boolean =
                        this.form.field('activitiesDates').isEmpty() &&
                        this.form.field('activitiesDates').value.endDate !== '';
                    if (
                        this.form.field('travelDates').isNotEmpty() &&
                        this.form.field('activitiesDates').isNotEmpty()
                    ) {
                        if (this.form.field('travelDates').value.endDate !== '') {
                            const travelDatesStartDate: moment.Moment = moment(
                                this.form.field('travelDates').value.startDate,
                            );
                            const travelDatesEndDate: moment.Moment = moment(
                                this.form.field('travelDates').value.endDate,
                            );
                            const activitiesDatesStartDate: moment.Moment = moment(
                                this.form.field('activitiesDates').value.startDate,
                            );
                            const activitiesDatesEndDate: moment.Moment = moment(
                                this.form.field('activitiesDates').value.endDate,
                            );
                            result =
                                activitiesDatesStartDate.isSameOrAfter(travelDatesStartDate, 'day') &&
                                activitiesDatesEndDate.isSameOrBefore(travelDatesEndDate, 'day');
                        } else {
                            if (!this.isRanged.value) {
                                const tripDuration: number = parseInt(this.form.field('tripDuration').value, 10);
                                const travelDatesStartDate: moment.Moment = moment(
                                    this.form.field('travelDates').value.startDate,
                                );
                                const travelDatesEndDate: moment.Moment = moment(
                                    this.form.field('travelDates').value.startDate,
                                ).add(tripDuration, 'days');
                                const activitiesDatesStartDate: moment.Moment = moment(
                                    this.form.field('activitiesDates').value.startDate,
                                );
                                const activitiesDatesEndDate: moment.Moment = moment(
                                    this.form.field('activitiesDates').value.endDate,
                                );
                                result =
                                    activitiesDatesStartDate.isSameOrAfter(travelDatesStartDate, 'day') &&
                                    activitiesDatesEndDate.isSameOrBefore(travelDatesEndDate, 'day');
                            } else {
                                result = !this.form.field('travelDates').isEmpty();
                            }
                        }
                    } else {
                        result = !activitiesDatesIsEmpty;
                    }
                }

                return result;
            },
        };
    }

    public allTripsDurationValidators(): object {
        return {
            requiredIfVisible: (): boolean => {
                return this.showAllTripsDuration.value ? this.form.field('allTripsDuration').isNotEmpty() : true;
            },
        };
    }
}
