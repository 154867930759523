import { reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import SpaFormStorageService from '@/Services/SpaFormStorageService';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useStore } from '@/Composables/Store/Store';
import { StoreDefinition } from 'pinia';
import { ActivePlusStore } from '@/Composables/Store/Types/ActivePlus';
import RequestService from '@/Services/request.service';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { useDefine } from '@/Composables/Define';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { default as ErrorService } from '@/Services/error.service';
import { ActivePlus } from '@/Apps/ActivePlus/Interfaces/ActivePlusInterface';
import { StoredSelectedObject } from '@/Apps/ActivePlus/Interfaces/StoredSelectedObject';
import { StoredInsuredPerson } from '@/Apps/ActivePlus/Interfaces/StoredInsuredPerson';

interface StoredFields {
    selectedObjects: StoredSelectedObject[];
    insuredPersons: StoredInsuredPerson[];
    programIc: string;
    products: ActivePlus[];
    coverage: string;
}

export default class Storage {
    public fields!: UnwrapNestedRefs<StoredFields>;
    public products: Ref<ActivePlus[]> = ref([]);
    public store: StoreDefinition<string, ActivePlusStore> = useStore().activePlus();
    private static instance: Storage;
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();

    public static getInstance(): Storage {
        if (!Storage.instance) {
            Storage.instance = new Storage();
            Storage.instance.init();
        }

        return Storage.instance;
    }

    public init(): void {
        const fields: Partial<StoredFields> = this.spaFormStorageService.formStorageFields();
        this.fields = reactive({
            selectedObjects: fields.selectedObjects || [],
            insuredPersons: fields.insuredPersons || [],
            programIc: fields.programIc || '',
            products: fields.products || [],
            coverage: fields.coverage || '',
        });
        this.spaFormStorageService.applyDataProviderCallback((): StoredFields => this.fields);
    }

    public updateRoute(): void {
        this.store().updateRoute();
    }

    public destroyRoute(): void {
        this.store().destroyRoute();
    }

    public selectedProduct(): ActivePlus {
        const result: ActivePlus | undefined = this.fields.programIc
            ? this.products.value.find((program: ActivePlus): boolean => program.id === this.fields.programIc)
            : undefined;
        if (!result) {
            throw 'Product not found';
        }

        return result;
    }

    public async fetchProducts(): Promise<void> {
        return this.products.value.length === 0
            ? RequestService.getInstance()
                  .get({
                      uri: Url.Ajax.activePlusProducts,
                  })
                  .then((response: AxiosResponse): void => {
                      if (useDefine().validResponse(response)) {
                          this.products.value = response.data.data.body.products;
                      } else {
                          throw response;
                      }
                  })
                  .catch((reason: DynamicDictionary): void => {
                      ErrorService.log(ErrorType.Error, 'Active plus policy / fetchProducts', reason);
                      throw reason;
                  })
            : new Promise((resolve) => resolve());
    }
}
