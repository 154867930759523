<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import { computed, onMounted, PropType, ref, Ref } from 'vue';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { InputOption } from '@/Interfaces/InputOptionInterface';

const props = defineProps({
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    formField: { type: Object as PropType<FormField<string>>, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    options: { type: Array<InputOption>, default: () => [new InputOptionBuilder().setName('-----').build()] },
});

const emit = defineEmits(['change']);

const isVisibleOptions: Ref<boolean> = computed(() => {
    return !isDropDownMode.value || dropDownIsOpened.value;
});

const isDropDownMode: Ref<boolean> = computed(() => {
    return props.options.length > 1;
});

const isSingleValue: Ref<boolean> = computed(() => {
    return props.options.length === 1;
});

const formValueCustom: Ref<string> = computed(() => {
    let result: string = '';
    (props.options as InputOption[]).forEach((option: InputOption): void => {
        if (option.value === props.formField.value) {
            result = customPostfix(option.custom as LimitedVariant);
        }
    });

    return result;
});

const formValueModified: Ref<boolean> = computed(() => {
    let result: boolean = false;
    (props.options as InputOption[]).forEach((option: InputOption): void => {
        if (option.value === props.formField.value) {
            result = isModified(option.custom as LimitedVariant);
        }
    });

    return result;
});
const dropDownIsOpened: Ref<boolean> = ref(false);

onMounted((): void => {
    if (props.formField.isEmpty() && props.options.length > 0) {
        select(props.options[0] as InputOption);
    }
});

function select(option: InputOption): void {
    if (option.value !== props.formField.value) {
        props.formField.patch(option.value as string);
        dropDownIsOpened.value = false;
        emit('change', props.formField.value);
    }
}

function onOpenerClick(): void {
    if (!isSingleValue.value) {
        dropDownIsOpened.value = !dropDownIsOpened.value;
    } else {
        dropDownIsOpened.value = false;
    }
}

function closeDropElements(): void {
    dropDownIsOpened.value = false;
}

function customPostfix(custom: LimitedVariant): string {
    return custom ? ((custom as DynamicDictionary).postfix as string) : '';
}

function isModified(custom: LimitedVariant): boolean {
    return custom ? ((custom as DynamicDictionary).modified as boolean) : false;
}
</script>

<template>
    <div
        class="input input-smart-radio"
        :data-type="formField.name !== '' ? formField.name : 'smartRadio'"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : `${formField.value}`"
    >
        <span v-if="label !== ''" class="buttons-label">{{ label }}</span>
        <div :id="formField.name" v-click-outside="closeDropElements" class="wrapper drop">
            <div
                class="opener button"
                :class="{
                    modified: formValueModified,
                    border: isVisibleOptions && !isSingleValue,
                    single: isSingleValue,
                }"
                @click="onOpenerClick"
            >
                <div class="text-block">
                    <span class="text">{{ formField.value }}</span>
                    <span v-if="formValueCustom !== ''" class="dot"></span>
                    <span v-if="formValueCustom !== ''" class="text">{{ formValueCustom }}</span>
                </div>
                <span v-if="!isSingleValue" class="icon-opener" :class="{ opened: isVisibleOptions }">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 5L5 1L9 5"
                            stroke="#5448C8"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </span>
            </div>
            <div class="buttons-wrapper">
                <div v-if="isVisibleOptions && !isSingleValue" class="buttons" :class="{ regular: !isDropDownMode }">
                    <span v-if="label !== '' && !isDropDownMode" class="buttons-label">{{ label }}</span>
                    <button
                        v-for="(option, index) in options"
                        :key="index"
                        class="button"
                        :class="{
                            active: formField.value === option.value && !(option.disabled || disabled),
                            modified: isModified(option.custom as LimitedVariant),
                        }"
                        :disabled="option.disabled || disabled"
                        :data-type="(formField.name !== '' ? formField.name : 'smartRadio') + '-' + index"
                        :data-index="index"
                        @click="select(option)"
                    >
                        <span class="icon"></span>
                        <span class="text-block">
                            <span class="text" v-html="option.name"></span>
                            <span v-if="customPostfix(option.custom as LimitedVariant) !== ''" class="dot"></span>
                            <span v-if="customPostfix(option.custom as LimitedVariant) !== ''" class="text">{{
                                customPostfix(option.custom as LimitedVariant)
                            }}</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-smart-radio {
    scroll-margin-top: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;

    .buttons-label {
        display: none;
        font-weight: 500;
        font-size: var(--font-size-nano);
        margin-right: var(--size-small);
        color: var(--black-400);
        align-items: center;
        min-height: 40px;
        margin-bottom: var(--size-femto);

        @media (min-width: 950px) {
            display: flex;
        }
    }

    .wrapper {
        min-height: 40px;
        padding: 0;

        .opener {
            cursor: pointer;
        }

        .buttons {
            flex-flow: row wrap;
            position: relative;

            &.regular {
                width: 100%;
                flex-direction: row;
                justify-content: left;
            }
        }

        .button {
            box-shadow: none;
            position: relative;
            width: 383px;
            height: 40px;
            flex-direction: row;
            padding: var(--size-nano);
            border-radius: 8px;
            border: 2px solid transparent;
            font-size: var(--font-size-nano);
            margin-right: var(--size-small);
            background-color: var(--component-color-background-base);
            justify-content: left;
            margin-bottom: 6px;

            &:last-child {
                margin-right: 0;
            }

            .icon {
                width: 20px;
                height: 20px;
                min-width: 20px;
                border: 2px solid var(--black-500);
                border-radius: 20px;
                margin-right: var(--size-nano);
                transition: all 0.3s ease-in;
            }

            .icon-opener {
                margin-left: 18px;
                rotate: 180deg;
                transition: all 0.3s ease-in;

                &.opened {
                    rotate: 0deg;
                }
            }

            .text-block {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text {
                    color: var(--text-color-default);
                    font-weight: 500;
                }

                .dot {
                    display: block;
                    width: 2px;
                    height: 2px;
                    background-color: var(--black-400);
                    border-radius: 2px;
                }
            }

            &:not(.border):hover {
                border: 2px solid var(--blue-400);

                &::before {
                    content: '';
                    position: absolute;
                    background-color: var(--blue-400);
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    left: 0;
                }
            }

            &.active {
                border: 2px solid var(--brand-blue);

                .icon {
                    border: 7px solid var(--brand-blue);
                }
            }

            &.modified {
                &::after {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    right: -6px;
                    top: -5px;
                    border-radius: 6px;
                    background-color: var(--orange-500);
                }
            }

            &:disabled {
                cursor: default;

                .icon {
                    opacity: 0.4;
                }

                .text-block {
                    .text {
                        opacity: 0.4;
                    }
                }
            }
        }

        &.drop {
            .buttons-wrapper {
                position: absolute;
                z-index: 1;
                top: 42px;
            }

            .opener {
                margin-bottom: 2px;
                margin-right: 0;

                &:not(.border):hover {
                    border: 2px solid var(--blue-400);
                }

                &::before {
                    display: none;
                }
            }

            .buttons {
                flex-direction: column;
                filter: drop-shadow(0 15px 15px rgb(0 0 0 / 0.2));

                .button {
                    transition: none;
                    margin-bottom: 0;
                    border-radius: 0;
                    margin-right: 0;
                    border: 2px solid var(--black-200);
                    border-bottom: none;

                    &:first-child {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }

                    &:last-child {
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        border-bottom: 2px solid var(--black-200);
                    }

                    &::before {
                        border-radius: 0;
                    }

                    &:hover {
                        border: none;
                        padding: 14px 14px var(--size-nano);

                        &:first-child {
                            &::before {
                                top: 0;
                                border-top-left-radius: 8px;
                                border-top-right-radius: 8px;
                            }
                        }

                        &:last-child {
                            padding: 14px;

                            &::before {
                                border-bottom-left-radius: 8px;
                                border-bottom-right-radius: 8px;
                            }
                        }
                    }
                }
            }

            .border {
                border: 2px solid var(--brand-blue);
            }

            .single {
                pointer-events: none;
            }
        }
    }
}
</style>
