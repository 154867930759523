import ClaimDocuments from '@/Components/Claims/ClaimDocuments/Interfaces/ClaimDocuments';
import ClaimDocumentsContent from '@/Components/Claims/ClaimDocuments/Interfaces/ClaimDocumentsContent';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

export default class ClaimDetailsDocumentsBuilder {
    private claimDocuments: ClaimDocuments = this.cleanValues();

    public reset(): this {
        this.claimDocuments = this.cleanValues();

        return this;
    }

    private cleanValues(): ClaimDocuments {
        return new (class implements ClaimDocuments {
            public component: string = 'AppClaimDocuments';
            public content: ClaimDocumentsContent = new (class implements ClaimDocumentsContent {
                public title: string = '';
                public documentBlocks: DynamicDictionary[] = [];
            })();
        })();
    }

    public build(): ClaimDocuments {
        return this.claimDocuments;
    }
}
