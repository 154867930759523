<script setup lang="ts">
import Modal from '@/Services/modal.service';

const props = defineProps({
    disableClose: { type: Boolean, default: false },
});
const emit = defineEmits(['close']);

function close(): void {
    Modal.getInstance().closeModal();
    emit('close');
}
</script>

<template>
    <aside class="single-modal">
        <div class="wrapper wrapper-filled" tabindex="0">
            <button v-if="!disableClose" class="modal-close close" @click="close()">
                <svg width="32" height="32" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 1L13 13M13 1L1 13"
                        stroke="#9297A0"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </button>
            <slot></slot>
        </div>
    </aside>
</template>

<style lang="scss" scoped>
.single-modal {
    position: fixed;

    @include fullscreen;

    z-index: 400;

    > .overlay {
        position: absolute;

        @include fullscreen;

        z-index: 1;
        background: var(--brand-black);
        opacity: 0.24;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    > .wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: var(--size-big);
        overflow-y: auto;

        @include respond-above('sm') {
            justify-content: flex-start;
        }

        &-filled {
            background-color: var(--background-light);
        }
    }
}
</style>
