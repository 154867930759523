<script setup lang="ts">
import TwoWayModal from '@/Apps/TwoWayCommunication/Services/TwoWayModalService';
import { computed, Ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';

const twoWayModalService: TwoWayModal = TwoWayModal.getInstance();

const { translateForType } = useTranslate();

const translationType: string = 'communications';

const modalTitle: Ref<string> = computed(() => {
    const replacements: DynamicDictionary = {
        '%name%': twoWayModalService.content().name,
    };

    return translateForType('two_way_modal_title', translationType, replacements);
});

const modalInfo: Ref<string> = computed(() => {
    const replacements: DynamicDictionary = {
        '%count%': String(twoWayModalService.content().messagesCount),
    };

    return translateForType('two_way_modal_info', translationType, replacements);
});

const viewMessageButtonConfig: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        title: translateForType('two_way_view_messages', translationType),
        backgroundColor: ButtonBackground.Red,
        textColor: ButtonTextColor.White,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
});

const proceedToMyBtaButtonConfig: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        title: translateForType('two_way_proceed', translationType),
        backgroundColor: ButtonBackground.White,
        backgroundColorHover: ButtonBackground.White,
        callback: proceedToMyBta,
    };
});

const viewMessagesUrl: Ref<string> = computed(() => {
    return TwoWayCommunication.getInstance().communicationsUrl();
});

function proceedToMyBta(): void {
    twoWayModalService.hide();
}
</script>

<template>
    <span class="two-way-modal">
        <div v-if="twoWayModalService.isVisible.value" class="two-way-modal-container">
            <div class="container">
                <img src="images/one/communications/communications-modal-image.png" alt="" />
                <h3 class="title">{{ modalTitle }}</h3>
                <div class="info">{{ modalInfo }}</div>
                <div class="buttons">
                    <app-button-with-callback
                        v-bind="viewMessageButtonConfig"
                        :url="viewMessagesUrl"
                        :target="'_self'"
                    ></app-button-with-callback>
                    <app-button-with-callback v-bind="proceedToMyBtaButtonConfig"></app-button-with-callback>
                </div>
            </div>
        </div>
    </span>
</template>

<style lang="scss" scoped>
.two-way-modal {
    .two-way-modal-container {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--background-light);
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: center;

        > .container {
            width: 100%;
            max-width: 327px;
            height: 100%;
            max-height: 466px;
            margin-top: 80px;
            text-align: center;

            img {
                width: 100%;
                border-bottom: 1px solid var(--teal-600);
                margin-bottom: 10px;
            }

            .title {
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .info {
                height: 60px;
                display: flex;
                justify-content: center;
                color: var(--text-color-attention);
            }

            .buttons {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .button-with-callback {
                    min-height: 52px;
                }
            }
        }
    }
}
</style>
