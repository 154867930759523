<script lang="ts">
import { computed, defineComponent, reactive, ref, Ref, UnwrapNestedRefs, nextTick } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Form from '@/Assets/Libraries/Form/Form';
import { useTranslate } from '@/Composables/Translate';
import { useDebounce } from '@/Composables/Debounce';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { SecurityDevice } from '@/Interfaces/SecurityDeviceInterface';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import AgreementPayment from '@/Assets/Libraries/Payment/AgreementPayment';
import UserPolicy from '@/Interfaces/user.policy.interface';
import PolicySchedule from '@/Interfaces/policy.schedule.interface';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import UserPolicyDetails from '@/Interfaces/user.policy.details.interface';
import NumericArrayBuilder from '@/Builders/NumericArrayBuilder';
import InputTagIcon from '@/Components/Inputs/InputText/InputTagIcon';
import { CoveredPopupBuilder } from '@/Pages/ItemsAndBelongings/Classes/CoveredPopupBuilder';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import ColumnsDefinitions from '@/Components/Lists/SmartGrid/Interfaces/ColumnsDefinitionsInterface';
import { TemplateCellRenderer } from '@/Components/Lists/SmartGrid/Renderers/TemplateCellRenderer';
import ProductBlockItem from '@/Components/Other/ProductBlock/Interfaces/ProductBlockItemInterface';
import { Subscription } from 'rxjs';
import CellRendererTemplate from '@/Components/Lists/SmartGrid/Interfaces/CellRendererTemplateInterface';
import BottomNotification from '@/Services/bottom.notification.service';
import BottomNotificationLevel from '@/Enums/BottomNotificationLevelEnum';
import ListItem from '@/Components/Lists/ItemsListWithIcons/ListItem';
import StatusIcon from '@/Components/Tooltips/StatusIcon/StatusIcon.enum';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import FieldsetBuilder from '@/Components/Lists/FieldsetList/Builders/FieldsetBuilder';
import Field from '@/Components/Lists/FieldsetList/Interfaces/Field.interface';
import FieldBuilder from '@/Components/Lists/FieldsetList/Builders/FieldBuilder';
import FieldName from '@/Components/Lists/FieldsetList/Enums/FieldName.enum';
import FieldComponent from '@/Components/Lists/FieldsetList/Enums/FieldComponent.enum';
import Sanitizer from '@/Services/sanitizer.service';
import FieldsetLayout from '@/Components/Lists/FieldsetList/Enums/FieldsetLayout.enum';
import moment from 'moment/moment';
import FormField from '@/Assets/Libraries/Form/FormField';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import CountriesWithRegion from '@/Interfaces/countries.with.region.interface';
import { AxiosResponse } from 'axios';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useAxios } from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import ProductBlockItemBuilder from '@/Components/Other/ProductBlock/Builders/ProductBlockItemBuilder';
import InputRadioIcon from '@/Components/Inputs/InputRadio/Enums/InputRadioIconEnum';
import OptionsSmartTypes from '@/Components/Options/OptionsSmartList/Enums/OptionsSmartTypesEnum';
import OptionsSmartCustom from '@/Components/Options/OptionsSmartList/Interfaces/OptionsSmartCustomInterface';
import { OptionsCustomValue } from '@/Components/Options/OptionsSmartList/Types/OptionsCustomValueType';
import Tooltipster from '@/Interfaces/tooltipster.interface';
import InputDateWithCalendarBinds from '@/Components/Options/OptionsSmartList/Components/Interfaces/InputDateWithCalendarBindsInterface';
import OptionsSmartCustomSelectState from '@/Components/Options/OptionsSmartList/Interfaces/OptionsSmartCustomSelectStateInterface';
import OptionSmartColor from '@/Components/Options/OptionsSmartList/Enums/OptionSmartColorEnum';
import Validation from '@/Services/validation.service';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import PopupBase from '@/Assets/Libraries/Popups/PopupBase';
import CoveredPopupTypes from '@/Components/Popups/CoveredPopup/Enums/CoveredPopupTypes';
import PopupIcon from '@/Enums/PopupIconEnum';
import PopupInput from '@/Enums/PopupInputEnum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import InsuredRisksBuilder from '@/Components/Lists/InsuredRisks/Builders/InsuredRisksBuilder';
import PolicyLimit from '@/Interfaces/OnePolicy/PolicyLimit';
import PolicyDetailsOverviewBuilder from '@/Components/Policies/PolicyDetailsOverview/Builders/PolicyDetailsOverviewBuilder';
import AgreementTypes from '@/Components/Policies/PolicyBlock/Enums/AgreementTypesEnum';
import SubscriptionState from '@/Enums/OnePolicy/SubscriptionStateEnum';
import PolicyDetailsInvoicesBuilder from '@/Components/Policies/PolicyDetailsInvoices/Builders/PolicyDetailsInvoicesBuilder';
import PolicyDetailsObjectsBuilder from '@/Components/Policies/PolicyDetailsObjects/Builders/PolicyDetailsObjectsBuilder';
import PolicyDetailsPaymentMethodBuilder from '@/Components/Policies/PolicyDetailsPaymentMethod/Builders/PolicyDetailsPaymentMethodBuilder';
import PolicyDetailsPersonsBuilder from '@/Components/Policies/PolicyDetailsPersons/Builders/PolicyDetailsPersonsBuilder';
import PolicyDetailsContentTabsBuilder from '@/Components/Policies/PolicyDetails/Builders/PolicyDetailsContentTabsBuilder';
import PolicyDetailsContentBuilder from '@/Components/Policies/PolicyDetails/Builders/PolicyDetailsContentBuilder';
import DrawerService from '@/Services/drawer.service';
import PolicyDetails from '@/Components/Policies/PolicyDetails/Services/PolicyDetailsService';
import PolicyDetailsPersonsContent from '@/Components/Policies/PolicyDetailsPersons/Interfaces/PolicyDetailsPersonsContentInterface';
import { MovableProperty } from '@/Interfaces/Resources/MovableProperties/MovablePropertyInterface';
import { useDefine } from '@/Composables/Define';
import ButtonsWithCallbackBuilder from '@/Components/Buttons/ButtonWithCallback/Builders/ButtonsWithCallbackBuilder';
import ContactUsButton from '@/Components/Other/ContactUs/Interfaces/ContactUsButtonInterface';
import ContactUsButtonsBuilder from '@/Components/Other/ContactUs/Builders/ContactUsButtonsBuilder';
import TwoWayModal from '@/Apps/TwoWayCommunication/Services/TwoWayModalService';
import Attachment from '@/Components/TwoWayCommunication/Message/Interfaces/Attachment';
import ResultMessage from '@/Components/Other/ResultMessage/Services/ResultMessageService';
import Fieldset from '@/Components/Lists/FieldsetList/Interfaces/Fieldset.interface';
import RequestService from '@/Services/request.service';
import ClaimDetailsContentTabsBuilder from '@/Components/Claims/ClaimDetails/Builders/ClaimDetailsContentTabsBuilder';
import ClaimOverviewBuilder from '@/Components/Claims/ClaimOverview/Builders/ClaimOverviewBuilder';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import ClaimDetailsTabs from '@/Components/Claims/ClaimDetails/Enums/ClaimDetailsTabsEnum';
import ClaimDetailsContentBuilder from '@/Components/Claims/ClaimDetails/Builders/ClaimDetailsContentBuilder';
import PersonDetails from '@/Interfaces/PersonDetailsInterface';
import IndemnityReceiverPersonDetails from '@/Components/Widgets/IndemnityReceiver/Interfaces/IndemnityReceiverPersonDetailsInterface.ts';
import PersonContacts from '@/Interfaces/PersonContactsInterface';
import PersonBankDetails from '@/Interfaces/PersonBankDetailsInterface';
import DecisionBuilder from '@/Components/Claims/ClaimOverview/Builders/DecisionBuilder';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { debounce, debouncePromise } = useDebounce();

        const CurrentStep: number = 1;
        const Facility: string = 'dev';
        const customNumber: DynamicDictionary = {
            Five: 5,
            Six: 6,
            Seven: 7,
            Eight: 8,
            Nine: 9,
            Ten: 10,
            Twenty: 20,
            Thirty: 30,
            Forty: 40,
            Fifty: 50,
            Sixty: 60,
            Seventy: 70,
            Eighty: 80,
            Ninety: 90,
            Hundred: 100,
        };
        const currentDate: Date = new Date();
        const loadMoreListItems: number[] = new NumericArrayBuilder()
            .withFrom(NumericArrayBuilder.One)
            .withTo(NumericArrayBuilder.Ten)
            .build();
        const itemsListContent: string[] = [
            'Mouse, 240 m<sup>2</sup>',
            'Fish, 30 m<sup>2</sup>',
            'Horse, 140 m<sup>2</sup>',
            'Bug, 10 m<sup>2</sup>',
            'Weasel, 50 m<sup>2</sup>',
        ];
        const addressBlocksEventDate: string = '2022-06-01 12:14:00';
        const regionsMessage: string = 'Regions Message';
        const closeRegionsMessage: string = 'Close Regions Message';
        const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
        const gridInputOptions: InputOption[] = [
            new InputOptionBuilder().setName('G-Name 0').setValue('G-Val 0').build(),
            new InputOptionBuilder().setName('G-Name 1').setValue('G-Val 1').build(),
            new InputOptionBuilder().setName('G-Name 2').setValue('G-Val 2').build(),
            new InputOptionBuilder().setName('G-Name 3').setValue('G-Val 3').build(),
            new InputOptionBuilder().setName('G-Name 4').setValue('G-Val 4').build(),
        ];
        const infoWithActionsButtons: Ref<ButtonWithCallbackParams[]> = ref([]);
        const contactUsButtons: Ref<ContactUsButton[]> = ref([]);

        const progressStepsState: Ref<number> = ref(0);
        const twoWayModal: Ref<boolean> = ref(false);
        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const securityDevices: Ref<SecurityDevice[]> = ref([]);
        const inputOptions: Ref<InputOption[]> = ref([]);
        const inputOptionsWithIcons: Ref<InputOption[]> = ref([]);
        const popupWideOptions: Ref<InputOption[]> = ref([]);
        const inputOptionsBadge: Ref<InputOption[]> = ref([]);
        const inputOptionsBadgeDigits: Ref<InputOption[]> = ref([]);
        const claims = ref([
            {
                status: 'registered',
                submitDate: '05.09.2023',
                title: 'Health claim',
                reason: 'Medical expenses',
                claimNumber: 'ISDH-117496/45-24',
                sumApproved: 500.49,
                attachments: [
                    new (class implements Attachment {
                        public id: string = '222';
                        public name: string = 'My file';
                        public type: string = 'pdf';
                        public downloadLink: string = '/some/url/to/file.php';
                    })(),
                ],
            },
            {
                status: 'approved',
                submitDate: '05.09.2023',
                title: 'Travel claim',
                reason: 'Medical expenses',
                claimNumber: 'JO62930012',
                sumApproved: 250.0,
            },
            {
                status: 'declined',
                submitDate: '05.09.2023',
                title: 'Ogres iela 24-4, Rīga',
                reason: 'Water flood',
                claimNumber: 'ISDH-117496/45-24',
            },
        ]);
        const agreementPayment: UnwrapNestedRefs<AgreementPayment> = reactive(
            new AgreementPayment(
                {} as UserPolicy,
                {
                    status: 'unpaid',
                    payment_schedule_amount: '19.21',
                    payment_schedule_term: '31.12.2027',
                    payment_schedule_id: 'TODO12345',
                } as PolicySchedule,
            ),
        );
        const insurance: UnwrapNestedRefs<Agreement> = reactive({
            id: '1111111',
            type: 'Travel',
            typeId: 'LV_TRAVEL_V2',
            agreementNumber: 'ZX12121212',
            subscriptionNumber: '14214124',
            insuranceTermsId: '1241414124',
            insuranceTermsText: 'Insurance Term Text',
            objectName: 'World',
            insuredObjectCount: 3,
            paymentCount: 3,
            premium: 10.99,
            validFrom: { date: '2023-12-12', timezone: '1' },
            validTo: { date: '2023-12-12', timezone: '1' },
            status: 'Active',
            isActive: true,
            isSubscription: false,
            isIndividual: false,
            isEndingSoon: true,
            isRenewed: true,
            isUpcoming: false,
            payment: {
                isLate: false,
                isUpcoming: true,
                dueDate: { date: '2023-12-12', timezone: '1' },
                amount: 56,
                currency: 'EUR',
            },
            holder: { id: '12414142', type: 'clients' },
            name: 'Travel',
            subscriptionHistory: [],
            upcomingAgreements: [],
        });
        const policy: UnwrapNestedRefs<UserPolicy> = reactive({
            agreement_number: 'LV21-03-10015046-9',
            policyDetails: { terms: [{ printoutFileLink: 'some-link' }] } as UserPolicyDetails,
            date_from: '01.01.2021',
            date_to: '31.12.2027',
        } as UserPolicy);
        const addressBlocksOptions: Ref<InputOption[]> = ref([]);
        const optionsListOptions: Ref<InputOption[]> = ref([]);
        const inputOptionsSmartList: Ref<InputOption[]> = ref([]);
        const inputOptionsSmartListRadio: Ref<InputOption[]> = ref([]);
        const inputOptionsCheckboxTest: Ref<InputOption[]> = ref([]);
        const formIsRestored: Ref<boolean> = ref(false);
        const isVisibleBlock: Ref<boolean> = ref(false);
        const travelTargetUrlParams: Ref<string> = ref('');
        const rightIcon: UnwrapNestedRefs<InputTagIcon> = reactive({
            name: 'edit',
            cssClass: 'black-to-grey',
        });
        const travelTargets: Ref<DynamicDictionary[]> = ref([]);
        const smartOptions2: Ref<InputOption[]> = ref([]);
        const smartOptions10: Ref<InputOption[]> = ref([]);
        const receivedSearch: Ref<string> = ref('');
        const receivedCallback: Ref<string> = ref('');
        const gridSearchTerm: Ref<string> = ref('');
        const gridColumnDefs: Ref<ColumnsDefinitions[]> = ref([
            {
                field: 'make',
                headerName: 'Make',
                valueFormatter: (params: DynamicDictionary) => {
                    return String(params.value).toUpperCase();
                },
                editable: true,
            },
            {
                field: 'model',
                headerName: 'Model',
                editable: false,
                tooltipValueGetter: (params: DynamicDictionary) => {
                    return params.value;
                },
                tooltipComponent: TemplateCellRenderer,
                tooltipComponentParams: tooltipComponentTemplateProgramName.bind(this),
            },
            {
                field: 'price',
                headerName: 'Price',
                editable: false,
                cellClass: 'app-input-select',
                component: {
                    name: 'AppInputSelect',
                    data: gridInputOptions,
                },
                cellRendererParams: {
                    template: '<div class="grid-cell-arrow-down">@</div>',
                },
            },
            {
                field: 'color',
                headerName: 'Color',
                customTooltipRendererParams: cellTopPanelTooltipRenderer.bind(this),
            },
        ]);
        const gridRowData: Ref<DynamicDictionary[]> = ref([
            { make: 'Toyota', model: 'Celica', price: 'G-Val 2', color: 'red' },
            { make: 'Ford', model: 'Mondeo', price: '32000', color: 'yellow' },
            { make: 'Porsche', model: 'Boxster', price: 'G-Val 4', color: 'red' },
            { make: 'Wurst', model: 'Smoked', price: 7, color: 'blue' },
            { make: 'Cookie', model: 'Crunchy', price: 1.5, color: 'red' },
            { make: 'PC', model: 'New', price: 12000, color: 'red' },
            { make: 'PC', model: 'Renewed', price: 700, color: 'blue' },
            { make: 'Display', model: 'LED', price: 200, color: 'blue' },
            { make: 'Display', model: 'LCD', price: 60, color: 'yellow' },
            { make: 'Display', model: '120hz', price: 400, color: 'blue' },
        ]);
        const products: Ref<ProductBlockItem[]> = ref([]);
        const itemsListCallbackTestStringFirst: Ref<string> = ref('No value yet');
        const itemsListCallbackTestStringSecond: Ref<string> = ref('No value yet');
        const files = ref([
            {
                link: '2235252525asfafa124124',
                name: 'test-file-1',
                mime: 'pdf',
            },
            {
                link: '115135252525asfafa124124',
                name: 'test-file-2',
                mime: 'pdf',
            },
        ]);

        const gridEventCallback: Function = eventCallbackReceiver;
        const gridDataChangeCallback: Function = dataChangeCallbackReceiver;

        const inputIconRight: Ref<InputTagIcon> = computed(() => {
            return rightIcon;
        });

        const listItems: Ref<ListItem[]> = computed(() => {
            return [
                {
                    name: 'just_a_button_with_callback',
                    icon: 'new_document',
                    callback: testCallbackFirst,
                },
                {
                    name: 'just_a_button_with_different_callback',
                    icon: 'new_document',
                    callback: testCallbackSecond,
                },
                {
                    name: 'just_a_button_without_callback',
                    icon: 'new_document',
                },
                {
                    name: 'link_name_translated_2',
                    url: 'https://www.delfi.lv',
                    icon: 'document',
                },
                {
                    name: 'link_name_translated_3',
                    url: 'https://www.delfi.lv',
                    icon: 'document',
                },
                {
                    name: 'link_name_translated_4',
                    url: 'https://www.delfi.lv',
                    icon: 'document',
                },
            ];
        });

        const statusError: Ref<string> = computed(() => {
            return StatusIcon.Error;
        });

        const statusValid: Ref<string> = computed(() => {
            return StatusIcon.Valid;
        });

        const itemsListWithIconsCallbackTextFirst: Ref<string> = computed(() => {
            return itemsListCallbackTestStringFirst.value;
        });

        const itemsListWithIconsCallbackTextSecond: Ref<string> = computed(() => {
            return itemsListCallbackTestStringSecond.value;
        });

        const travelTargetCustomUrl: Ref<string> = computed(() => {
            return '/' + useTranslate().language() + '/ajax/devcomponentspreview/travelDestinationsLt/';
        });

        const travelTargetValue: Ref<DynamicDictionary> = computed(() => {
            return {
                target: travelTargetUrlParams.value,
            };
        });

        const buttonParams1: Ref<ButtonWithCallbackParams> = computed(() => {
            return {
                title: 'with callback',
                icon: ButtonIcon.Check,
                backgroundColor: ButtonBackground.Green,
                textColor: ButtonTextColor.White,
                iconColor: ButtonIconColor.White,
            };
        });

        const buttonParamsDrawer: Ref<ButtonWithCallbackParams> = computed(() => {
            const md: number = 960;

            return {
                title: 'Show Drawer: ' + (btaBase.windowWidth.value <= md ? 'mobile' : 'pc'),
                backgroundColor: btaBase.windowWidth.value <= md ? ButtonBackground.Blue : ButtonBackground.Green,
                textColor: ButtonTextColor.White,
                iconColor: ButtonIconColor.White,
            };
        });

        const buttonParamsModal: Ref<ButtonWithCallbackParams> = computed(() => {
            return {
                title: 'Show Modal (autoPhotoUpload)',
                backgroundColor: ButtonBackground.Blue,
                textColor: ButtonTextColor.White,
                iconColor: ButtonIconColor.White,
            };
        });

        const buttonParams2: Ref<ButtonWithCallbackParams> = computed(() => {
            return {
                title: 'red',
                icon: ButtonIcon.Delete,
                backgroundColor: ButtonBackground.Red,
                textColor: ButtonTextColor.White,
                iconColor: ButtonIconColor.White,
            };
        });

        const buttonParams3: Ref<ButtonWithCallbackParams> = computed(() => {
            return {
                title: 'badge',
                mode: 'badge',
                textColor: ButtonTextColor.Green,
                backgroundColor: ButtonBackground.Blue,
            };
        });

        const messageAttachments: Ref<Attachment[]> = computed(() => {
            return [
                { id: 'x', name: 'Atlīdzības lēmums', downloadLink: '/assets/awdawdaw.png', type: 'pdf' },
                { id: 'y', name: 'Būvdarbu tāme', downloadLink: '/assets/awdawd.pdf', type: 'pdf' },
                { id: 'z', name: 'Būvdarbu eksperta slēdziens', downloadLink: '/assets/awd.ZIP', type: 'pdf' },
            ];
        });

        const subjectListValue: Ref<string> = computed(() => {
            const fieldValue: DynamicDictionary[] = form.field('dev-fieldset-list').value.subjects;
            let content: string = '';
            if (fieldValue) {
                content = fieldValue
                    .map((subject: DynamicDictionary, index: number): string => {
                        return `<span style="color: limegreen;">${JSON.stringify(fieldValue[index])}</span>`;
                    })
                    .join('<br>');
            }

            return '<br>' + content;
        });

        const devFieldset: Ref<Fieldset> = computed(() => {
            const fieldset: FieldsetBuilder = new FieldsetBuilder();
            const fields: Field[] = [
                new FieldBuilder()
                    .withName(FieldName.FirstName)
                    .withComponent(FieldComponent.InputText)
                    .withLabel('btar_name')
                    .withPlaceholder('btar_first_name_placeholder')
                    .withValidator('required')
                    .withSanitizer(Sanitizer.cleanName)
                    .build(),
                new FieldBuilder()
                    .withName(FieldName.LastName)
                    .withComponent(FieldComponent.InputText)
                    .withLabel('btar_surname')
                    .withPlaceholder('btar_last_name_placeholder')
                    .withValidator('required')
                    .withSanitizer(Sanitizer.cleanName)
                    .build(),
                new FieldBuilder()
                    .withName(FieldName.DateOfBirth)
                    .withComponent(FieldComponent.InputDate)
                    .withLabel('btar_birth_date')
                    .withPlaceholder('btar_birth_date_placeholder')
                    .withValidator('required')
                    .build(),
            ];
            fields.forEach((field: Field): void => {
                fieldset.withField(field);
            });
            fieldset.withLayout(FieldsetLayout.ThreeColumns);

            return fieldset.build();
        });

        const cardUpdateMessage: Ref<DynamicDictionary> = computed(() => {
            return {
                type: 'success',
                message: 'btar_key',
            };
        });

        const minDateForConfirmPopupWithCalendar: Ref<Date> = computed(() => {
            const minDate: moment.Moment = moment().add(1, 'days');

            return minDate.toDate();
        });

        const maxDateForConfirmPopupWithCalendar: Ref<Date> = computed(() => {
            const maxDate: moment.Moment = moment().add(1, 'days');
            const dateOffset: number = 2;
            maxDate.add(dateOffset, 'months');

            return maxDate.toDate();
        });

        const searchResult: Ref<string> = computed(() => {
            return receivedSearch.value;
        });

        function personDetailsChange(value: PersonDetails): void {
            console.debug('form.value', form.field('personDetails').value);
        }

        function personContactsChange(value: PersonContacts): void {
            console.debug('form.value', form.field('PersonContacts').value);
        }

        function personBankChange(value: PersonBankDetails): void {
            console.debug('form.value', form.field('personBankDetails').value);
        }

        const formValidPolicyHolder: Ref<string> = computed(() => {
            return form.field('personDetails').isValid ? 'valid' : 'invalid';
        });

        function indemnitySubmit(): void {
            BottomNotification.getInstance().pushNotification(BottomNotificationLevel.Info, 'Indemnity Submit Action');
        }

        function indemnityReceiverChange(value: PersonDetails): void {
            console.debug('form.value', form.field('indemnityReceiver').value);
        }

        const formValidIndemnityReceiver: Ref<string> = computed(() => {
            return form.field('indemnityReceiver').isValid ? 'valid' : 'invalid';
        });

        const firstUserPersonDetails: Ref<PersonDetails> = computed(() => {
            return {
                isAuthenticated: false,
                isPolicyHolder: true,
                isResident: false,
                name: 'My Name',
                surname: 'My Surname',
                personCode: '35806240226',
                country: {
                    iso: 'AF',
                    phoneCode: '93',
                    ic: 'AFG',
                },
                birthDate: 'Sun Nov 11 1990 00:00:00',
            };
        });

        const indemnityPersonDetails: Ref<IndemnityReceiverPersonDetails> = computed(() => {
            return {
                name: 'My Name',
                surname: 'My Surname',
                personCode: '35806240226',
                country: {
                    iso: 'EE',
                    phoneCode: '372',
                    code: 'EST',
                    name: '',
                },
                birthDate: '',
            };
        });

        function setupForm(): void {
            form.addField(new FormField('indemnityReceiver'));
            form.addField(new FormField('personDetails'));
            form.addField(new FormField('personContacts'));
            form.addField(new FormField('personBankDetails'));
            form.addField(new FormField('popupWideForm'));
            form.addField(new FormField('regions'));
            const reports: Record<string, boolean> = {
                'Test text 0': true,
                'Test text 1': true,
                'Test text 2': true,
                'Test text 3': true,
            };
            for (const key in reports) {
                inputOptionsCheckboxTest.value.push(
                    new InputOptionBuilder()
                        .setName(useTranslate().translateForType(key, ''))
                        .setValue(key)
                        .setCustom(reports[key])
                        .build(),
                );
            }
            form.addField(new FormField('input-options-checkbox-test'));
            form.addField(new FormField('input-options-smart-list'));
            form.addField(new FormField('input-options-smart-list-radio'));
            const currentTime =
                withLeadingZero(currentDate.getHours()) + ':' + withLeadingZero(currentDate.getMinutes());
            form.addField(
                new FormField(
                    'options-list-checkbox-1',
                    {
                        selected: '00001',
                        customText: { '00001': 'Test custom text 2' },
                    },
                    'required',
                ),
            );
            form.addField(
                new FormField(
                    'options-list-checkbox-2',
                    {
                        selected: '00003',
                        customText: { '00003': 'Test custom text 3' },
                    },
                    'required',
                ),
            );
            form.addField(
                new FormField(
                    'options-list-radio',
                    {
                        selected: '00003',
                        customText: { '00003': 'Test custom text radio' },
                    },
                    'required',
                ),
            );
            form.addField(new FormField('address-blocks'));
            form.addField(new FormField('product-panel-blocks'));
            form.addField(new FormField('input-time', '11:11'));
            form.addField(new FormField('input-text-1', 'Some text'));
            form.addField(new FormField('input-textarea-1', 'Some textarea text'));
            form.addField(
                new FormField('input-textarea-x', 'This text will never be overwritten by restore nor saved.'),
            );
            form.addField(new FormField('input-number-1', 3));
            form.addField(new FormField('input-checkbox-1', true));
            form.addField(new FormField('input-switch-1', true));
            form.addField(new FormField('input-radio-switch-1', '2'));
            form.addField(new FormField('input-date-with-calendar-1', currentDate));
            form.addField(new FormField('input-date-with-dropdown-1', currentDate));
            form.addField(new FormField('input-time-1', currentTime));
            form.addField(new FormField('input-time-2', ''));
            form.addField(new FormField('country-1', ''));
            form.addField(new FormField('country-2', ''));
            form.addField(new FormField('phone-with-country-1', { phone: '25555555', country: '371' }));
            form.addField(new FormField('input-plate-number-1', 'AB1234'));
            form.addField(new FormField('security-device-content'));
            form.addField(new FormField('input-multi-checkbox-1', ''));
            form.addField(new FormField('input-radio-1'));
            form.addField(new FormField('input-radio-2'));
            form.addField(new FormField('input-radio-3'));
            form.addField(new FormField('input-radio-icons'));
            form.addField(new FormField('input-radio-badge'));
            form.addField(new FormField('input-radio-digits'));
            form.addField(new FormField('input-radio-overlayed'));
            form.addField(new FormField('dev-address-apartment', '', 'required'));
            form.addField(new FormField('dev-address-postal', '', 'required'));
            form.addField(new FormField('dev-address-mixed', '', 'required'));
            form.addField(new FormField('input-bank', '', 'required'));
            form.addField(new FormField('input-company-type', '', 'required'));
            form.addField(new FormField('text-with-feedback', '', isValidEmail()));
            form.addField(new FormField('text-with-feedback-2', '', isValidEmail2()));
            form.addField(new FormField('text-with-feedback-disabled-err', '', isValidEmailDis()));
            form.addField(new FormField('property-area', '', isValidPropertyArea()));
            form.addField(new FormField('credit-card-details'));
            form.addField(new FormField('credit-card-update'));
            form.addField(new FormField('credit-card-update-add'));
            form.addField(new FormField('input-email'));
            form.addField(new FormField('input-bank-account'));
            form.addField(new FormField('input-map-place'));
            form.addField(new FormField('input-map-address'));
            form.addField(new FormField('input-both-icons'));
            form.addField(new FormField('smartRadio2'));
            form.addField(new FormField('smartRadio10'));
            form.addField(new FormField('badge-id-form'));
            form.addField(new FormField('input-vehicle-make'));
            form.addField(new FormField('input-date-dmy'));
            form.addField(new FormField('input-date-ymd'));
            form.addField(new FormField('dev-fieldset-list'));
            form.addField(new FormField('human-body-parts'));
            form.addField(new FormField('consents-list'));
            form.addField(new FormField('input-count'));
            form.setReady();
            formIsReady.value = true;
        }

        function tooltipComponentTemplateProgramName(params: DynamicDictionary): CellRendererTemplate {
            const templateHtml: string =
                '<span style="background-color: aquamarine">' + params.rowIndex + ' : @</span>';

            return new (class implements CellRendererTemplate {
                public template: string = templateHtml;
            })();
        }

        function cellTopPanelTooltipRenderer(): CellRendererTemplate {
            const templateHtml: string = '<div class="ag-one-tooltip-top-blue">@</div>';

            return new (class implements CellRendererTemplate {
                public template: string = templateHtml;
            })();
        }

        function eventCallbackReceiver(params: DynamicDictionary): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Success,
                'Received event: ' + params.type + ' value: ' + params.value,
            );
        }

        function dataChangeCallbackReceiver(rows: DynamicDictionary[]): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Warning,
                'Data Grid Content Is Changed',
            );
        }

        function changeFirstCellData(): void {
            (gridRowData.value[0] as DynamicDictionary).make = 'New Value';
        }

        function toggleSecondColumnEditable(): void {
            gridColumnDefs.value[1].editable = !gridColumnDefs.value[1].editable;
            if (gridColumnDefs.value[1].editable) {
                BottomNotification.getInstance().pushNotification(
                    BottomNotificationLevel.Info,
                    'You can edit 2nd column data now',
                );
            } else {
                BottomNotification.getInstance().pushNotification(
                    BottomNotificationLevel.Error,
                    "You can' edit 2nd column data now",
                );
            }
        }

        function caseBlockButtons(myId: string): ButtonWithCallbackParams[] {
            return ButtonsWithCallbackBuilder.getInstance()
                .withTitle('Select')
                .withBackgroundColor(ButtonBackground.White)
                .withBackgroundColorHover(ButtonBackground.Transparent)
                .withTextColor(ButtonTextColor.Black)
                .withCallback((params: DynamicDictionary): void => {
                    BottomNotification.getInstance().pushNotification(BottomNotificationLevel.Info, params.id);
                })
                .withCallbackParams({ id: myId })
                .build();
        }

        function onConfirmSubjects(value: DynamicDictionary[]): void {
            BottomNotification.getInstance().pushNotification(BottomNotificationLevel.Info, JSON.stringify(value));
        }

        function onGridSearchCallback(value: string): void {
            gridSearchTerm.value = value;
        }

        function toggleRightIcon(): void {
            rightIcon.cssClass.includes('grey')
                ? (rightIcon.cssClass = 'black-to-green')
                : (rightIcon.cssClass = 'black-to-grey');
        }

        function testCallbackFirst(): void {
            itemsListCallbackTestStringFirst.value = String(Math.random());
        }

        function testCallbackSecond(): void {
            const delay: number = 100;
            itemsListCallbackTestStringSecond.value = String(Math.round(Math.random() * delay));
        }

        function devFileUploadCallback(param: DynamicDictionary): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Warning,
                'file upload receiver: ' + JSON.stringify(param),
            );
        }

        function testButtonCallback(param: DynamicDictionary): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Warning,
                'callback works: ' + JSON.stringify(param),
            );
        }

        function testButtonCallback2(param: LimitedVariant): void {
            BottomNotification.getInstance().pushNotification(BottomNotificationLevel.Warning, JSON.stringify(param));
        }

        const onSelectClick = debounce(onDebouncedClick, customNumber.Hundred * customNumber.Ten);

        const onCustomDebounceClick: Function = debouncePromise(
            onDebouncedCustomClick,
            customNumber.Hundred * customNumber.Ten,
        );

        function thisWillBeTriggeredBeforeNewTabOpens(param: LimitedVariant): void {
            console.debug('Callback before open new tab');
        }

        function callbackOnEvent(): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Success,
                'callback on event works!',
            );
        }

        function onRegionChanged(value: DynamicDictionary): void {}

        function onRegionOptionsChange(options: InputOption[]): void {}

        function setTravelTargetUrlParams(param: string): void {
            travelTargetUrlParams.value = param;
        }

        function onCountriesSelection(value: CountriesWithRegion): void {}

        function fetchTravelTargetsLt(): void {
            useAxios()
                .get('/ajax/actions/travelTargets')
                .then((response: AxiosResponse<DynamicDictionary>) => {
                    travelTargets.value = response.data.data.body.targets;
                })
                .catch((reason: LimitedVariant) => {
                    console.debug(ErrorType.Error + '::fetchTravelTargetsLt', reason as DynamicDictionary);
                })
                .finally(() => {
                    fetchOnePolicies();
                });
        }

        function fetchOnePolicies(): void {
            RequestService.getInstance()
                .get({ uri: Url.Ajax.oneDayPolicies })
                .then((response: AxiosResponse): void => {
                    const axiosResponse: DynamicDictionary = response.data.data.body;
                    axiosResponse.policies.forEach((policyObject: DynamicDictionary, index: number) => {
                        const productBlockItemBuilder: ProductBlockItemBuilder = new ProductBlockItemBuilder();
                        const terms: DynamicDictionary = policyObject.paymentTerms[0];
                        const price: number = terms.prices[0].price;
                        const selectButtonText: string =
                            useTranslate().translate('one_day_select') +
                            ' ' +
                            useTranslate().translate('one_day_product_' + policyObject.id);
                        productBlockItemBuilder
                            .withProductId(policyObject.id)
                            .withProductName(useTranslate().translate('one_day_product_' + policyObject.id))
                            .withDiscountSum(price)
                            .withDiscountSumPostfix(useTranslate().translate('euro_per_day'))
                            //.withDynamicContent('AppProductBlockRisksInnerTable', terms.risks)
                            .withButtonSelectTitle(selectButtonText)
                            .withButtonCustomTitle(useTranslate().translate('see_what_covered'));
                        if (index === 0) {
                            productBlockItemBuilder
                                .withBadgeText('Test')
                                .withDiscountFullSum(price + customNumber.Nine, 'Save: @%');
                        }
                        products.value.push(productBlockItemBuilder.build());
                    });
                })
                .catch((reason: DynamicDictionary) => {
                    console.debug('fetchOnePolicies', reason);
                });
        }

        function onCustomClick(): void {
            onCustomDebounceClick()
                .then(() => {
                    console.debug('onCustomClick::then');
                })
                .catch((reason: DynamicDictionary) => {
                    console.debug('onCustomClick::catch', reason);
                })
                .finally(() => {
                    console.debug('onCustomClick::finally');
                });
        }

        function onDebouncedClick(params: DynamicDictionary): void {
            console.debug('on select:', params);
        }

        function onDebouncedCustomClick(params: DynamicDictionary): void {
            console.debug('on custom:', params);
        }

        function onInputOptionsSmartListRadioChange(param: any): void {
            console.debug(param);
        }

        function showResultMessage(isPopup: boolean = false): void {
            const resultMessage: ResultMessage = ResultMessage.getInstance();
            resultMessage
                .withTitle('Message successfully submitted!')
                .withDescription(
                    'Our team is on it and will get back to you shortly.<br>You can track the status of your message in the<br>My Communication section.',
                )
                .withModalMode(!isPopup)
                .withButtons(
                    ButtonsWithCallbackBuilder.getInstance()
                        .newButton()
                        .withTitle(useTranslate().translateForType('go_to_my_communications', 'communications'))
                        .withTextColor(ButtonTextColor.Black)
                        .withTextColorHover(ButtonTextColor.White)
                        .withBackgroundColor(ButtonBackground.White)
                        .withBackgroundColorHover(ButtonBackground.White)
                        .withCallback((params: DynamicDictionary): void => {
                            BottomNotification.getInstance().pushNotification(
                                BottomNotificationLevel.Success,
                                'Clicked and closed!',
                            );
                            ResultMessage.getInstance().hide();
                        })
                        .build(),
                )
                .show();
        }

        function makeInputOptions(): void {
            contactUsButtons.value = ContactUsButtonsBuilder.getInstance()
                .widthUrlButton('www.bta.lv', 'www.bta.lv')
                .widthMailButton('bta@bta.lv', 'bta@bta.lv')
                .widthPhoneButton('+371 26 12 12 12', '+37126121212')
                .build();

            infoWithActionsButtons.value = ButtonsWithCallbackBuilder.getInstance()
                .reset()
                .newButton()
                .withTitle('New communication')
                .withIcon(ButtonIcon.Edit)
                .withCallbackParams({ id: 'New communication' })
                .withCallback((params: DynamicDictionary): void => {
                    BottomNotification.getInstance().pushNotification(
                        BottomNotificationLevel.Success,
                        'Clicked: ' + params.id,
                    );
                })
                .newButton()
                .withTitle('Additional info')
                .withTextColor(ButtonTextColor.Black)
                .withTextColorHover(ButtonTextColor.White)
                .withBackgroundColor(ButtonBackground.White)
                .withBackgroundColorHover(ButtonBackground.White)
                .withCallbackParams({ id: 'Additional info' })
                .withCallback((params: DynamicDictionary): void => {
                    BottomNotification.getInstance().pushNotification(
                        BottomNotificationLevel.Success,
                        'Clicked: ' + params.id,
                    );
                })
                .newButton()
                .withTitle('Additional info')
                .withTextColor(ButtonTextColor.Black)
                .withTextColorHover(ButtonTextColor.White)
                .withBackgroundColor(ButtonBackground.White)
                .withBackgroundColorHover(ButtonBackground.White)
                .withCallbackParams({ id: 'Additional info' })
                .withCallback((params: DynamicDictionary): void => {
                    BottomNotification.getInstance().pushNotification(
                        BottomNotificationLevel.Success,
                        'Clicked: ' + params.id,
                    );
                })
                .build();

            const minDateYears: number = 5;
            const newInputOptions: { name: string; value: number; icon: string }[] = [
                { name: 'Option 1', value: 1, icon: InputRadioIcon.Car },
                { name: 'Option 2', value: 2, icon: InputRadioIcon.Motorcycle },
                { name: 'Option 3', value: 3, icon: InputRadioIcon.Truck },
                { name: 'Option 4', value: 4, icon: InputRadioIcon.Truck },
                { name: 'Option 5', value: 5, icon: InputRadioIcon.Truck },
                { name: 'Option 6', value: 6, icon: InputRadioIcon.Truck },
                { name: 'Option 7', value: 7, icon: InputRadioIcon.Truck },
                { name: 'Option 8', value: 8, icon: InputRadioIcon.Truck },
            ];
            inputOptions.value = newInputOptions.map((option: any) => {
                return new InputOptionBuilder().setValue(option.value).setName(option.name).build();
            });
            inputOptionsWithIcons.value = newInputOptions.map((option: any) => {
                return new InputOptionBuilder()
                    .setValue(option.value)
                    .setName(option.name)
                    .setIcon(option.icon)
                    .build();
            });
            const maxLongItems: number = 25;
            const program: string =
                'V4 Ambulance and stationary + Ambulance rehabilitation + Sport(12 times) + B4 MDrugs (50%, 75 EUR) + Dentist (50%, 300 EUR)';
            const popupWideInputOptions: { name: string; value: string }[] = [];
            for (let i: number = 0; i < maxLongItems; i++) {
                popupWideInputOptions.push({
                    name: program,
                    value: 'IC_' + i,
                });
            }
            popupWideOptions.value = popupWideInputOptions.map((option: any) => {
                return new InputOptionBuilder().setValue(option.value).setName(option.name).build();
            });
            const newInputOptionsBadge: { name: string; value: string }[] = [
                { name: 'Active', value: 'active' },
                { name: 'Inactive', value: 'inactive' },
            ];
            inputOptionsBadge.value = newInputOptionsBadge.map((option: any) => {
                return new InputOptionBuilder().setValue(option.value).setName(option.name).build();
            });
            const newInputOptionsBadgeDigits: { name: string; value: number; custom: number }[] = [
                { name: 'Foo Badge', value: 0, custom: 13 },
                { name: 'Baz Badge', value: 1, custom: 0 },
                { name: 'Bar Badge', value: 2, custom: 7 },
            ];
            inputOptionsBadgeDigits.value = newInputOptionsBadgeDigits.map((option: any) => {
                return new InputOptionBuilder()
                    .setValue(option.value)
                    .setName(option.name)
                    .setCustom(option.custom)
                    .build();
            });
            addressBlocksOptions.value = [
                new (class implements InputOption {
                    public name: string = 'Teikas gatve 13-2, Rīga, LV-1017, Latvija';
                    public value: string | number | boolean = '29217273122';
                    public custom: LimitedVariant = {
                        type: 'claims_property_house',
                        policy: 'LV21-B2-10010243-3',
                        insuredPersonFirstName: 'Test namē',
                        insuredPersonLastName: 'Test surnamē',
                        insuredPersonIdentityNumber: '070997-11437',
                    };
                })(),
                new (class implements InputOption {
                    public name: string = 'Jūrmalas gatve 16A-45, Rīga, LV-1083, Latvija';
                    public value: string | number | boolean = '39247951167';
                    public custom: LimitedVariant = {
                        type: 'claims_property_apartment',
                        policy: 'LV21-B2-10010243-3',
                        insuredPersonFirstName: 'Test namē',
                        insuredPersonLastName: 'Test surnamē',
                        insuredPersonIdentityNumber: '070997-11437',
                    };
                })(),
                new (class implements InputOption {
                    public name: string = 'Ogres gatve 12A-11, Rīga, LV-1045, Latvija';
                    public value: string | number | boolean = '68365466731';
                    public custom: LimitedVariant = {
                        type: 'claims_property_house',
                        policy: 'LV21-B2-10010243-3',
                        insuredPersonFirstName: 'Test namē',
                        insuredPersonLastName: 'Test surnamē',
                        insuredPersonIdentityNumber: '070997-11437',
                    };
                })(),
            ];
            inputOptionsSmartList.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Regular;
                    public value: string | number | boolean = '000';
                })(),
            );
            inputOptionsSmartList.value.push(
                new (class implements InputOption {
                    public name: string = 'with DISABLED: ' + OptionsSmartTypes.Regular;
                    public value: string | number | boolean = '001';
                    public disabled: boolean = true;
                })(),
            );
            inputOptionsSmartList.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Info;
                    public value: string | number | boolean = '002';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Info;
                        public initialValue: OptionsCustomValue =
                            'This risk is not covered by the selected policy.<br>Refunds cannot be claimed.';
                    })();
                    public tooltip: Tooltipster = new (class implements Tooltipster {
                        public description: string = 'Tooltip description';
                        public title: string = 'Tooltip title';
                    })();
                })(),
            );
            inputOptionsSmartList.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Text;
                    public value: string | number | boolean = '003';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Text;
                        public initialValue: OptionsCustomValue = '';
                        public placeholder: string = 'Please write additional info here...';
                        public required: boolean = true;
                    })();
                })(),
            );
            inputOptionsSmartList.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Textarea;
                    public value: string | number | boolean = '004';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Textarea;
                        public initialValue: OptionsCustomValue = 'Predefined value';
                        public placeholder: string = 'Please write additional info here...';
                    })();
                    public tooltip: Tooltipster = new (class implements Tooltipster {
                        public description: string = 'Tooltip description';
                        public title: string = 'Tooltip title';
                    })();
                })(),
            );
            inputOptionsSmartList.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Sum;
                    public value: string | number | boolean = '005';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Sum;
                        public initialValue: OptionsCustomValue = 1200;
                        public required: boolean = true;
                    })();
                })(),
            );
            inputOptionsSmartList.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Calendar;
                    public value: string | number | boolean = '006';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Calendar;
                        public initialValue: OptionsCustomValue = {
                            startDate: moment().subtract(2, 'days').toDate(),
                            endDate: moment().subtract(1, 'days').toDate(),
                        };
                        public required: boolean = true;
                        public binds: InputDateWithCalendarBinds = {
                            isRange: true,
                            placeholder: 'Select event date',
                            numberOfCalendars: 2,
                            numberOfDays: 364,
                            format: 'DD/MM/YYYY',
                            minDate: moment().subtract(minDateYears, 'years').toDate(),
                            maxDate: moment().toDate(),
                            returnRaw: false,
                            forcedRange: false,
                            startDate: new Date(),
                        };
                    })();
                })(),
            );

            inputOptionsSmartListRadio.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Regular;
                    public value: string | number | boolean = '000';
                })(),
            );
            inputOptionsSmartListRadio.value.push(
                new (class implements InputOption {
                    public name: string = 'with DISABLED: ' + OptionsSmartTypes.Regular;
                    public value: string | number | boolean = '001';
                    public disabled: boolean = true;
                })(),
            );
            inputOptionsSmartListRadio.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Info;
                    public value: string | number | boolean = '002';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Info;
                        public initialValue: OptionsCustomValue =
                            'This risk is not covered by the selected policy.<br>Refunds cannot be claimed.';
                        /* INFO:
                        this will be applied when item is selected
                        borderColor - color of selected border
                        isValid - make form valid/invalid for this selection
                        customComparator - do your compare and return your color and disabled status
                     */
                        public selectState: OptionsSmartCustomSelectState = new (class
                            implements OptionsSmartCustomSelectState
                        {
                            //public borderColor: OptionSmartColor = OptionSmartColor.Red;
                            //public isValid: boolean = false;
                            public customComparator: Function = (): OptionsSmartCustomSelectState => {
                                /* INFO: do your own custom check and return valid state
                                this will overwrite borderColor & isValid declared above so you can omit them
                            */
                                return new (class implements OptionsSmartCustomSelectState {
                                    public borderColor: OptionSmartColor = OptionSmartColor.Red;
                                    public isInvalid: boolean = true;
                                })();
                            };
                        })();
                    })();
                    public tooltip: Tooltipster = new (class implements Tooltipster {
                        public description: string = 'Tooltip description';
                        public title: string = 'Tooltip title';
                    })();
                })(),
            );
            inputOptionsSmartListRadio.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Text;
                    public value: string | number | boolean = '003';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Text;
                        public initialValue: OptionsCustomValue = '';
                        public placeholder: string = 'Please write additional info here...';
                        public required: boolean = true;
                        /* INFO:
                        this will be applied when item is selected
                        borderColor - color of selected border
                        isInvalid - make form valid/invalid for this selection
                     */
                        public selectState: OptionsSmartCustomSelectState = new (class
                            implements OptionsSmartCustomSelectState
                        {
                            public borderColor: OptionSmartColor = OptionSmartColor.Orange;
                            public isInvalid: boolean = false;
                        })();
                    })();
                })(),
            );
            inputOptionsSmartListRadio.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Textarea;
                    public value: string | number | boolean = '004';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Textarea;
                        public initialValue: OptionsCustomValue = 'Predefined value';
                        public placeholder: string = 'Please write additional info here...';
                    })();
                    public tooltip: Tooltipster = new (class implements Tooltipster {
                        public description: string = 'Tooltip description';
                        public title: string = 'Tooltip title';
                    })();
                })(),
            );
            inputOptionsSmartListRadio.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Sum;
                    public value: string | number | boolean = '005';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Sum;
                        public initialValue: OptionsCustomValue = 1200;
                        public required: boolean = true;
                    })();
                })(),
            );
            inputOptionsSmartListRadio.value.push(
                new (class implements InputOption {
                    public name: string = 'with: ' + OptionsSmartTypes.Calendar;
                    public value: string | number | boolean = '006';
                    public custom: OptionsSmartCustom = new (class implements OptionsSmartCustom {
                        public type: OptionsSmartTypes = OptionsSmartTypes.Calendar;
                        public initialValue: OptionsCustomValue = {
                            startDate: moment().subtract(2, 'days').toDate(),
                            endDate: moment().subtract(1, 'days').toDate(),
                        };
                        public required: boolean = true;
                        public binds: InputDateWithCalendarBinds = {
                            isRange: true,
                            placeholder: 'Select event date',
                            numberOfCalendars: 2,
                            numberOfDays: 364,
                            format: 'DD/MM/YYYY',
                            minDate: moment().subtract(minDateYears, 'years').toDate(),
                            maxDate: moment().toDate(),
                            returnRaw: false,
                            forcedRange: false,
                            startDate: new Date(),
                        };
                    })();
                })(),
            );
            const maxLoop: number = 4;
            for (let i: number = 0; i < maxLoop; i++) {
                optionsListOptions.value.push(
                    new (class implements InputOption {
                        public name: string = 'Option Text Description ' + i;
                        public value: string | number | boolean = '0000' + i;
                        public disabled: boolean = false;
                    })(),
                );
            }
            optionsListOptions.value[2].disabled = true;
            optionsListOptions.value[3].custom = true;
            smartOptions2.value.push(
                new (class implements InputOption {
                    public name: string = 'LV22-58-00000126-0';
                    public value: string | number | boolean = 'LV22-58-00000126-0';
                    public custom: LimitedVariant = {
                        postfix: '12/10/2022 — 12/10/2023',
                        modified: true,
                    };
                    public disabled: boolean = false;
                    public hidden: boolean = false;
                })(),
            );
            const maxLoop10: number = 10;
            const disabledSmartElement: number = 5;
            const hiddenSmartElement: number = 8;
            for (let i: number = 0; i < maxLoop10; i++) {
                const custom: DynamicDictionary = {
                    postfix: i === maxLoop10 - 1 ? '' : '12/10/2022 — 12/10/2023',
                    modified: i === 1,
                };
                smartOptions10.value.push(
                    new (class implements InputOption {
                        public name: string = 'LV22-58-00000126-' + i;
                        public value: string | number | boolean = 'LV22-58-00000126-' + i;
                        public custom?: LimitedVariant = custom;
                        public disabled: boolean = i === disabledSmartElement;
                        public hidden: boolean = i === hiddenSmartElement;
                    })(),
                );
            }
        }

        function onPopupWideChange(value: string): void {
            console.debug('onPopupWideChange', value);
        }

        function changeAction(): void {
            BottomNotification.getInstance().pushNotification(BottomNotificationLevel.Error, 'items button action');
        }

        function onTimerFormReset(): void {
            form.field('input-time-1').clear();
            form.field('input-time-2').clear();
        }

        function onAddressBlocksChanged(newValue: string, oldValue: string): void {}

        function onCustomActionPress(): void {}

        function securityDeviceConsentChange(value: boolean): void {
            console.debug('on:securityDeviceConsentChange(' + value + ')', form.field('security-device-content').value);
        }

        function isValidEmail(): object {
            return {
                isValidEmail: () => {
                    return Validation.isValidEmail(form.field('text-with-feedback').value);
                },
            };
        }

        function isValidEmail2(): object {
            return {
                isValidEmail: () => {
                    return Validation.isValidEmail(form.field('text-with-feedback-2').value);
                },
            };
        }

        function isValidEmailDis(): object {
            return {
                isValidEmail: () => {
                    return Validation.isValidEmail(form.field('text-with-feedback-disabled-err').value);
                },
            };
        }

        function isValidPropertyArea() {
            return {
                isValidEmail: () => {
                    return Validation.isValidEmail(form.field('property-area').value);
                },
            };
        }

        function saveForms(): Promise<void> {
            return btaBase.userStorage
                .saveFormsPromise()
                .then(() => {
                    return Promise.resolve();
                })
                .catch(() => {
                    btaBase.popup.applyErrorTitle('Error').applyErrorDetails('Form save failed');
                    PopupService.getInstance().show(new OnePopup().withType().error);
                });
        }

        function onShowDetails(id: string): void {
            BottomNotification.getInstance().pushNotification(BottomNotificationLevel.Success, 'onShowDetails: ' + id);
        }

        function onRefreshStatus(id: string): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Success,
                'onRefreshStatus: ' + id,
            );
        }

        function saveAndReload(): void {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            saveForms().then(() => {
                window.location.reload();
            });
        }

        function withLeadingZero(value: number): string {
            const lengthForPrepend: number = 10;

            return (value < lengthForPrepend ? '0' : '') + value;
        }

        function showSimpleErrorPopup(): void {
            const simpleErrorPopup: PopupBase = new OnePopup().withType().simpleError;
            btaBase.popupService.show(simpleErrorPopup);
        }

        function showCoveredMultiplePopup(): void {
            coveredPopup
                .withContent(coveredData())
                .withCoveredType(CoveredPopupTypes.CoveredPopupMultiple)
                .withCoveredTitle('title_multiple')
                .withExcludedRisks(['INSURED_ONE_DEVICE', 'INSURED_ALL_BELONGINGS'])
                .build();

            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        function showCoveredSinglePopup(): void {
            coveredPopup
                .withContent([coveredData()[0]])
                .withCoveredType(CoveredPopupTypes.CoveredPopupSingle)
                .withCoveredTitle('title_single')
                .build();

            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        function onEvent(): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Success,
                'An event was successfully triggered',
            );
        }

        function doProgressStepsState(): void {
            progressStepsState.value++;
            if (progressStepsState.value > 3) {
                progressStepsState.value = 0;
            }
        }

        const progressStepsStateNames: Ref<string[]> = ref([
            'Claim submitted',
            'Documents awaited',
            'In claim handling',
            'Decision done',
        ]);

        function showCommunicationModal(): void {
            twoWayModal.value = !twoWayModal.value;
            const tmpModal: TwoWayModal = TwoWayModal.getInstance();
            if (twoWayModal.value) {
                tmpModal.show();
            } else {
                tmpModal.hide();
            }
        }

        function closeCommunicationModal(): void {
            showCommunicationModal();
        }

        const isVisibleCloseCommModal: Ref<boolean> = computed(() => {
            return TwoWayModal.getInstance().isVisible.value;
        });

        function showMyPopup(): void {
            const confirmPopup: PopupBase = new OnePopup()
                .withType()
                .confirmPopup.withTitle('my title')
                .withDescription('my description')
                .withIcon(PopupIcon.AlertOctagon)
                .withConfirmCallback(onConfirmCallback)
                .withCancelCallback(onCancelCallback);
            btaBase.popupService.show(confirmPopup);
        }

        function showMyPopup2(): void {
            const confirmPopup: PopupBase = new OnePopup()
                .withType()
                .confirmPopup.withTitle('my title')
                .withDescription('my description')
                .withCancelButtonText('Custom cancel text')
                .withConfirmButtonText('Custom confirm text')
                .withIcon(PopupIcon.AlertTriangle)
                .withConfirmCallback(onConfirmCallback)
                .withCancelCallback(onCancelCallback);
            btaBase.popupService.show(confirmPopup);
        }

        function showMyPopup3(): void {
            const confirmPopup: PopupBase = new OnePopup()
                .withType()
                .confirmPopup.withTitle('Send card to whomever you please')
                .withConfirmButtonText('Send card link')
                .withConfirmButtonColor(ButtonBackground.Blue)
                .withIcon(PopupIcon.CardSend)
                .withInput(PopupInput.Email)
                .withConfirmCallback(onConfirmCallbackWithParam)
                .withCancelCallback(onCancelCallback);
            btaBase.popupService.show(confirmPopup);
        }

        function showMyPopup4(): void {
            const confirmPopup: PopupBase = new OnePopup()
                .withType()
                .confirmPopup.withTitle('Remove policy with date')
                .withConfirmButtonText('Confirm and remove')
                .withIcon(PopupIcon.AlertTriangle)
                .withInput(PopupInput.Calendar)
                .withMinDateForCalendarInput(minDateForConfirmPopupWithCalendar.value)
                .withMaxDateForCalendarInput(maxDateForConfirmPopupWithCalendar.value)
                .withConfirmCallback(onConfirmCallbackWithDateParam)
                .withCancelCallback(onCancelCallback);
            btaBase.popupService.show(confirmPopup);
        }

        function showOneConfirmPopup(): void {
            const confirmPopup: PopupBase = new OnePopup()
                .withType()
                .oneConfirm.withTitle('Money Payout')
                .withDescription(
                    'Please take a note that only the owner of vehicle can receive a money payout.<br /><br />According to the MTPL act, if you opt for  a money payout, BTA will pay 70% of the calculated amount.',
                )
                .withButtons([
                    {
                        title: 'Choose other option',
                        textColor: ButtonTextColor.Black,
                        backgroundColor: ButtonBackground.White,
                        backgroundColorHover: ButtonBackground.Transparent,
                        borderColor: ButtonBorder.Pale,
                        callback() {
                            console.debug('Choose other option');
                        },
                    },
                    {
                        title: 'Confirm',
                        textColor: ButtonTextColor.White,
                        backgroundColor: ButtonBackground.Red,
                        icon: ButtonIcon.LongArrowRight,
                        iconPosition: ButtonIconPosition.Right,
                        callback() {
                            console.debug('Confirm');
                        },
                    },
                ]);
            btaBase.popupService.show(confirmPopup);
        }

        function showOneConfirmPopup2(): void {
            const confirmPopup: PopupBase = new OnePopup()
                .withType()
                .oneConfirm.withTitle('No insured vehicle is found')
                .withDescription(
                    'Please check the data at https://services.ltab.lv/lv/checkocta\n<br /><br />or contact BTA via phone or email',
                )
                .withButtons([
                    {
                        title: 'Phone Call',
                        icon: ButtonIcon.Phone,
                        textColor: ButtonTextColor.White,
                        backgroundColor: ButtonBackground.Red,
                        callback() {
                            console.debug('Phone Call');
                        },
                    },
                    {
                        title: 'Mail',
                        icon: ButtonIcon.Mail,
                        textColor: ButtonTextColor.White,
                        backgroundColor: ButtonBackground.Red,
                        callback() {
                            console.debug('Mail');
                        },
                    },
                    {
                        title: 'Back',
                        textColor: ButtonTextColor.Black,
                        backgroundColor: ButtonBackground.White,
                        backgroundColorHover: ButtonBackground.Transparent,
                        borderColor: ButtonBorder.Pale,
                        icon: ButtonIcon.LongArrowLeft,
                        iconPosition: ButtonIconPosition.Left,
                        iconColor: ButtonIconColor.Black,
                        callback() {
                            console.debug('Back');
                        },
                    },
                ]);
            btaBase.popupService.show(confirmPopup);
        }

        function onConfirmCallbackWithParam(param: string): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Success,
                'You entered this value - ' + param,
            );
        }

        function onConfirmCallbackWithDateParam(param: DynamicDictionary): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Success,
                'You selected this date - ' + moment(param.startDate).format('DD.MM.YYYY'),
            );
        }

        function onConfirmCallback(): void {
            BottomNotification.getInstance().pushNotification(BottomNotificationLevel.Success, 'Popup confirmed!');
        }

        function onCancelCallback(): void {
            BottomNotification.getInstance().pushNotification(BottomNotificationLevel.Warning, 'Popup canceled!');
        }

        function onSearchReceive(data: DynamicDictionary): void {
            receivedSearch.value = JSON.stringify(data);
        }

        function onSearchCallback(data: string): void {
            receivedCallback.value = data;
        }

        function onCardWidgetAddSubmit(): void {
            console.debug('onCardWidgetAddSubmit');
        }

        function onMessageClick(id: string): void {
            BottomNotification.getInstance().pushNotification(
                BottomNotificationLevel.Success,
                'Message action clicked with id: ' + id,
            );
        }

        function showClaimDrawer(): void {
            const contentTabsBuilder: ClaimDetailsContentTabsBuilder = new ClaimDetailsContentTabsBuilder();
            const drawerOverviewBuilder: ClaimOverviewBuilder = new ClaimOverviewBuilder();
            const decisionBuilder: DecisionBuilder = new DecisionBuilder();
            const preFormattedText: string = TransferStateService.getInstance().get('preformatted');
            const documents: Attachment[] = [
                {
                    id: '0',
                    name: 'Health claim decision',
                    type: 'pdf',
                    downloadLink: 'some/url/to.pdf',
                },
                {
                    id: '1',
                    name: 'Other document',
                    type: 'pdf',
                    downloadLink: 'some/url/to.pdf',
                },
            ];
            decisionBuilder
                .withDecisionOpened()
                .withBlockTitle(useTranslate().translate('claim_decision'))
                .withBlockDescription(preFormattedText)
                .withDocumentTitle(useTranslate().translate('decision_documents'))
                .withDocuments(documents);
            drawerOverviewBuilder
                .withUploadBlockEnabled()
                .withUploadBlockPolicyId('policyId')
                .withUploadBlockPolicyNumber('policyNumber')
                .withUploadBlockUploaderPolicyType('policyType')
                .withUploadBlockUploaderClaimNumber('claimNumber')
                .withUploadBlockUploaderClaimId('claimId')
                .withDetailsBlockClaimType('claimType')
                .withDetailsBlockClaimNumber('claimNumber')
                .withDetailsBlockSubmitDate('submitDate')
                .withDetailsBlockPolicyNumber('policyNumber')
                .withDetailsBlockCompensationSum('sumApproved')
                .withDetailsBlockCompensationDate('compensationDate')
                .withDetailsBlockCloseDate('closeDate')
                .withStatusBlockEnabled()
                .withStatusBlockProgressSteps([
                    'Claim submitted',
                    'Documents awaited',
                    'In claim handling',
                    'Decision done',
                ])
                .withStatusBlockCurrentStep(1)
                .withDecisionBlockEnabled()
                .withDecisionBlockData([decisionBuilder.build()]);

            contentTabsBuilder
                .startNewTab(ClaimDetailsTabs.Overview)
                .withTitle(ClaimDetailsTabs.Overview)
                .withModule(drawerOverviewBuilder.build());

            const contentBuilder: ClaimDetailsContentBuilder = new ClaimDetailsContentBuilder();
            contentBuilder
                .withTranslationsType('')
                .withType(AgreementTypes.Casco)
                .withDescriptionTitle('Title')
                .withTabs(contentTabsBuilder.build())
                .withActiveTab(0);

            DrawerService.getInstance().withComponentContent(contentBuilder.build()).show();
        }

        function prepareDrawer(): void {
            const initialBadge: number = 1;
            const documentAmount: number = 22;
            const riskInsuredSum: number = 7500;
            const maxElements: number = 5;
            const uid: string = 'ICXX';
            const premium: number = 120;
            const insuredRisksBuilder: InsuredRisksBuilder = new InsuredRisksBuilder();
            for (let i: number = 0; i < maxElements * 2; i++) {
                insuredRisksBuilder
                    .startNewRisk()
                    .withRiskIc('IC-Y' + i)
                    .withRiskName('Risk name Y' + i)
                    .withRiskInsuredSum(riskInsuredSum);
            }
            const policyLimits: PolicyLimit[] = [
                {
                    id: 'basic',
                    type: 'insurance-limits',
                    name: 'Basic program',
                    insuranceGroup: 'basic',
                    sumLeft: 7466.22,
                    sumTotal: 7466.74,
                    currency: 'EUR',
                    description: [
                        "With a doctor's referral, hospital expenses are paid for without queuing the state-paid services queue. Payment is made for the stay, doctors’ consultations, examinations and a surgical operation, when such is required. As  well as expenses for an increased quality service room.",
                        'The amount compensated per a single case of treatment € 750 at contractual institutions of BTA, € 750 at non-contractual institutions.',
                    ],
                    includedLimitIds: [],
                    limits: [
                        {
                            id: '1',
                            type: 'insurance-limits',
                            name: 'Outpatient care',
                            insuranceGroup: '1',
                            currency: 'EUR',
                            description: [],
                            includedLimitIds: [],
                        },
                    ],
                },
                {
                    id: '6',
                    type: 'insurance-limits',
                    name: 'Outpatient rehabilitation',
                    insuranceGroup: '6',
                    sumLeft: 0.99,
                    sumTotal: 1,
                    currency: 'EUR',
                    description: [
                        'Expenses for medically indicated rehabilitation doctor and rehabilitation services indicated by the treating doctor are compensated. The health care service provider must have a corresponding license.',
                    ],
                    includedLimitIds: [],
                    limits: [],
                },
            ];
            const drawerOverviewBuilder: PolicyDetailsOverviewBuilder = new PolicyDetailsOverviewBuilder();
            drawerOverviewBuilder
                .withPaymentWidgetEnabled()
                .withPaymentWidgetInvoiceCount(2)
                .withPaymentWidgetAmountToPay('120.23')
                .withPaymentWidgetAdditionalButtonText('View invoice')
                .withRenewBlockEnabled()
                .withRenewBlockMessage(
                    useTranslate().translateForType('renew_policy_message', 'dashboard', {
                        '%policy%': 'TPL policy for Mercedes Benz E280 / KA1882',
                        '%expire%': '15.09.2022.',
                    }),
                )
                .withSubscriptionsBlockVisible()
                .withPolicyType(AgreementTypes.Mtpl)
                .withSubscriptionsBlockAddStateBadge(SubscriptionState.Active)
                .withSubscriptionsBlockAddStateBadge(SubscriptionState.EndingSoon)
                .withSubscriptionsBlockAddStateBadge(SubscriptionState.Inactive)
                .withSubscriptionsBlockAddStateBadge(SubscriptionState.Upcoming)
                .withSubscriptionsBlockPolicyId('SDB734852')
                .withSubscriptionsBlockInsuredPeriod('01.02.2023-25.02.2023')
                .withSubscriptionsBlockPayments(2)
                .withSubscriptionsBlockPremium(premium)
                //.withSubscriptionsBlockBrokerPayments() //DBG:...
                .withNoOverduePaymentsBlock()
                .withInsuredRisksBlock()
                .withInsuredRisks(insuredRisksBuilder.build())
                .withPolicyLimits(policyLimits)
                .withSubscriptionsBlockNewDocument('Policy', 'Printouts/subscription/TODO_policyID', 'PDF')
                .withSubscriptionsBlockNewDocument('What Insured', 'Printouts/subscription/TODO_whatInsuredID', 'PDF')
                .withSubscriptionsBlockNewDocument('Application', 'Printouts/subscription/TODO_applicationID', 'PDF')
                .withSubscriptionsBlockActionsBlockVisible();

            const drawerInvoicesBuilder: PolicyDetailsInvoicesBuilder = new PolicyDetailsInvoicesBuilder();
            drawerInvoicesBuilder.withLanguage('lv');
            for (let i: number = 0; i < maxElements * 3; i++) {
                drawerInvoicesBuilder
                    .startNewDocument()
                    .withDocumentId('IC' + i)
                    .withDocumentTitle('Mercedes Benz E280')
                    .withDocumentAgreement('SDB931056')
                    .withDocumentAmount(documentAmount)
                    .withDocumentDueTo('28.02.2023');
            }

            const drawerDocumentsBuilder: PolicyDetailsInvoicesBuilder = new PolicyDetailsInvoicesBuilder();
            drawerDocumentsBuilder.withLanguage('lv').withTitle('Documents').withShowZeroAmount(false);
            for (let i: number = 0; i < maxElements * 2; i++) {
                drawerDocumentsBuilder
                    .startNewDocument()
                    .withDocumentId('IC' + i)
                    .withDocumentAgreement('AAB931056')
                    .withDocumentTitle('Annex');
            }

            const drawerHistoryBuilder: PolicyDetailsInvoicesBuilder = new PolicyDetailsInvoicesBuilder();
            drawerHistoryBuilder
                .withLanguage('lv')
                .withUseBadge(false)
                .withTitle('Green card and Certificate history')
                .startNewDocumentBlock('Feb 2023')
                .startNewDocument()
                .withDocumentId(uid)
                .withDocumentAgreement('SDB931056')
                .withDocumentPolicyPeriod('01.02.2023-28.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocument()
                .withDocumentId(uid)
                .withDocumentAgreement('SDB931058')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocument()
                .withDocumentId(uid)
                .withDocumentAgreement('SDB931011')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocument()
                .withDocumentId(uid)
                .withDocumentAgreement('SDB931011')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocumentBlock('Jan 2023')
                .startNewDocument()
                .withDocumentId(uid)
                .withDocumentAgreement('SDB931011')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocumentBlock('Jan 2023')
                .startNewDocument()
                .withDocumentId(uid)
                .withDocumentAgreement('SDB931011')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocument()
                .withDocumentId(uid)
                .withDocumentAgreement('SDB931011')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocument()
                .withDocumentAgreement('SDB931011')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocumentBlock('Jan 2023')
                .startNewDocument()
                .withDocumentAgreement('SDB931011')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocument()
                .withDocumentAgreement('SDB931011')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocumentBlock('Jan 2023')
                .startNewDocument()
                .withDocumentAgreement('SDB931011')
                .withDocumentPolicyPeriod('01.02.2023-25.02.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocument()
                .withDocumentAgreement('SDB931033')
                .withDocumentPolicyPeriod('01.01.2023-25.01.2023')
                .withDocumentAmount(documentAmount)
                .startNewDocument()
                .withDocumentAgreement('SDB931033')
                .withDocumentPolicyPeriod('01.01.2023-25.01.2023')
                .withDocumentAmount(documentAmount);

            const drawerObjectsBuilder: PolicyDetailsObjectsBuilder = new PolicyDetailsObjectsBuilder();
            drawerObjectsBuilder
                .startNewProperty()
                .withPropertyName('Avotu iela 7-5, Limbaži')
                .startNewProperty()
                .withPropertyName('Kaivas iela 29 K-3-94, Rīga')
                .startNewProperty()
                .withPropertyName('Augusta Deglava iela 14-50, Rīga')
                .startNewProperty()
                .withPropertyName('Ogres iela 24-4, Rīga');

            const drawerPaymentMethodBuilder: PolicyDetailsPaymentMethodBuilder =
                new PolicyDetailsPaymentMethodBuilder();
            drawerPaymentMethodBuilder.withCardNumbers('1234').withCardStatus('INSUFFICIENT_FUNDS').build();

            const drawerPersonsBuilder: PolicyDetailsPersonsBuilder = new PolicyDetailsPersonsBuilder();
            drawerPersonsBuilder
                .startNewPerson()
                .withPersonName('Jevgēnijs')
                .withPersonSurname('Troickis')
                .withPersonProgram('Adult program D3')
                .withPersonRisks(insuredRisksBuilder.build())
                .startNewPerson()
                .withPersonName('Jeļena')
                .withPersonSurname('Troicka')
                .withPersonProgram('Adult program D3')
                .withPersonRisks(insuredRisksBuilder.build())
                .startNewPerson()
                .withPersonName('Jeļizaveta')
                .withPersonSurname('Troicka')
                .withPersonProgram('Adult program D3')
                .withPersonRisks(insuredRisksBuilder.build());

            const contentTabsBuilder: PolicyDetailsContentTabsBuilder = new PolicyDetailsContentTabsBuilder();
            contentTabsBuilder
                .startNewTab('overview')
                .withTitle('Overview')
                .withModule(drawerOverviewBuilder.build())
                .startNewTab('invoices')
                .withTitle('Invoices')
                .withModule(drawerInvoicesBuilder.build())
                .startNewTab('documents') //INFO: no params means id == String(index), '2' in this case
                .withTitle('Documents')
                .withModule(drawerDocumentsBuilder.build())
                .startNewTab('history')
                .withTitle('History')
                .withBadgeValue(initialBadge)
                .withModule(drawerHistoryBuilder.build())
                .startNewTab('objects')
                .withTitle('Objects')
                .withModule(drawerObjectsBuilder.build())
                .startNewTab()
                .withTitle('Card')
                .withModule(drawerPaymentMethodBuilder.build())
                .startNewTab('persons')
                .withTitle('Persons')
                .withModule(drawerPersonsBuilder.build());

            const contentBuilder: PolicyDetailsContentBuilder = new PolicyDetailsContentBuilder();
            contentBuilder
                .withTranslationsType('dashboard')
                .withType(AgreementTypes.Mtpl)
                .withTitle('MTPL')
                .withDescriptionTitle('Mercedes Benz E280')
                .withTabs(contentTabsBuilder.build())
                .withActiveTab(0);

            DrawerService.getInstance().withComponentContent(contentBuilder.build());
        }

        function coveredData(): MovableProperty[] {
            return [
                {
                    coveragePlans: [
                        {
                            insuredSum: 500,
                            price: 4.99,
                            risks: [
                                {
                                    id: 'ALL_RISKS',
                                    insuredSum: 500,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: false,
                                },
                                {
                                    id: 'INSURED_ONE_DEVICE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'FIRE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'LIQUID_LEAKAGE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'THIRD_PARTY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OUTSIDE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OTHER',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'DEDUCTIBLE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'TERRITORY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_ELECTRONIC_RISKS',
                                    insuredSum: 500,
                                    isAdditional: true,
                                    isFeatured: true,
                                    price: 1.99,
                                    withoutInsuredSum: true,
                                },
                            ],
                        },
                        {
                            insuredSum: 1000,
                            price: 6.99,
                            risks: [
                                {
                                    id: 'ALL_RISKS',
                                    insuredSum: 1000,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: false,
                                },
                                {
                                    id: 'INSURED_ONE_DEVICE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'FIRE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'LIQUID_LEAKAGE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'THIRD_PARTY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OUTSIDE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OTHER',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'DEDUCTIBLE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'TERRITORY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_ELECTRONIC_RISKS',
                                    insuredSum: 1000,
                                    isAdditional: true,
                                    isFeatured: true,
                                    price: 2.99,
                                    withoutInsuredSum: true,
                                },
                            ],
                        },
                        {
                            insuredSum: 1500,
                            price: 9.99,
                            risks: [
                                {
                                    id: 'ALL_RISKS',
                                    insuredSum: 1500,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: false,
                                },
                                {
                                    id: 'INSURED_ONE_DEVICE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'FIRE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'LIQUID_LEAKAGE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'THIRD_PARTY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OUTSIDE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OTHER',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'DEDUCTIBLE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'TERRITORY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_ELECTRONIC_RISKS',
                                    insuredSum: 1500,
                                    isAdditional: true,
                                    isFeatured: true,
                                    price: 3.99,
                                    withoutInsuredSum: true,
                                },
                            ],
                        },
                    ],
                    id: 'ONE_DEVICE_INSURANCE',
                    price: 4.99,
                    type: 'movable-properties',
                    params: {},
                },
                {
                    coveragePlans: [
                        {
                            insuredSum: 5000,
                            price: 7.99,
                            risks: [
                                {
                                    id: 'ALL_RISKS',
                                    insuredSum: 5000,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: false,
                                },
                                {
                                    id: 'INSURED_ALL_BELONGINGS',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'FIRE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'LIQUID_LEAKAGE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'THIRD_PARTY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OUTSIDE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OTHER',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'DEDUCTIBLE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'TERRITORY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_ELECTRONIC_RISKS',
                                    insuredSum: 5000,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_EXTENDED_WARRANTY',
                                    insuredSum: 1000,
                                    isAdditional: true,
                                    isFeatured: false,
                                    price: 2,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_AID_KIT',
                                    insuredSum: 350,
                                    isAdditional: true,
                                    isFeatured: false,
                                    price: 0.5,
                                    withoutInsuredSum: true,
                                },
                            ],
                        },
                        {
                            insuredSum: 10000,
                            price: 11.99,
                            risks: [
                                {
                                    id: 'ALL_RISKS',
                                    insuredSum: 10000,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: false,
                                },
                                {
                                    id: 'INSURED_ALL_BELONGINGS',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'FIRE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'LIQUID_LEAKAGE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'THIRD_PARTY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OUTSIDE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OTHER',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'DEDUCTIBLE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'TERRITORY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_ELECTRONIC_RISKS',
                                    insuredSum: 10000,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_EXTENDED_WARRANTY',
                                    insuredSum: 1000,
                                    isAdditional: true,
                                    isFeatured: false,
                                    price: 2,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_AID_KIT',
                                    insuredSum: 350,
                                    isAdditional: true,
                                    isFeatured: false,
                                    price: 0.5,
                                    withoutInsuredSum: true,
                                },
                            ],
                        },
                        {
                            insuredSum: 15000,
                            price: 14.99,
                            risks: [
                                {
                                    id: 'ALL_RISKS',
                                    insuredSum: 15000,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: false,
                                },
                                {
                                    id: 'INSURED_ALL_BELONGINGS',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'FIRE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'LIQUID_LEAKAGE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'THIRD_PARTY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OUTSIDE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'OTHER',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'DEDUCTIBLE',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: false,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'TERRITORY',
                                    insuredSum: 0,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_ELECTRONIC_RISKS',
                                    insuredSum: 15000,
                                    isAdditional: false,
                                    isFeatured: true,
                                    price: 0,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_EXTENDED_WARRANTY',
                                    insuredSum: 1000,
                                    isAdditional: true,
                                    isFeatured: false,
                                    price: 2,
                                    withoutInsuredSum: true,
                                },
                                {
                                    id: 'PD31_R_AID_KIT',
                                    insuredSum: 350,
                                    isAdditional: true,
                                    isFeatured: false,
                                    price: 0.5,
                                    withoutInsuredSum: true,
                                },
                            ],
                        },
                    ],
                    id: 'ALL_MOVABLE_PROPERTY',
                    price: 7.99,
                    type: 'movable-properties',
                    params: {},
                },
            ];
        }

        function showDrawer(): void {
            const longBadge: number = 131;
            //INFO: example access for drawer
            PolicyDetails.getInstance().policyTitle += '*';
            PolicyDetails.getInstance().policyType = AgreementTypes.Mtpl;
            PolicyDetails.getInstance().descriptionTitle += '*';
            PolicyDetails.getInstance().activateTab('overview');
            //PolicyDetails.getInstance().activateTab(2);
            PolicyDetails.getInstance().applyTabBadge('history', longBadge);
            (PolicyDetails.getInstance().tabContent('persons') as PolicyDetailsPersonsContent).persons[0].name += '*';
            DrawerService.getInstance().show();
        }

        function testFields(): DynamicDictionary {
            const dateForCalendar: Date = new Date();
            dateForCalendar.setHours(0, 0, 0, 0);
            dateForCalendar.setDate(dateForCalendar.getDate() + 1);

            return {
                'smartRadio10': {
                    patchValue: 'LV22-58-00000126-3',
                    resultValue: 'LV22-58-00000126-3',
                },
                'regions': {
                    patchValue: 'UGA',
                    resultValue: 'UGA',
                },
                'popupWideForm': {
                    patchValue: 'IC_0',
                    resultValue: 'IC_0',
                },
                'address-blocks': { finder: '', object: '39247951167' },
                'input-time': '12:13',
                'input-text-1': 'input-text-1-test',
                'input-textarea-1': 'input-textarea-1-test',
                'input-number-1': 13,
                'input-checkbox-1': false,
                'input-switch-1': false,
                'input-radio-switch-1': '1',
                'input-date-with-calendar-1': {
                    patchValue: dateForCalendar,
                    resultValue: {
                        endDate: '',
                        startDate: String(dateForCalendar),
                    },
                },
                'input-date-with-dropdown-1': {
                    patchValue: new Date('2002-11-11'),
                    resultValue: 'Mon Nov 11 2002 00:00:00 GMT+0200 (Eastern European Standard Time)',
                },
                'input-time-1': '10:13',
                'country-1': {
                    ic: 'AFG',
                    iso: 'AF',
                    phoneCode: '93',
                },
                'country-2': {
                    ic: 'DZA',
                    iso: 'DZ',
                    phoneCode: '213',
                },
                'phone-with-country-1': {
                    patchValue: {
                        phone: '29123456',
                        country: '371',
                    },
                    resultValue: {
                        code: undefined,
                        country: '371',
                        fixed: '',
                        mobile: '29123456',
                        phone: '29123456',
                    },
                },
                'input-plate-number-1': 'TT1313',
                'security-device-content': true,
                'input-radio-1': 1,
                'input-radio-2': 2,
                'input-radio-3': 3,
                'input-radio-badge': 'active',
                'input-radio-digits': 0,
                'dev-address-apartment': {
                    addressCode: '18071.100002.100075.100861.611275',
                    apartment: '13',
                    countryId: '',
                    detailed: '',
                    label: 'Lauka-Tooma, Hara küla, Kuusalu vald, Harju maakond',
                    postCode: '74810',
                    value: '611275',
                },
                'dev-address-postal': {
                    addressCode: '18071.6761104064.6761104257.6761178197.4876313028',
                    apartment: '',
                    countryId: '',
                    detailed: '',
                    label: 'Vilna, Toolse küla, Haljala vald, Lääne-Viru maakond',
                    postCode: '45443',
                    value: '4876313028',
                },
                'dev-address-mixed': {
                    addressCode: '18071.6761104019.6761104372.6761183543.654174',
                    apartment: '15',
                    countryId: '',
                    detailed: '',
                    label: 'Rippina, Lutike küla, Otepää vald, Valga maakond',
                    postCode: '67504',
                    value: '654174',
                },
                'input-bank': {
                    id: 4,
                    name: 'DNB Pank AS',
                    registrationNumber: '11315936',
                    typeIc: 'AS',
                },
                'input-company-type': {
                    iso: 'KÜ',
                    name: 'Korteriühistu',
                },
                'text-with-feedback': 'test13@test.tt',
                'credit-card-details': {
                    patchValue: {
                        cvc: '123',
                        holderName: 'tstname',
                        number: '4314229999999913',
                        validThru: {
                            month: 6,
                            year: 2024,
                        },
                    },
                    resultValue: {
                        cvc: '',
                        holderName: '',
                        number: '',
                        validThru: '',
                    },
                },
                'input-email': 'test@test13.tt',
            };
        }

        function applyValuesForTest(): void {
            formIsRestored.value = false;
            const testFormFields: DynamicDictionary = testFields();
            for (const o in testFormFields) {
                useDefine().isSet(testFormFields[o].patchValue)
                    ? form.field(o).patch(testFormFields[o].patchValue)
                    : form.field(o).patch(testFormFields[o]);
            }
            nextTick(() => {
                saveAndReload();
            });
        }

        function objectsNotEqual(expected: DynamicDictionary, actual: DynamicDictionary): boolean {
            let result: boolean = false;
            for (const o in expected) {
                if (typeof expected[o] === 'object') {
                    if (objectsNotEqual(expected[o], actual[o])) {
                        result = true;
                    }
                } else if (expected[o] !== actual[o]) {
                    result = true;
                }
            }

            return result;
        }

        function checkRestoredValueForTest(): string {
            const result: string[] = [];
            const testFormFields: DynamicDictionary = testFields();
            for (const o in testFormFields) {
                const isDynamicValue: boolean = useDefine().isSet(testFormFields[o].patchValue);
                const expectedValue: DynamicDictionary = isDynamicValue
                    ? testFormFields[o].resultValue
                    : testFormFields[o];
                const actualValue: DynamicDictionary = form.field(o).value;
                switch (typeof expectedValue) {
                    case 'object':
                        if (objectsNotEqual(expectedValue, actualValue)) {
                            result.push(o + ' string[' + expectedValue + ':' + String(actualValue) + ']');
                        }
                        break;
                    case 'string':
                        if (expectedValue !== String(actualValue)) {
                            result.push(o + ' string[' + expectedValue + ':' + String(actualValue) + ']');
                        }
                        break;
                    case 'number':
                        if (expectedValue !== Number(actualValue)) {
                            result.push(o + ' number[' + expectedValue + ':' + Number(actualValue) + ']');
                        }
                        break;
                    case 'boolean':
                        if (expectedValue !== Boolean(actualValue)) {
                            result.push(o + ' boolean[' + expectedValue + ':' + Boolean(actualValue) + ']');
                        }
                        break;
                    default:
                        result.push('Unknown type for field: ' + o + typeof expectedValue);
                }
            }

            return result.join(', ');
        }

        function claimButtons(): ButtonWithCallbackParams[] {
            return new ButtonsWithCallbackBuilder()
                .newButton()
                .withTitle('View Details')
                .withBackgroundColor(ButtonBackground.Red)
                .withBackgroundColorHover(ButtonBackground.Red)
                .withTextColor(ButtonTextColor.White)
                .withCallback((params: DynamicDictionary): void => {
                    BottomNotification.getInstance().pushNotification(
                        BottomNotificationLevel.Success,
                        'Clicked: ' + params.id,
                    );
                })
                .withCallbackParams({ id: 'View Details' })
                .newButton()
                .withTitle('Select')
                .withBackgroundColor(ButtonBackground.White)
                .withBackgroundColorHover(ButtonBackground.Transparent)
                .withTextColor(ButtonTextColor.Black)
                .withCallback((params: DynamicDictionary): void => {
                    BottomNotification.getInstance().pushNotification(
                        BottomNotificationLevel.Success,
                        'Clicked: ' + params.id,
                    );
                })
                .withCallbackParams({ id: 'Selected' })
                .build();
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                customNumber,
                currentDate,
                loadMoreListItems,
                itemsListContent,
                addressBlocksEventDate,
                itemsListCallbackTestStringFirst,
                itemsListCallbackTestStringSecond,
                regionsMessage,
                closeRegionsMessage,
                coveredPopup,
                gridInputOptions,
                securityDevices,
                inputOptions,
                inputOptionsWithIcons,
                popupWideOptions,
                inputOptionsBadge,
                inputOptionsBadgeDigits,
                agreementPayment,
                insurance,
                claims,
                policy,
                addressBlocksOptions,
                optionsListOptions,
                inputOptionsSmartList,
                inputOptionsSmartListRadio,
                inputOptionsCheckboxTest,
                formIsRestored,
                isVisibleBlock,
                travelTargetUrlParams,
                rightIcon,
                travelTargets,
                smartOptions2,
                smartOptions10,
                receivedSearch,
                receivedCallback,
                gridSearchTerm,
                gridColumnDefs,
                gridRowData,
                products,
                setupForm,
                tooltipComponentTemplateProgramName,
                cellTopPanelTooltipRenderer,
                eventCallbackReceiver,
                dataChangeCallbackReceiver,
                changeFirstCellData,
                toggleSecondColumnEditable,
                onConfirmSubjects,
                onGridSearchCallback,
                toggleRightIcon,
                inputIconRight,
                listItems,
                statusError,
                statusValid,
                itemsListWithIconsCallbackTextFirst,
                itemsListWithIconsCallbackTextSecond,
                travelTargetCustomUrl,
                travelTargetValue,
                buttonParams1,
                buttonParamsDrawer,
                buttonParamsModal,
                buttonParams2,
                buttonParams3,
                messageAttachments,
                subjectListValue,
                devFieldset,
                cardUpdateMessage,
                minDateForConfirmPopupWithCalendar,
                maxDateForConfirmPopupWithCalendar,
                searchResult,
                devFileUploadCallback,
                testButtonCallback,
                testButtonCallback2,
                onSelectClick,
                onCustomDebounceClick,
                thisWillBeTriggeredBeforeNewTabOpens,
                callbackOnEvent,
                onRegionChanged,
                onRegionOptionsChange,
                setTravelTargetUrlParams,
                onCountriesSelection,
                fetchTravelTargetsLt,
                fetchOnePolicies,
                onCustomClick,
                onDebouncedClick,
                onDebouncedCustomClick,
                onInputOptionsSmartListRadioChange,
                makeInputOptions,
                onPopupWideChange,
                changeAction,
                onTimerFormReset,
                onAddressBlocksChanged,
                onCustomActionPress,
                isVisibleCloseCommModal,
                securityDeviceConsentChange,
                isValidEmail,
                isValidEmail2,
                isValidEmailDis,
                isValidPropertyArea,
                saveForms,
                onShowDetails,
                onRefreshStatus,
                saveAndReload,
                withLeadingZero,
                showSimpleErrorPopup,
                progressStepsState,
                showCoveredMultiplePopup,
                showCoveredSinglePopup,
                doProgressStepsState,
                onEvent,
                showCommunicationModal,
                progressStepsStateNames,
                showMyPopup,
                showMyPopup2,
                showMyPopup3,
                showMyPopup4,
                showOneConfirmPopup,
                showOneConfirmPopup2,
                onConfirmCallbackWithParam,
                onConfirmCallbackWithDateParam,
                onConfirmCallback,
                onCancelCallback,
                onSearchReceive,
                onSearchCallback,
                onCardWidgetAddSubmit,
                onMessageClick,
                closeCommunicationModal,
                prepareDrawer,
                showClaimDrawer,
                coveredData,
                showDrawer,
                gridEventCallback,
                gridDataChangeCallback,
                showResultMessage,
                infoWithActionsButtons,
                contactUsButtons,
                testFields,
                applyValuesForTest,
                objectsNotEqual,
                checkRestoredValueForTest,
                claimButtons,
                files,
                caseBlockButtons,
                personDetailsChange,
                firstUserPersonDetails,
                formValidPolicyHolder,
                indemnityReceiverChange,
                indemnityPersonDetails,
                formValidIndemnityReceiver,
                indemnitySubmit,
                personContactsChange,
                personBankChange,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);

        this.securityDevices.push(
            new (class implements SecurityDevice {
                public description: string = "Ignition keys with manufacturer's immobilizer";
                public deviceIc: string = '2';
                public quantity: string = '2';
            })(),
        );
        this.makeInputOptions();
        this.setupForm();
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe(() => {
            this.formIsRestored = true;
            this.fetchTravelTargetsLt();
            onAppIsPreparedAndReady.unsubscribe();
        });
        const onAfterFormRestoredSubscription: Subscription = this.userStorage.onFormStorageDataIsReady.subscribe(
            (value: number) => {
                this.isVisibleBlock = true;
                onAfterFormRestoredSubscription.unsubscribe();
            },
        );

        this.prepareDrawer();
    },
});
</script>
