import VueApp from '@/Core/Routing/VueApp';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import { App } from 'vue';
import Router from '@/Core/Routing/Router';
import { createPinia } from 'pinia';
import { ComponentPublicInstance } from 'vue';
import GlobalDirectives from '@/Directives/Directives';
import AppComponent from '@/Apps/Renters/App.vue';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/Routing/RouteStepper/RouteStepper.vue';
import InsurancePage from '@/Apps/Renters/Pages/RentersInsurancePage.vue';
import CoveragePage from '@/Apps/Renters/Pages/RentersCoveragePage.vue';
import AddressPage from '@/Apps/Renters/Pages/RentersAddressPage.vue';
import SummaryAndPaymentPage from '@/Apps/Renters/Pages/RentersSummaryAndPaymentPage.vue';
import OptionsPage from '@/Apps/Renters/Pages/RentersOptionsPage.vue';
import CoveredPopupRentersMulti from '@/Components/Popups/CoveredPopup/CoveredPopupRentersMulti.vue';
import RentersMini from '@/Components/Other/ProductBlock/Components/MiniPlans/RentersMini.vue';
import RentersOption from '@/Components/Lists/AdditionalOptionsList/Components/RentersOption.vue';
import ProductBlockInnerRentersPolicy from '@/Components/Other/ProductBlock/Components/ProductBlockInnerRentersPolicy.vue';
import CoveredPopupRentersSingle from '@/Components/Popups/CoveredPopup/CoveredPopupRentersSingle.vue';
import LanguageSwitcher from '@/Components/Widgets/LanguageSwitcher/LanguageSwitcher.vue';
import Popup from '@/Components/Popups/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import ContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import InputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';
import InputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import InputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import CustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import ProductBlock from '@/Components/Other/ProductBlock/ProductBlock.vue';
import InputMultiCheckbox from '@/Components/Inputs/InputMultiCheckbox/InputMultiCheckbox.vue';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import PersonListWithSearch from '@/Components/Lists/PersonListWithSearch/PersonListWithSearch.vue';
import InputText from '@/Components/Inputs/InputText/InputText.vue';
import PhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import InputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import InputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import InputDateWithDropdown from '@/Components/Inputs/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputNumber from '@/Components/Inputs/InputNumber/InputNumber.vue';
import FilesUploader from '@/Components/Files/FilesUploader/FilesUploader.vue';
import Drawer from '@/Components/Modals/Drawer/Drawer.vue';
import TextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';
import InputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import AdditionalOptionsList from '@/Components/Lists/AdditionalOptionsList/AdditionalOptionsList.vue';
import InsurancePlanWidget from '@/Components/Widgets/InsurancePlanWidget/InsurancePlanWidget.vue';
import AddressFinder from '@/Components/Inputs/AddressFinder/AddressFinder.vue';
import InputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import InputIdentityNumber from '@/Components/Inputs/InputIdentityNumber/InputIdentityNumber.vue';
import InputDate from '@/Components/Inputs/InputDate/InputDate.vue';
import PopupOpener from '@/Components/Popups/PopupOpener/PopupOpener.vue';
import PaymentMethods from '@/Components/Widgets/PaymentMethods/PaymentMethods.vue';
import ConsentsList from '@/Components/Lists/ConsentsList/ConsentsList.vue';
import SelectAccountDropdown from '@/Components/Widgets/SelectAccountDropdown/SelectAccountDropdown.vue';
import Badge from '@/Components/Buttons/Badge/Badge.vue';
import ItemsListWithIcons from '@/Components/Lists/ItemsListWithIcons/ItemsListWithIcons.vue';
import ProductLayout from '@/Layouts/ProductLayout.vue';
import CommunicationNotificator from '@/Components/TwoWayCommunication/CommunicationNotificator/CommunicationNotificator.vue';

const pages: Components = {
    RentersInsurancePage: InsurancePage,
    RentersCoveragePage: CoveragePage,
    RentersOptionsPage: OptionsPage,
    RentersAddressPage: AddressPage,
    RentersSummaryAndPaymentPage: SummaryAndPaymentPage,
};

const components: Components = {
    AppProductLayout: ProductLayout,
    AppRouteStepper: RouteStepper,
    AppProductBlockInnerRentersPolicy: ProductBlockInnerRentersPolicy,
    AppRentersMini: RentersMini,
    AppRentersOption: RentersOption,
    AppCoveredPopupRentersMulti: CoveredPopupRentersMulti,
    AppCoveredPopupRentersSingle: CoveredPopupRentersSingle,
    AppLanguageSwitcher: LanguageSwitcher,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppContentLoader: ContentLoader,
    AppInputRadio: InputRadio,
    AppInputEmail: InputEmail,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppCustomForm: CustomForm,
    AppProductBlock: ProductBlock,
    AppInputMultiCheckbox: InputMultiCheckbox,
    AppButtonWithCallback: ButtonWithCallback,
    AppPersonListWithSearch: PersonListWithSearch,
    AppInputText: InputText,
    AppPhoneWithCountry: PhoneWithCountry,
    AppInputCheckbox: InputCheckbox,
    AppInputSelect: InputSelect,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputNumber: InputNumber,
    AppFilesUploader: FilesUploader,
    AppDrawer: Drawer,
    AppTextWithTip: TextWithTip,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppAdditionalOptionsList: AdditionalOptionsList,
    AppInsurancePlanWidget: InsurancePlanWidget,
    AppAddressFinder: AddressFinder,
    AppInputCountry: InputCountry,
    AppInputIdentityNumber: InputIdentityNumber,
    AppInputDate: InputDate,
    AppPopupOpener: PopupOpener,
    AppConsentsList: ConsentsList,
    AppPaymentMethods: PaymentMethods,
    AppSelectAccountDropdown: SelectAccountDropdown,
    AppBadge: Badge,
    AppItemsListWithIcons: ItemsListWithIcons,
    AppCommunicationNotificator: CommunicationNotificator,
};

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({ ...pages, ...components })
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());
        app.use(createPinia());
        return app.mount(selector);
    }
}
