<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import { onMounted, PropType } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import MovablePropertyOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/MovablePropertyOptionInterface';
import MovablePropertyOptionEmitters from '@/Components/Lists/AdditionalOptionsList/Enums/MovablePropertyOptionEmitters';
import AppInputSwitch from '@/Components/Inputs/InputSwitch/InputSwitch.vue';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/Interfaces/image.tag.attributes';
import { usePrice } from '@/Composables/Price';

const props = defineProps({
    formField: { type: FormField, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    option: {
        type: Object as PropType<MovablePropertyOption>,
        default: () => {},
    },
});
const translationType: string = 'items_and_belongings';
const { translateForType } = useTranslate();
const { sparse } = usePrice();
const emit = defineEmits([MovablePropertyOptionEmitters.Toggle]);

onMounted((): void => {
    emit(MovablePropertyOptionEmitters.Toggle, {
        [props.option.risk.id]: props.option.enabled.value,
    });
});

function onAdditionalOptionChange(optionState: boolean): void {
    emit(MovablePropertyOptionEmitters.Toggle, {
        [props.option.risk.id]: optionState,
    });
}

function optionSubtitle(option: MovablePropertyOption): string {
    let result: string[] = [translateForType(option.risk.id + '_SWITCHOFF', translationType)];
    if (option.enabled.value) {
        result = [useHtml().imgTag(successIconAttributes())];
        if (option.risk.price > 0) {
            result.push(
                [
                    translateForType('included_for_price', translationType, {
                        price: String(sparse(option.risk.price, false)),
                    }),
                    translateForType('payment_frequency', translationType),
                ].join(''),
            );
        } else {
            result.push(translateForType('included_for_free', translationType));
        }
    }

    return result.join('&nbsp;');
}

function successIconAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    };
}
</script>

<template>
    <div
        class="movable-property-option"
        :class="{ ...formField.classes(), enabled: option.enabled.value }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div class="title flex column">
            <div class="switch flex space-between align-center">
                {{ option.name }}
                <app-input-switch
                    v-if="option.risk.price > 0"
                    :class="{ off: !option.enabled.value }"
                    :form-field="option.enabled"
                    :data-store-disabled="true"
                    @change="onAdditionalOptionChange($event)"
                ></app-input-switch>
            </div>
            <div class="subtitle" v-html="optionSubtitle(option)"></div>
        </div>
        <div class="details flex column">
            <div v-if="option.risk.price > 0 && !option.enabled.value" class="price flex space-between align-center">
                {{ translateForType('price', translationType) }}
                <span class="green">
                    <span class="amount">{{ '+&nbsp;' + sparse(option.risk.price, false) }}</span>
                    <span class="amount-text" v-html="option.paymentFrequency"></span>
                </span>
            </div>
            <hr />
            <div class="coverage flex space-between">
                {{ translateForType('coverage_amount', translationType) }}
                <span class="amount">{{ sparse(option.risk.insuredSum, true) }} &euro;</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.movable-property-option {
    background: var(--white);
    width: 100%;
    border-radius: var(--size-pico);
    padding: var(--size-small);
    border: 2px solid transparent;

    &.enabled {
        border-color: var(--component-color-border-active);
        background: linear-gradient(0deg, var(--system-color-success-light) 0%, var(--system-color-success-light) 100%),
            var(--white);

        .subtitle {
            color: var(--text-color-link);
        }
    }

    .title {
        gap: var(--size-pico);

        .switch {
            height: 22px;
            font-size: var(--font-size-small);
            font-weight: 700;
        }

        .subtitle {
            font-size: var(--font-size-nano);
        }
    }

    .details {
        gap: var(--size-tiny);
        margin-top: var(--size-small);
        font-size: var(--font-size-nano);

        .price {
            font-size: var(--font-size-nano);

            .amount {
                font-size: var(--font-size-medium);
                font-weight: 700;
            }

            .amount-text {
                font-size: var(--font-size-tiny);
            }
        }

        .coverage {
            .amount {
                font-size: var(--font-size-tiny);
                font-weight: 700;
            }
        }
    }

    .green {
        color: var(--text-color-link);
    }

    .flex {
        display: flex;

        &.column {
            flex-direction: column;
        }

        &.space-between {
            justify-content: space-between;
        }

        &.align-center {
            align-items: center;
        }
    }

    @include respond-above('sm') {
        width: 440px;
    }

    hr {
        border-top: 1px solid var(--black-100);
    }
}
</style>
