<script setup lang="ts">
import ResultMessage from '@/Components/Other/ResultMessage/Services/ResultMessageService';
import ResultMessageType from '@/Components/Other/ResultMessage/Enums/ResultMessageTypeEnum';

const resultMessage: ResultMessage = ResultMessage.getInstance();
</script>

<template>
    <div class="result-message">
        <div v-if="resultMessage.isVisible.value" class="result-message-container">
            <aside class="overlay" :class="{ 'popup-mode': resultMessage.isModal() }"></aside>
            <div class="container">
                <img
                    v-if="resultMessage.content().type === ResultMessageType.Success"
                    class="image"
                    src="images/one/success--icon--big.svg"
                    alt=""
                    width="60"
                    height="60"
                />
                <img
                    v-if="resultMessage.content().type === ResultMessageType.Warning"
                    class="image"
                    src="images/one/warning--icon--big.svg"
                    alt=""
                    width="60"
                    height="60"
                />
                <img
                    v-if="resultMessage.content().type === ResultMessageType.Error"
                    class="image"
                    src="images/one/error--icon--big.svg"
                    alt=""
                    width="60"
                    height="60"
                />
                <div class="wrapper">
                    <div v-if="resultMessage.content().title !== ''" class="title">
                        {{ resultMessage.content().title }}
                    </div>
                    <div
                        v-if="resultMessage.content().description !== ''"
                        class="description"
                        v-html="resultMessage.content().description"
                    ></div>
                </div>
                <div class="buttons">
                    <app-button-with-callback
                        v-for="(button, index) in resultMessage.content().buttons"
                        :key="index"
                        v-bind="button"
                    >
                    </app-button-with-callback>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.result-message {
    .result-message-container {
        position: fixed;
        width: 100%;
        height: calc(100% - 80px);
        top: 80px;
        left: 0;
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: center;

        .overlay {
            position: absolute;
            inset: 0;
            z-index: 1;
            background: var(--brand-black);
            opacity: 0.24;

            &.popup-mode {
                background-color: var(--background-light);
                opacity: 1;
            }

            @include respond-below('xs') {
                background-color: var(--background-light);
                opacity: 1;
            }
        }

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: auto;
            max-width: 520px;
            min-height: 400px;
            background-color: var(--background-light);
            position: relative;
            z-index: 1;
            padding: var(--size-small);
            text-align: center;
            border-radius: var(--size-tiny);

            @include respond-above('sm') {
                padding: 60px;
            }

            .image {
                margin-bottom: var(--size-normal);
            }

            .wrapper {
                display: inline-block;

                @include respond-above('sm') {
                    width: min-content;
                }

                .title {
                    max-width: 100%;
                    font-size: var(--font-size-medium);
                    font-weight: 700;
                    margin-bottom: var(--size-small);
                    line-height: 28.8px;

                    @include respond-above('xs') {
                        text-wrap: nowrap;
                    }
                }

                .description {
                    max-width: 100%;
                    font-size: var(--font-size-tiny);
                    color: var(--text-color-subtle);
                    margin-bottom: var(--size-big);
                    line-height: 22.4px;
                    word-wrap: break-word;
                }
            }

            .buttons {
                display: flex;
                gap: var(--size-small);
                flex-direction: column;
                align-items: center;
                margin: 0 var(--size-small);
                width: 100%;

                .button-with-callback {
                    font-weight: 600;
                    max-width: 335px;
                    min-width: 286px;
                    min-height: 52px;
                    width: 100%;

                    @include respond-above('sm') {
                        width: 250px;
                        min-width: 250px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                @include respond-below('sm') {
                    flex-direction: column;
                }
            }
        }
    }
}
</style>
