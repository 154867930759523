import RecipientApp from '@/Pages/ClaimsProperty/Recipient/Recipient.vue';
import Validation from '@/Services/validation.service';

export default class RecipientValidators {
    private context!: Readonly<InstanceType<typeof RecipientApp>>;

    public addContext(context: Readonly<InstanceType<typeof RecipientApp>>): void {
        this.context = context;
    }

    public birthDateValidator(): object {
        return {
            validBirthDate: () => {
                return this.app().isNonResident && this.app().form.field('beneficiaryBirthDate').isTouched
                    ? this.app().form.field('beneficiaryBirthDate').value !== ''
                    : true;
            },
        };
    }

    public companyNameValidator(): object {
        return {
            validCompanyName: () => {
                return this.app().isLegalPerson && this.app().form.field('beneficiaryCompanyName').isTouched
                    ? this.app().form.field('beneficiaryCompanyName').value !== ''
                    : true;
            },
        };
    }

    public companyTypeValidator(): object {
        return {
            validCompanyType: () => {
                return this.app().isLegalPerson && !this.app().form.field('beneficiaryCompanyType').isEmpty()
                    ? this.app().form.field('beneficiaryCompanyType').value !== ''
                    : true;
            },
        };
    }

    public emailValidator(): object {
        return {
            validEmail: () => {
                return this.app().form.field('beneficiaryEmail').isTouched
                    ? this.app().form.field('beneficiaryEmail').value !== ''
                    : true;
            },
        };
    }

    public firstNameValidator(): object {
        return {
            validFirstName: (value: string) => {
                return this.app().form.field('beneficiaryFirstName').isTouched
                    ? Validation.isValidCaption(value)
                    : true;
            },
        };
    }

    public lastNameValidator(): object {
        return {
            validLastName: (value: string) => {
                return this.app().form.field('beneficiaryLastName').isTouched ? Validation.isValidCaption(value) : true;
            },
        };
    }

    public identityNumberValidator(): object {
        return {
            validIdentityNumber: () => {
                return this.app().form.field('beneficiaryIdentityNumber').isTouched
                    ? this.isValidIdentityNumber()
                    : true;
            },
        };
    }

    public phoneValidator(): object {
        return {
            validPhone: () => {
                return this.app().form.field('beneficiaryPhone').isTouched
                    ? this.app().form.field('beneficiaryPhone').value !== ''
                    : true;
            },
        };
    }

    public registrationNumberValidator(): object {
        return {
            validRegistrationNumber: () => {
                return this.app().form.field('beneficiaryRegistrationNumber').isTouched
                    ? this.app().form.field('beneficiaryRegistrationNumber').value !== ''
                    : true;
            },
        };
    }

    public countryValidator(): object {
        return {
            validCountry: (): boolean => {
                return this.app().form.field('beneficiaryCountry').isTouched
                    ? this.app().form.field('beneficiaryCountry').value !== ''
                    : true;
            },
        };
    }

    private isValidIdentityNumber(): boolean {
        const useCountry: boolean = this.app().isNonResident && this.app().otherPersonIsBeneficiary;
        return useCountry
            ? Validation.isValidNaturalPersonCode(
                  this.app().form.field('beneficiaryIdentityNumber').value,
                  this.app().form.field('beneficiaryCountry').value.iso,
              )
            : Validation.isValidNaturalPersonCode(this.app().form.field('beneficiaryIdentityNumber').value);
    }

    private app(): Readonly<InstanceType<typeof RecipientApp>> {
        return this.context;
    }
}
