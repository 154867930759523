<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, ref, Ref } from 'vue';
import { AxiosResponse } from 'axios';
import moment from 'moment/moment';
import { Router, useRouter } from 'vue-router';
import Form from '@/Assets/Libraries/Form/Form';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import FormField from '@/Assets/Libraries/Form/FormField';
import Url from '@/Enums/UrlEnum';
import Value from '@/Assets/Libraries/Form/Value';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import OneDate from '@/Assets/Libraries/Date/OneDate';
import PopupService from '@/Services/custom.popup.service';
import Storage from '@/Apps/SolarPanels/Services/Storage';
import { useTranslate } from '@/Composables/Translate';
import { useNavigate } from '@/Composables/Navigate';
import { useFormatter } from '@/Composables/Formatter';
import { useDefine } from '@/Composables/Define';
import { Program } from '@/Apps/SolarPanels/Interfaces/ProductProgram';
import { SolarPanels } from '@/Apps/SolarPanels/Interfaces/SolarPanelsInterface';
import { CoveredPopupBuilder } from '@/Apps/SolarPanels/Builders/CoveredPopupBuilder';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import User from '@/Services/user.service';
import StepsGuard from '@/Apps/SolarPanels/Services/StepsGuard';
import Sanitizer from '@/Services/sanitizer.service';
import Validation from '@/Services/validation.service';
import SettingsService from '@/Services/settings.service';
import ExtractDataService from '@/Services/extract.data.service';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import { useHtml } from '@/Composables/Html';
import { CoverageRisk } from '@/Apps/SolarPanels/Interfaces/CoverageRiskInterface';
import OneBaseService from '@/Services/OneBaseService';
import { useAxios } from '@/Composables/Axios';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import AppInputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import AppPopupOpener from '@/Components/Popups/PopupOpener/PopupOpener.vue';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import AppInputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppInputIdentityNumber from '@/Components/Inputs/InputIdentityNumber/InputIdentityNumber.vue';
import AppInputDate from '@/Components/Inputs/InputDate/InputDate.vue';
import AppInputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import AppPhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import AppConsentsList from '@/Components/Lists/ConsentsList/ConsentsList.vue';
import AppSubscribeAndPay from '@/Components/Other/SubscribeAndPay/SubscribeAndPay.vue';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import OneBase from '@/Interfaces/OneBaseInterface';
import DateRange from '@/Interfaces/date.range.interface';
import { CountryComponentParams } from '@/Components/Inputs/InputCountry/CountryComponentParams';
import PhoneField from '@/Interfaces/phone.field.interface';
import { useScroll } from '@/Composables/Scroll';

const base: OneBase = OneBaseService.getInstance();
const router: Router = useRouter();
const { translate, translateForType } = useTranslate();
const { sparsePrice } = useFormatter();
const { isSet } = useDefine();
const { imgTag } = useHtml();
const user: User = User.getInstance();
const popupService: PopupService = PopupService.getInstance();
const settingsService: SettingsService = SettingsService.getInstance();
const extractDataService: ExtractDataService = new ExtractDataService();
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const storage: Storage = Storage.getInstance();
const form: Form<{
    policyStartDate: DateRange;
    policyHolderSameAsInsured: boolean;
    policyHolderIsResident: boolean;
    policyHolderCountry: CountryComponentParams;
    policyHolderBirthDate: Date | string;
    marketingConsents: DynamicDictionary;
    policyHolderFirstName: string;
    policyHolderLastName: string;
    policyHolderIdentityNumber: string;
    policyHolderEmail: string;
    policyHolderPhone: PhoneField;
    agreeTerms: boolean;
}> = new Form({ useValidationV2: true });
const Facility: string = 'solar-panels-insurance';
const TranslationType: string = 'solar_panels_insurance';
const ConsentsGlue: string = ':';
const policyStartMinDate: Date = moment().add(1, 'days').toDate();
const policyStartMaxDate: Date = moment().add(30, 'days').toDate();
const selectedProgram: Program = storage.fields.selectedProgram as Program;
const policyInsuredSum: number = storage.fields.selectedProgram!.insuredSum;
const payment: number = storage.fields.selectedProgram!.price;
const solarPanelsAddress: DynamicDictionary = storage.fields.address as DynamicDictionary;
const solarPanelsAddressValue: string = solarPanelsAddress.addressName;
const solarPanelsApartmentValue: string =
    solarPanelsAddress.apartment && solarPanelsAddress.apartment !== ''
        ? solarPanelsAddress.apartment
        : solarPanelsAddress.detailed;
const solarPanelsAddressDetailedLabel: string =
    solarPanelsAddress.apartment && solarPanelsAddress.apartment !== ''
        ? 'caption_insured_object_apartment'
        : 'caption_insured_object_detailed_address';

const isAuthenticatedUser: Ref<boolean> = computed(() => {
    return user.isLogged();
});

const showMarketingConsents: Ref<boolean> = computed(() => {
    return !user.current.receiveMarketing;
});

const policyStartDate: Ref<string> = computed(() => {
    const result: Date = moment(form.field('policyStartDate').value.startDate).toDate();

    return OneDate.short(result);
});

const policyEndDate: Ref<string> = computed(() => {
    const startDate: Date = form.field('policyStartDate').value.startDate as unknown as Date;
    const result: Date = moment(startDate).add(1, 'year').subtract(1, 'day').toDate();

    return OneDate.short(result);
});

const policyHolderIsFromBalticCountries: Ref<boolean> = computed(() => {
    return policyHolderIsResident.value || ['EE', 'LV', 'LT'].includes(form.field('policyHolderCountry').value.iso);
});

const policyHolderIsResident: Ref<boolean> = computed(() => {
    return form.field('policyHolderIsResident').value;
});

const birthDateRequired: Ref<boolean> = computed(() => {
    return (
        !isAuthenticatedUser.value && (!policyHolderIsFromBalticCountries.value || policyHolderCodeIsWithoutDate.value)
    );
});

const policyHolderCodeIsWithoutDate: Ref<boolean> = computed(() => {
    const identityNumberField: FormField = form.field('policyHolderIdentityNumber');
    const identityNumber: string = String(identityNumberField.value);

    return (
        identityNumberField.isNotEmpty() &&
        Validation.isValidPersonCodeWithoutDate(identityNumber) &&
        form.field('policyHolderCountry').value.iso === 'LV'
    );
});

defineExpose({
    coveredPopup,
});

onMounted(() => {
    base.applySpa(getCurrentInstance());
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    if (isSet(storage.fields.selectedProgram)) {
        storage.fetchProducts().then(() => {
            setupForm();
            buildCoveredPopup();
        });
    }
});

function setupForm(): void {
    form.addField(new FormField('policyStartDate').addValidators({ required: Validation.required }));
    form.addField(new FormField('policyHolderSameAsInsured'));
    form.addField(new FormField('policyHolderIsResident'));
    form.addField(
        new FormField('policyHolderCountry').addValidators({
            required: Validation.requiredIf((): boolean => !isAuthenticatedUser.value),
        }),
    );
    form.addField(
        new FormField('policyHolderBirthDate').addValidators({
            required: Validation.requiredIf((): boolean => !isAuthenticatedUser.value),
        }),
    );
    form.addField(new FormField('marketingConsents'));
    form.addField(
        new FormField('policyHolderFirstName')
            .addValidators({
                required: Validation.required,
                validCaption: Validation.isValidCaption,
            })
            .addSanitizer(Sanitizer.cleanUppercaseName),
    );
    form.addField(
        new FormField('policyHolderLastName')
            .addValidators({
                required: Validation.required,
                validCaption: Validation.isValidCaption,
            })
            .addSanitizer(Sanitizer.cleanUppercaseName),
    );
    form.addField(new FormField('policyHolderIdentityNumber').addValidators({ required: Validation.required }));
    form.addField(new FormField('policyHolderEmail').addValidators({ required: Validation.required }));
    form.addField(new FormField('policyHolderPhone').addValidators({ required: Validation.required }));
    form.addField(new FormField('agreeTerms').addValidators({ required: Validation.required }));
    patchDefaultValues();
    patchUserFields();
    form.setReady();
}

function buildCoveredPopup(): void {
    const product: SolarPanels[] = [];
    const storedProductId: string = storage.fields.selectedProgram!.programIc;
    product.push(
        storage.products.value.filter((solarPanels: SolarPanels): boolean => solarPanels.id === storedProductId)[0],
    );
    coveredPopup
        .withCoveredType('AppCoveredPopupSolarPanelsMulti')
        .withContent(product)
        .withCoveragePlanKey(storage.fields.selectedProgram!.insuredSum)
        .withSingleMode();
}

function coveredButtonParams(): ButtonWithCallbackParams {
    return {
        title: translateForType('what_covered_solar_panels', TranslationType),
        textColor: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.White,
        backgroundColorHover: ButtonBackground.White,
        icon: ButtonIcon.Covered,
        iconColor: ButtonIconColor.Green,
        borderColor: ButtonBorder.Pale,
    };
}

function marketingConsents(): string[] {
    const selectedConsents: string = form.field('marketingConsents').value.selected;

    return new Value(selectedConsents).isNotEmpty() ? selectedConsents.split(ConsentsGlue) : [];
}

function minPolicyStartDate(): Date {
    const offset: number = settingsService.movablePropertySettings().minPolicyStartDateOffset;

    return moment().add(offset, 'day').toDate();
}

function patchUserFields(): void {
    if (isAuthenticatedUser.value) {
        const currentUser: UserCredentials = user.current;
        form.field('policyHolderFirstName').patch(currentUser.firstname);
        form.field('policyHolderLastName').patch(currentUser.lastname);
        form.field('policyHolderIdentityNumber').patch(currentUser.personCode);
        if (form.field('policyHolderEmail').isEmpty()) {
            form.field('policyHolderEmail').patch(currentUser.email);
        }
        if (form.field('policyHolderPhone').isEmpty()) {
            form.field('policyHolderPhone').patch({
                code: currentUser.code,
                iso: currentUser.code,
                country: currentUser.phoneCode,
                phone: currentUser.phone,
            } as PhoneField);
        }
    }
}

function redirectToBank(): void {
    useNavigate().navigate(Url.PaymentsApi.paymentsPay);
}

function updatePolicyHolderBirthDate(): void {
    const date: string | undefined = isAuthenticatedUser.value
        ? user.current.birthDate
        : extractedPolicyHolderBirthDate();
    if (date) {
        form.field('policyHolderBirthDate').patch(date);
    }
}

function scrollToElements(elements: JQuery): void {
    if (elements.length > 0) {
        elements[0].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
        });
    }
}

function policyHolder(): DynamicDictionary {
    return {
        firstName: form.field('policyHolderFirstName').value,
        lastName: form.field('policyHolderLastName').value,
        identityNumber: form.field('policyHolderIdentityNumber').value,
        country: form.field('policyHolderCountry').value,
        birthDate: OneDate.iris(form.field('policyHolderBirthDate').value),
        email: form.field('policyHolderEmail').value,
        phone: form.field('policyHolderPhone').value,
        isResident: isAuthenticatedUser.value ? true : policyHolderIsResident.value,
        marketingConsents: marketingConsents(),
    };
}

function patchDefaultValues(): void {
    patchDefaultHolderCountry();
    form.field('policyHolderIsResident').patch(true);
    form.field('policyStartDate').patch({ startDate: minPolicyStartDate() as unknown as string, endDate: '' });
    updatePolicyHolderBirthDate();
}

function patchDefaultHolderCountry(): void {
    form.field('policyHolderCountry').patch({
        ic: '100000000',
        iso: settingsService.localeIso(),
        phoneCode: settingsService.phoneCode(),
    });
}

function extractedPolicyHolderBirthDate(): string | undefined {
    let extractedBirthDate: Date | undefined;
    if (policyHolderIsFromBalticCountries.value && !policyHolderCodeIsWithoutDate.value) {
        extractedBirthDate = extractDataService.birthDateFromPersonCode(
            form.field('policyHolderIdentityNumber').value,
            form.field('policyHolderCountry').value.iso,
        );
    }

    return extractedBirthDate ? OneDate.iris(extractedBirthDate) : undefined;
}

function onShowCoveredPopupClick(): void {
    popupService.show(new OnePopup().withType().oneCovered);
}

function onIsResidentClick(): void {
    if (form.field('policyHolderIsResident').value) {
        patchDefaultHolderCountry();
    } else {
        form.field('policyHolderCountry').clear();
    }
}

function onConfirmAndPayClick(): void {
    form.markAsAttempted().then((): void => {
        if (form.isValid()) {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            useAxios()
                .post(Url.Ajax.solarPanelsPurchase, submitParams())
                .then((response: AxiosResponse): void => {
                    if (useDefine().validResponse(response)) {
                        redirectToBank();
                    } else {
                        PopupService.getInstance().hide();
                        base.error.show(ErrorType.Error, 'submitPayment', response);
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    PopupService.getInstance().hide();
                    Error.getInstance().show(
                        ErrorType.Error,
                        'Solar panels insurance / SummaryAndPayment / onConfirmAndPay',
                        reason,
                    );
                });
        } else {
            useScroll().scrollInvalidFieldToView(form);
        }
    });
}

function submitParams(): DynamicDictionary {
    const bankLinkId: number = RecurringPaymentBanklink.ByCountry[new AppCountry().iso()];
    const selectedProduct: SolarPanels = storage.selectedProduct();

    return {
        'customParam[facility]': Facility,
        'planId': selectedProduct.id,
        'termsIc': selectedProduct.params.termsIc,
        'deductibleIc': selectedProduct.params.deductibleIc,
        'sumInsured': selectedProgram.insuredSum,
        'risks': transformedRisks(includedRisks()),
        'policyStartDate': OneDate.iris(policyStartDate.value),
        'address': storage.fields.address,
        'policyHolder': policyHolder(),
        'bankLinkId': bankLinkId,
        'paymentFailRedirectUrl': router.currentRoute.value.path,
    };
}

function additionalRisks(): string[] {
    const result: string[] = [];
    storage.products.value.forEach((product: SolarPanels) => {
        product.risks.map((risk: CoverageRisk) => {
            if (!risk.isIncluded) {
                result.push(risk.id);
            }
        });
    });

    return [...new Set(result)];
}

function includedRisks(): CoverageRisk[] {
    let result: CoverageRisk[] = [];
    additionalRisks().map((additionalRisk: string) => {
        const risks: CoverageRisk[] = storage
            .selectedProduct()
            .risks.filter((risk: CoverageRisk) => risk.id === additionalRisk && risk.isIncluded);
        result = result.concat(risks);
    });

    return result;
}

function transformedRisks(risks: CoverageRisk[]): DynamicDictionary[] {
    const result: DynamicDictionary[] = [];
    const deductibleIc: string = storage.selectedProduct().params.deductibleIc;
    risks.forEach((includedRisk: CoverageRisk) => {
        const risk: DynamicDictionary = {};
        risk.ic = includedRisk.id;
        risk.isSelected = includedRisk.isIncluded;
        risk.sumInsured = selectedProgram.insuredSum;
        risk.deductibleIc = deductibleIc;
        result.push(risk);
    });

    return result;
}

function productName(): string {
    return translateForType(`summary_${storage.selectedProduct().id}`.toLowerCase(), TranslationType);
}
</script>

<template>
    <div class="container content-text">
        <div v-if="!form.isReady()" class="loading">
            <app-content-loader></app-content-loader>
        </div>
        <app-custom-form v-if="form.isReady()" :id="'form-summary-and-payment'" :form="form" class="form">
            <h1 class="page-title">{{ translateForType('title_summary_and_payment', TranslationType) }}</h1>
            <h2 class="page-subtitle">{{ translateForType('subtitle_summary_and_payment', TranslationType) }}</h2>
            <section id="whiteboard-0" class="whiteboard">
                <div class="whiteboard-title">
                    {{ translateForType('section_title_policy_details', TranslationType) }}
                </div>
                <ul class="list details">
                    <li id="whiteboard-item-0-0" class="item">
                        <span>{{ translateForType('caption_insurance_object', TranslationType) }}</span>
                        <span class="item-name">{{ translateForType('label_insured_product', TranslationType) }}</span>
                    </li>
                    <li id="whiteboard-item-0-1" class="item">
                        <span>{{ translateForType('caption_insurance_sum', TranslationType) }}</span>
                        <span class="item-name">{{ sparsePrice(policyInsuredSum, true) }} &euro;</span>
                    </li>
                    <li id="whiteboard-item-0-2" class="item">
                        <span>{{ translateForType('caption_insured_object_address', TranslationType) }}</span>
                        <span class="item-name">{{ solarPanelsAddressValue }}</span>
                    </li>
                    <li v-if="solarPanelsApartmentValue !== ''" id="whiteboard-item-0-3" class="item">
                        <span>{{ translateForType(solarPanelsAddressDetailedLabel, TranslationType) }}</span>
                        <span class="item-name">{{ solarPanelsApartmentValue }}</span>
                    </li>
                </ul>
                <app-button-with-callback
                    v-bind="coveredButtonParams()"
                    @button-callback-click="onShowCoveredPopupClick"
                >
                </app-button-with-callback>
            </section>
            <section id="whiteboard-1" class="whiteboard">
                <div class="whiteboard-title">
                    {{ translateForType('section_title_insurance_period', TranslationType) }}
                </div>
                <div class="title">{{ translateForType('caption_policy_start_date', TranslationType) }}</div>
                <app-input-date-with-calendar
                    class="policy-start-date"
                    :form-field="form.field('policyStartDate')"
                    :min-date="policyStartMinDate"
                    :max-date="policyStartMaxDate"
                    :number-of-calendars="1"
                >
                </app-input-date-with-calendar>
                <div class="list">
                    <div class="item">
                        <span>{{ translateForType('caption_policy_period', TranslationType) }}</span>
                        <span class="item-name">{{ policyStartDate + ' - ' + policyEndDate }}</span>
                    </div>
                </div>
            </section>
            <section id="whiteboard-2" class="whiteboard">
                <div class="whiteboard-title">
                    {{ translateForType('section_title_payment_details', TranslationType) }}
                </div>
                <div class="list">
                    <div class="item">
                        <span>{{ productName() }}</span>
                        <span class="item-name">
                            {{ sparsePrice(payment, false) }}
                            {{ translateForType('eur_per_month', TranslationType) }}
                        </span>
                    </div>
                    <div class="item">
                        <span>{{ translateForType('summary_number_of_payments', TranslationType) }}</span>
                        <span class="item-name">12</span>
                    </div>
                    <div class="item total inline">
                        <span class="item-name payment">{{
                            translateForType('label_total_payment', TranslationType)
                        }}</span>
                        <span class="item-total">
                            {{ sparsePrice(payment, false) }}
                            <span class="currency">{{ translateForType('eur_per_month', TranslationType) }}</span>
                        </span>
                    </div>
                </div>
            </section>
            <section id="whiteboard-3" class="whiteboard">
                <app-popup-opener
                    v-if="!isAuthenticatedUser"
                    class="login-opener"
                    :button-params="{ title: translateForType('login_with_online_bank', 'components') }"
                >
                </app-popup-opener>
                <div class="whiteboard-title">
                    {{ translateForType('section_title_policy_holder', TranslationType) }}
                </div>
                <app-input-checkbox
                    v-if="!isAuthenticatedUser"
                    :form-field="form.field('policyHolderIsResident')"
                    @click="onIsResidentClick"
                >
                    {{ translateForType('checkbox_is_resident', TranslationType) }}
                </app-input-checkbox>
                <app-input-country
                    v-if="!isAuthenticatedUser && !policyHolderIsResident"
                    :include-default-country="false"
                    :patch-default-country="false"
                    :disable-error-text="true"
                    :form-field="form.field('policyHolderCountry')"
                    :label="translate('btar_country')"
                    :placeholder="translate('btar_country_placeholder')"
                >
                </app-input-country>
                <div class="credentials three-columns">
                    <app-input-text
                        :label="translate('btar_name')"
                        :disabled="isAuthenticatedUser"
                        :form-field="form.field('policyHolderFirstName')"
                        :placeholder="translate('btar_first_name_placeholder')"
                    >
                    </app-input-text>
                    <app-input-text
                        :label="translate('btar_surname')"
                        :disabled="isAuthenticatedUser"
                        :form-field="form.field('policyHolderLastName')"
                        :placeholder="translate('btar_last_name_placeholder')"
                    >
                    </app-input-text>
                    <app-input-identity-number
                        :form-field="form.field('policyHolderIdentityNumber')"
                        :disabled="isAuthenticatedUser"
                        :country="form.field('policyHolderCountry').value.iso"
                        :resident="policyHolderIsResident"
                        :label="translate('btar_personal_code')"
                        :placeholder="translate('btar_identity_number_placeholder')"
                        @blur="updatePolicyHolderBirthDate"
                    >
                    </app-input-identity-number>
                </div>
                <div>
                    <div v-if="birthDateRequired" class="birth-date">
                        <app-input-date
                            :validate-birth-date="true"
                            :form-field="form.field('policyHolderBirthDate')"
                            :label="translate('btar_birth_date')"
                        >
                        </app-input-date>
                    </div>
                </div>
                <div class="credentials three-columns">
                    <app-input-email
                        :label="translate('btar_email')"
                        :disable-error-text="true"
                        :form-field="form.field('policyHolderEmail')"
                        :placeholder="translate('btar_email_placeholder')"
                    >
                    </app-input-email>
                    <app-phone-with-country
                        :label="translate('btar_phone')"
                        :disable-error-text="true"
                        :allow-empty="false"
                        :form-field="form.field('policyHolderPhone')"
                        :placeholder="translate('btar_phone_placeholder')"
                    >
                    </app-phone-with-country>
                </div>
            </section>
            <section id="whiteboard-4" class="whiteboard">
                <div class="whiteboard-title">
                    {{ translateForType('title_terms_and_agreements', TranslationType) }}
                </div>
                <div
                    class="purchase-terms"
                    v-html="translateForType('description_terms_and_agreements', TranslationType)"
                ></div>
                <hr class="line-break" />
                <app-input-checkbox :form-field="form.field('agreeTerms')">
                    {{ translateForType('checkbox_agree_terms', TranslationType) }}
                </app-input-checkbox>
                <hr class="line-break" />
                <app-consents-list v-if="showMarketingConsents" :form-field="form.field('marketingConsents')">
                </app-consents-list>
            </section>
            <app-subscribe-and-pay
                :amount="Number(sparsePrice(payment, false))"
                :callback="onConfirmAndPayClick"
                :heading-level="2"
                :wrapper-classes="'whiteboard'"
            ></app-subscribe-and-pay>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.custom-form {
    flex-direction: column;
    align-items: flex-start;

    @include respond-above('sm') {
        gap: var(--size-pico);
        align-items: center;
    }

    :deep(.subscribe-and-pay-heading) {
        font-size: var(--font-size-big);
        margin-bottom: calc(var(--size-small) - var(--size-pico));
        margin-top: calc(var(--size-big) - var(--size-pico));
    }

    :deep(.subscribe-and-pay) {
        &.whiteboard {
            margin-top: 0 !important;
        }
    }
}

.page-subtitle {
    @include respond-above('sm') {
        margin-top: 0 !important;
    }
}

.whiteboard {
    .login-opener {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include respond-above('sm') {
            align-items: flex-end;
            position: absolute;
            max-width: 720px;
        }
    }

    .whiteboard-title {
        color: var(--text-color-default);
        font-size: var(--font-size-small);
        font-weight: 700;
    }

    .line-break {
        width: 100%;
        height: 1px;
        background-color: var(--component-color-border-default);
    }

    .button-with-callback {
        width: 100%;
        height: 52px;
        font-size: var(--font-size-tiny);
        font-weight: 600;
    }

    .input-date-with-calendar {
        width: 100%;
    }

    .birth-date {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-nano);
        align-self: stretch;

        :deep(.input-date) {
            width: 100%;

            .wrapper {
                padding: var(--size-small);
                height: 52px;
                justify-content: left;
            }
        }

        .date-hint {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-self: stretch;
            color: var(--text-color-subtlest);
            font-feature-settings: 'liga' off;
            font-size: var(--font-size-nano);
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            flex: 100;
        }
    }

    .list {
        width: 100%;
        border-radius: var(--size-pico);
        border: 1px solid var(--black-100);
        display: flex;
        flex-direction: column;

        .item {
            display: flex;
            padding: var(--size-small);
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: var(--size-pico);
            align-self: stretch;
            font-size: var(--font-size-nano);

            @include respond-above('sm') {
                flex-direction: row;
                justify-content: space-between;
                padding: var(--size-small);
            }

            &.inline {
                display: flex;
                padding: var(--size-small);
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                gap: var(--size-pico);
                align-self: stretch;
                font-size: var(--font-size-nano);
                text-align: left;
            }

            &.total {
                background: var(--system-color-success-light);
            }

            .item-total {
                font-size: var(--font-size-medium);
                font-weight: 700;
            }

            .currency {
                font-size: var(--font-size-tiny);
                font-weight: 600;
            }

            .item-name {
                font-weight: 600;

                @include respond-above('sm') {
                    width: 50%;
                    text-align: right;
                }

                &.payment {
                    text-align: left;
                    font-size: var(--font-size-nano);
                    font-weight: 600;
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--black-100);
            }
        }
    }

    .input-country {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .button.red {
        width: 100%;
    }

    .credentials {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        align-items: stretch;
        gap: inherit;

        @include respond-above('sm') {
            flex-direction: row;
            gap: var(--size-small);
        }

        &.three-columns {
            .input {
                @include respond-above('sm') {
                    width: 100%;
                }
            }
        }
    }
}
</style>
