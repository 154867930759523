import Validation from '@/Services/validation.service';
import moment from 'moment';
import ModalTab from '@/Pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import DeletePersonTab from '@/Pages/LegalPerson/Workbench/Common/Tabs/DeletePersonTab';

export default class DeletePersonValidators {
    private context!: ModalTab;

    public addContext(context: ModalTab): void {
        this.context = context;
    }

    public identityNumberValidator(fieldIndex: number): object {
        return {
            validIdentityNumber: () => {
                return this.modalTab().form.field('personCode_' + fieldIndex).isTouched
                    ? this.isValidIdentityNumber(fieldIndex)
                    : true;
            },
        };
    }

    public policyStartDateValidator(fieldIndex: number): object {
        return {
            validPolicyStartDate: () => {
                return this.modalTab().form.field('startDate_' + fieldIndex).isTouched
                    ? this.isValidPolicyStartDate(fieldIndex)
                    : true;
            },
        };
    }

    public policyEndDateValidator(fieldIndex: number): object {
        return {
            validPolicyEndDate: () => {
                return this.modalTab().form.field('endDate_' + fieldIndex).isTouched
                    ? this.isValidPolicyEndDate(fieldIndex)
                    : true;
            },
        };
    }

    private isValidIdentityNumber(fieldIndex: number): boolean {
        const personIsResident: boolean = this.modalTab().form.field('resident_' + fieldIndex).value;
        const fieldIsEmpty: boolean = this.modalTab()
            .form.field('personCode_' + fieldIndex)
            .isEmpty();
        const validationResult: boolean = personIsResident
            ? Validation.isValidNaturalPersonCode(this.modalTab().form.field('personCode_' + fieldIndex).value)
            : true;

        return validationResult && !fieldIsEmpty;
    }

    private isValidPolicyStartDate(fieldIndex: number): boolean {
        const dateFromForm: Date = this.modalTab().form.field('startDate_' + fieldIndex).value.startDate;

        return moment(dateFromForm).isAfter(moment());
    }

    private isValidPolicyEndDate(fieldIndex: number): boolean {
        const dateFromForm: Date = this.modalTab().form.field('endDate_' + fieldIndex).value.startDate;

        return moment(dateFromForm).isBetween(
            moment(this.modalTab().minPolicyEndDate),
            moment(this.modalTab().maxPolicyEndDate),
            'day',
            '[]',
        );
    }

    private modalTab(): DeletePersonTab {
        return this.context as DeletePersonTab;
    }
}
