<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, PropType, Ref, ref, watch, nextTick } from 'vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import Form from '@/Assets/Libraries/Form/Form';
import ColumnsDefinitions from '@/Components/Lists/SmartGrid/Interfaces/ColumnsDefinitionsInterface';
import DefaultDefinitions from '@/Components/Lists/SmartGrid/Interfaces/DefaultDefinitionsInterface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import GridApi from '@/Components/Lists/SmartGrid/Interfaces/GridApiInterface';
import { useDebounce } from '@/Composables/Debounce';
import GridComponentOptions from '@/Components/Lists/SmartGrid/Interfaces/GridComponentOptionsInterface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import GridComponent from '@/Components/Lists/SmartGrid/Interfaces/GridComponent';
import { useDefine } from '@/Composables/Define';
import { AgGridPlugin } from '@/Assets/Libraries/Aggrid/ag-grid.plugin';
import GridCallback from '@/Components/Lists/SmartGrid/Enums/GridCallbackEnum';
import { useTranslate } from '@/Composables/Translate';
import { AgGridLocaleBase } from '@/Components/Lists/SmartGrid/Localization/BaseLocalization';
import TooltipRenderer from '@/Components/Lists/SmartGrid/Renderers/TooltipRenderer';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { TemplateCellRenderer } from '@/Components/Lists/SmartGrid/Renderers/TemplateCellRenderer';
import { useError } from '@/Composables/Error';
import LookupString from '@/Assets/Libraries/Search/LookupString';
import OneGrid from '@/Assets/Libraries/Aggrid/OneGridLibrary';
import { InputOption } from '@/Interfaces/InputOptionInterface';

const props = defineProps({
    formField: { type: Object as PropType<FormField<string>>, default: () => new FormField('') },
    id: {
        type: String,
        default: () => {
            return 'grid-' + Math.random();
        },
    },
    customTheme: { type: String, default: '' },
    filterTerm: { type: String, default: '' },
    columnDefs: { type: Array as PropType<ColumnsDefinitions[]>, default: () => [] },
    wrap: { type: Boolean, default: false },
    defaultColDef: {
        type: Object as PropType<DefaultDefinitions>,
        default: () => {
            return {};
        },
    },
    locales: {
        type: Object as PropType<DynamicDictionary>,
        default: () => {
            return {};
        },
    },
    animateRows: { type: Boolean, default: true },
    suppressRowClickSelection: { type: Boolean, default: false },
    resetOnFilter: { type: Boolean, default: false },
    rowData: { type: Array as PropType<DynamicDictionary[]>, default: () => [] },
    registerEvents: { type: Array as PropType<string[]>, default: () => [] },
    paginationPageSize: { type: Number, default: 250 },
    isRowSelectableCallback: {
        type: Function,
        default: null,
    },
    rowClassesCallback: {
        type: Function,
        default: null,
    },
    paginatorRendererCallback: {
        type: Function,
        default: null,
    },
});

const emit = defineEmits(['api', 'event-callback', 'data-changed-callback', 'filter-status']);

const { debounce } = useDebounce();
const { isSet } = useDefine();
const { translate } = useTranslate();
const { logError } = useError();

const form: Form = new Form();
const resizeDebounce: Function = debounce(() => resizeListener(), 300);
const defaultMaxRowsPerPage: number = 250;

let api: GridApi | null = null;
let agGridRef: DynamicDictionary = {};
let gridDiv: Element | null = null;

const currentComponent: Ref<string> = ref('');
const customTooltipHasEventCallback: Ref<boolean> = ref(false);
const isReady: Ref<boolean> = ref(false);
const filtering: Ref<boolean> = ref(false);
const gridIsRendered: Ref<boolean> = ref(false);
const componentOptions: Ref<InputOption[]> = ref([]);
const currentEvent: Ref<DynamicDictionary> = ref({});
const gridOptions: Ref<GridComponentOptions | null> = ref(null);

const gridId: Ref<string> = computed(() => {
    return props.id ? props.id : 'grid-' + String(Math.random()).replaceAll('.', '').replaceAll(',', '');
});

const gridThemes: Ref<string> = computed(() => {
    const result: string[] = ['grid', 'ag-theme-alpine', 'ag-theme-one'];
    if (props.customTheme) {
        result.push(props.customTheme);
    }

    return result.join(' ');
});

const currentComponentClass: Ref<string> = computed(() => {
    return String(currentComponent.value)
        .replace(/([a-z0–9])([A-Z])/g, '$1-$2')
        .toLowerCase();
});

watch(
    () => props.rowData,
    (newValue, oldValue) => {
        emitDataChangedCallback();
        rebuildContent();
        applyPagination();
        resizeListener();
    },
    { deep: true, immediate: true },
);

watch(
    () => props.columnDefs,
    (newData, oldData) => {
        checkColumnsDefinitions(newData);
        api?.setColumnDefs(addRealIndexToColumnDef(newData));
        resizeListener();
    },
    { deep: true, immediate: true },
);

watch(
    () => props.filterTerm,
    (newData, oldData) => {
        if (newData !== oldData) {
            if (props.resetOnFilter && api) {
                api.deselectAll();
            }
            filtering.value = true;
            emitFilterStatus(true);
            api?.onFilterChanged();
        }
    },
    { deep: true, immediate: true },
);

onMounted(() => {
    setupForm();
    AgGridPlugin.init().then(() => {
        init();
    });
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', resizeDebounce());
    props.registerEvents.forEach((eventName: string) => {
        api!.removeEventListener(eventName, emitEventCallback);
    });
    if (customTooltipHasEventCallback.value) {
        api!.removeEventListener(GridCallback.CellMouseOut, customTooltipCellMouseOutEventCallback);
    }
});

function onComponentChange(params: DynamicDictionary): void {
    props.formField.patch(String(params));
    (props as DynamicDictionary).rowData[currentEvent.value.data.agGridRowRealIndex][currentEvent.value.colDef.field] =
        params;
    resetComponent();
}

function onComponentClose(): void {
    nextTick(() => {
        resetComponent();
    });
}

function resetComponent(): void {
    currentComponent.value = '';
    componentOptions.value = [];
    currentEvent.value = {};
    props.formField.clear();
}

function setupForm(): void {
    form.addField(new FormField(gridId.value + 'grid-form'));
}

function init(): void {
    gridDiv = document.querySelector('#' + props.id);
    buildGridContent();
    agGridRef = OneGrid.build(gridDiv as Element, gridOptions.value as GridComponentOptions);
    api = (gridOptions.value as DynamicDictionary).api;
    api!.setDomLayout('autoHeight');
    emitApi();
    addListeners();
    applyPagination();
    isReady.value = true;
}

function buildGridContent(): void {
    buildColumnDefsIfNeeded();
    buildGridOptions();
}

function buildColumnDefsIfNeeded(): void {
    if (props.columnDefs && props.rowData && props.rowData.length > 0 && props.columnDefs.length === 0) {
        (props as DynamicDictionary).columnDefs = [];
        Object.keys(props.rowData[0]).forEach((key: string) => {
            (props as DynamicDictionary).columnDefs.push({
                field: key,
                headerName: key,
                editable: true,
            });
        });
    }
}

function buildGridOptions(): void {
    checkColumnsDefinitions(props.columnDefs);
    gridOptions.value = {
        columnDefs: addRealIndexToColumnDef(props.columnDefs),
        rowData: addRealIndexToRows(props.rowData),
        defaultColDef: defaultDefinitions(),
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        animateRows: props.animateRows,
        pagination: true,
        paginationPageSize: Math.min(props.paginationPageSize, defaultMaxRowsPerPage),
        tooltipShowDelay: 10,
        tooltipMouseTrack: true,
        suppressRowClickSelection: props.suppressRowClickSelection,
        enableCellTextSelection: true,
        suppressPropertyNamesCheck: true,
        suppressDragLeaveHidesColumns: true,
        overlayNoRowsTemplate: '<span>' + translate('grid_no_rows') + '</span>',
        localeText: props.locales ? props.locales : AgGridLocaleBase,
        doesExternalFilterPass: externalFilterIsPassed,
        paginationNumberFormatter: paginationNumberFormatted,
        onFirstDataRendered: onFirstDataRendered,
        isExternalFilterPresent: isExternalFilterPresent,
        isRowSelectable: isRowSelectable,
        getRowClass: getRowClass,
        onModelUpdated: onModelUpdated,
        onPaginationChanged: onPaginationChanged,
        onCellClicked: onCellClicked,
    };
}

function defaultDefinitions(): DefaultDefinitions {
    const result: DefaultDefinitions = hasDefaultColDefinitions()
        ? props.defaultColDef
        : new (class implements DefaultDefinitions {
              public editable: boolean = false;
              public resizable: boolean = false;
              public sortable: boolean = false;
              public suppressMovable: boolean = true;
          })();
    if (props.wrap) {
        result.autoHeight = true;
        result.wrapText = true;
    }

    return result;
}

function paginationNumberFormatted(params: DynamicDictionary): string {
    if (useDefine().isSet(props.paginatorRendererCallback)) {
        return String(props.paginatorRendererCallback().template).replace('@', String(params.value));
    } else {
        return params.value;
    }
}

function isRowSelectable(rowNode: DynamicDictionary): boolean {
    let result: boolean = true;
    if (useDefine().isSet(props.isRowSelectableCallback)) {
        result = props.isRowSelectableCallback(rowNode);
    }

    return result;
}

function getRowClass(params: DynamicDictionary): string {
    let result: string = '';
    if (useDefine().isSet(props.rowClassesCallback)) {
        result = props.rowClassesCallback(params);
    }

    return result;
}

function onModelUpdated(): void {
    if (filtering.value) {
        filtering.value = false;
        emitFilterStatus(false);
    }
}

function onPaginationChanged(): void {
    if (props.paginationPageSize > 0 && gridIsRendered.value) {
        emitEventCallback({ type: GridCallback.PaginationChanged });
    }
}

function onCellClicked(event: DynamicDictionary): void {
    emitEventCallback(event);
    if (event.colDef.customTooltipRendererParams) {
        new TooltipRenderer().init(props.id, event, event.colDef.customTooltipRendererParams);
    }
}

function onFirstDataRendered(): void {
    gridIsRendered.value = true;
}

function isExternalFilterPresent(): boolean {
    return true;
}

function hasDefaultColDefinitions(): boolean {
    return props.defaultColDef && Object.keys(props.defaultColDef).length > 0;
}

function addListeners(): void {
    window.addEventListener('resize', resizeListener);
    api!.addEventListener('firstDataRendered', resizeListener);
    api!.addEventListener('cellEditingStopped', () => {
        emitDataChangedCallback();
    });
    props.registerEvents.forEach((eventName: string) => {
        api!.addEventListener(eventName, emitEventCallback);
    });
    props.columnDefs.forEach((def: ColumnsDefinitions) => {
        if (def.customTooltipRendererParams && !customTooltipHasEventCallback.value) {
            api!.addEventListener(GridCallback.CellMouseOut, customTooltipCellMouseOutEventCallback);
            customTooltipHasEventCallback.value = true;
        }
    });
}

function customTooltipCellMouseOutEventCallback(event: DynamicDictionary): void {
    TooltipRenderer.clear(props.id);
}

function checkColumnsDefinitions(columnsDefinitions: ColumnsDefinitions[]): void {
    columnsDefinitions.forEach((def: ColumnsDefinitions) => {
        let message: string;
        if (def.cellRendererParams) {
            if (!def.cellRenderer) {
                def.cellRenderer = TemplateCellRenderer;
            }
        } else if (def.cellRenderer) {
            if (!def.cellRendererParams) {
                message = 'column ' + def.field + 'has "cellRenderer" but has no "cellRendererParams"';
                logError(ErrorType.Error, 'Grid column definitions error', message);
            }
        }
        if (def.cellRenderer) {
            if (def.valueFormatter) {
                message = 'column ' + def.field + 'can only have "cellRenderer" or "valueFormatter"';
                logError(ErrorType.Error, 'Grid column definitions error', message);
            }
        }
    });
}

function applyPagination(): void {
    if (gridOptions.value) {
        const enabled: boolean = gridOptions.value.rowData.length > props.paginationPageSize;
        api?.setPagination(enabled);
    }
}

function resizeListener(): void {
    TooltipRenderer.clear(props.id);
    if (api) {
        api.sizeColumnsToFit();
    }
}

function addRealIndexToColumnDef(columns: ColumnsDefinitions[]): ColumnsDefinitions[] {
    let found: boolean = false;
    columns.forEach((column: ColumnsDefinitions) => {
        if (column.field === 'agGridRowRealIndex') {
            found = true;
            column.headerName = '';
            column.hide = true;
            column.suppressToolPanel = true;
        }
    });
    if (!found) {
        columns.push({
            field: 'agGridRowRealIndex',
            headerName: '',
            hide: true,
            suppressToolPanel: true,
        });
    }
    return columns;
}

function addRealIndexToRows(rowData: DynamicDictionary[]): DynamicDictionary[] {
    rowData.forEach((row: DynamicDictionary, index: number) => {
        row.agGridRowRealIndex = index;
    });

    return rowData;
}

function rebuildContent(): void {
    if (gridOptions.value) {
        gridOptions.value.rowData = addRealIndexToRows(props.rowData);
        api?.setRowData(gridOptions.value.rowData);
    }
}

function externalFilterIsPassed(node: DynamicDictionary): boolean {
    const term: string = props.filterTerm.toLowerCase();

    return (
        term === '' ||
        Object.keys(node.data).some((key: string) => {
            return new LookupString()
                .withSearchTerm(term)
                .withTargetValue(node.data[key])
                .useStringStartsWith()
                .match();
        })
    );
}

function emitApi(): void {
    emit('api', api as GridApi);
}

function emitEventCallback(event: DynamicDictionary): void {
    currentEvent.value = event;
    if (isSet(currentEvent.value.colDef) && isSet(currentEvent.value.colDef.component)) {
        const component: GridComponent = currentEvent.value.colDef.component as GridComponent;
        currentComponent.value = component.name;
        componentOptions.value = component.data;
        const patchValue: LimitedVariant =
            props.rowData[currentEvent.value.data.agGridRowRealIndex][currentEvent.value.colDef.field];
        props.formField.patch(patchValue as string);
        form.field(gridId.value + 'grid-form').patch(patchValue);
    }

    emit('event-callback', currentEvent.value);
}

function emitDataChangedCallback(): void {
    const result: DynamicDictionary = gridOptions.value ? gridOptions.value.rowData : {};
    emit('data-changed-callback', result);
}

function emitFilterStatus(status: boolean): void {
    emit('filter-status', status);
}
</script>

<template>
    <div class="grid-container">
        <div :id="gridId" :class="gridThemes"></div>
        <div v-if="currentComponent !== ''" class="popup-wrapper">
            <div v-if="currentComponent !== ''" class="overlay"></div>
            <component
                :is="currentComponent"
                class="grid-component"
                :class="currentComponentClass"
                :form-field="form.field(gridId + 'grid-form')"
                :always-expanded="true"
                :options="componentOptions"
                @change="onComponentChange($event)"
                @close="onComponentClose"
            ></component>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.grid-container {
    width: 100%;
    height: auto;

    .grid {
        position: relative;
        width: 100%;
        height: 100%;

        :deep(.grid-custom-tooltip) {
            position: absolute;
        }
    }

    .popup-wrapper {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        z-index: 1000;

        @include fullscreen;

        .overlay {
            position: absolute;
            background-color: var(--black-200);

            @include fullscreen;
        }

        .grid-component {
            width: 100%;
            max-width: 1093px;
        }
    }
}
</style>
