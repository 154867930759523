import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

export default class BusinessTab {
    private context: DynamicDictionary = {};

    public addContext(context: DynamicDictionary): void {
        this.context = context;
    }

    public init(): void {}

    public destroy(): void {}

    public setupForm(): void {}

    public onPolicyChanged(policy: string): void {}

    protected get app(): DynamicDictionary {
        return this.context;
    }
}
