<script setup lang="ts">
import Icon from '@/Components/TwoWayCommunication/MessageCard/Icon.enum';
import { computed, Ref } from 'vue';
import OneDate from '@/Assets/Libraries/Date/OneDate';

const props = defineProps({
    name: { type: String, default: '' },
    date: { type: String, default: '' },
    icon: { type: String, default: Icon.None },
    withBadge: { type: Boolean, default: false },
});

const noIcon: Ref<boolean> = computed((): boolean => {
    return props.icon === Icon.None;
});

const avatarInitials: Ref<string> = computed((): string => {
    const nameParts: string[] = props.name.split(' ');
    let result: string;
    if (nameParts.length === 1) {
        result = nameParts[0].charAt(0).toUpperCase() + nameParts[0].charAt(0).toUpperCase();
    } else {
        const firstInitial: string = nameParts[0].charAt(0).toUpperCase();
        const surnameIndex: number = nameParts.length > 2 ? 2 : 1;
        const lastInitial: string = nameParts[surnameIndex].charAt(0).toUpperCase();
        result = firstInitial + lastInitial;
    }

    return result;
});

const iconPath: Ref<string> = computed((): string => {
    return '/images/one/components/message-card/' + props.icon + '.svg';
});

const formattedDate: Ref<string> = computed(() => {
    let result: string = OneDate.short(props.date);
    const time: string = OneDate.custom(props.date, 'HH:mm');
    if (time !== '00:00') {
        result += '<span class="time">' + time + '</span>';
    }

    return result;
});
</script>
<template>
    <div class="user-message-card">
        <div class="icon-background" :class="noIcon ? 'grey' : 'red'">
            <span v-if="noIcon" class="icon text" v-html="avatarInitials"></span>
            <img v-else class="icon img" alt="" :src="iconPath" />
        </div>
        <div class="info">
            <div class="data">
                <div class="name">
                    {{ name }}
                    <app-badge v-if="withBadge" :title="'badge_new'" class="badge"></app-badge>
                </div>
                <span class="dot"></span>
                <div class="date" v-html="formattedDate"></div>
            </div>
            <app-badge v-if="withBadge" :title="'badge_new'" class="badge-device"></app-badge>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.user-message-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--size-nano);

    .icon-background {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--size-medium);
        height: var(--size-medium);
        border-radius: 50%;
        flex-shrink: 0;

        @include respond-above('sm') {
            width: var(--size-big);
            height: var(--size-big);
        }

        .icon {
            font-size: var(--font-size-nano);
            font-weight: 600;
            color: var(--text-color-subtle);
        }

        &.red {
            background: var(--brand-red);
        }

        &.grey {
            background: var(--background-light);
        }
    }

    .info {
        display: flex;
        flex-direction: row;
        width: 100%;

        @include respond-above('sm') {
            flex-direction: row;
        }

        .data {
            display: flex;
            flex-direction: row;
            align-items: center;

            @include respond-above('sm') {
                flex-direction: column;
                align-items: flex-start;
                gap: var(--size-femto);
            }
        }

        .name {
            display: flex;
            font-weight: 600;
            font-size: var(--font-size-nano);

            @include respond-below('sm') {
                font-size: var(--font-size-pico);
            }
        }

        .date {
            font-weight: 500;
            font-size: var(--font-size-nano);
            color: var(--text-color-subtle);
            margin-right: var(--size-nano);

            @include respond-below('sm') {
                font-size: var(--font-size-pico);
            }

            :deep(.time) {
                margin-left: var(--size-pico);
            }
        }

        .badge {
            display: none;
            align-self: center;
            margin-left: auto;

            @include respond-above('sm') {
                display: flex;
                align-self: start;
                margin-left: var(--size-nano);
            }
        }

        .badge-device {
            display: none;

            @include respond-below('sm') {
                align-self: start;
                margin-left: var(--size-nano);
                display: flex;
            }
        }

        .dot {
            display: block;
            width: 2px;
            height: 2px;
            background-color: var(--black-400);
            border-radius: 2px;
            margin: 0 var(--size-pico);
            color: var(--text-color-subtle);
            align-self: center;

            @include respond-above('sm') {
                display: none;
            }
        }
    }
}
</style>
