import Form from '@/Assets/Libraries/Form/Form';
import { ref, Ref } from 'vue';

export const useAutoForms = (): AutoForms => {
    const form: Form = new Form();
    const reminderForm: Form = new Form();
    const vehicleProduct: Form = new Form();
    const fieldsPopupForm: Form = new Form();
    const missingParamsForm: Form = new Form();
    const formsAreReady: Ref<boolean> = ref(false);

    return {
        form,
        reminderForm,
        vehicleProduct,
        fieldsPopupForm,
        missingParamsForm,
        formsAreReady,
    };
};

export interface AutoForms {
    form: Form;
    reminderForm: Form;
    vehicleProduct: Form;
    fieldsPopupForm: Form;
    missingParamsForm: Form;
    formsAreReady: Ref<boolean>;
}
