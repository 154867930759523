import { markRaw } from 'vue';
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Sanitizer from '@/Services/sanitizer.service';
import Translations from '@/Services/translations.service';
import moment from 'moment';
import PersonState from '@/Enums/PersonStateEnum';
import PopupBase from '@/Assets/Libraries/Popups/PopupBase';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import PopupIcon from '@/Enums/PopupIconEnum';
import Url from '@/Enums/UrlEnum';
import BottomNotification from '@/Services/bottom.notification.service';
import BottomNotificationLevel from '@/Enums/BottomNotificationLevelEnum';
import StatusIcon from '@/Components/Tooltips/StatusIcon/StatusIcon.enum';
import Value from '@/Assets/Libraries/Form/Value';
import LookupString from '@/Assets/Libraries/Search/LookupString';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import { InputSearchExposable } from '@/Components/Inputs/InputSearch/InputSearch';
import { useCurrency } from '@/Composables/Currency';
import ModalTab from '@/Pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import LegalInsuredPerson from '@/Pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import DeletePersonValidators from '@/Pages/LegalPerson/Workbench/Common/Validators/DeletePersonValidators';
import DeletePersonsFromExcelTab from '@/Pages/LegalPerson/Workbench/Common/Tabs/DeletePersonsFromExcelTab';
import LegalPersonModalState from '@/Pages/LegalPerson/Workbench/Enums/LegalPersonModalStateEnum';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import PopupService from '@/Services/custom.popup.service';
import RequestService from '@/Services/request.service';

export default class DeletePersonTab extends ModalTab {
    public form: Form = markRaw(new Form());
    public rows: LegalInsuredPerson[] = [];

    private readonly RowStatusCheckInterval: number = 1000;
    private readonly DuplicateRowIcon: string = StatusIcon.Duplicate;

    private filter: string = '';
    private validators: DeletePersonValidators;
    private loading: boolean = false;
    private filterButtonsParams?: ButtonWithCallbackParams;
    private showAllButtonIsActive: boolean = false;
    private rowStatusHandlerInterval!: NodeJS.Timeout;
    private duplicateInRowsMessage: string = this.translated('popup_duplicate_in_rows');
    private notFoundInListMessage: string = this.translated('popup_not_found_in_list');
    private isProcessingMessage: string = this.translated('popup_in_processing');
    private alreadyExcludedMessage: string = this.translated('popup_already_excluded');
    private personIsRelativeMessage: string = this.translated('popup_relative');
    private static instance: DeletePersonTab;

    public constructor() {
        super();
        this.validators = new DeletePersonValidators();
        this.validators.addContext(this);
    }

    public static getInstance(): DeletePersonTab {
        if (!DeletePersonTab.instance) {
            DeletePersonTab.instance = new DeletePersonTab();
        }

        return DeletePersonTab.instance;
    }

    public get isSelectAllDisabled(): boolean {
        return this.rows.length === 0;
    }

    public get showRemovePersonsButton(): boolean {
        return this.removablePersonsCount > 0;
    }

    public get showSubmitPersonsButton(): boolean {
        return this.rows.length > 0;
    }

    public get showRemoveInvalidPersonsButton(): boolean {
        return this.invalidPersonsCount > 0;
    }

    public get removePersonsButtonTitle(): string {
        return this.translated('popup_remove_selected_rows') + ' &middot; ' + this.removablePersonsCount;
    }

    public get removeInvalidPersonsButtonTitle(): string {
        return this.translated('popup_remove_invalid_rows') + ' &middot; ' + this.invalidPersonsCount;
    }

    public get filterPersonsButtonParams(): ButtonWithCallbackParams {
        return (this.filterButtonsParams = {
            title: this.translated('popup_show_errored_rows') + ' &middot; ' + this.invalidPersonsCount,
            icon: ButtonIcon.Warning,
            iconColor: ButtonIconColor.Red,
            backgroundColorHover: ButtonBackground.Grey,
        });
    }

    public get showAllButtonParams(): ButtonWithCallbackParams {
        return {
            title: this.translated('popup_show_all_rows') + ' &middot; ' + this.rows.length,
            icon: ButtonIcon.Eye,
            iconColor: ButtonBackground.Grey,
            backgroundColorHover: ButtonBackground.Grey,
        };
    }

    public get removablePersonsCount(): number {
        return this.selectedVisibleRows.length;
    }

    public get invalidPersonsCount(): number {
        return this.visibleInvalidRows.length;
    }

    public get submitPersonsButtonParams(): ButtonWithCallbackParams {
        const title: string =
            this.rows.filter(
                (row: LegalInsuredPerson): boolean =>
                    row.icon === StatusIcon.Error || row.icon === StatusIcon.InProgress,
            ).length > 0
                ? this.translated('popup_validate_on_submit')
                : this.translated('submit_x_person_remove').replace('%count%', String(this.rows.length));
        return {
            title: title,
            icon: ButtonIcon.Check,
            backgroundColor: ButtonBackground.Red,
            textColor: ButtonTextColor.White,
            iconColor: ButtonIconColor.White,
        };
    }

    public get minPolicyEndDate(): Date {
        return new AppCountry().isLT() ? moment().subtract(1, 'day').toDate() : moment().add(1, 'day').toDate();
    }

    public get maxPolicyEndDate(): Date {
        return this.modal.app.currentAgreementEndDate().subtract(1, 'day').toDate();
    }

    public get excelRows() {
        return DeletePersonsFromExcelTab.getInstance().excelRows;
    }

    public get showAllButtonIsVisible() {
        return this.filter !== '' || this.showAllButtonIsActive;
    }

    public init(): void {
        this.resetRowVisibility();
        this.applySelectedRows();
        this.patchRows();
        this.applyRowStatusChecks();
        this.modal.markDataAsEdited();
        this.form.setReady();
    }

    public destroy(): void {
        this.resetRows();
        this.resetFilter();
        this.form.destroy();
        this.removeRowStatusChecks();
    }

    public isRowVisible(rowIndex: number): boolean {
        return this.rows[rowIndex].isVisible as boolean;
    }

    public onRemoveSelectedClick(): void {
        this.loading = true;
        this.removePersons().then((): void => {
            this.applyFormFieldValidators();
            this.form.field('selectAll').patch(false);
            this.loading = false;
        });
    }

    public onRemoveInvalidClick(): void {
        this.loading = true;
        this.removeInvalidPersons().then((): void => {
            this.applyFormFieldValidators();
            this.loading = false;
        });
    }

    public onShowOnlyErrorsClick(): void {
        this.resetRowVisibility();
        this.rows.forEach((row: LegalInsuredPerson): void => {
            if (row.icon !== StatusIcon.Error) {
                row.isVisible = false;
            }
        });
        this.showAllButtonIsActive = true;
    }

    public onShowAllClick(): void {
        this.resetFilter();
        const inputSearch: DynamicDictionary | undefined = this.modal.app.componentRef('inputSearch');
        if (inputSearch) {
            (inputSearch as InputSearchExposable).onClearClick();
        }
        this.showAllButtonIsActive = false;
    }

    public onExcelImport(): void {
        this.modal.preserveModalQueue(LegalPersonModalState.DeletePersonsFromExcel);
        this.resetFilter();
    }

    public onSelectAllClick(): void {
        const selectAllState: boolean = this.form.field('selectAll').value;
        this.form
            .fields()
            .filter((field: FormField): boolean => field.name.includes('selected_'))
            .forEach((field: FormField): void => {
                if (this.isRowVisible(this.fieldIndex(field))) {
                    field.patch(selectAllState);
                }
            });
    }

    public onSubmitPersonsClick(): void {
        this.applyFormFieldValidators();
        this.form.touch().then((): void => {
            this.form.validate().then((): void => {
                this.rePatch().then((): void => {
                    if (!this.hasRowStatusErrors() && this.form.isValid()) {
                        this.showRemovePersonsPopup();
                    }
                });
            });
        });
    }

    public showRemovePersonsPopup(): void {
        const removePersonPopup: PopupBase = new OnePopup()
            .withType()
            .confirmPopup.withTitle(
                this.translated('confirm_removing_x_persons').replace('%count%', String(this.rows.length)),
            )
            .withDescription(this.translated('confirm_changes_description'))
            .withConfirmButtonText(this.translated('confirm_and_submit'))
            .withIcon(PopupIcon.AlertTriangle)
            .withConfirmCallback('onRemovePersonsConfirm', this);
        this.modal.app.popupServices.show(removePersonPopup);
    }

    public onRemovePersonsConfirm(): void {
        PopupService.getInstance()
            .hide()
            .then((): void => {
                this.deleteInsuredPersons()
                    .then((): void => {
                        this.modal.app.fetchPolicyDetailsWithoutCache().then((): void => {
                            PopupService.getInstance().hide();
                            this.modal.hideModalPopup();
                            BottomNotification.getInstance().pushNotification(
                                BottomNotificationLevel.Success,
                                this.modal.app.localized('toastr_success_person_delete'),
                            );
                        });
                    })
                    .catch((): void => {
                        PopupService.getInstance()
                            .hide()
                            .then((): void => {
                                this.modal.showSimpleErrorPopup();
                            });
                    });
            });
    }

    public onCancelClick(): void {
        if (this.rows.length > 0) {
            this.modal.markDataAsEdited();
        }
        this.modal.closeModalPopup();
    }

    public onManualSearch(searchTerm: string): void {
        this.filter = searchTerm;
        this.resetRowVisibility();
        if (searchTerm !== '') {
            this.updateRowVisibility();
        }
    }

    public patchRows(): void {
        const supportedFormats: string[] = ['DD/MM/YYYY', 'DD.MM.YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD'];
        this.rows = this.rows.concat(this.excelRows);
        this.setupForm();
        this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
            this.form.field('firstName_' + index).patch(row.firstName.toUpperCase());
            this.form.field('lastName_' + index).patch(row.lastName.toUpperCase());
            this.form.field('personCode_' + index).patch(row.personCode);
            this.form
                .field('startDate_' + index)
                .patch(
                    row.startDate === ''
                        ? ''
                        : moment(row.startDate!, supportedFormats).format(this.modal.app.DateFormat),
                );
            if (this.modal && this.modal.inputFieldsAreLocked) {
                this.form.field('endDate_' + index).patch('');
                this.applyFormFieldValidators();
                this.form.touch().then((): void => {
                    this.form.validate().then();
                });
            } else {
                this.form.field('endDate_' + index).patch(
                    row.endDate === ''
                        ? ''
                        : {
                              startDate: moment(row.endDate, supportedFormats).toDate(),
                              endDate: '',
                          },
                );
            }
            if (this.isAlreadyExcluded(index)) {
                const endDate: string = this.alreadyExcludedEndDate(index);
                if (endDate !== '') {
                    this.form.field('endDate_' + index).patch({
                        startDate: moment(row.endDate, supportedFormats).toDate(),
                        endDate: '',
                    });
                }
            }
            this.form.field('insuranceProgram_' + index).patch(row.insuranceProgram.name);
            if (row.premium!.amount) {
                this.form
                    .field('premium_' + index)
                    .patch(row.premium!.amount + ' ' + useCurrency().currency(row.premium!.currency));
            } else {
                this.form.field('premium_' + index).patch('-');
            }
        });
    }

    public erroredEntryMessage(rowIndex: number): string {
        let result: string = '';
        if (this.notFoundInList(rowIndex)) {
            result = this.notFoundInListMessage;
        }
        if (this.isAlreadyExcluded(rowIndex)) {
            result = this.alreadyExcludedMessage;
        }
        if (this.isProcessing(rowIndex)) {
            result = this.isProcessingMessage;
        }
        if (this.hasDuplicateInRows(rowIndex)) {
            result = this.duplicateInRowsMessage;
        }
        if (this.isRelative(rowIndex)) {
            result = this.personIsRelativeMessage;
        }

        return result;
    }

    public hasRowStatusError(rowIndex: number): boolean {
        let result: boolean = this.notFoundInList(rowIndex);
        if (!result) {
            result =
                this.hasDuplicateInRows(rowIndex) ||
                this.isProcessing(rowIndex) ||
                this.isAlreadyExcluded(rowIndex) ||
                this.isRelative(rowIndex);
        }

        return result;
    }

    public notFoundInList(rowIndex: number): boolean {
        const personCodes: string[] = this.modal.app.insuredPersons.persons.map((person: LegalInsuredPerson): string =>
            person.personCode.replace('-', ''),
        );
        const rowPersonCode: string = String(this.form.field('personCode_' + rowIndex).value).replace('-', '');

        return !personCodes.includes(rowPersonCode);
    }

    public isProcessing(rowIndex: number): boolean {
        const personCodes: string[] = this.modal.app.insuredPersons.persons
            .filter(
                (person: LegalInsuredPerson) =>
                    person.status === PersonState.New || person.status === PersonState.Error,
            )
            .map((person: LegalInsuredPerson): string => person.personCode.replace('-', ''));
        const rowPersonCode: string = String(this.form.field('personCode_' + rowIndex).value).replace('-', '');

        return personCodes.includes(rowPersonCode);
    }

    public isAlreadyExcluded(rowIndex: number): boolean {
        return (
            (!moment(this.rows[rowIndex].endDate).isSame(
                moment(this.modal.app.currentAgreement!.endDate.date),
                'day',
            ) ||
                this.rows[rowIndex].status === PersonState.Ended) &&
            this.rows[rowIndex].status !== PersonState.Active
        );
    }

    public alreadyExcludedEndDate(rowIndex: number): string {
        let result: string = '';
        if (this.isAlreadyExcluded(rowIndex) && this.rows[rowIndex].endDate) {
            result = this.rows[rowIndex].endDate as string;
        }

        return result;
    }

    public hasDuplicateInRows(rowIndex: number): boolean {
        const currentFieldName: string = 'personCode_' + rowIndex;
        const personCodes: string[] = this.form
            .fields()
            .filter(
                (field: FormField): boolean =>
                    field.name.includes('personCode') &&
                    field.name !== currentFieldName &&
                    rowIndex > this.fieldIndex(field) &&
                    field.isNotEmpty(),
            )
            .map((field: FormField): string => String(field.value).replace('-', ''));
        const rowPersonCode: string = String(this.form.field('personCode_' + rowIndex).value).replace('-', '');

        return personCodes.length > 0 ? personCodes.includes(rowPersonCode) : false;
    }

    public isRelative(rowIndex: number): boolean {
        return this.rows[rowIndex].relationIc === PersonState.Relative;
    }

    private rePatch(): Promise<void> {
        return new Promise((resolve) => {
            this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
                if (
                    this.modal.app.insuredPersons.persons.some(
                        (person: LegalInsuredPerson): boolean =>
                            person.personCode.replace('-', '') ===
                            this.form.field('personCode_' + index).value.replace('-', ''),
                    )
                ) {
                    const personFromList: LegalInsuredPerson = this.modal.app.insuredPersons.persons.find(
                        (person: LegalInsuredPerson): boolean =>
                            person.personCode.replace('-', '') ===
                            this.form.field('personCode_' + index).value.replace('-', ''),
                    )!;
                    this.form.field('firstName_' + index).patch(personFromList.firstName);
                    this.form.field('lastName_' + index).patch(personFromList.lastName);
                    this.form.field('personCode_' + index).patch(personFromList.personCode);
                    this.form
                        .field('startDate_' + index)
                        .patch(moment(personFromList.startDate).format(this.modal.app.DateFormat));
                    this.form.field('insuranceProgram_' + index).patch(personFromList.insuranceProgram.name);
                    if (personFromList.premium!.amount) {
                        this.form
                            .field('premium_' + index)
                            .patch(
                                personFromList.premium!.amount +
                                    ' ' +
                                    useCurrency().currency(personFromList.premium!.currency),
                            );
                    }
                    row.resident = personFromList.resident;
                }
            });
            resolve();
        });
    }

    private hasRowStatusErrors(): boolean {
        let result: boolean = false;
        this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
            if (this.hasRowStatusError(index)) {
                result = true;
            }
        });

        return result;
    }

    private applyRowStatusChecks(): void {
        this.rowStatusHandlerInterval = setInterval(this.updateStatusIcons, this.RowStatusCheckInterval, this);
    }

    private removeRowStatusChecks(): void {
        clearInterval(this.rowStatusHandlerInterval);
    }

    private updateStatusIcons(tab: DeletePersonTab): void {
        const indexDelimiter: string = '_';
        tab.rows.forEach((row: LegalInsuredPerson, index: number): void => {
            if (row.icon !== StatusIcon.Valid) {
                const rowInProgress: FormField | undefined = tab.form.fields().find((field: FormField): boolean => {
                    const fieldName: string = field.name.substring(0, field.name.indexOf(indexDelimiter));

                    return (
                        tab.necessaryFields().includes(fieldName) &&
                        field.name.substring(field.name.indexOf(indexDelimiter) + 1) === String(index) &&
                        (!field.isTouched || !field.isValid || new Value(field.value).isEmpty())
                    );
                });
                if (typeof rowInProgress === 'undefined') {
                    tab.rows[index].icon = StatusIcon.Valid;
                }
            }
            if (row.icon === StatusIcon.Error && tab.form.invalidRows().length === 0) {
                row.icon = StatusIcon.InProgress;
            }
            if (tab.form.invalidRows().includes(index) || tab.hasRowStatusError(index)) {
                tab.rows[index].icon = StatusIcon.Error;
            }
        });
    }

    private deleteInsuredPersons(): Promise<DynamicDictionary> {
        PopupService.getInstance().show(new OnePopup().withType().loading);
        const assembledPersons: DynamicDictionary = this.assembledPersons();
        return RequestService.getInstance()
            .post({ uri: Url.Ajax.legalPersonDeletePersons, content: assembledPersons })
            .then((response: DynamicDictionary): DynamicDictionary => {
                return response;
            });
    }

    private assembledPersons(): DynamicDictionary[] {
        return this.rows.map((row: LegalInsuredPerson, index: number): DynamicDictionary => {
            return {
                id: this.modal.app.insuredPersons.insuredPersonObjectId(this.form.field('personCode_' + index).value),
                personId: this.modal.app.insuredPersons.insuredPersonId(this.form.field('personCode_' + index).value),
                endDate: moment(this.form.field('endDate_' + index).value.startDate).format('YYYY-MM-DD'),
                agreement: { id: this.modal.app.currentAgreement!.id, number: this.modal.app.policyId },
            };
        });
    }

    private applySelectedRows(): void {
        this.rows = this.modal.app.insuredPersons
            .insuredPersonsBySelectedRows(true)
            .map((person: LegalInsuredPerson) => {
                return this.addRow(person);
            });
    }

    private necessaryFields(): string[] {
        return ['firstName', 'lastName', 'personCode', 'startDate', 'endDate', 'insuranceProgram'];
    }

    private addRow(person: LegalInsuredPerson): LegalInsuredPerson {
        return {
            id: person.id,
            status: person.status,
            firstName: person.firstName,
            lastName: person.lastName,
            personCode: person.personCode,
            resident: person.resident,
            startDate: person.startDate,
            endDate: person.endDate,
            birthDate: person.birthDate,
            insuranceProgram: person.insuranceProgram,
            premium: { amount: person.premium?.amount, currency: person.premium?.currency },
            isVisible: true,
            processingRecords: person.processingRecords,
            icon: StatusIcon.InProgress,
            relationIc: person.relationIc,
            email: '',
        } as LegalInsuredPerson;
    }

    private fieldIndex(field: FormField): number {
        return Number(field.name.substring(field.name.indexOf('_') + 1));
    }

    private get selectedVisibleRows(): LegalInsuredPerson[] {
        return this.rows.filter(
            (row: LegalInsuredPerson, index: number) => row.isVisible && this.form.field('selected_' + index).value,
        );
    }

    private get visibleInvalidRows(): LegalInsuredPerson[] {
        return this.rows.filter(
            (row: LegalInsuredPerson, index: number) => row.isVisible && row.icon === StatusIcon.Error,
        );
    }

    private translated(key: string, replacements?: DynamicDictionary): string {
        return Translations.getInstance().localized(key, 'legal', replacements);
    }

    private updateRowVisibility(): void {
        const searchIndexes: number[] = [];
        const foundFields: FormField[] = this.form
            .fields()
            .filter((field: FormField): boolean =>
                new LookupString()
                    .withSearchTerm(this.filter)
                    .withTargetValue(field.value)
                    .useStringStartsWith()
                    .match(),
            );
        foundFields.forEach((field: FormField): void => {
            searchIndexes.push(this.fieldIndex(field));
        });
        this.rows.forEach((row: LegalInsuredPerson, index: number): void => {
            if (![...new Set(searchIndexes)].includes(index)) {
                row.isVisible = false;
            }
        });
    }

    private resetRowVisibility(): void {
        this.rows.forEach((row: LegalInsuredPerson): void => {
            row.isVisible = true;
        });
    }

    private resetFilter(): void {
        this.filter = '';
        this.resetRowVisibility();
    }

    private resetRows(): void {
        DeletePersonsFromExcelTab.getInstance().excelRows = [];
        this.rows = [];
    }

    private removePersons(): Promise<void> {
        return new Promise((resolve) => {
            const rowsToRemove: FormField[] = this.form
                .fields()
                .filter((field: FormField): boolean => field.name.includes('selected_') && field.value);
            const rows: LegalInsuredPerson[] = [];
            rowsToRemove.reverse().forEach((field: FormField): void => {
                if (this.isRowVisible(this.fieldIndex(field))) {
                    rows.push(this.rows[this.fieldIndex(field)]);
                    this.rows.splice(this.fieldIndex(field), 1);
                    this.form.removeRow(this.fieldIndex(field));
                }
            });
            this.modal.app.insuredPersons.deselectRows(rows);
            resolve();
        });
    }

    private removeInvalidPersons(): Promise<void> {
        return new Promise((resolve) => {
            const rowsToRemove: number[] = this.rows
                .filter((row: LegalInsuredPerson): boolean => row.icon === StatusIcon.Error)
                .map((row: LegalInsuredPerson): number => this.rows.indexOf(row));
            const rows: LegalInsuredPerson[] = [];
            rowsToRemove.reverse().forEach((rowIndex: number): void => {
                if (this.isRowVisible(rowIndex)) {
                    rows.push(this.rows[rowIndex]);
                    this.rows.splice(rowIndex, 1);
                    this.form.removeRow(rowIndex);
                }
            });
            this.modal.app.insuredPersons.deselectRows(rows);
            resolve();
        });
    }

    private setupForm(): void {
        this.form.addField(new FormField('selectAll', false));
        this.rows.forEach((row: DynamicDictionary, index: number): void => {
            this.form.addField(new FormField('selected_' + index));
            this.form.addField(new FormField('firstName_' + index));
            this.form.addField(new FormField('lastName_' + index));
            this.form.addField(new FormField('personCode_' + index, '', '', Sanitizer.cleanPersonCode));
            this.form.addField(new FormField('startDate_' + index));
            this.form.addField(new FormField('endDate_' + index));
            this.form.addField(new FormField('insuranceProgram_' + index));
            this.form.addField(new FormField('premium_' + index));
        });
    }

    private addIdentityNumberFieldValidators(): void {
        this.form
            .fields()
            .filter((field: FormField): boolean => field.name.includes('personCode'))
            .forEach(
                (field: FormField, index: number): FormField =>
                    field.addValidators(this.validators.identityNumberValidator(index)),
            );
    }

    private applyFormFieldValidators(): void {
        this.addRequiredValidators();
        this.addIdentityNumberFieldValidators();
        this.addEndDateValidators();
    }

    private addRequiredValidators(): void {
        this.form
            .fields()
            .filter((field: FormField): boolean => field.name.includes('endDate'))
            .forEach((field: FormField): FormField => field.addValidators('required'));
    }

    private addEndDateValidators(): void {
        this.form
            .fields()
            .filter((field: FormField): boolean => field.name.includes('endDate'))
            .forEach(
                (field: FormField, index: number): FormField =>
                    field.addValidators(this.validators.policyEndDateValidator(index)),
            );
    }
}
