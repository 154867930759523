export const useConversions = (): ConversionsParams => {
    /**
     * @var string[] unitsReference
     * Bytes are converted to max available unit as index from this array.
     */
    const unitsReference: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const bytesToMb = (valueInBytes: number, decimals: number = 0): number => {
        return convertedBytes(valueInBytes, decimals, 2);
    };

    function convertedBytes(valueInBytes: number, decimals: number = 0, unit: number | null = null): number {
        const bytes: number = 1024;
        /**
         * @var number conversionUnit
         * This calculation result is an index of @var unitsReference
         */
        const conversionUnit: number = unit ?? Math.floor(Math.log(valueInBytes) / Math.log(bytes));

        return parseFloat((valueInBytes / Math.pow(bytes, conversionUnit)).toFixed(decimals));
    }

    return {
        bytesToMb,
    };
};

export interface ConversionsParams {
    bytesToMb: (valueInBytes: number, decimals?: number) => number;
}
