import Validation from '@/Services/validation.service';
import Form from '@/Assets/Libraries/Form/Form';
import { Ref, ref } from 'vue';
import ClaimOption from '@/Interfaces/claim.option.interface';

export default class CascoVehicleStepValidators {
    private form: Form = new Form();
    private driverIsPortalUser: Ref<boolean> = ref(false);
    private driverIsResident: Ref<boolean> = ref(false);
    private driverIsNonResident: Ref<boolean> = ref(false);
    private panelByName: ((panelName: string) => ClaimOption) | undefined;
    private readonly IdentityNumberMinDigits: number = 10;

    public init(
        form: Form,
        driverIsPortalUser: Ref<boolean>,
        driverIsResident: Ref<boolean>,
        panelByName: (panelName: string) => ClaimOption,
    ): void {
        this.form = form;
        this.driverIsPortalUser = driverIsPortalUser;
        this.driverIsResident = driverIsResident;
        this.panelByName = panelByName;
    }

    public residentIdentityNumberValidators() {
        return {
            isValidIdentityNumber: () => {
                return !this.driverIsPortalUser.value &&
                    this.driverIsResident.value &&
                    this.form.field('identityNumberResident').isTouched &&
                    !this.form.field('identityNumberResident').isEmpty()
                    ? Validation.isValidNaturalPersonCode(this.form.field('identityNumberResident').value)
                    : true;
            },
        };
    }

    public driverNameValidators(fieldName: string) {
        return {
            isValidDriverName: () => {
                return !this.driverIsPortalUser.value &&
                    this.driverIsNonResident.value &&
                    this.form.field(fieldName).isTouched &&
                    !this.form.field(fieldName).isEmpty()
                    ? Validation.isValidCaption(this.form.field(fieldName).value)
                    : true;
            },
        };
    }

    public radioFieldValidators(fieldName: string) {
        return {
            optionSelected: () => {
                return this.form.field(fieldName).isTouched && this.panelByName!(fieldName).visible
                    ? this.form.field(fieldName).value.selected !== ''
                    : true;
            },
        };
    }

    public requiredFieldValidator(fieldName: string, type: string): object {
        return {
            isRequired: () => {
                return this.form.field(this.fieldType(fieldName)).value.includes(type)
                    ? !this.form.field(fieldName).isEmpty()
                    : true;
            },
        };
    }

    public emailFieldValidator(fieldName: string): string {
        return this.form.field(this.fieldType(fieldName)).value !== 'bankBeneficiary' ? 'required' : '';
    }

    public phoneFieldValidator(fieldName: string): object {
        return {
            isValidPhoneNumber: () => {
                return this.form.field(this.fieldType(fieldName)).value !== 'bankBeneficiary'
                    ? this.form.field(fieldName).value.phone !== ''
                    : true;
            },
        };
    }

    public identityNumberValidator(fieldName: string): object {
        return {
            isValidIdentityNumber: () => {
                return this.form.field(this.fieldType(fieldName)).value.includes('private')
                    ? Validation.isValidNaturalPersonCode(this.form.field(fieldName).value)
                    : true;
            },
        };
    }

    public registrationNumberValidator(fieldName: string): object {
        return {
            isValidRegistrationNumber: () => {
                return this.form.field(this.fieldType(fieldName)).value.includes('corporate')
                    ? Validation.isValidCompanyRegistrationNumber(this.form.field(fieldName).value)
                    : true;
            },
        };
    }

    public bankFieldValidator(fieldName: string): object {
        return {
            isrequired: () => {
                return this.form.field(this.fieldType(fieldName)).value.includes('bank')
                    ? !this.form.field(fieldName).isEmpty()
                    : true;
            },
        };
    }

    private fieldType(fieldName: string): string {
        let fieldType: string = '';
        switch (true) {
            case fieldName.includes('policyHolder'):
                fieldType = 'policyHolderType';
                break;
            case fieldName.includes('insuredPerson'):
                fieldType = 'insuredPersonType';
                break;
            case fieldName.includes('beneficiary'):
                fieldType = 'beneficiaryType';
                break;
            default:
        }

        return fieldType;
    }
}
