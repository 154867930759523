import { Renters } from '@/Apps/Renters/Interfaces/RentersInterface';
import { reactive, Ref, ref, UnwrapNestedRefs } from 'vue';
import SpaFormStorageService from '@/Services/SpaFormStorageService';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { StoreDefinition } from 'pinia';
import { useStore } from '@/Composables/Store/Store';
import { RentersStore } from '@/Composables/Store/Types/Renters';
import PhoneField from '@/Interfaces/phone.field.interface';
import RequestService from '@/Services/request.service';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { useDefine } from '@/Composables/Define';
import { default as ErrorService } from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import DateRange from '@/Interfaces/date.range.interface';
import { CountryComponentParams } from '@/Components/Inputs/InputCountry/CountryComponentParams';

interface RenterFormFields {
    programIc: string;
    products: Renters[];
    marketingConsents: DynamicDictionary;
    insuredAddress: DynamicDictionary;
    insuredFirstName: string;
    insuredLastName: string;
    insuredIdentityNumber: string;
    insuredCountry: CountryComponentParams;
    insuredBirthDate: string;
    agreeTerms: boolean;
    holderEmail: string;
    coverage: string;
    additionalOptions: DynamicDictionary;
    insuredIsResident: boolean;
    insuredIsAuthenticated: boolean;
    policyStartDate: DateRange;
    policyHolderSameAsInsured: boolean;
    policyHolderIsResident: boolean;
    policyHolderCountry: CountryComponentParams;
    policyHolderFirstName: string;
    policyHolderLastName: string;
    policyHolderEmail: string;
    policyHolderPhone: PhoneField;
    policyHolderIdentityNumber: string;
    policyHolderBirthDate: string;
}

export default class RentersService {
    public renters: Ref<Renters[]> = ref([] as Renters[]);
    public fields!: UnwrapNestedRefs<RenterFormFields>;
    public products: Ref<Renters[]> = ref([]);
    public store: StoreDefinition<string, RentersStore> = useStore().renters();
    private static instance: RentersService;
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();

    public static getInstance(): RentersService {
        if (!RentersService.instance) {
            RentersService.instance = new RentersService();
            RentersService.instance.init();
        }

        return RentersService.instance;
    }

    public updateRoute(): void {
        this.store().updateRoute();
    }

    public destroyRoute(): void {
        this.store().destroyRoute();
    }

    public selectedProduct(): Renters {
        const result: Renters | undefined = this.fields.programIc
            ? this.products.value.find((program: Renters): boolean => program.id === this.fields.programIc)
            : undefined;
        if (!result) {
            throw 'Product not found';
        }

        return result;
    }

    public async fetchProducts(): Promise<void> {
        return this.products.value.length === 0
            ? RequestService.getInstance()
                  .get({
                      uri: Url.Ajax.renters,
                  })
                  .then((response: AxiosResponse): void => {
                      if (useDefine().validResponse(response)) {
                          this.products.value = response.data.data.body.products;
                      } else {
                          throw response;
                      }
                  })
                  .catch((reason: DynamicDictionary): void => {
                      ErrorService.log(ErrorType.Error, 'Renters policy / fetchProducts', reason);
                      throw reason;
                  })
            : new Promise((resolve) => resolve());
    }

    private init(): void {
        this.fields = reactive(this.spaFormStorageService.formStorageFields());
        this.spaFormStorageService.applyDataProviderCallback((): RenterFormFields => this.fields);
    }
}
