import {
    Router,
    useRouter,
    RouteRecord,
    RouteLocationNormalized,
    NavigationGuardNext,
    RouteLocationNormalizedLoaded,
} from 'vue-router';
import ValidRoutes from '@/Services/ValidRoutesService';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import { useScroll } from '@/Composables/Scroll';

export default class TwoWayGuardsService {
    private static instance: TwoWayGuardsService;
    private router: Router = useRouter();

    public static getInstance(): TwoWayGuardsService {
        if (!TwoWayGuardsService.instance) {
            TwoWayGuardsService.instance = new TwoWayGuardsService();
            TwoWayGuardsService.instance.prepareRoutes();
        }
        return TwoWayGuardsService.instance;
    }

    public init(): void {
        this.router.beforeEach(
            (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext): void => {
                const routeIndex: number = this.routeIndexByName(to.name as string);
                let hasInvalidRoutes: boolean = false;
                TwoWayGuardsService.instance.router.getRoutes().forEach((route: RouteRecord, index: number): void => {
                    if (index < routeIndex && !ValidRoutes.getInstance().routeIsValid(index)) {
                        hasInvalidRoutes = true;
                    }
                });
                if (hasInvalidRoutes) {
                    next(false);
                } else {
                    next();
                }
            },
        );
        this.router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded): void => {
            useScroll().scrollToTopInstantly();
            TwoWayCommunication.getInstance().actionsAfterRouteChanged(from.name as string, to.name as string);
        });
    }

    public applyStepValidity(step: number, isValid: boolean, clearAhead: boolean = false): void {
        for (let i: number = 0; i <= step; i++) {
            ValidRoutes.getInstance().applyRoute(i, isValid);
        }
        if (clearAhead) {
            TwoWayGuardsService.instance.router.getRoutes().forEach((route: RouteRecord, index: number): void => {
                if (index > step) {
                    ValidRoutes.getInstance().applyRoute(index, false);
                }
            });
        }
    }

    private prepareRoutes(): void {
        TwoWayGuardsService.instance.router.getRoutes().forEach((value: RouteRecord, index: number): void => {
            ValidRoutes.getInstance().applyRoute(index, false);
        });
    }

    private routeIndexByName(routeName: string): number {
        let result: number = 0;
        TwoWayGuardsService.instance.router.getRoutes().forEach((route: RouteRecord, index: number): void => {
            if (route.name === routeName) {
                result = index;
            }
        });

        return result;
    }
}
