import PopupBase from '@/Assets/Libraries/Popups/PopupBase';
import PopupType from '@/Enums/PopupTypeEnum';
import { VueApp } from '@/Types/VueAppType';

export default class SelectAccount extends PopupBase {
    public constructor() {
        super();
        this.applyType(PopupType.SelectAccount);
    }

    public withHeader(): SelectAccount {
        this.applyHeaderVisibility(true);

        return this;
    }

    public withoutHeader(): SelectAccount {
        this.applyHeaderVisibility(false);

        return this;
    }

    public withCloseButton(): SelectAccount {
        this.applyCloseButtonVisibility(true);

        return this;
    }

    public withoutCloseButton(): SelectAccount {
        this.applyCloseButtonVisibility(false);

        return this;
    }

    public withClickCallback(model: string | Function, context: VueApp | null = null): SelectAccount {
        this.applyOnClickCallback(model, context);

        return this;
    }
}
