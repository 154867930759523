<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import ProductBlockItem from '@/Components/Other/ProductBlock/Interfaces/ProductBlockItemInterface';
import { useScroll } from '@/Composables/Scroll';
import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import { useTranslate } from '@/Composables/Translate';
import ProductBlockItemBuilder from '@/Components/Other/ProductBlock/Builders/ProductBlockItemBuilder';
import FormField from '@/Assets/Libraries/Form/FormField';
import Form from '@/Assets/Libraries/Form/Form';
import { AxiosResponse } from 'axios';
import { Bicycle } from '@/Interfaces/Resources/Bicycles/BicycleInterface';
import { CoverageRisk } from '@/Interfaces/Resources/Bicycles/CoverageRiskInterface';
import { CoveragePlan } from '@/Interfaces/Resources/Bicycles/CoveragePlanInterface';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { CoveredPopupBuilder } from '@/Pages/Bicycle/Classes/CoveredPopupBuilder';
import CoveredPopupTypes from '@/Components/Popups/CoveredPopup/Enums/CoveredPopupTypes';
import { ErrorInterface, useError } from '@/Composables/Error';
import { take } from 'rxjs/operators';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const { translate, translateForType } = useTranslate();
        const { logError }: ErrorInterface = useError();
        const request: AxiosParams = useAxios();
        const stepsSubmitter = useStepsSubmitter();
        const CurrentStep: number = 1;
        const Facility: string = 'bicycle_policy';
        const TranslationType: string = 'bicycle_policy';
        const ProductsContainerId: string = 'bicycleProducts';
        const BadgeTextSuffix: string = '_BADGE_TEXT';
        const IcTranslationSuffix: string = '_MAIN';
        const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
        const form: Form = new Form({ useValidationV2: true });
        const programs: Ref<ProductBlockItem[]> = ref([]);
        const program: Ref<string> = ref('');

        function onAppReady(): void {
            btaBase.dynamicStepper.enableAll();
            fetchProducts();
        }

        function fetchProducts(): void {
            request
                .get(Url.Ajax.bicycles, {})
                .then((response: AxiosResponse): void => {
                    const policies: DynamicDictionary = response.data.data.body;
                    buildProductBlocks(policies.policies);
                    coveredPopup
                        .withCoveredTitle('covered_compare')
                        .withCoveredType(CoveredPopupTypes.CoveredPopupBicycleMulti)
                        .withTranslationKey('bicycle_policy')
                        .withOnSelectClick(onSelectProductClick as Function)
                        .withContent(policies.policies)
                        .build();
                    form.addField(new FormField('product-panel-blocks'));
                    form.setReady();
                    stepsSubmitter.addForm(form);
                })
                .catch((reason: DynamicDictionary): void => {
                    Error.log(ErrorType.Error, 'Bicycle policy / Policy APP / onAppReady', reason);
                });
        }

        function onSubmitStep(): void {
            useScroll().scrollToId(ProductsContainerId).then();
        }

        function onSelectProductClick(programId: string[]): void {
            program.value = programId[0];
            btaBase.captcha
                .executeCaptcha(submit)
                .then()
                .catch((reason: string): void => {
                    logError(ErrorType.Error, 'onSubmitStep', reason);
                });
        }

        function onCustomProductClick(): void {
            showCoveredPopup();
        }

        function buildProductBlocks(products: Bicycle[]): void {
            products.forEach((policy: Bicycle): void => {
                const programIc: string = policy.id.toLowerCase();
                const productBlockItemBuilder: ProductBlockItemBuilder = new ProductBlockItemBuilder();
                const productName: string = translateForType('bicycle_product_' + programIc, TranslationType);
                const price: number = policy.price;
                const badgeText: string = translateForType(policy.id + BadgeTextSuffix, TranslationType);
                const territoryText: string = translateForType(
                    policy.params.insuranceTerritoryIc + IcTranslationSuffix,
                    TranslationType,
                );
                const discountPostfix: string = translate('euro_per_day');
                const customModule: string = 'AppProductBlockInnerBicyclePolicy';
                const selectButtonText: string = translateForType('select_' + programIc, TranslationType);
                const customButtonText: string = translateForType('what_covered_bicycle', TranslationType);
                const featuredRisks: CoverageRisk[] = policy.coveragePlans
                    .map((plan: CoveragePlan): CoverageRisk[] => plan.risks)
                    .flat()
                    .filter((risk: CoverageRisk): boolean => risk.isFeatured);
                const dynamicContent: DynamicDictionary = {
                    risks: featuredRisks,
                    territory: territoryText,
                };
                productBlockItemBuilder
                    .withProductId(policy.id)
                    .withProductName(productName)
                    .withDiscountSum(price)
                    .withDiscountSumPostfix(discountPostfix)
                    .withDynamicContent(customModule, dynamicContent)
                    .withButtonSelectTitle(selectButtonText)
                    .withButtonCustomTitle(customButtonText);
                if (policy.params.withBadge) {
                    productBlockItemBuilder.withBadgeText(badgeText);
                }
                if (policy.params.defaultOnMobile) {
                    productBlockItemBuilder.asDefaultOnMobile();
                }
                programs.value.push(productBlockItemBuilder.build());
            });
        }

        function submit(token: string): void {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            prepareSubmit(token);
            stepsSubmitter.proceedStep('', 0);
        }

        function prepareSubmit(token: string): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('productId', program.value);
            stepsSubmitter.addSubmitCustomParam('g-recaptcha-response', token);
        }

        function showCoveredPopup(): void {
            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                stepsSubmitter,
                programs,
                form,
                coveredPopup,
                onAppReady,
                onSubmitStep,
                onSelectProductClick,
                onCustomProductClick,
                submit,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.initReCaptcha();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.onAppIsPreparedAndReady.pipe(take(1)).subscribe((): void => {
            this.onAppReady();
        });
    },
});
</script>
