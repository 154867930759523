import { LimitedVariant } from '@/Types/LimitedVariantType';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import ResponseCode from '@/Enums/ResponseCodeEnum';

export const useDefine = (): DefineParams => {
    const isSet = (property: LimitedVariant | undefined): boolean => {
        return typeof property !== 'undefined' && property !== null;
    };

    const validResponse = (response: DynamicDictionary): boolean => {
        return (
            isSet(response.status) &&
            response.status === ResponseCode.Ok &&
            Object.keys(response.data).length > 0 &&
            !isErrorResponse(response)
        );
    };

    const isErrorResponse = (response: DynamicDictionary): boolean => {
        return typeof response.data.errors !== 'undefined';
    };

    const isCallable = (callable: DynamicDictionary | Function | string): boolean => {
        return typeof callable === 'function';
    };

    const endsWith = (value: string, substring: string): boolean => {
        return value.indexOf(substring, value.length - substring.length) !== -1;
    };

    const isVisibleVerticalScrollbar = (): boolean => {
        let result: boolean = false;
        const windowHeight: number | undefined = $(window).height();
        if (windowHeight && windowHeight > 0) {
            const bodyElement: HTMLElement = $('body').get(0) as HTMLElement;
            const scrollHeight: number = Math.max(bodyElement.clientHeight, bodyElement.scrollHeight);
            result = windowHeight < scrollHeight;
        }

        return result;
    };

    const isExternalLink = (link: string): boolean => {
        const pattern: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

        return pattern.test(link);
    };

    const assocArrayLength = (object: DynamicDictionary): number => {
        return object ? Object.keys(object).length : 0;
    };
    const arrayContains = (haystack: LimitedVariant[], needle: LimitedVariant): boolean => {
        let result: boolean = false;
        if (haystack) {
            const length: number = haystack.length;
            for (let i: number = 0; i < length; i++) {
                if (haystack[i] === needle) {
                    result = true;
                    break;
                }
            }
        }

        return result;
    };

    const arrayContainsIndex = (haystack: LimitedVariant[], needle: LimitedVariant): number => {
        let result: number = -1;
        if (haystack) {
            const length: number = haystack.length;
            for (let i: number = 0; i < length; i++) {
                if (haystack[i] === needle) {
                    result = i;
                    break;
                }
            }
        }

        return result;
    };

    const objectMembersCount = (object: DynamicDictionary): number => {
        let result: number = 0;
        if (object.constructor === Object) {
            result = Object.keys(object).length;
        }

        return result;
    };

    const validNumber = (val: DynamicDictionary): number | DynamicDictionary => {
        return isNumeric(val) ? Number(val) : val;
    };

    const isNumeric = (value: any): boolean => {
        return typeof value === 'number' && !isNaN(value);
    };

    const isLinkAnchor = (url: string): boolean => {
        const anchor: string = url.split('#').pop() ?? '';

        return url.length !== anchor.length && anchor !== '';
    };

    return {
        isSet,
        validResponse,
        isErrorResponse,
        isCallable,
        endsWith,
        isVisibleVerticalScrollbar,
        isExternalLink,
        assocArrayLength,
        arrayContains,
        arrayContainsIndex,
        objectMembersCount,
        validNumber,
        isLinkAnchor,
    };
};

export interface DefineParams {
    isSet: (property: LimitedVariant | undefined) => boolean;
    validResponse: (response: DynamicDictionary) => boolean;
    isErrorResponse: (response: DynamicDictionary) => boolean;
    isCallable: (callable: DynamicDictionary | Function | string) => boolean;
    endsWith: (value: string, substring: string) => boolean;
    isVisibleVerticalScrollbar: () => boolean;
    isExternalLink: (link: string) => boolean;
    assocArrayLength: (object: DynamicDictionary) => number;
    arrayContains: (haystack: LimitedVariant[], needle: LimitedVariant) => boolean;
    arrayContainsIndex: (haystack: LimitedVariant[], needle: LimitedVariant) => number;
    objectMembersCount: (object: DynamicDictionary) => number;
    validNumber: (val: DynamicDictionary) => number | DynamicDictionary;
    isLinkAnchor: (url: string) => boolean;
}
