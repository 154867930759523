import PersonState from '@/Enums/PersonStateEnum';
import StatusIcon from '@/Components/Tooltips/StatusIcon/StatusIcon.enum';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import PersonCodeGenerator from '@/Assets/Libraries/Generators/PersonCodeGenerator';
import moment from 'moment';
import ModalTab from '@/Pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import LegalInsuredPerson from '@/Pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import LegalInsuranceProgram from '@/Pages/LegalPerson/Workbench/Interfaces/LegalInsuranceProgramInterface';
import AddPersonTab from '@/Pages/LegalPerson/Workbench/Common/Tabs/AddPersonTab';
import Validation from '@/Services/validation.service';
import AppCountry from '@/Assets/Libraries/App/AppCountry';

export default class AddPersonsFromExcelTab extends ModalTab {
    public fileBeforeUploadCallback: Function = this.fileBeforeUploadCallbackReceiver.bind(this);
    public excelRows: LegalInsuredPerson[] = [];

    private static instance: AddPersonsFromExcelTab;
    private readonly ResidentRowIndex: number = 0;
    private readonly CountryRowIndex: number = 1;
    private readonly EmailRowIndex: number = 2;
    private readonly PersonCodeRowIndex: number = 3;
    private readonly BirthDateRowIndex: number = 4;
    private readonly FirstNameRowIndex: number = 5;
    private readonly LastNameRowIndex: number = 6;
    private readonly InsuranceProgramRowIndex: number = 7;
    private readonly StartDateRowIndex: number = 8;
    private personCodeGenerator: PersonCodeGenerator = new PersonCodeGenerator(new AppCountry().iso());

    public static getInstance(): AddPersonsFromExcelTab {
        if (!AddPersonsFromExcelTab.instance) {
            AddPersonsFromExcelTab.instance = new AddPersonsFromExcelTab();
        }

        return AddPersonsFromExcelTab.instance;
    }

    public fileUploadReceiver(rows: DynamicDictionary[]): void {
        rows.shift();
        const personCodes: string[] = this.modal.app.insuredPersons.persons.map(
            (person: LegalInsuredPerson) => person.personCode,
        );
        const result: LegalInsuredPerson[] = rows.map((row: DynamicDictionary): LegalInsuredPerson => {
            const residentStatus: boolean = Validation.isValidNaturalPersonCode(
                row[this.PersonCodeRowIndex].toString(),
            );
            const personCode: string = this.personCodeGenerator
                .withExistingCodes(personCodes)
                .withBirthDate(moment(row[this.BirthDateRowIndex]).toDate())
                .withPersonCode(row[this.PersonCodeRowIndex].toString())
                .generate();
            return {
                status: PersonState.Active,
                firstName: row[this.FirstNameRowIndex],
                lastName: row[this.LastNameRowIndex],
                personCode: personCode,
                resident: residentStatus,
                countryIc: row[this.CountryRowIndex],
                email: row[this.EmailRowIndex],
                startDate: row[this.StartDateRowIndex],
                birthDate: row[this.BirthDateRowIndex],
                insuranceProgram: this.insuranceProgramByName(row[this.InsuranceProgramRowIndex]) || { id: '' },
                isVisible: true,
                isSelected: false,
                icon: StatusIcon.InProgress,
                processingRecords: [],
            } as LegalInsuredPerson;
        });
        this.updateRows(result).then((): void => {
            AddPersonTab.getInstance().patchRows();
            this.modal.app.businessModal.hideModalPopup();
        });
    }

    public updateRows(rows: LegalInsuredPerson[]): Promise<void> {
        return new Promise((resolve): void => {
            this.excelRows = rows;
            resolve();
        });
    }

    private fileBeforeUploadCallbackReceiver(file: File): void {
        const processingText: string = this.modal.app.translated('add_persons_excel_processing_process');
        this.modal.app.businessModal.currentLoadingFileName = processingText.replace('%file_name%', file.name);
    }

    private insuranceProgramByName(name: string): LegalInsuranceProgram | undefined {
        return this.modal.app.legalAvailablePrograms.find(
            (program: LegalInsuranceProgram): boolean => program.name === name,
        );
    }
}
