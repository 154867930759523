<script setup lang="ts">
import { reactive } from 'vue';
import AppButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import AppButtonPrimaryAlternative from '@/Components/Buttons/ButtonPrimaryAlternative/ButtonPrimaryAlternative.vue';
import AppButtonSecondary from '@/Components/Buttons/ButtonSecondary/ButtonSecondary.vue';
import AppButtonTertiary from '@/Components/Buttons/ButtonTertiary/ButtonTertiary.vue';
import AppSvg from '@/Components/Other/Svg/Svg.vue';

interface ButtonState {
    clicks: number;
    isLoading: boolean;
}

const buttonStates: {
    primary: ButtonState;
    primaryAlternative: ButtonState;
    secondary: ButtonState;
    tertiary: ButtonState;
    disabled: ButtonState;
} = reactive({
    primary: { clicks: 0, isLoading: false },
    primaryAlternative: { clicks: 0, isLoading: false },
    secondary: { clicks: 0, isLoading: false },
    tertiary: { clicks: 0, isLoading: false },
    disabled: { clicks: 0, isLoading: false },
});

function onClick(button: ButtonState): void {
    button.isLoading = true;
    button.clicks++;
    setTimeout(() => (button.isLoading = false), 1000);
}
</script>

<template>
    <div class="container">
        <app-button-primary :loading="buttonStates.primary.isLoading" @click="onClick(buttonStates.primary)">
            <template #start>
                <app-svg src="images/one/arrow-left.svg"></app-svg>
            </template>
            Primary ({{ buttonStates.primary.clicks }})
        </app-button-primary>
        <app-button-primary-alternative
            :loading="buttonStates.primaryAlternative.isLoading"
            @click="onClick(buttonStates.primaryAlternative)"
        >
            <template #start>
                <app-svg src="images/one/arrow-left.svg"></app-svg>
            </template>
            Primary alternative ({{ buttonStates.primaryAlternative.clicks }})
        </app-button-primary-alternative>
        <app-button-secondary :loading="buttonStates.secondary.isLoading" @click="onClick(buttonStates.secondary)">
            <template #start>
                <app-svg src="images/one/arrow-left.svg"></app-svg>
            </template>
            Secondary ({{ buttonStates.secondary.clicks }})
        </app-button-secondary>
        <app-button-tertiary :loading="buttonStates.tertiary.isLoading" @click="onClick(buttonStates.tertiary)">
            <template #start>
                <app-svg src="images/one/arrow-left.svg"></app-svg>
            </template>
            Tertiary ({{ buttonStates.tertiary.clicks }})
        </app-button-tertiary>
        <app-button-tertiary
            :loading="buttonStates.disabled.isLoading"
            :disabled="true"
            @click="onClick(buttonStates.disabled)"
        >
            <template #start>
                <app-svg src="images/one/arrow-left.svg"></app-svg>
            </template>
            Disabled ({{ buttonStates.disabled.clicks }})
        </app-button-tertiary>
    </div>
</template>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    :deep(.loading) {
        margin: 0;
    }
}
</style>
