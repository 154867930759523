<script setup lang="ts">
import { getCurrentInstance, onMounted } from 'vue';
import { Router, useRouter } from 'vue-router';
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import Storage from '@/Apps/ActivePlus/Services/Storage';
import { useTranslate } from '@/Composables/Translate';
import StepsGuard from '@/Apps/ActivePlus/Services/StepsGuard';
import OneBaseService from '@/Services/OneBaseService';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import OneBase from '@/Interfaces/OneBaseInterface';

const base: OneBase = OneBaseService.getInstance();
const router: Router = useRouter();
const { translate } = useTranslate();
const storage: Storage = Storage.getInstance();
const form: Form = new Form();

onMounted(() => {
    base.applySpa(getCurrentInstance());
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    setupForm();
});

function setupForm(): void {
    resetForm();
    form.setReady();
}

function resetForm(): void {
    form.fields().forEach((field: FormField): void => {
        field.markAsUntouched();
    });
}
</script>

<template>
    <div class="container content-text">
        <app-custom-form v-if="form.isReady()" :id="'form-summary-and-payment'" :form="form" class="form">
        </app-custom-form>
    </div>
</template>
