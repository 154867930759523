<script setup lang="ts"></script>

<template>
    <div class="page">
        <div v-if="$slots.title" class="page-header">
            <slot name="title"></slot>
        </div>
        <div class="page-content">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.page {
    display: grid;
    gap: var(--size-big);
    width: 100%;
    max-width: 960px;

    .page-header {
        :deep(.title) {
            font-weight: 600;
            font-size: var(--font-size-big);
            color: var(--text-color-default);
            line-height: var(--line-height-basic);
            display: block;
        }
    }

    .page-content {
        display: grid;
        gap: var(--size-big);
    }

    .page-content > *:last-child {
        margin-bottom: var(--size-big);
    }
}
</style>
