import SettingsService from '@/Services/settings.service';

export default class EmailValidator {
    public isValidEmail(value: string): boolean {
        const emailPattern: RegExp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return value === '' ? true : emailPattern.test(String(value).toLowerCase());
    }

    public isValidRestrictedEmail(value: string): boolean {
        const settings: SettingsService = SettingsService.getInstance();
        const restrictedDomains: string[] = settings.restrictedEmailDomains();
        const isRestrictedDomain = restrictedDomains
            .map((domain: string) => (domain.startsWith('.') ? domain : '@' + domain))
            .some((domain) => String(value).endsWith(domain));
        return !isRestrictedDomain;
    }
}
