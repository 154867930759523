<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useAxios } from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { useNavigate } from '@/Composables/Navigate';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useDefine } from '@/Composables/Define';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const CurrentStep: number = 1;
        const Facility: string = 'private-onboarding';

        const currentItemId: Ref<number> = ref(0);
        const updateDetailsRedirectUrl: Ref<string> = ref('');

        function pinIsActive(pinId: number): boolean {
            return currentItemId.value === pinId;
        }

        function nextTip(): void {
            currentItemId.value++;
        }

        function applyRedirectUrl(value: string): void {
            updateDetailsRedirectUrl.value = value;
        }

        function closeOnboarding(): void {
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);
            useAxios()
                .get(Url.Ajax.disableOnBoarding)
                .then((value: AxiosResponse<any>): void => {
                    if (useDefine().validResponse(value)) {
                        useNavigate().navigate(updateDetailsRedirectUrl.value);
                    } else {
                        btaBase.lockInput(false);
                        btaBase.error.show(ErrorType.Error, 'disableOnBoarding', value);
                    }
                })
                .catch((reason: any): void => {
                    btaBase.lockInput(false);
                    btaBase.error.show(ErrorType.Error, 'disableOnBoarding', reason);
                });
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                currentItemId,
                updateDetailsRedirectUrl,
                pinIsActive,
                nextTip,
                applyRedirectUrl,
                closeOnboarding,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(false);
    },
});
</script>
