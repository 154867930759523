<script setup lang="ts">
import { PropType, Ref, computed } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/Assets/Libraries/Form/FormField';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/Interfaces/image.tag.attributes';

const props = defineProps({
    formField: { type: Object as PropType<FormField<DynamicDictionary>>, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    cardNumbers: { type: String, default: '' },
    cardStatus: { type: String, default: '' },
    showAgreeTerms: { type: Boolean, default: true },
    type: { type: String as PropType<'add' | 'update'>, default: '' },
});
const emit = defineEmits(['card-widget-submit']);
const { translate } = useTranslate();
const { imgTag } = useHtml();

const segmentMask: string = '&nbsp;&bull;&bull;&bull;&bull;&nbsp;';
const warningIcon: Ref<string> = computed((): string => {
    return imgTag(warningIconAttributes());
});
const buttonText: Ref<string> = computed((): string => {
    const translationId: string =
        props.type === 'add' ? 'credit_card_widget_add_card' : 'credit_card_widget_button_text';

    return translate(translationId);
});
const numberMask: Ref<string> = computed((): string => {
    return (
        '<div class="card-mask">' +
        '<div class="masked-numbers">' +
        '<span class="mask">' +
        segmentMask +
        '</span>' +
        props.cardNumbers +
        '</div></div>'
    );
});
const isActiveCard: Ref<boolean> = computed((): boolean => {
    return props.cardStatus === 'active';
});

function validateAndSubmit(): void {
    emit('card-widget-submit');
}

function warningIconAttributes(): ImageTagAttributes {
    const source: string = 'images/one/circle-exclamation-mark.svg';
    return {
        class: '',
        src: source,
        width: 36,
        height: 24,
    };
}
</script>

<template>
    <div :id="'credit-card-widget'" class="credit-card-widget">
        <div v-if="type === 'update'" class="flex align-center heavier small">
            <div class="icon" v-html="warningIcon"></div>
            {{ translate('credit_card_widget_title_warning') }}
        </div>
        <div v-if="type === 'add'" class="flex align-center heavier small gap">
            {{ translate('credit_card_widget_title_add') }}
        </div>
        <div v-if="type === 'update'" class="padded flex align-center plain nano">
            <p class="black-text" v-html="translate('credit_card_widget_sub_title_warning')"></p>
        </div>
        <div
            class="card-status border-radius-small"
            :class="{ 'red-background': !isActiveCard, 'grey-background': isActiveCard }"
        >
            <img class="icon" src="images/one/bank/generic_card.svg" alt="card-icon" />
            <div class="mask" v-html="numberMask"></div>
        </div>
        <button
            :id="'cardWidgetSubmit'"
            class="button red margin-top border-radius-small full-width"
            @click="validateAndSubmit"
        >
            {{ buttonText }}
        </button>
    </div>
</template>

<style lang="scss" scoped>
.credit-card-widget {
    width: 100%;
    padding: var(--size-normal);
    border-radius: 16px;
    scroll-margin-top: 4em;
    background-color: var(--component-color-background-base);

    &.full-width {
        @include respond-above('sm') {
            width: 100%;
        }
    }

    &.no-shadow {
        box-shadow: none;
    }

    .gap {
        margin-bottom: 20px;
    }

    @include respond-above('sm') {
        width: 327px;
        box-shadow: 0 1px 0 rgb(146 151 160 / 0.32);
    }

    .card-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 88px;
        padding: var(--size-small);

        :deep(.card-mask) {
            .masked-numbers {
                .mask {
                    font-size: var(--font-size-medium);
                    vertical-align: sub;
                }
            }
        }
    }

    :deep(label) {
        color: var(--text-color-default);
    }

    .padded {
        padding-bottom: 26px;

        &:not(:first-child) {
            padding-top: 26px;
        }
    }

    .red-background {
        background-color: rgb(227 6 19 / 0.05);

        img {
            filter: var(--to-color-red);
        }
    }

    .grey-background {
        background-color: var(--background-light);

        img {
            filter: var(--to-color-green);
        }
    }

    .red-text {
        color: var(--brand-red);
    }

    .grey-text {
        color: var(--black-200);
    }

    .black-text {
        color: var(--text-color-default);
    }

    .flex {
        display: flex;
    }

    .space-between {
        justify-content: space-between;
    }

    .align-center {
        align-items: center;
    }

    .plain {
        font-weight: initial;
    }

    .heavy {
        font-weight: 600;
    }

    .tiny {
        font-size: var(--font-size-tiny);
    }

    .small {
        font-size: var(--font-size-small);
    }

    .nano {
        font-size: var(--font-size-nano);
    }

    .heavier {
        font-weight: 700;
    }

    .full-width {
        width: 100%;
    }

    .border-radius-small {
        border-radius: 8px;
    }

    .margin-top {
        margin-top: var(--size-normal);
    }
}
</style>
