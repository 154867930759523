import { markRaw } from 'vue';
import { CoverageRisk } from '@/Interfaces/Resources/MovableProperties/CoverageRiskInterface';
import FormField from '@/Assets/Libraries/Form/FormField';
import RentersOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/RentersOptionInterface';

export default class RentersOptionBuilder {
    private option: RentersOption = new (class implements RentersOption {
        public name: string = '';
        public risk!: CoverageRisk;
        public component: string = 'AppRentersOption';
        public enabled: FormField<boolean> = markRaw(new FormField('enabled', false));
        public paymentFrequency: string = '';
    })();

    public withName(value: string): RentersOptionBuilder {
        this.option.name = value;

        return this;
    }

    public withPaymentFrequency(value: string): RentersOptionBuilder {
        this.option.paymentFrequency = value;

        return this;
    }

    public withCoverageRisk(value: CoverageRisk): RentersOptionBuilder {
        this.option.risk = value;

        return this;
    }

    public withState(value: boolean): RentersOptionBuilder {
        this.option.enabled.setValue(value);

        return this;
    }

    public build(): RentersOption {
        return this.option;
    }
}
