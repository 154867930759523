import { useNumbers } from '@/Composables/Numbers';
import { AxiosResponse } from 'axios';
import { useDefine } from '@/Composables/Define';
import PayByLinkService from '@/Apps/PayByLink/Pay/Services/PayByLinkService';
import { MtplOffer } from '@/Interfaces/Resources/Offers/MtplOfferInterface';
import AdditionalOptionsMap from '@/Apps/PayByLink/Classes/AdditionalOptionsMap';
import OfferRisk from '@/Apps/PayByLink/Pay/Interfaces/OfferRiskInterface';
import AppCountry from '@/Assets/Libraries/App/AppCountry';

export const usePayByLink = (): PayByLinkParams => {
    type CountryReturnType = 'LV' | 'LT' | 'EE';
    const Fluctuation: number = 0.01;
    const BaseRisks: { LV: string[]; EE: string[]; LT: string[] } = {
        LV: [],
        EE: [],
        LT: ['TT_R_PROPERTY', 'TT_R_PERSON'],
    };

    const duePayment = (premiums: number[], paymentCountOrIc: string): number => {
        const monthsCount: number = Number(paymentCountOrIc.replace(/\D/, ''));
        const totalPremium: number = useNumbers().arraySum(premiums);
        const onePaymentValue: number = fraction(division(totalPremium, monthsCount), 5);
        const onePaymentValueFraction: number = fraction(onePaymentValue);
        const difference: number = subtraction(onePaymentValue, onePaymentValueFraction);
        const totalDifference: number = multiplication(difference, monthsCount, 100);

        return addition(fraction(totalDifference), onePaymentValueFraction, 100);
    };

    const laterPayment = (premiums: number[], paymentCountOrIc: string): number => {
        const monthsCount: number = Number(paymentCountOrIc.replace(/\D/, ''));
        const totalPremium: number = useNumbers().arraySum(premiums);
        const onePaymentValue: number = fraction(division(totalPremium, monthsCount));
        const onePaymentValueFraction: number = fraction(onePaymentValue);
        const difference: number = subtraction(onePaymentValue, onePaymentValueFraction);
        const totalDifference: number = multiplication(difference, monthsCount, 100);

        return subtraction(onePaymentValueFraction, fraction(totalDifference), 100);
    };

    const useCalculationValidation = (): boolean => {
        let result: boolean = true;
        const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
        const pblDriverAge: number | undefined = payByLinkService.payByLink.value.youngestDriver;
        const storedDriverAge: string | undefined = payByLinkService.fields.driverAge;
        if (pblDriverAge && storedDriverAge) {
            result = String(pblDriverAge) === String(storedDriverAge);
        }

        return result;
    };

    const acceptCalculationResponse = (response: AxiosResponse): boolean => {
        let result: boolean = false;
        const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
        const periodIc: string = payByLinkService.payByLink.value.periodIc;
        const pblTotalPremium: number = payByLinkService.payByLink.value.premium;
        const includedRisks: string[] = includedDefaultRisks().map(
            (risk: OfferRisk): string => `${new AdditionalOptionsMap().keyByIc(risk.ic)}Premium`,
        );
        if (useDefine().validResponse(response)) {
            if (useCalculationValidation()) {
                const offer: MtplOffer = response.data.data.body.offer;
                const basePrice: number = offerBasePrice(offer);
                const riskPrices: number[] = includedRisks.map((riskKey: string) => offer.prices[periodIc][riskKey]);
                result = [
                    addition(pblTotalPremium, Fluctuation),
                    pblTotalPremium,
                    subtraction(pblTotalPremium, Fluctuation),
                ].includes(useNumbers().arraySum([basePrice].concat(riskPrices)));
            } else {
                result = true;
            }
        }

        return result;
    };

    const includedDefaultRisks = (): OfferRisk[] => {
        const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
        const countryIso: CountryReturnType = new AppCountry().iso() as CountryReturnType;

        return payByLinkService.payByLink.value.offerRisks.filter(
            (risk: OfferRisk) =>
                risk.included && !BaseRisks[countryIso].some((baseRisk: string): boolean => baseRisk === risk.ic),
        );
    };

    const baseRisks = (): OfferRisk[] => {
        const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
        const countryIso: CountryReturnType = new AppCountry().iso() as CountryReturnType;

        return payByLinkService.payByLink.value.offerRisks.filter((risk: OfferRisk) =>
            BaseRisks[countryIso].some((baseRisk: string): boolean => baseRisk === risk.ic),
        );
    };

    const basePremium = (): number => {
        return useNumbers().arraySum(baseRisks().map((risk) => risk.premium));
    };

    const offerBasePrice = (offer: MtplOffer): number => {
        const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
        const periodIc: string = payByLinkService.payByLink.value.periodIc;
        const paymentCountIc: string = `M${payByLinkService.payByLink.value.paymentCount}`;
        const hasMultiplePremiums: boolean = typeof offer.prices[periodIc].premium === 'object';

        return hasMultiplePremiums ? offer.prices[periodIc].premium[paymentCountIc] : offer.prices[periodIc].premium;
    };

    /**
     * @param value float value
     * @param decimals decimals count
     * @return value with given decimals without any rounding
     */
    const fraction = (value: number, decimals: number = 2): number => {
        const expression: RegExp = new RegExp(String.raw`^-?\d+(?:\.\d{0,${decimals}})?`, 'g');
        const result: string[] = value.toString().match(expression) ?? [];

        return Number(result[0]);
    };

    const division = (dividend: number, divisor: number, decimalsMultiplier: number = 100000): number => {
        return Math.round((dividend / divisor + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    };

    const subtraction = (minuend: number, subtrahend: number, decimalsMultiplier: number = 100000): number => {
        return Math.round((minuend - subtrahend + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    };

    const addition = (augend: number, addend: number, decimalsMultiplier: number = 100000): number => {
        return Math.round((augend + addend + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    };

    const multiplication = (multiplier: number, multiplicand: number, decimalsMultiplier: number = 100000): number => {
        return Math.round((multiplier * multiplicand + Number.EPSILON) * decimalsMultiplier) / decimalsMultiplier;
    };

    return {
        duePayment,
        laterPayment,
        acceptCalculationResponse,
        includedDefaultRisks,
        basePremium,
    };
};

export interface PayByLinkParams {
    duePayment: (premiums: number[], paymentCountOrIc: string) => number;
    laterPayment: (premiums: number[], paymentCountOrIc: string) => number;
    basePremium: () => number;
    acceptCalculationResponse: (response: AxiosResponse) => boolean;
    includedDefaultRisks: () => OfferRisk[];
}
