import { VueApp } from '@/Types/VueAppType';
import PersonProcessState from '@/Enums/PersonProcessStateEnum';
import LegalInsuredPerson from '@/Pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import ProcessingDetailsTab from '@/Pages/LegalPerson/Workbench/Common/Tabs/ProcessingDetailsTab';
import LegalPersonModalState from '@/Pages/LegalPerson/Workbench/Enums/LegalPersonModalStateEnum';
import ProcessingRecord from '@/Pages/LegalPerson/Workbench/Interfaces/ProcessingRecordInterface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';

const { isSet } = useDefine();

export default class LegalProcessing {
    private context: VueApp;
    private processingPersons: LegalInsuredPerson[] = [];
    private processingPersonsManual: LegalInsuredPerson[] = [];
    public onShowDetailsClick: Function = this.onShowDetailsClickReceiver.bind(this);
    public onShowDetailsClickManual: Function = this.onShowDetailsClickReceiverManual.bind(this);
    public onRefreshStatusClick: Function = this.onRefreshStatusClickReceiver.bind(this);

    public addContext(context: DynamicDictionary): void {
        this.context = context;
    }

    public onRefreshStatusClickReceiver(): void {
        this.app.fetchPolicyDetailsWithoutCache().then();
    }

    public onShowDetailsClickReceiver(): void {
        ProcessingDetailsTab.getInstance().personsForProcessing = this.processingPersons;
        this.app.businessModal.applyModalState(LegalPersonModalState.ProcessingDetails).showModalPopup();
    }

    public onShowDetailsClickReceiverManual(): void {
        ProcessingDetailsTab.getInstance().personsForProcessing = this.processingPersonsManual;
        this.app.businessModal.applyModalState(LegalPersonModalState.ProcessingDetails).showModalPopup();
    }

    public applyPersons(persons: LegalInsuredPerson[]): void {
        this.processingPersons = [];
        this.processingPersonsManual = [];
        persons.forEach((person: LegalInsuredPerson): void => {
            if (isSet(person.processingRecords)) {
                person.processingRecords.forEach((record: ProcessingRecord): void => {
                    if (record.status !== PersonProcessState.Failed) {
                        this.processingPersons.push(person);
                    } else {
                        this.processingPersonsManual.push(person);
                    }
                });
            }
        });
    }

    public get count(): number {
        return this.processingPersons.length;
    }

    public get manualCount(): number {
        return this.processingPersonsManual.length;
    }

    public get persons(): LegalInsuredPerson[] {
        return this.processingPersons;
    }

    public get personsManual(): LegalInsuredPerson[] {
        return this.processingPersonsManual;
    }

    private get app(): DynamicDictionary {
        return this.context;
    }
}
