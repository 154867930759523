import { AxiosError, AxiosResponse } from 'axios';
import BaseApi from '@/Services/Api/base.api';
import Url from '@/Enums/UrlEnum';
import UserPolicy from '@/Interfaces/user.policy.interface';
import UserPolicyDetails from '@/Interfaces/user.policy.details.interface';
import UserPolicies from '@/Interfaces/user.policies.interface';
import UserPolicySubscription from '@/Interfaces/user.policy.subscription.interface';
import UserPolicySubscriptionCancellation from '@/Interfaces/user.policy.subscription.cancellation.interface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { useAxios } from '@/Composables/Axios';

export default class AgreementApi extends BaseApi {
    private static instance: AgreementApi;
    private readonly policyTypeOffer: string = 'offer';

    public static getInstance(): AgreementApi {
        if (!AgreementApi.instance) {
            AgreementApi.instance = new AgreementApi();
        }

        return AgreementApi.instance;
    }

    public agreementDetails(agreement: UserPolicy): Promise<UserPolicyDetails> {
        const content: DynamicDictionary = {
            agreementId: agreement.agreement_id,
            agreementNumber: agreement.agreement_number,
            holderId: agreement.holder_id,
            owner: agreement.owner,
            offer: agreement.payType === this.policyTypeOffer,
            lang: this.language(),
            status: agreement.status,
            liteObjectId: agreement.liteObjectId,
        };

        return useAxios()
            .post(Url.Ajax.dashboardGetPolicy, content)
            .then((value: AxiosResponse<DynamicDictionary>) => {
                return value.data.data.body.policy;
            })
            .catch((error: AxiosError): void => {
                throw error;
            });
    }

    public agreements(): Promise<UserPolicies> {
        const content: DynamicDictionary = {
            lang: this.language(),
        };

        return useAxios()
            .post(Url.Ajax.dashboardGetPolicies, content)
            .then((value: AxiosResponse<DynamicDictionary>) => {
                return value.data.data.body;
            })
            .catch((error: AxiosError): void => {
                throw error;
            });
    }

    public cancelAgreementSubscription(
        subscription: UserPolicySubscription,
        reasonCode: string,
        isHolder: boolean,
    ): Promise<UserPolicySubscriptionCancellation> {
        const content: DynamicDictionary = {
            lang: this.language(),
            subscriptionAgreementNumber: subscription.subscriptionNumber,
            subscriptionAgreementId: subscription.subscriptionId,
            terminationReasonIc: reasonCode,
            isHolder: isHolder ? 'true' : 'false',
        };

        return useAxios()
            .post(Url.Ajax.dashboardCancelSubscription, content)
            .then((value: AxiosResponse) => {
                if (this.isSet(value.data.errors)) {
                    throw new Error(value.data.errors.code);
                }
                return value.data.data.body.cancellation;
            })
            .catch((error: AxiosError): void => {
                throw error;
            });
    }

    public terminateSubscription(
        agreementNumber: string,
        agreementId: string,
        reasonCode: string,
    ): Promise<UserPolicySubscriptionCancellation> {
        const content: DynamicDictionary = {
            lang: this.language(),
            subscriptionAgreementNumber: agreementNumber,
            subscriptionAgreementId: agreementId,
            terminationReasonIc: reasonCode,
            isHolder: 'true',
        };

        return useAxios()
            .post(Url.Ajax.dashboardCancelSubscription, content)
            .then((value: AxiosResponse) => {
                if (this.isSet(value.data.errors)) {
                    throw new Error(value.data.errors.code);
                }

                return value.data.data.body.cancellation;
            })
            .catch((error: AxiosError): void => {
                throw error;
            });
    }
}
