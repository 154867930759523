<script lang="ts">
import { computed, defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import VehicleProduct from '@/Enums/VehicleProductEnum';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useDefine } from '@/Composables/Define';
import { useTranslate } from '@/Composables/Translate';
import ThankYouPageParams from '@/Enums/ThankYouPageParamsEnum';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useTransforms } from '@/Composables/Transforms';
import RequestService from '@/Services/request.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { validResponse, isSet } = useDefine();
        const { translate } = useTranslate();

        const CurrentStep: number = 4;
        const Facility: string = 'auto-policy';

        const mtplDownloadUrl: Ref<string> = ref('');
        const cascoDownloadUrl: Ref<string> = ref('');
        const policyDownloadUrl: Ref<string> = ref('');
        const invoiceDownloadUrl: Ref<string> = ref('');
        const appDownloadUrl: Ref<string> = ref('');
        const certDownloadUrl: Ref<string> = ref('');
        const policyType: Ref<string> = ref('');
        const order: Ref<DynamicDictionary> = ref({});
        const stepStorageOptions: Ref<DynamicDictionary> = ref({});
        const necessaryStepsStorageData: Ref<DynamicDictionary> = ref({});
        const thankYouPageParams: Ref<DynamicDictionary> = ref({});
        const printoutsFetched: Ref<boolean> = ref(false);

        const hasPageParams: Ref<boolean> = computed(() => {
            return isSet(thankYouPageParams.value) && isSet(thankYouPageParams.value.type);
        });

        const isPayByLinkPolicyPayment: Ref<boolean> = computed(() => {
            return isSet(order.value.paymentOptions) && isSet(order.value.policies) && isSet(order.value.offers)
                ? order.value.paymentOptions.paymentType === 'pay-by-link' &&
                      order.value.policies.length > 0 &&
                      order.value.offers.length === 0
                : false;
        });

        const pageParamsMessage: Ref<string> = computed(() => {
            return hasPageParams.value ? translate(thankYouPageParams.value.message) : '';
        });

        const isMtplDownloadVisible: Ref<boolean> = computed(() => {
            const vehicleProduct: string = vehicleProductType();

            return (
                (vehicleProduct === VehicleProduct.Mtpl || vehicleProduct === VehicleProduct.Bundle) &&
                mtplDownloadUrl.value !== ''
            );
        });

        const isCascoDownloadVisible: Ref<boolean> = computed(() => {
            const vehicleProduct: string = vehicleProductType();

            return (
                (vehicleProduct === VehicleProduct.Casco || vehicleProduct === VehicleProduct.Bundle) &&
                cascoDownloadUrl.value !== ''
            );
        });

        const isDescriptionVisible: Ref<boolean> = computed(() => {
            return policiesHasPrintouts();
        });

        const isApprovalCaseNecessary: Ref<boolean> = computed(() => {
            return hasPageParams.value && thankYouPageParams.value.type === ThankYouPageParams.ApprovalCase;
        });

        const isCasco: Ref<boolean> = computed(() => {
            return vehicleProductType() === VehicleProduct.Casco;
        });

        const isBundle: Ref<boolean> = computed(() => {
            return vehicleProductType() === VehicleProduct.Bundle;
        });

        function prepare(): void {
            $(() => {
                if (printoutsCouldBeFetched()) {
                    fetchPrintouts();
                }
            });
        }

        function appendOrder(orderSafeJson: string): void {
            order.value = JSON.parse(useTransforms().transformedVueSafeString(orderSafeJson));
        }

        function applyThankYouPageParams(value: DynamicDictionary): void {
            thankYouPageParams.value = value;
        }

        function applyNecessaryStepsStorageData(value: DynamicDictionary): void {
            necessaryStepsStorageData.value = value;
        }

        function applyStepStorageOptions(value: DynamicDictionary): void {
            stepStorageOptions.value = value;
        }

        function applyPolicyType(value: string): void {
            policyType.value = value;
        }

        function printoutsCouldBeFetched(): boolean {
            return !printoutsCouldNotBeFetched() && !isPayByLinkPolicyPayment.value;
        }

        function printoutsCouldNotBeFetched(): boolean {
            return (
                (vehicleProductType() !== VehicleProduct.Bundle && isApprovalCaseNecessary.value) ||
                isOpenedFromApp() ||
                !policiesHasPrintouts()
            );
        }

        function policiesHasPrintouts(): boolean {
            return order.value.policies.some((policy: { hasPrintouts: boolean }): boolean => policy.hasPrintouts);
        }

        function isOpenedFromApp(): boolean {
            return btaBase.settings.value('INITIATOR') === 'app';
        }

        function vehicleProductType(): string {
            let returnValue: string;
            const hasNecessaryStepsStorageData: boolean = Object.keys(necessaryStepsStorageData.value).length > 0;
            if (hasNecessaryStepsStorageData) {
                returnValue = necessaryStepsStorageData.value.vehicleProduct;
            } else {
                returnValue = policyType.value;
            }

            return returnValue;
        }

        function isValidPrintout(value: any): boolean {
            return isSet(value.data) && value.data.length > 0 && !value.data.errors;
        }

        function fetchPrintouts(): void {
            btaBase.lockInput(true);
            PopupService.getInstance().show(new OnePopup().withType().loading);
            RequestService.getInstance()
                .post({ uri: Url.PaymentsApi.printout, returnRaw: true })
                .then((value: AxiosResponse<DynamicDictionary>) => {
                    if (validResponse(value)) {
                        (value.data as { product: string; documentType: string; link: string }[]).forEach(
                            (item: Record<string, string>) => {
                                switch (item.product.toUpperCase()) {
                                    case VehicleProduct.Mtpl:
                                        if (item.documentType === 'PRINTOUT_AGR') {
                                            mtplDownloadUrl.value = item.link;
                                        }
                                        break;
                                    case VehicleProduct.Casco:
                                        if (item.documentType === 'PRINTOUT_AGR') {
                                            cascoDownloadUrl.value = item.link;
                                        }
                                        break;
                                    default: {
                                        switch (item.documentType) {
                                            case 'PRINTOUT_AGR':
                                                policyDownloadUrl.value = item.link;
                                                break;
                                            case 'PRINTOUT_INV':
                                                invoiceDownloadUrl.value = item.link;
                                                break;
                                            case 'PRINTOUT_APP':
                                                appDownloadUrl.value = item.link;
                                                break;
                                            case 'PRINTOUT_CERT':
                                                certDownloadUrl.value = item.link;
                                                break;
                                            default:
                                        }
                                    }
                                }
                            },
                        );
                        btaBase.lockInput(false);
                        PopupService.getInstance().hide();
                    }
                })
                .catch((reason: any) => {
                    btaBase.lockInput(false);
                    btaBase.error.show(ErrorType.Error, 'fetchPrintouts', reason);
                })
                .finally(() => {
                    printoutsFetched.value = true;
                });
        }

        const loadingCompleted: Ref<boolean> = computed(() => {
            return !printoutsCouldBeFetched() || printoutsFetched.value;
        });

        return {
            ...btaBase,
            ...{
                Facility,
                CurrentStep,
                mtplDownloadUrl,
                cascoDownloadUrl,
                policyDownloadUrl,
                invoiceDownloadUrl,
                appDownloadUrl,
                certDownloadUrl,
                policyType,
                order,
                stepStorageOptions,
                necessaryStepsStorageData,
                thankYouPageParams,
                hasPageParams,
                isPayByLinkPolicyPayment,
                pageParamsMessage,
                isMtplDownloadVisible,
                isCascoDownloadVisible,
                isDescriptionVisible,
                isApprovalCaseNecessary,
                isCasco,
                isBundle,
                applyThankYouPageParams,
                applyNecessaryStepsStorageData,
                applyStepStorageOptions,
                applyPolicyType,
                policiesHasPrintouts,
                printoutsCouldBeFetched,
                printoutsCouldNotBeFetched,
                prepare,
                appendOrder,
                isOpenedFromApp,
                vehicleProductType,
                fetchPrintouts,
                isValidPrintout,
                loadingCompleted,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setFacility(this.Facility);
        this.setStep(this.CurrentStep);
        this.setStorageUsage(true);
        this.prepare();
    },
});
</script>
