<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import OneBase from '@/Interfaces/OneBaseInterface';
import MobileUploadModule from '@/Modules/MobileUpload/MobileUploadModule.vue';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const mobileUploadModule = new VueModule(MobileUploadModule).mount();

        const mobileUpload: Ref<boolean> = ref(true);
        const reference: Ref<string> = ref('');

        const setReference = (): void => {
            reference.value = window.location.href.split(/[/s,]+/).pop()!;
        };

        return {
            ...btaBase,
            ...{
                mobileUploadModule,
                mobileUpload,
                reference,
                setReference,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.setReference();

        this.mobileUploadModule.create();
        this.mobileUploadModule.beforeModuleMounted();
        this.mobileUploadModule.moduleMounted();
    },
});
</script>
