<script setup lang="ts"></script>
<template>
    <div class="layout">
        <div class="layout-container container-full-width">
            <div class="route-content">
                <router-view v-slot="{ Component }">
                    <transition :name="'fade'" mode="out-in">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.layout {
    display: flex;
    flex-direction: column;
    align-items: center;

    .layout-container {
        display: grid;
        grid-template-columns: auto;
        gap: 32px 5px;
        width: 100%;
        margin: 20px 0 40px;
    }
}
</style>
