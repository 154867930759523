<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import { Ref, ref, computed, watch, onMounted, reactive, PropType } from 'vue';
import CompanyType from '@/Interfaces/company.type.interface';
import OptionValue from '@/Interfaces/option.value.interface';
import CompanyTypeListItem from '@/Interfaces/company.type.options.list.item.interface';
import { AxiosResponse } from 'axios';
import VueEvent from '@/Classes/VueEventClass';
import Url from '@/Enums/UrlEnum';
import CompanyTypes from '@/Interfaces/company.types.interface';
import Translations from '@/Services/translations.service';
import AppPopup from '@/Components/Popups/Popup/Popup.vue';
import RequestService from '@/Services/request.service';

const props = defineProps({
    placeholder: { type: String, default: () => '' },
    formField: { type: Object as PropType<FormField<CompanyType>>, default: () => new FormField('') },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    disableErrorText: { type: Boolean, default: false },
    supportTextMessage: { type: String, default: '' },
    popupLabel: { type: String, default: '' },
    showResidentValues: { type: Boolean, default: true },
});

const emit = defineEmits(['change', 'input', 'close', 'click']);
const translations: Translations = Translations.getInstance();
const MaxDefaultElements: number = 5;
const companyType: OptionValue = reactive({
    id: ':void:',
    value: '',
});
const isVisibleDropdown: Ref<boolean> = computed(() => {
    return typedValue.value !== '' || inputInFocus.value;
});
const companyTypeData: Ref<CompanyType> = computed(() => {
    return {
        iso: companyType.id,
        name: companyType.value,
    };
});

const companyInput: Ref<HTMLElement | null> = ref(null);
const companyTypes: Ref<CompanyType[]> = ref([]);
let companyTypesResident!: CompanyType[];
let companyTypesNonResident!: CompanyType[];
const visible: Ref<boolean> = ref(false);
const typedValue: Ref<string> = ref('');
const options: Ref<CompanyTypeListItem[]> = ref([]);
const inputInFocus: Ref<boolean> = ref(false);
let index: number = 0;
const selectorPlaceholder: Ref<string> = ref('');

watch(
    () => companyType.id,
    () => {
        props.formField?.setValue(companyTypeData.value);
        props.formField.validate();
        emitChange();
    },
);

watch(
    () => props.showResidentValues,
    () => {
        updateCompanyTypesForDropdown();
        resetFormFieldValue();
        emitChange();
    },
);

onMounted((): void => {
    init();
    selectorPlaceholder.value =
        props.placeholder === ''
            ? (selectorPlaceholder.value = translations.localized('input_company_type_placeholder'))
            : (selectorPlaceholder.value = props.placeholder);
    if (!props.formField.isEmpty()) {
        companyType.id = props.formField.value.iso;
        companyType.value = props.formField?.value.name;
    }
    props.formField.onPatch.subscribe((): void => {
        companyType.id = props.formField.value.iso;
        companyType.value = props.formField.value.name;
    });
});

function showListSearch(event: VueEvent): void {
    if (!props.disabled) {
        prepareOptionsList(event, true);
    }
}

function isVisibleItem(current: CompanyTypeListItem): boolean {
    let result: boolean = false;
    if (typedValue.value) {
        result = current.name.toLowerCase().includes(typedValue.value.toLowerCase());
    } else if (inputInFocus.value) {
        result = current.index < MaxDefaultElements;
    }

    return result;
}

function onFocus(event: VueEvent): void {
    inputInFocus.value = true;
}

function onBlur(event: VueEvent): void {
    setTimeout(() => {
        inputInFocus.value = false;
    }, 500);
}

function onOptionSelectClick(event: VueEvent): void {
    companyType.id = event.data('id');
    companyType.value = event.data('value');
    close();
}

function closePopup(): void {
    close();
}

function init(): void {
    if (companyTypes.value.length === 0) {
        fetchCompanyTypes();
    }
}

function fetchCompanyTypes(): void {
    RequestService.getInstance()
        .get({ uri: Url.Ajax.fetchCompanyTypes })
        .then((value: AxiosResponse) => {
            const companyTypeNames: CompanyTypes = JSON.parse(value.data.data.body.types);
            applyCompanyTypes(companyTypeNames);
        });
}

function applyCompanyTypes(types: CompanyTypes): void {
    companyTypesResident = types.resident;
    companyTypesNonResident = types.nonResident;
    companyTypes.value = props.showResidentValues ? companyTypesResident : companyTypesNonResident;
}

function prepareOptionsList(event: VueEvent, withSearch: boolean = false): void {
    clear();
    for (const o in companyTypes.value) {
        addItem(o, companyTypes.value[o].name);
    }
    typedValue.value = '';
    visible.value = true;
}

function clear(): void {
    options.value = [];
    index = 0;
}

function addItem(companyTypeIso: string, companyTypeName: string): void {
    const itemIndex: number = index++;
    options.value.push(
        new (class implements CompanyTypeListItem {
            public iso: string = companyTypeIso;
            public name: string = companyTypeName;
            public index: number = itemIndex;
        })(),
    );
}

function close(): void {
    visible.value = false;
    emit('close');
}

function openDropdown() {
    if (!inputInFocus.value) {
        inputInFocus.value = true;
    }
    companyInput.value?.focus();
}

function closeDropdown() {
    if (inputInFocus.value) {
        inputInFocus.value = false;
    }
    clearInput();
}

function clearInput() {
    typedValue.value = '';
}

function updateCompanyTypesForDropdown(): void {
    companyTypes.value = props.showResidentValues ? companyTypesResident : companyTypesNonResident;
}

function resetFormFieldValue(): void {
    props.formField.clear();
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();
    emit('change', props.formField.value);
}
</script>

<template>
    <div
        :id="formField.name"
        class="input input-company-type"
        :class="formField.classes()"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div v-if="label" class="label informative">
            <p v-html="label"></p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper select">
            <div
                :id="formField.name + '-showListSearch'"
                class="current button cursor-pointer"
                :class="{ disabled: props.disabled }"
                @click="showListSearch(new VueEvent($event))"
            >
                <span
                    v-if="companyType.value === ''"
                    class="text placeholder text-icon"
                    v-html="selectorPlaceholder"
                ></span>
                <span v-if="companyType.value !== ''" class="text text-icon" v-html="companyType.value"></span>
                <span class="icon arrow-icon">
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13 1L7 7L1 1"
                            stroke="#E30613"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </span>
            </div>
        </div>
        <div class="popups">
            <app-popup v-if="visible" class="simple list-search" @close="closePopup">
                <div class="title" v-html="popupLabel || label"></div>
                <div class="elements">
                    <div class="field">
                        <div class="added"></div>
                        <div class="search">
                            <div class="icon">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g id="search">
                                        <path
                                            id="Vector"
                                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                                            stroke="#9297A0"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            id="Vector_2"
                                            d="M21 21L16.65 16.65"
                                            stroke="#9297A0"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                </svg>
                            </div>
                            <input
                                :id="formField.name + '-typedValue'"
                                ref="companyInput"
                                v-model="typedValue"
                                class="text"
                                :placeholder="placeholder"
                                :autocomplete="'disabled'"
                                @focus="onFocus(new VueEvent($event))"
                                @blur="onBlur(new VueEvent($event))"
                            />
                            <span v-show="!isVisibleDropdown" class="dropdown-toggle" @click="openDropdown">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g id="dropdown-toggle">
                                        <path
                                            id="Vector"
                                            d="M8 10L12 14L16 10"
                                            stroke="#292C31"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                </svg>
                            </span>
                            <span v-show="isVisibleDropdown" class="dropdown-toggle" @click="closeDropdown">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g id="close--clear">
                                        <path
                                            id="Vector"
                                            d="M8 8L16 16M16 8L8 16"
                                            stroke="#9297A0"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                </svg>
                            </span>
                        </div>
                        <div class="dropdown" :class="{ hidden: !isVisibleDropdown }">
                            <button
                                v-for="(item, optionsIndex) in options"
                                :id="formField.name + '-dropdown-' + item.name"
                                :key="optionsIndex"
                                class="country-item"
                                :class="{ hidden: !isVisibleItem(item) }"
                                :data-value="item.name"
                                :data-id="item.iso"
                                @click="onOptionSelectClick(new VueEvent($event))"
                            >
                                <span class="text">{{ item.name }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </app-popup>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-company-type {
    scroll-margin-top: 4em;

    .wrapper {
        .disabled {
            background: var(--component-color-background-disabled);
        }
    }

    p {
        color: inherit;
    }

    .text {
        color: var(--text-color-default);
    }

    .placeholder {
        opacity: 0.56;
        color: var(--text-color-subtlest);
    }

    &.invalid:not(.untouched) {
        .current {
            border-color: var(--brand-red);
        }
    }

    .label.informative {
        display: flex;
        margin-bottom: 0;
        min-height: 31px;
        align-items: flex-start;

        p {
            line-height: 14px;
        }
    }

    :deep(.popups .single-popup.list-search > .wrapper) {
        border-radius: 16px;

        .title {
            font-size: 21px;
        }

        .elements .field {
            border-radius: var(--size-pico);
            min-height: unset;
            height: 40px;

            &:focus-within {
                outline: 1px solid var(--brand-blue);
                border: 1px solid var(--brand-blue);
            }

            .search {
                height: 100%;
                padding: 0 var(--size-nano);

                .icon {
                    margin-right: var(--size-nano);

                    svg {
                        display: block;
                    }
                }

                .dropdown-toggle svg {
                    display: block;
                }
            }

            .text {
                padding-left: 0;
                min-height: unset;
                background-color: var(--component-color-background-base);

                &::placeholder {
                    font-weight: 500;
                    font-size: var(--font-size-nano);
                    line-height: 16.8px;
                }
            }

            .dropdown {
                border: none;
                margin-top: var(--size-femto);
                padding: 12px;
                border-radius: var(--size-pico);

                &::-webkit-scrollbar {
                    display: none;
                }

                .country-item {
                    margin-top: var(--size-femto);
                    padding: 10px 6px;
                    width: auto;
                    height: 40px;
                    border-radius: var(--size-pico);

                    .text {
                        color: #292c31;
                    }

                    &:hover {
                        background-color: #9297a014;

                        .text {
                            background-color: unset;
                        }
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
