<script setup lang="ts">
import { getCurrentInstance, onMounted, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import PolicyDetailsPopup from '@/Pages/OneDashboard/Insurances/PolicyDetailsPopup';
import AppPolicyDetailsInvoices from '@/Components/Policies/PolicyDetailsInvoices/PolicyDetailsInvoices.vue';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import RequestService from '@/Services/request.service';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import Invoice from '@/Components/Policies/PolicyBlock/Interfaces/InvoiceInterface';
import { useMobileApp } from '@/Apps/MobileApp/Composables/MobileApp';

const Tab: string = 'invoices';

const viewIsReady: Ref<boolean> = ref(false);
const ajaxContentIsReady: Ref<boolean> = ref(false);
const content: Ref<DynamicDictionary> = ref({});

const { ajaxAgreement, agreementView, hasStoredAgreement, viewContent, emitFallbackEvent } = useMobileApp();

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    if (hasStoredAgreement()) {
        prepareContent();
    } else {
        emitFallbackEvent();
        ajaxContentIsReady.value = true;
    }
});

const prepareContent = (): void => {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    const view: PolicyDetailsPopup = agreementView();
    view.buildDetails();
    view.contentBuilder().stashContent();
    applyViewContent();
    viewIsReady.value = true;
    PopupService.getInstance().hide();
    prepareAdditionalContent();
};

const applyViewContent = (): void => {
    content.value = viewContent(Tab);
};

const prepareAdditionalContent = (): void => {
    RequestService.getInstance()
        .get({ uri: Url.MobileApp.invoices, content: ajaxAgreement() })
        .then((response: AxiosResponse): void => {
            const axiosResponse: DynamicDictionary = response.data.data.body;
            const view: PolicyDetailsPopup = agreementView();
            view.applyInvoices(axiosResponse.invoices as Invoice[]).buildDetails();
            view.contentBuilder().stashContent();
            ajaxContentIsReady.value = true;
            applyViewContent();
        });
};
</script>

<template>
    <div class="app-invoices-page">
        <app-content-loader v-if="viewIsReady && !ajaxContentIsReady"></app-content-loader>
        <app-policy-details-invoices
            v-if="viewIsReady && ajaxContentIsReady"
            :content="content"
        ></app-policy-details-invoices>
    </div>
</template>

<style lang="scss" scoped>
.app-invoices-page {
    width: 100%;
}
</style>
