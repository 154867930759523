<script setup lang="ts">
import { PropType } from 'vue';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import FormField from '@/Assets/Libraries/Form/FormField';

const props = defineProps({
    formField: { type: Object as PropType<FormField<string>>, required: true },
    options: { type: Array as PropType<InputOption[]>, required: true },
    disabled: { type: Boolean, default: false },
});

const emit = defineEmits(['change']);

function select(option: InputOption): void {
    props.formField.markAsTouched();
    if (option.value !== props.formField.value) {
        props.formField.setValue(option.value as string);
        props.formField.markAsDirty();
        emit('change', props.formField.value);
    }
}
</script>

<template>
    <div class="input-radio" :class="{ disabled }">
        <div class="grid-utility grid-columns-1 sm:grid-columns-12">
            <button
                v-for="(option, index) in options"
                :key="index"
                class="radio-button column-span-12 sm:column-span-4"
                :class="{ active: formField.value === option.value }"
                :disabled="disabled"
                @click="select(option)"
            >
                <span class="circle"></span>
                <span class="button-text">{{ option.name }}</span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-radio {
    width: 100%;

    .radio-button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 52px;
        padding: 0 20px 0 55px;
        border: none;
        text-align: left;
        border-radius: 8px;

        &::before {
            content: '';
            position: absolute;
            inset: 0;
            border: 2px solid var(--component-color-border-default);
            border-radius: 8px;
            transition: all 0.3s;
        }

        .circle {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 1.5rem;
            height: 1.5rem;
            border: 2px solid var(--component-color-border-default);
            border-radius: 50%;
            transition: all 0.3s;
        }

        .button-text {
            color: var(--component-color-text-value);
            font-size: var(--font-size-tiny);
        }

        &.active {
            background: linear-gradient(
                0deg,
                var(--system-color-success-light) 0%,
                var(--system-color-success-light) 100%
            );

            &::before {
                border: 2px solid var(--component-color-border-active);
            }

            .circle {
                border-color: var(--button-color-background-primary-green-default);

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: var(--size-nano);
                    height: var(--size-nano);
                    background-color: var(--button-color-background-primary-green-default);
                    border-radius: 50%;
                }
            }
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
</style>
