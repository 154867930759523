<script setup lang="ts">
import { onBeforeMount, onMounted } from 'vue';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

const props = defineProps({
    module: {
        type: [Object, Function],
        default: () => {
            return null;
        },
    },
});
const emit = defineEmits(['created', 'mounted']);

onBeforeMount(() => {
    if (props.module) {
        moduleSource().create();
        moduleSource().beforeModuleMounted();
        emit('created');
    }
});

onMounted(() => {
    if (props.module) {
        moduleSource().moduleMounted();
        emit('mounted');
    }
});

function moduleSource(): DynamicDictionary {
    return typeof props.module === 'function' ? props.module() : props.module;
}
</script>

<template>
    <section class="module main-container">
        <slot :module="module"></slot>
    </section>
</template>
