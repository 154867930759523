import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Travel from '@/Pages/Travel/Enums/Travel';

export default class TravelSteps {
    private static readonly mapping: DynamicDictionary = {
        [Travel.Default]: [Travel.InsuranceStep, Travel.CoverageStep, Travel.TravelersStep, Travel.SummaryStep],
        [Travel.WithAdditional]: [
            Travel.InsuranceStep,
            Travel.CoverageStep,
            Travel.AdditionalOptionsStep,
            Travel.TravelersStep,
            Travel.SummaryStep,
        ],
    };

    public static default(): number[] {
        return TravelSteps.mapping[Travel.Default];
    }

    public static additional(): number[] {
        return TravelSteps.mapping[Travel.WithAdditional];
    }
}
