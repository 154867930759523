<script setup lang="ts">
import { computed, nextTick, onMounted, PropType, ref, Ref, toRaw, watch } from 'vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import IndemnityReceiverPersonDetails from '@/Components/Widgets/IndemnityReceiver/Interfaces/IndemnityReceiverPersonDetailsInterface.ts';
import Country from '@/Interfaces/country.interface';
import Form from '@/Assets/Libraries/Form/Form';
import Value from '@/Assets/Libraries/Form/Value';
import User from '@/Services/user.service';
import SpaUserStorage from '@/Services/SpaUserStorageService';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { useTranslate } from '@/Composables/Translate';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Sanitizer from '@/Services/sanitizer.service';
import Validation from '@/Services/validation.service';
import { useDefine } from '@/Composables/Define';
import PersonCodeValidator from '@/Validators/PersonCodeValidator';
import AppCountry from '@/Assets/Libraries/App/AppCountry';
import Phone from '@/Interfaces/PhoneInterface';
import moment from 'moment';
import IbanValidator from '@/Validators/IbanValidator';
import PhoneType from '@/Enums/PhoneTypeEnum';
import IndemnityPersonDetails from '@/Components/Widgets/IndemnityReceiver/Interfaces/IndemnityPersonDetailsInterface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

const { translateForType } = useTranslate();

const props = defineProps({
    formField: {
        type: Object as PropType<FormField<IndemnityPersonDetails>>,
        default: () => new FormField(''),
    },
    translationType: { type: String, default: () => 'components' },
    indemnityReceiver: {
        type: Object as PropType<IndemnityReceiverPersonDetails>,
        default: () => {
            return new (class implements IndemnityReceiverPersonDetails {
                public name: string = '';
                public surname: string = '';
                public personCode: string = '';
                public country: Country = new (class implements Country {
                    public iso: string = '';
                    public phoneCode: string = '';
                    public code: string = '';
                    public name: string = '';
                })();
                public birthDate: string = '';
            })();
        },
    },
    indemnityReceiverOverrideWithLogged: { type: Boolean, default: false },
    hideSubmitButton: { type: Boolean, default: false },
    titleText: { type: String, default: () => 'who_will_receive_money' },
    agreeBasicText: { type: String, default: () => 'indemnity_agree_basic' },
    agreeAdditionalText: { type: String, default: () => 'indemnity_agree_additional' },
    proceedText: { type: String, default: () => 'proceed' },
});

const emit = defineEmits(['change', 'submit']);

const user: User = User.getInstance();
const spaUserStorage: SpaUserStorage = SpaUserStorage.getInstance();

const Me: string = 'me';
const Other: string = 'other';
const Resident: string = 'resident';
const NonResident: string = 'nonResident';
const BalticCountries: string[] = ['EE', 'LT', 'LV'];

const form: Form = new Form();
const inputOptionsPersonType: InputOption[] = [
    new InputOptionBuilder().setName(translated('me')).setValue(Me).build(),
    new InputOptionBuilder().setName(translated('other')).setValue(Other).build(),
];
const inputOptionsResidentType: InputOption[] = [
    new InputOptionBuilder().setName(translated('resident')).setValue(Resident).build(),
    new InputOptionBuilder().setName(translated('non_resident')).setValue(NonResident).build(),
];

const allowPolicyHolderWatch: Ref<boolean> = ref(false);

const isLogged: Ref<boolean> = computed(() => {
    return user.isLogged();
});

const isPolicyHolder: Ref<boolean> = computed(() => {
    return form.field('isPolicyHolder').value === Me;
});

const isResident: Ref<boolean> = computed(() => {
    return form.field('isResident').value === Resident;
});

const phoneNumberType: Ref<string> = computed(() => {
    let result: string = '';
    const value: Phone = form.field('phone').value;
    if (useDefine().isSet(value.phone)) {
        if (Validation.isValidMobilePhone(value.phone)) {
            result = PhoneType.Mobile;
        } else if (Validation.isValidFixedPhone(value.phone)) {
            result = PhoneType.Fixed;
        }
    }

    return result;
});

const personCodeIsAnonymous: Ref<boolean> = computed(() => {
    return new PersonCodeValidator().isValidPersonCodeWithoutDate(
        form.field('personCode').value,
        new AppCountry().iso(),
    );
});

const lockUserFields: Ref<boolean> = computed(() => {
    return isPolicyHolder.value;
});

const needToShowCountry: Ref<boolean> = computed(() => {
    return !isPolicyHolder.value && !isResident.value;
});

const needToShowBirthDate: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (!isPolicyHolder.value) {
        if (isResident.value) {
            result = personCodeIsAnonymous.value;
        } else {
            result = true;
        }
    }

    return result;
});

function setupForm(): void {
    form.addField(new FormField('isPolicyHolder', ''));
    form.addField(new FormField('isResident', ''));
    form.addField(new FormField('name', '', nameValidator(), Sanitizer.cleanName));
    form.addField(new FormField('surname', '', surnameValidator(), Sanitizer.cleanName));
    form.addField(new FormField('personCode', '', personCodeValidator(), clearPersonCode));
    form.addField(new FormField('birthDate', '', dateValidator()));
    form.addField(new FormField('country', '', countryValidator()));
    form.addField(new FormField('email', '', { required: Validation.required }));
    form.addField(new FormField('phone', '', { required: Validation.required }));
    form.addField(new FormField('account', '', accountValidator()));
    form.addField(new FormField('checkboxBase', false, { required: Validation.required }));
    form.addField(new FormField('checkboxAdditional', false, checkboxAdditionalValidator()));
    form.setReady();
}

function prepareFormField(): void {
    props.formField.addValidators({
        childrenAreValid: () => form.isValid(),
    });
    props.formField.onClear.subscribe(() => {
        applyValuesToChildFields();
    });
    props.formField.onPatch.subscribe(() => {
        applyValuesToChildFields();
    });
}

function applyDefaultValues(): void {
    form.field('isPolicyHolder').setValue(Me, true);
    form.field('isResident').setValue(Resident, true);
    form.field('name').setValue(props.indemnityReceiver.name || '', true);
    form.field('surname').setValue(props.indemnityReceiver.surname || '', true);
    form.field('personCode').setValue(props.indemnityReceiver.personCode || '', true);
}

function applyValuesToChildFields(): void {
    const value: IndemnityPersonDetails = toRaw(props.formField.value);
    if (!new Value(value).isEmpty()) {
        form.field('isPolicyHolder').patch(value.isPolicyHolder);
        form.field('isResident').patch(value.isResident);
        const fieldNames: string[] = [
            'name',
            'surname',
            'personCode',
            'birthDate',
            'country',
            'email',
            'phone',
            'account',
            'checkboxBase',
            'checkboxAdditional',
        ];
        let fieldValue: DynamicDictionary | string;
        fieldNames.forEach((fieldName: string) => {
            fieldValue = (value[fieldName as keyof IndemnityPersonDetails] || '') as DynamicDictionary | string;
            form.field(fieldName).patch(fieldValue);
            if (
                (fieldValue as string) === '' ||
                (fieldName === 'phone' && (fieldValue as DynamicDictionary).phone === '') ||
                (fieldName === 'country' && (fieldValue as DynamicDictionary).iso === '')
            ) {
                form.field(fieldName).markAsUntouched();
            }
        });
    }
    applyValues();
    nextTick(() => {
        allowPolicyHolderWatch.value = true;
    });
}

function applyValuesToChildFieldsLegacy(): void {
    applyValuesToChildFields();
}

function onChildrenChange(): void {
    if (allowPolicyHolderWatch.value) {
        applyValues();
    }
}

function applyValues(): void {
    props.formField.setValue({
        isPolicyHolder: form.field('isPolicyHolder').value,
        isResident: form.field('isResident').value,
        name: form.field('name').value || '',
        surname: form.field('surname').value || '',
        personCode: form.field('personCode').value || '',
        birthDate: form.field('birthDate').value || '',
        country: form.field('country').value || '',
        email: form.field('email').value || '',
        phone: form.field('phone').value || '',
        phoneType: phoneNumberType.value,
        account: form.field('account').value || '',
        currency: '',
        checkboxBase: form.field('checkboxBase').value || '',
        checkboxAdditional: form.field('checkboxAdditional').value || '',
    });
    props.formField.validate();
    props.formField.touch();
    emit('change', props.formField.value);
}

function translated(key: string): string {
    return translateForType(key, props.translationType);
}

function onSubmit(): void {
    emit('submit');
}

function nameValidator(): object {
    return {
        nameIsValid: (value: string) => {
            let result: boolean = true;
            if (!lockUserFields.value) {
                result = form.field('name').isTouched ? Validation.isValidCaption(value) : true;
            }

            return result;
        },
    };
}

function surnameValidator(): object {
    return {
        surnameIsValid: (value: string) => {
            let result: boolean = true;
            if (!lockUserFields.value) {
                result = form.field('surname').isTouched ? Validation.isValidCaption(value) : true;
            }

            return result;
        },
    };
}

function accountValidator(): object {
    return {
        accountIsValid: (value: string) => {
            if (value === '') {
                value = ' ';
            }

            return new IbanValidator().isValidIBANNumber(value);
        },
    };
}

function checkboxAdditionalValidator(): object {
    return {
        checkboxIsValid: (value: string) => {
            let result: boolean = false;
            if (!isPolicyHolder.value) {
                if (form.field('checkboxAdditional').isTouched) {
                    result = form.field('checkboxAdditional').value;
                }
            } else {
                result = true;
            }

            return result;
        },
    };
}

function personCodeValidator(): object {
    return {
        personCodeIsValid: (value: string) => {
            let result: boolean = true;
            if (!lockUserFields.value && form.field('personCode').isTouched) {
                if (!isResident.value) {
                    if (
                        useDefine().isSet(props.indemnityReceiver.country.code) &&
                        BalticCountries.includes(props.indemnityReceiver.country.iso)
                    ) {
                        result = new PersonCodeValidator().isIdentityNumberValid(
                            value,
                            props.indemnityReceiver.country.iso,
                        );
                    } else {
                        result = value.length >= 3;
                    }
                } else {
                    result = new PersonCodeValidator().isIdentityNumberValid(value, new AppCountry().iso());
                }
            }

            return result;
        },
    };
}

function countryValidator(): object {
    return {
        countryIsValid: (value: string) => {
            let result: boolean = false;
            if (needToShowCountry.value) {
                const countryValue: Country = toRaw(value) as unknown as Country;
                if (useDefine().isSet(countryValue.iso) && form.field('country').isTouched) {
                    result = countryValue.iso !== '';
                }
            } else {
                result = true;
            }

            return result;
        },
    };
}

function dateValidator(): object {
    return {
        countryIsValid: (value: string) => {
            let result: boolean = false;
            if (needToShowBirthDate.value) {
                const dateFromForm: Date | '' = value as Date | '';
                if (dateFromForm !== '') {
                    result = moment(dateFromForm).isValid();
                }
            } else {
                result = true;
            }

            return result;
        },
    };
}

function clearPersonCode(value: string): string {
    let result: string = value;
    if (isResident.value) {
        result = Sanitizer.clearPersonCode(value);
    }

    return result;
}

watch(
    () => props.formField.restoreProcessIsFinished,
    () => {
        applyValuesToChildFieldsLegacy();
    },
);

watch(
    () => isPolicyHolder.value,
    (newValue: boolean) => {
        if (allowPolicyHolderWatch.value) {
            if (newValue) {
                if (props.indemnityReceiverOverrideWithLogged && isLogged.value) {
                    form.field('name').setValue(user.current.firstname);
                    form.field('surname').setValue(user.current.lastname);
                    form.field('personCode').setValue(user.current.personCode);
                } else {
                    form.field('name').setValue(props.indemnityReceiver.name);
                    form.field('surname').setValue(props.indemnityReceiver.surname);
                    form.field('personCode').setValue(props.indemnityReceiver.personCode);
                }
            } else {
                form.field('name').clear();
                form.field('surname').clear();
                form.field('personCode').clear();
            }
            applyValues();
        }
    },
);

onMounted((): void => {
    setupForm();
    prepareFormField();
    applyDefaultValues();
    if (spaUserStorage.restoreProcessIsFinished.value) {
        applyValuesToChildFields();
    }
});
</script>

<template>
    <div
        v-if="form.isReady()"
        :id="formField.name"
        class="indemnity-receiver"
        :class="{ ...formField.classes() }"
        :data-store="formField.name"
        :data-store-value="JSON.stringify(formField.value)"
    >
        <div v-if="titleText !== ''" class="title">{{ translated(titleText) }}</div>
        <div class="person-details-person">
            <app-input-radio
                class="policy-holder"
                :custom-border-radius="true"
                :form-field="form.field('isPolicyHolder')"
                data-type="isPolicyHolder"
                :data-store-disabled="true"
                :options="inputOptionsPersonType"
                @change="onChildrenChange"
            >
            </app-input-radio>
            <app-input-radio
                v-if="!isPolicyHolder"
                class="resident"
                :custom-border-radius="true"
                :form-field="form.field('isResident')"
                data-type="isResident"
                :data-store-disabled="true"
                :options="inputOptionsResidentType"
                @change="onChildrenChange"
            >
            </app-input-radio>
        </div>
        <div class="country-block">
            <app-input-country
                v-if="needToShowCountry"
                class="country"
                :label="translated('country')"
                :include-default-country="false"
                :patch-default-country="false"
                :placeholder="translated('country_placeholder')"
                :form-field="form.field('country')"
                data-type="country"
                :data-store-disabled="true"
                @change="onChildrenChange"
            ></app-input-country>
        </div>
        <div class="person-details-base">
            <app-input-text
                class="name"
                :label="translated('name')"
                :disabled="lockUserFields"
                :form-field="form.field('name')"
                data-type="name"
                :placeholder="translated('name_placeholder')"
                :autocomplete="'given-name'"
                :data-store-disabled="true"
                @change="onChildrenChange"
            >
            </app-input-text>
            <app-input-text
                class="surname"
                :label="translated('surname')"
                :disabled="lockUserFields"
                :form-field="form.field('surname')"
                data-type="surname"
                :placeholder="translated('surname_placeholder')"
                :autocomplete="'family-name'"
                :data-store-disabled="true"
                @change="onChildrenChange"
            >
            </app-input-text>
            <app-input-text
                class="person-code"
                :label="translated('person_code')"
                :disabled="lockUserFields"
                :form-field="form.field('personCode')"
                data-type="personCode"
                :placeholder="translated('person_code_placeholder')"
                :autocomplete="'disabled'"
                :data-store-disabled="true"
                @change="onChildrenChange"
            >
            </app-input-text>
        </div>
        <div class="birth-date">
            <app-input-date
                v-if="needToShowBirthDate"
                class="date"
                :label="translated('birth_date')"
                :delimiter="'.'"
                :allow-invalid-value="true"
                :form-field="form.field('birthDate')"
                data-type="birthDate"
                :data-store-disabled="true"
                @change="onChildrenChange"
            ></app-input-date>
        </div>
        <div class="person-details-contacts">
            <app-input-email
                class="email"
                :label="translated('email')"
                :placeholder="translated('email_placeholder')"
                :form-field="form.field('email')"
                data-type="email"
                :data-store-disabled="true"
                @change="onChildrenChange"
            >
            </app-input-email>
            <app-phone-with-country
                class="phone"
                :label="translated('phone')"
                :placeholder="translated('phone_placeholder')"
                :disable-error-text="true"
                :mobile-mode-enabled="true"
                :form-field="form.field('phone')"
                data-type="phone"
                :data-store-disabled="true"
                @change="onChildrenChange"
            >
            </app-phone-with-country>
            <app-input-bank-account
                class="account"
                :label="translated('account')"
                :placeholder="translated('account_placeholder')"
                :form-field="form.field('account')"
                data-type="account"
                :data-store-disabled="true"
                @change="onChildrenChange"
            ></app-input-bank-account>
        </div>
        <div class="checkboxes">
            <app-input-checkbox
                class="base"
                :data-store-disabled="true"
                :form-field="form.field('checkboxBase')"
                data-type="checkboxBase"
                @change="onChildrenChange"
                ><span class="basic-text-formatted" v-html="translated(props.agreeBasicText)"></span>{{}}
            </app-input-checkbox>
            <app-input-checkbox
                v-if="!isPolicyHolder"
                class="additional"
                :data-store-disabled="true"
                :form-field="form.field('checkboxAdditional')"
                data-type="checkboxAdditional"
                @change="onChildrenChange"
            >
                {{ translated(props.agreeAdditionalText) }}
            </app-input-checkbox>
        </div>
        <div class="submit-block">
            <button
                v-if="!hideSubmitButton"
                class="button red submit-button"
                :disabled="!form.isValid()"
                data-type="submit"
                @click="onSubmit"
            >
                {{ translated(props.proceedText) }}
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.indemnity-receiver {
    width: 100%;

    .title {
        font-size: var(--font-size-small);
        font-weight: 700;
        line-height: var(--line-height-accented);
        margin-bottom: var(--size-small);
    }

    .person-details-person {
        margin-bottom: var(--size-nano);

        .policy-holder,
        .resident {
            margin-bottom: var(--size-normal);
        }

        :deep(.input-radio) {
            .buttons {
                button {
                    width: 50%;
                }
            }
        }
    }

    .country-block {
        .country {
            width: 100%;
            margin-bottom: var(--size-normal);
        }

        :deep(.country) {
            .label {
                p {
                    color: var(--component-color-text-value);
                    font-weight: 600;
                }
            }
        }
    }

    .person-details-base {
        display: flex;
        justify-content: space-between;
        gap: var(--size-small);

        .name,
        .surname,
        .person-code {
            margin-bottom: var(--size-normal);
            width: 33%;
        }

        @include respond-below('md') {
            flex-direction: column;
            gap: 0;

            .name,
            .surname,
            .person-code {
                width: 100%;
                max-width: 100%;
            }
        }

        :deep(.input-text) {
            .label {
                font-weight: 600;
            }
        }
    }

    .birth-date {
        .date {
            margin-bottom: var(--size-normal);
            width: 100%;

            :deep(.wrapper) {
                padding: 0 30px 0 20px;
                height: 52px;
                justify-content: flex-start;
            }

            :deep(.label) {
                label {
                    color: var(--component-color-text-value);
                    font-weight: 600;
                }
            }
        }

        @include respond-below('md') {
            flex-direction: column;
            gap: 0;

            .gap-control {
                width: 0;
            }
        }
    }

    .person-details-contacts {
        display: flex;
        justify-content: space-between;
        gap: var(--size-small);

        .email,
        .phone,
        .account {
            margin-bottom: var(--size-normal);
            width: 33%;
        }

        @include respond-below('md') {
            flex-direction: column;
            gap: 0;

            .email,
            .phone,
            .account {
                width: 100%;
            }
        }

        :deep(.phone),
        :deep(.input-text) {
            .label {
                font-weight: 600;

                label {
                    font-weight: 600;
                }
            }
        }
    }

    .checkboxes {
        .additional {
            margin-top: var(--size-small);
        }

        :deep(.base) {
            .title {
                .basic-text-formatted {
                    a {
                        color: var(--text-color-link);

                        &:hover,
                        &:active {
                            color: var(--text-color-link-hover);
                        }
                    }
                }
            }
        }

        :deep(.checkbox) {
            .title {
                color: var(--component-color-text-value);
                font-size: var(--font-size-tiny);
            }
        }
    }

    .submit-block {
        margin-top: 40px;

        .submit-button {
            @include respond-below('md') {
                width: 100%;
            }
        }
    }
}
</style>
