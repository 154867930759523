<script setup lang="ts">
import Popup from '@/Services/popup.service';
import TriggeredEvent = JQuery.TriggeredEvent;
import { onBeforeUnmount, onMounted, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import Dimensions from '@/Interfaces/dimensions.interface';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps({
    disableClose: { type: Boolean, default: false },
    closeOnOverlayClick: { type: Boolean, default: false },
    styles: { type: [String, Object], default: '' },
});
const emit = defineEmits(['close', 'keydown']);

let onWindowResizeSubscription!: Subscription;
let originalMaxWidth: number = 0;

const popupUuid: Ref<string> = ref('');

function close(): void {
    Popup.getInstance().hidePopup();
    emit('close');
}

function keydown(event: KeyboardEvent): void {
    if ((event as unknown as TriggeredEvent).key === 'Escape') {
        close();
    }
    emit('keydown');
}

function onOverlayClick(): void {
    if (props.closeOnOverlayClick) {
        close();
    }
}

function visibilityChanged(isVisible: boolean): void {
    if (isVisible) {
        $('.single-popup-focus').trigger('focus');
    }
}

function resize(): void {
    if (originalMaxWidth > 0) {
        const popupElement: JQuery = selfElement();
        if (popupElement.length > 0) {
            const parent: JQuery<HTMLElement> = popupElement.parent();
            if (parent.length > 0) {
                const offset: JQueryCoordinates | undefined = parent.offset();
                const innerWidth: number | undefined = popupElement.innerWidth();
                const width: number = Math.min(window.innerWidth, window.outerWidth);
                if (offset && innerWidth) {
                    let newInnerWidth: number = originalMaxWidth;
                    if (innerWidth + offset.left > width - offset.left) {
                        newInnerWidth = width - offset.left * 2;
                    }
                    popupElement.css('max-width', newInnerWidth + 'px');
                }
            }
        }
    }
}

function applyUuid(): Promise<void> {
    return new Promise((resolve) => {
        popupUuid.value = uuidv4();
        resolve();
    });
}

function selfElement(): JQuery {
    return $(`#${popupUuid.value}.single-popup`);
}

onMounted(() => {
    applyUuid().then((): void => {
        const elementMaxWidth: number = parseInt(selfElement().css('max-width'), 10);
        if (elementMaxWidth > 0) {
            originalMaxWidth = elementMaxWidth;
            onWindowResizeSubscription = OneBaseService.getInstance().onWindowResize.subscribe(
                (size: Dimensions): void => {
                    close();
                },
            );
            resize();
        }
    });
});

onBeforeUnmount(() => {
    if (onWindowResizeSubscription) {
        onWindowResizeSubscription.unsubscribe();
    }
});
</script>

<template>
    <aside :id="popupUuid" v-observe-visibility="visibilityChanged" class="single-popup">
        <aside class="overlay" @click="onOverlayClick()"></aside>
        <div class="wrapper single-popup-focus" tabindex="0" :style="styles" @keydown="keydown($event)">
            <button v-if="!disableClose" class="popup-close close" @click="close()">
                <svg
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="content"
                >
                    <circle cx="28" cy="28" r="28" class="circle" />
                    <path d="M22 22L34 34M34 22L22 34" stroke-linecap="round" stroke-linejoin="round" class="cross" />
                </svg>
            </button>
            <slot></slot>
        </div>
    </aside>
</template>

<style lang="scss" scoped>
.popup-close.close {
    .content {
        width: 40px;
        height: 40px;
    }

    .circle {
        fill: white;
    }

    .cross {
        opacity: 0.48;
        stroke: #9297a0;
        stroke-width: 2;
    }

    svg {
        &:hover {
            box-shadow: var(--box-shadow-zenith);
            border-radius: 20px;
        }
    }
}
</style>
