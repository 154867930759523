export enum FieldNames {
    // 1st
    WhatHappenedToTransport = 'whatHappenedToTransport',
    HasCarriersTripCancellationConfirmation = 'hasCarriersTripCancellationConfirmation',
    HoursDelayed = 'hoursDelayed',
    TotalExpensesBecauseOfDelay = 'totalExpensesBecauseOfDelay',
    // 2nd
    ReasonOfTripCancellation = 'reasonOfTripCancellation',
    HowWasTripPlanned = 'howWasTripPlanned',
    HadOtherInsuredPersonsWhoDidNotGo = 'hadOtherInsuredPersonsWhoDidNotGo',
    OtherInsuredPersonsWhoDidNotGo = 'otherInsuredPersonsWhoDidNotGo',
    OtherInsuredPersonsFromPolicy = 'otherInsuredPersonsFromPolicy',
    HadIndemnificationOfLoses = 'hadIndemnificationOfLoses',
    SumIndemnified = 'sumIndemnified',
    TripCancellationRelatedLoses = 'tripCancellationRelatedLoses',
}
