import AppItemsButton from '@/Components/Buttons/ItemsButton/ItemsButton.vue';
import AppOverlayButton from '@/Components/Buttons/OverlayButton/OverlayButton.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import Badge from '@/Components/Buttons/Badge/Badge.vue';
import Button from '@/Components/Buttons/Button/Button.vue';
import ButtonPrimary from '@/Components/Buttons/ButtonPrimary/ButtonPrimary.vue';
import ButtonPrimaryAlternative from '@/Components/Buttons/ButtonPrimaryAlternative/ButtonPrimaryAlternative.vue';
import ButtonSecondary from '@/Components/Buttons/ButtonSecondary/ButtonSecondary.vue';
import ButtonTertiary from '@/Components/Buttons/ButtonTertiary/ButtonTertiary.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppItemsButton: AppItemsButton,
    AppOverlayButton: AppOverlayButton,
    AppButtonWithCallback: AppButtonWithCallback,
    AppBadge: Badge,
    AppButton: Button,
    AppButtonPrimary: ButtonPrimary,
    AppButtonPrimaryAlternative: ButtonPrimaryAlternative,
    AppButtonSecondary: ButtonSecondary,
    AppButtonTertiary: ButtonTertiary,
} as Components;
