<script lang="ts">
import { defineComponent, Ref, ref, nextTick } from 'vue';
import Error from '@/Services/error.service';
import OneBaseService from '@/Services/OneBaseService';
import { useModule } from '@/Composables/Module';
import OneModule from '@/Interfaces/OneModuleInterface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import moment from 'moment/moment';
import FormField from '@/Assets/Libraries/Form/FormField';
import { Observable, of } from 'rxjs';
import { useTranslate } from '@/Composables/Translate';
import { FileByLink } from '@/Interfaces/Resources/file.by.link.interface';
import Form from '@/Assets/Libraries/Form/Form';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Url from '@/Enums/UrlEnum';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import { useDefine } from '@/Composables/Define';
import PopupService from '@/Services/custom.popup.service';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useClipboard } from '@/Composables/Clipboard';

const { translate } = useTranslate();
const { validResponse } = useDefine();
const { copyToClipboard } = useClipboard();

export default defineComponent({
    setup() {
        const error: Error = Error.getInstance();
        const module: OneModule = useModule();
        const request: AxiosParams = useAxios();
        const moduleForm: Form = new Form();
        const ready: Ref<boolean> = ref(false);
        const generatedFileByLink: Ref<FileByLink | null> = ref(null);
        const minCreationDate: Date = moment().add(-3, 'months').toDate();
        const documentTypes: Record<string, string>[] = [
            { id: 'POLICY', defaultClientMessageTypeId: '', nameKey: 'btar_printout_agreement' },
            { id: 'PROPOSAL', defaultClientMessageTypeId: '', nameKey: 'btar_printout_proposal' },
            { id: 'CLAIM_CASE', defaultClientMessageTypeId: '', nameKey: 'btar_printout_claim_case' },
        ];
        const clientMessageTypes: Record<string, string>[] = [
            { id: 'MSG001', nameKey: 'btar_client_message_type_msg001' },
            { id: 'MSG002', nameKey: 'btar_client_message_type_msg002' },
            { id: 'MSG003', nameKey: 'btar_client_message_type_msg003' },
        ];
        const renew: boolean = true;

        const create = (): void => {};

        const beforeModuleMounted = () => {
            module.beforeModuleMounted();
            if (!ready.value) {
                ready.value = true;
                const onExternalDataIsReady: Observable<void> = module.ready.value ? of(void 0) : module.onModuleReady;
                onExternalDataIsReady.subscribe((): void => {
                    nextTick((): void => {
                        setupForm();
                        module.applyModuleType();
                    });
                });
            }
        };

        const moduleMounted = () => {
            module.moduleMounted();
        };

        const setupForm = (): void => {
            moduleForm.addField(new FormField('documentNumber', '', 'required'));
            moduleForm.addField(new FormField('documentType', '', 'required'));
            moduleForm.addField(
                new FormField('clientMessageType', '', {
                    oneOfFieldsIsNotEmpty: () => {
                        return !moduleForm.field('clientMessageType').isEmpty();
                    },
                }),
            );
            moduleForm.addField(new FormField('insuredObjectText', ''));
            moduleForm.addField(new FormField('minimalFileCreationDate', ''));
            moduleForm.addField(new FormField('validTo', ''));
            moduleForm.setReady();
        };

        const setClientMessageTypeForDocumentType = (documentTypeId: string): void => {
            const documentType: Record<string, string> | undefined = documentTypes.find(
                (item: Record<string, string>) => item.id === documentTypeId,
            );
            if (documentType) {
                const clientMessageType: Record<string, string> | undefined = clientMessageTypes.find(
                    (item: Record<string, string>) => item.id === documentType.defaultClientMessageTypeId,
                );
                if (clientMessageType) {
                    moduleForm.field('clientMessageType').patch(clientMessageType.id);
                }
            }
        };

        const documentTypesInputOptions = (): InputOption[] => {
            return documentTypes.map((item: Record<string, string>) =>
                new InputOptionBuilder().setValue(item.id).setName(translate(item.nameKey)).build(),
            );
        };

        const clientMessageTypesInputOptions = (): InputOption[] => {
            return clientMessageTypes.map((item: Record<string, string>) => {
                return new InputOptionBuilder().setValue(item.id).setName(translate(item.nameKey)).build();
            });
        };

        const copyLink = (): void => {
            if (generatedFileByLink.value) {
                copyToClipboard(generatedFileByLink.value.filesUploadUrl);
            }
        };

        const generateLink = (): void => {
            nextTick(() => {
                PopupService.getInstance().show(new OnePopup().withType().loading);
            });
            const params: any = {
                documentNumber: moduleForm.field('documentNumber').value,
                documentType: moduleForm.field('documentType').value,
                clientMessageType: moduleForm.field('clientMessageType').value,
                clientMessageText: moduleForm.field('clientMessageText').value,
                insuredObjectText: moduleForm.field('insuredObjectText').value,
                minimalFileCreationDate: moduleForm.field('minimalFileCreationDate').value
                    ? moment(moduleForm.field('minimalFileCreationDate').value.startDate).unix()
                    : null,
                validTo: moduleForm.field('validTo').value
                    ? moment(moduleForm.field('validTo').value.startDate).unix()
                    : null,
            };
            request
                .post(Url.Ajax.filesByLinkGenerate, params)
                .then((value: any) => {
                    PopupService.getInstance().hide();
                    if (validResponse(value) && value.data.data.status === 'OK') {
                        generatedFileByLink.value = value.data.data.body;
                    } else {
                        throw value;
                    }
                })
                .catch((reason: any) => {
                    PopupService.getInstance().hide();
                    error.show(ErrorType.Error, 'filesByLinkGenerateLink', reason);
                });
        };

        return {
            ...module,
            ...{
                renew,
                generatedFileByLink,
                moduleForm,
                minCreationDate,
                create,
                beforeModuleMounted,
                moduleMounted,
                setClientMessageTypeForDocumentType,
                documentTypesInputOptions,
                clientMessageTypesInputOptions,
                copyLink,
                generateLink,
            },
        };
    },

    mounted() {
        OneBaseService.getInstance().applyApp(this);
    },
});
</script>
