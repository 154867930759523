import PopupBase from '@/Assets/Libraries/Popups/PopupBase';
import PopupType from '@/Enums/PopupTypeEnum';
import NextBestActionCustomer from '@/Interfaces/next.best.action.customer.interface';

export default class NextBestAction extends PopupBase {
    public constructor() {
        super();
        this.applyType(PopupType.NextBestAction);
    }

    public withData(value: NextBestActionCustomer | null): NextBestAction {
        this.applyData(value);

        return this;
    }
}
