import PayByLink from '@/Apps/PayByLink/Pay/Interfaces/PayByLinkInterface';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import { reactive, Ref, ref, UnwrapNestedRefs } from 'vue';
import SpaFormStorageService from '@/Services/SpaFormStorageService';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { MtplOffer } from '@/Interfaces/Resources/Offers/MtplOfferInterface';

interface PayByLinkFormFields {
    offerPeriod: string;
    offerPaymentCount: string;
    driverAge: string;
    offerOptions: DynamicDictionary;
    marketingConsents: DynamicDictionary;
    agreeTerms: boolean;
    holderEmail: string;
}

export default class PayByLinkService {
    public payByLink: Ref<PayByLink> = ref({} as PayByLink);
    public mtplOffer: Ref<MtplOffer> = ref({} as MtplOffer);
    public renewedOfferId: Ref<string> = ref('');
    public hasCalculationError: Ref<boolean> = ref(false);
    public fields!: UnwrapNestedRefs<PayByLinkFormFields>;
    private static instance: PayByLinkService;
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();

    public static getInstance(): PayByLinkService {
        if (!PayByLinkService.instance) {
            PayByLinkService.instance = new PayByLinkService();
        }
        PayByLinkService.instance.init();

        return PayByLinkService.instance;
    }

    public init(): void {
        this.payByLink.value = TransferStateService.getInstance().get<PayByLink>('payByLink');
        this.mtplOffer.value = TransferStateService.getInstance().get<MtplOffer>('mtplOffer');
        this.renewedOfferId.value = TransferStateService.getInstance().get<string>('renewedOfferId');
        if (!this.fields) {
            this.fields = reactive(this.spaFormStorageService.formStorageFields());
        }
        this.spaFormStorageService.applyDataProviderCallback((): PayByLinkFormFields => this.fields);
    }
}
