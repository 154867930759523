<script lang="ts">
import { defineComponent, Ref, ref, nextTick } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import { useModule } from '@/Composables/Module';
import OneModule from '@/Interfaces/OneModuleInterface';
import { Observable, of } from 'rxjs';
import { FileUploadParams, useFileUpload } from '@/Composables/FileUpload';

export default defineComponent({
    setup() {
        const module: OneModule = useModule();
        const fileByLinkUpload: FileUploadParams = useFileUpload();

        const ready: Ref<boolean> = ref(false);
        const isLoading: Ref<boolean> = ref(false);
        const isSuccessMessage: Ref<boolean> = ref(false);
        const uploader: string = 'mobile';
        const renew: boolean = true;

        const create = (): void => {};

        const beforeModuleMounted = () => {
            module.beforeModuleMounted();
            if (!ready.value) {
                ready.value = true;
                const onExternalDataIsReady: Observable<void> = module.ready.value ? of(void 0) : module.onModuleReady;
                onExternalDataIsReady.subscribe((): void => {
                    nextTick((): void => {
                        fileByLinkUpload.filesRequired.value = false;
                        module.applyModuleType();
                    });
                });
            }
        };

        const moduleMounted = () => {
            module.moduleMounted();
        };

        return {
            ...module,
            ...{
                renew,
                isLoading,
                isSuccessMessage,
                uploader,
                fileByLinkUpload,
                create,
                beforeModuleMounted,
                moduleMounted,
            },
        };
    },

    mounted() {
        OneBaseService.getInstance().applyApp(this);
    },
});
</script>
