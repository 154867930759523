import { PropType } from 'vue';
import { Size } from '@/Components/Buttons/Button/ButtonEnums';

export default {
    size: { type: String as PropType<Size>, default: Size.Large },
    expand: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    href: { type: String as PropType<string | null>, default: null },
};
