<script lang="ts">
import { computed, defineComponent, nextTick, ref, Ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import { Router, useRouter, RouteRecordRaw } from 'vue-router';
import RouteStepperStep from '@/Components/Routing/RouteStepper/RouteStepperStepInterface';
import { Subscription } from 'rxjs';
import Translations from '@/Services/translations.service';
import ClaimsTravelService from '@/Apps/ClaimsTravel/Services/ClaimsTravelService';
import GuardsService from '@/Apps/ClaimsTravel/Services/GuardsService';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import TravelType from '@/Apps/ClaimsTravel/Interfaces/TravelType';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const claimsTravelService: ClaimsTravelService = ClaimsTravelService.getInstance();
        const guardService: GuardsService = GuardsService.getInstance();
        const transferService: TransferStateService = TransferStateService.getInstance();

        const router: Router = useRouter();

        const CurrentStep: number = 1;
        const Facility: string = 'one-claims-travel';
        const TranslationType: string = 'claims_travel';
        const landingPageRoute: string = transferService.get('landingPageRoute');
        const landingPageTitle: string = transferService.get('landingPageTitle');

        const appPreparedAndReady: Ref<boolean> = ref(false);

        const steps: RouteStepperStep[] = [
            {
                path: landingPageRoute,
                meta: { stepLabel: landingPageTitle, title: landingPageTitle },
                redirect: '',
            } as RouteRecordRaw,
        ]
            .concat(router.options.routes || [])
            .map((routeConfig: RouteRecordRaw, index: number): RouteStepperStep => {
                return {
                    routeConfig: routeConfig,
                    label: routeConfig.meta?.title as string,
                    rowIndex: index + 1,
                    visible: true,
                };
            });

        function applyInitialStepUrl(initialStepUrl: string): void {
            claimsTravelService.initialStepUrl = initialStepUrl;
        }

        function applyTravelTypes(types: TravelType[]): void {
            claimsTravelService.travelTypes = types;
        }

        function applyTripCancellationRiskIc(riskIc: string): void {
            claimsTravelService.tripCancellationRiskIc = riskIc;
        }

        function applyThankYouStepUrl(url: string): void {
            claimsTravelService.thankYouStepUrl = url;
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                TranslationType,
                steps,
                guardService,
                claimsTravelService,
                appPreparedAndReady,
                applyInitialStepUrl,
                applyThankYouStepUrl,
                applyTravelTypes,
                applyTripCancellationRiskIc,
            },
        };
    },
    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.setStorageUsage(false);
        Translations.getInstance().addType(this.TranslationType);
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.appPreparedAndReady = true;
            this.claimsTravelService.init();
            this.guardService.applyInitialStepUrl(this.claimsTravelService.initialStepUrl);
            this.guardService.init();
            nextTick(() => {
                this.sticky.init();
            });
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
