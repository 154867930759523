import OneRisk from '@/Interfaces/one.risk.interface';
import PropertyRisk from '@/Interfaces/property.risk.interface';
import PropertyRiskData from '@/Interfaces/property.risk.data.interface';
import { useTranslate } from '@/Composables/Translate';
import { usePrice } from '@/Composables/Price';
import PropertyEnum from '@/Enums/PropertyEnum';

const { translateForType, hasLocalization } = useTranslate();
const { sparse } = usePrice();

export default class CoveredPopupProperty {
    public contentID: string = '';
    public title: string = '';
    public description: string = '';
    public deductible: string = '';
    public coveredRisks: PropertyRisk[] = [];
    public oneRisks: OneRisk[] = [];
    private translationType: string = PropertyEnum.TranslationType;

    public build(id: string, deductible: string = '', risks: PropertyRiskData[] = []): CoveredPopupProperty {
        this.contentID = id;
        this.title = translateForType('one_property_policy_covered_title', this.translationType);
        this.description = translateForType('one_property_policy_covered_description', this.translationType);
        this.coveredRisks = [];
        if (risks.length > 0) {
            this.addRisks(risks);
        }
        this.deductible = '';
        if (deductible !== '') {
            this.deductible = sparse(parseFloat(deductible), false);
        }

        return this;
    }

    public addCustomRisk(riskIc: string, riskPrefix: string = ''): void {
        const riskCombinedId: string = (riskPrefix !== '' ? riskPrefix + '_' : '') + riskIc;
        const title: string = translateForType('one_property_risk_' + riskCombinedId, this.translationType);
        const description: string = translateForType(
            'one_property_risk_' + riskCombinedId + '_tip_description',
            this.translationType,
        );
        const tipTitle: string = 'one_property_risk_' + riskCombinedId + '_tip_title';
        const tooltipTitle: string = hasLocalization(tipTitle, this.translationType)
            ? translateForType(tipTitle, this.translationType)
            : '';
        const tipDescription: string = 'one_property_risk_' + riskCombinedId + '_tip_description';
        const tooltipDescription: string = hasLocalization(tipDescription, this.translationType)
            ? translateForType(tipDescription, this.translationType)
            : '';
        if (!this.hasRisk(riskIc, riskPrefix)) {
            this.coveredRisks.push(
                new (class implements PropertyRisk {
                    public id: string = riskIc;
                    public prefix: string = riskPrefix;
                    public title: string = title;
                    public description: string = description;
                    public tooltipTitle: string = tooltipTitle;
                    public tooltipDescription: string = tooltipDescription;
                })(),
            );
        }
    }

    private hasRisk(riskIc: string, riskPrefix: string): boolean {
        let result: boolean = false;
        this.coveredRisks.forEach((risk: PropertyRisk) => {
            if (risk.id === riskIc && risk.prefix === riskPrefix) {
                result = true;
            }
        });

        return result;
    }

    private addRisks(risks: PropertyRiskData[]): void {
        risks.forEach((risk: PropertyRiskData) => {
            this.addCustomRisk(risk.id, risk.prefixByIc);
        });
    }
}
