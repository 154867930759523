<script setup lang="ts">
import Form from '@/Assets/Libraries/Form/Form';
import { onMounted, ref, Ref, getCurrentInstance } from 'vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import { Router, useRouter } from 'vue-router';
import { useTranslate } from '@/Composables/Translate';
import PopupService from '@/Services/custom.popup.service';
import { CoveredPopupBuilder } from '@/Apps/Renters/Builders/CoveredPopupBuilder';
import OnePopup from '@/Assets/Libraries/Popups/OnePopup';
import { useDefine } from '@/Composables/Define';
import StepsGuard from '@/Apps/Renters/Services/StepsGuard';
import RentersService from '@/Apps/Renters/Services/RentersService';
import { useScroll } from '@/Composables/Scroll';
import { Renters } from '@/Apps/Renters/Interfaces/RentersInterface';
import ProductBlockItemBuilder from '@/Components/Other/ProductBlock/Builders/ProductBlockItemBuilder';
import { CoverageRisk } from '@/Apps/Renters/Interfaces/CoverageRiskInterface';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import OneBaseService from '@/Services/OneBaseService';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppProductBlock from '@/Components/Other/ProductBlock/ProductBlock.vue';
import ProductBlockItem from '@/Components/Other/ProductBlock/Interfaces/ProductBlockItemInterface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import OneBase from '@/Interfaces/OneBaseInterface';
import { ErrorInterface, useError } from '@/Composables/Error';

const router: Router = useRouter();
const { translateForType } = useTranslate();
const { isSet, isLinkAnchor } = useDefine();
const { logError }: ErrorInterface = useError();

const form: Form<{ 'product-panel-blocks': unknown }> = new Form({ useValidationV2: true });
const popupService: PopupService = PopupService.getInstance();
const btaBase: OneBase = OneBaseService.getInstance();
const TranslationType: string = 'renters';
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const productBlocks: Ref<ProductBlockItem[]> = ref([]);
const BadgeTextSuffix: string = '_BADGE_TEXT';
const rentersService: RentersService = RentersService.getInstance();
const selectedProgram: Ref<string> = ref('');

defineExpose({
    coveredPopup,
    submit,
});

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    StepsGuard.getInstance(rentersService).init();
    if (shouldReroute()) {
        reRoute();
    } else {
        rentersService.fetchProducts().then((): void => {
            setupForm();
            buildProductBlocks();
            buildCoveredPopup();
            useScroll().scrollToTop();
        });
    }
});

function setupForm(): void {
    form.addField(new FormField('product-panel-blocks'));
    form.setReady();
}

function buildCoveredPopup(): void {
    coveredPopup
        .withCoveredType('AppCoveredPopupRentersMulti')
        .withTranslationKey(TranslationType)
        .withCoveredTitle('covered_compare')
        .withOnSelectClick(onSelectProductClick as Function)
        .withContent(rentersService.products.value);
}

function onSelectProductClick(programs: string[]): void {
    selectedProgram.value = programs[0];
    btaBase.captcha
        .executeCaptcha(submit)
        .then()
        .catch((reason: string): void => {
            logError(ErrorType.Error, 'onSubmitStep', reason);
        });
}

function submit(): void {
    prepareSubmit();
    router.push({ name: 'renters-coverage' });
    popupService.hide();
}

function prepareSubmit(): void {
    rentersService.fields.programIc = selectedProgram.value;
}

function onShowCoveredPopupClick(): void {
    popupService.show(new OnePopup().withType().oneCovered);
}

function buildProductBlocks(): void {
    rentersService.products.value.forEach((policy: Renters): void => {
        const programIc: string = policy.id.toLowerCase();
        const productBlockItemBuilder: ProductBlockItemBuilder = new ProductBlockItemBuilder();
        const productName: string = translateForType(programIc, TranslationType);
        const price: number = policy.defaultPrice;
        const badgeText: string = translateForType(policy.id + BadgeTextSuffix, TranslationType);
        const customModule: string = 'AppProductBlockInnerRentersPolicy';
        const selectButtonText: string = translateForType('insure_now', TranslationType);
        const customButtonText: string = translateForType('what_covered', TranslationType);
        const risks: CoverageRisk[] = policy.risks as CoverageRisk[];
        const dynamicContent: DynamicDictionary = {
            insuredSum: policy.defaultInsuredSum,
            risks: risks,
        };
        productBlockItemBuilder
            .withProductId(policy.id)
            .withProductName(productName)
            .withDiscountSum(price)
            .withDiscountTooltip({
                title: translateForType('price_from', TranslationType),
                description: translateForType('tooltip_product_block_description', TranslationType),
            })
            .withDynamicContent(customModule, dynamicContent)
            .withButtonSelectTitle(selectButtonText)
            .withButtonCustomTitle(customButtonText);
        if (policy.params.withBadge) {
            productBlockItemBuilder.withBadgeText(badgeText);
        }
        if (policy.params.defaultOnMobile) {
            productBlockItemBuilder.asDefaultOnMobile();
        }
        productBlocks.value.push(productBlockItemBuilder.build());
    });
}

function reRoute(): void {
    router.push(rentersService.store().currentRoute);
}

function shouldReroute(): boolean {
    const isCurrentRoute: boolean = rentersService.store().currentRoute !== '';
    const isStorageSelectedProgram: boolean = isSet(rentersService.fields.programIc);

    return isStorageSelectedProgram && isCurrentRoute && !isLinkAnchor(location.href);
}
</script>

<template>
    <div class="wrapper">
        <div class="content">
            <section class="header">
                <h2 class="title">{{ translateForType('title_renters', TranslationType) }}</h2>
                <div class="description">
                    {{ translateForType('subtitle_renters', TranslationType) }}
                </div>
                <div v-if="!form.isReady()" class="loading">
                    <app-content-loader></app-content-loader>
                </div>
            </section>
            <app-custom-form else :form="form" class="form">
                <app-product-block
                    :id="'renterProducts'"
                    :form-field="form.field('product-panel-blocks')"
                    :products="productBlocks"
                    :product-sum-type="'mo'"
                    :selected-frame-hidden="true"
                    :mini-plans-component="'AppRentersMini'"
                    @selected="onSelectProductClick"
                    @custom="onShowCoveredPopupClick"
                >
                </app-product-block>
            </app-custom-form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.wrapper {
    .content {
        .header {
            text-align: center;
            margin-bottom: 40px;

            .description {
                font-size: var(--size-small);
                font-weight: 500;
                color: var(--text-color-subtle);
                text-align: center;
                line-height: 140%;
                margin-bottom: 44px;
            }
        }

        .loading {
            margin-top: 60px;
            left: 50%;
        }
    }
}
</style>
