import { Ref, ref } from 'vue';
import StringDictionary from '@/Interfaces/string.dictionary.interface';
import OneDashboardTabs from '@/Pages/OneDashboard/Enums/OneDashboardTabs';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { useTranslate } from '@/Composables/Translate';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';

export const useOneDashboard = (): OneDashboardInterface => {
    const navigationUrls: Ref<StringDictionary> = ref({});
    const currentActiveTab: Ref<string> = ref(OneDashboardTabs.None);
    const navigationButtons: Ref<InputOption[]> = ref([]);
    const { translateForType, applyTranslationType, type } = useTranslate();

    const buildNavigationBelt = (): void => {
        navigationButtons.value = [];
        Object.keys(navigationUrls.value).forEach((value: string): void => {
            if (value !== '') {
                navigationButtons.value.push(
                    new InputOptionBuilder()
                        .setName(translated(value.replaceAll('-', '_')))
                        .setValue(navigationUrls.value[value])
                        .setCustom({
                            active: currentActiveTab.value === value,
                            counter: 0,
                            tab: value,
                        })
                        .build(),
                );
            }
        });
    };

    const applyNavigationUrls = (urls: StringDictionary): void => {
        navigationUrls.value = urls;
    };

    const applyTabUid = (tab: string): void => {
        currentActiveTab.value = tab;
        applyActiveTab(tab);
    };

    const applyActiveTab = (tab: string): void => {
        navigationButtons.value.forEach((item: InputOption): void => {
            if (item.custom) {
                (item.custom as DynamicDictionary).active = (item.custom as DynamicDictionary).tab === tab;
            }
        });
    };

    const translated = (stringId: string): string => {
        applyTranslationType('dashboard');
        return translateForType(stringId, type());
    };

    return {
        navigationUrls,
        currentActiveTab,
        navigationButtons,
        buildNavigationBelt,
        applyNavigationUrls,
        applyTabUid,
        applyActiveTab,
        translated,
    };
};

export interface OneDashboardInterface {
    navigationUrls: Ref<StringDictionary>;
    currentActiveTab: Ref<string>;
    navigationButtons: Ref<InputOption[]>;
    buildNavigationBelt: () => void;
    applyNavigationUrls: (urls: StringDictionary) => void;
    applyTabUid: (tab: string) => void;
    applyActiveTab: (tab: string) => void;
    translated: (stringId: string) => string;
}
