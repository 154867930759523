<script setup lang="ts">
import { computed, onMounted, ref, Ref } from 'vue';
import FormField from '@/Assets/Libraries/Form/FormField';
import Map from '@/Enums/MapEnum';
import MapControlsOptions from '@/Components/Maps/MapControls/MapControlsOptions';
import MapLocation from '@/Interfaces/map.location.interface';
import SettingsService from '@/Services/settings.service';
import Error from '@/Services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import Value from '@/Assets/Libraries/Form/Value';
import { useDefine } from '@/Composables/Define';
import { useTranslate } from '@/Composables/Translate';
import { useMaps } from '@/Composables/Maps';
import AppMapControls from '@/Components/Maps/MapControls/MapControls.vue';

const props = defineProps({
    componentName: { type: String, default: 'MapWithAddress' },
    translationType: { type: String, default: 'components' },
    formField: { type: FormField<MapLocation>, default: () => new FormField('') },
    label: { type: String, default: '' },
    customSearchPlaceholder: { type: String, default: '' },
    dataStoreDisabled: { type: Boolean, default: false },
});
const emit = defineEmits(['change']);
const { translateForType } = useTranslate();
const { isSet } = useDefine();
const { mapLoader, createMap, locationIcon, changeBasemap, mapSettings } = useMaps();
const address: Ref<string> = ref('');
const selectedLocation: Ref<MapLocation | null> = ref(null);
const options: MapControlsOptions = new MapControlsOptions();
options.zoomControl = true;
options.homeControl = true;
options.geolocateControl = true;
options.basemapControl = true;
const isLabelVisible: Ref<boolean> = computed(() => {
    return props.label !== '';
});

const isCustomPlaceholder: Ref<boolean> = computed(() => {
    return props.customSearchPlaceholder !== '';
});

let map: google.maps.Map | null = null;
let marker!: google.maps.Marker;

onMounted((): void => {
    initMap();
});

function initMap(): void {
    const mapId: string = 'map-with-address';
    mapLoader()
        .load()
        .then(() => {
            map = createMap(mapId) as google.maps.Map;
            map.addListener('click', (event: google.maps.IconMouseEvent) => {
                if (event.placeId) {
                    searchByPlaceId(event.placeId);
                } else {
                    searchLocationAndApplyFormField(event.latLng!.lng(), event.latLng!.lat());
                }
            });
            setupMap();
        })
        .catch(() => {
            showError(Map.Error.MapCreationFail, initMap.name);
        });
}

function setupMap(): void {
    setupCursors();
    setupControls();
    setupAutoSuggest();
    restoreStoredLocation();
}

function setupControls(): void {
    const mapControls: HTMLElement = document.getElementById('map-with-address-controls') as HTMLElement;
    map!.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(mapControls);
}

function setupCursors(): void {
    map!.setOptions({ draggableCursor: 'crosshair' });
}

function setupAutoSuggest(): void {
    const attributes = {
        fields: ['name', 'geometry', 'place_id', 'formatted_address'],
        strictBounds: false,
    };
    const autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
        googleSearchElement(),
        attributes,
    );
    autocomplete.addListener('place_changed', () => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        const geometry: google.maps.places.PlaceGeometry | undefined = place.geometry;
        const location: google.maps.LatLng | undefined = geometry?.location;
        if (place && geometry && location) {
            if (place.place_id) {
                searchByPlaceId(place.place_id);
            } else {
                searchLocationAndApplyFormField(Number(location.lng()), Number(location.lat()));
            }
            map!.panTo({ lat: location.lat(), lng: location.lng() });
            map!.setZoom(mapSettings.maxZoom);
        }
    });
}

function storeLocation(): void {
    selectedLocation.value!.mapTypeId = String(map!.getMapTypeId());
    props.formField.patch(selectedLocation.value!);
}

function updateLocation(): void {
    storeLocation();
    createMarker();
    emit('change', selectedLocation);
}

function restoreStoredLocation(): void {
    if (!new Value(props.formField.value.address).isEmpty()) {
        address.value = props.formField.value.address;
    }
    if (!new Value(props.formField.value.id).isEmpty()) {
        selectedLocation.value = props.formField.value as MapLocation;
        map!.setMapTypeId(selectedLocation.value.mapTypeId);
        createMarker();
    } else {
        selectedLocation.value = {
            id: '',
            address: '',
            latitude: 0,
            longitude: 0,
            countryIso: '',
            mapTypeId: String(map!.getMapTypeId()),
        };
    }
}

function createMarker(): void {
    const coordinates: google.maps.LatLng = new google.maps.LatLng(
        Number(selectedLocation.value!.latitude),
        Number(selectedLocation.value!.longitude),
    ) as google.maps.LatLng;
    removeMarker();
    marker = new google.maps.Marker({
        position: coordinates,
        title: selectedLocation.value!.address,
        map: map,
    });
    locationIcon(marker);
}

function removeMarker(): void {
    if (isSet(marker)) {
        marker.setMap(null);
    }
}

function googleSearchElement(): HTMLInputElement {
    const input: HTMLCollectionOf<Element> = document.getElementsByClassName('suggestions-search');

    return input.item(0) as HTMLInputElement;
}

function searchLocationAndApplyFormField(longitude: number, latitude: number): void {
    const location: google.maps.LatLng = new google.maps.LatLng(latitude, longitude);
    sendGeoCoderRequest({ location: location });
}

function searchByPlaceId(placeId: string): void {
    sendGeoCoderRequest({ placeId: placeId });
}

function sendGeoCoderRequest(params: google.maps.GeocoderRequest): void {
    new google.maps.Geocoder().geocode(
        params,
        (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
            if (results && results.length > 0 && status === google.maps.GeocoderStatus.OK) {
                const geoCoderResult: google.maps.GeocoderResult = results[0];
                selectedLocation.value!.latitude = geoCoderResult.geometry.location.lat();
                selectedLocation.value!.longitude = geoCoderResult.geometry.location.lng();
                selectedLocation.value!.id = geoCoderResult.place_id;
                selectedLocation.value!.address = geoCoderResult.formatted_address;
                selectedLocation.value!.countryIso = countryIso(geoCoderResult);
                selectedLocation.value!.mapTypeId = map!.getMapTypeId()!;
                address.value = selectedLocation.value!.address;
                updateLocation();
            }
        },
    );
}

function onClearLocation(): void {
    selectedLocation.value!.id = '';
    props.formField.clear();
    address.value = '';
    removeMarker();
}

function showError(errorType: string, functionName: string): void {
    Error.getInstance().show(
        ErrorType.Error,
        props.componentName + '::' + functionName,
        translateForType(errorType, props.translationType),
    );
}

function onZoomIn(): void {
    map!.setZoom(Number(map!.getZoom()) + 1);
}

function onZoomOut(): void {
    map!.setZoom(Number(map!.getZoom()) - 1);
}

function onMapHome(): void {
    map!.setCenter(mapSettings.defaultMapCenter);
    map!.setZoom(mapSettings.defaultZoom);
}

function onLocatePlacesNearMe(): void {
    if (isSet(navigator.geolocation)) {
        navigator.geolocation.getCurrentPosition((position: DynamicDictionary) => {
            const coordinates: google.maps.LatLng = new google.maps.LatLng(
                position.coords!.latitude,
                position.coords!.longitude,
            );
            map!.setCenter(coordinates);
            map!.setZoom(mapSettings.maxZoom);
        });
    } else {
        map!.setCenter(mapSettings.defaultMapCenter);
        map!.setZoom(mapSettings.locateZoom);
        showError(Map.Error.MapUnsuportGeolocations, onLocatePlacesNearMe.name);
    }
}

function onChangeBasemap(): void {
    changeBasemap(map!);
    storeLocation();
}

function clearIsVisible(): boolean {
    return address.value !== '';
}

function countryIso(geocoderResult: google.maps.GeocoderResult): string {
    let result: string = SettingsService.getInstance().localeIso();
    if (geocoderResult.address_components) {
        const countryComponent: google.maps.GeocoderAddressComponent | undefined =
            geocoderResult.address_components.find((component: google.maps.GeocoderAddressComponent): boolean =>
                component.types.includes('country'),
            );
        if (countryComponent) {
            result = countryComponent.short_name;
        }
    }

    return result;
}
</script>
<template>
    <div
        class="map-with-address"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div>
            <div v-if="isLabelVisible" class="label">
                <label>{{ label }}</label>
            </div>
            <div class="search-container">
                <div class="google-search-suggestions">
                    <button class="geo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path
                                fill="#000"
                                d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                                class="pin"
                            />
                            <path
                                fill="#fff"
                                d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                                class="dot"
                            />
                        </svg>
                    </button>
                    <input
                        v-model="address"
                        type="text"
                        :placeholder="
                            isCustomPlaceholder
                                ? customSearchPlaceholder
                                : translateForType('map_drop_pin_or_type_address', translationType)
                        "
                        class="suggestions-search"
                    />
                    <button v-if="clearIsVisible()" class="clear" @click="onClearLocation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path
                                stroke="#9297a0"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M15.1038 4.66848C15.3158 4.45654 15.5674 4.28843 15.8443 4.17373C16.1212 4.05903 16.418 4 16.7177 4C17.0174 4 17.3142 4.05903 17.5911 4.17373C17.868 4.28843 18.1196 4.45654 18.3315 4.66848C18.5435 4.88041 18.7116 5.13201 18.8263 5.40891C18.941 5.68582 19 5.9826 19 6.28232C19 6.58204 18.941 6.87882 18.8263 7.15573C18.7116 7.43263 18.5435 7.68423 18.3315 7.89617L7.43807 18.7896L3 20L4.21038 15.5619L15.1038 4.66848Z"
                                class="clear-color"
                            />
                            <rect fill="#9297a0" width="11" height="1.5" x="10" y="19" class="clear-color" rx=".75" />
                            <path stroke="#9297a0" stroke-width="1.5" d="M13 6L17 10" class="clear-color" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div id="map-with-address" class="map"></div>
        <div class="hidden">
            <app-map-controls
                id="map-with-address-controls"
                :options="options"
                @map-zoom-in="onZoomIn"
                @map-zoom-out="onZoomOut"
                @map-home="onMapHome"
                @map-geolocate="onLocatePlacesNearMe"
                @map-basemap="onChangeBasemap"
            >
            </app-map-controls>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.map-with-address {
    width: 100%;
    overflow: hidden;

    .map {
        height: 352px;
        border-radius: 8px;

        @include respond-above('lg') {
            height: 512px;
        }
    }

    .search-container {
        position: relative;

        .google-search-suggestions {
            display: flex;
            justify-content: space-between;
            border: 2px solid var(--component-color-border-default);
            padding: 13px;
            border-radius: 6px;
            margin-bottom: 10px;

            .geo {
                svg {
                    .pin {
                        fill: var(--brand-red);
                    }
                }

                &:hover {
                    svg {
                        .pin {
                            fill: var(--text-color-default);
                        }
                    }
                }
            }

            .clear {
                &:hover {
                    .clear-color {
                        stroke: var(--text-color-default);
                    }
                }
            }

            .suggestions-search {
                display: block;
                width: 100%;
                line-height: 27px;
                margin-left: 10px;
                margin-right: 10px;
                font-size: var(--font-size-nano);
                font-weight: 600;

                span {
                    color: var(--black-500);
                    font-weight: 200;
                }
            }
        }

        .google-suggestions {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;

            &-item {
                color: yellow;
            }
        }
    }

    .label {
        margin-bottom: var(--size-pico);
        font-size: var(--font-size-nano);
        font-weight: 600;
    }

    .search-box {
        width: 100%;
        margin-bottom: var(--size-nano);
    }

    .control-button {
        background-color: var(--white);
        width: 28px;
        height: 28px;
        margin: 7px 7px -2px 0;

        .icon {
            align-items: center;
            margin-top: 3px;
        }

        &:hover {
            path {
                stroke: var(--text-color-default);
            }
        }
    }
}
</style>
