<script setup lang="ts">
import { computed, onMounted, PropType, Ref, ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import { useHtml } from '@/Composables/Html';
import { usePrice } from '@/Composables/Price';
import { CoverageRisk } from '@/Interfaces/Resources/MovableProperties/CoverageRiskInterface';
import { MovableProperty } from '@/Interfaces/Resources/MovableProperties/MovablePropertyInterface';
import ImageTagAttributes from '@/Interfaces/image.tag.attributes';
import IconSide from '@/Components/Tooltips/TextWithTip/IconSide.enum';
import CoveredPopupAutomationIterator from '@/Components/Popups/CoveredPopup/Classes/CoveredPopupAutomationIterator';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';

export interface CoveredRisks {
    id: string;
    risks: (CoverageRisk | null)[];
}

const props = defineProps({
    title: { type: String, default: '' },
    policies: { type: Array as PropType<MovableProperty[]>, default: () => [] },
    excludedRisks: { type: Array, default: () => [] },
    coveragePlanKey: { type: Number, default: 0 },
    translationType: { type: String, default: '' },
});

const emit = defineEmits(['selected', 'blur', 'close']);

const { translate, translateForType } = useTranslate();
const { price, sparse } = usePrice();

const additionalRisks = ref<CoveredRisks[]>([]);
const standardRisks = ref<CoveredRisks[]>([]);

const buttonParams: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        backgroundColor: ButtonBackground.Red,
        textColor: ButtonTextColor.White,
        iconColor: ButtonIconColor.White,
    };
});

onMounted((): void => {
    init();
    CoveredPopupAutomationIterator.init();
});

function init(): void {
    setupRisks();
}

function setupRisks(): void {
    let standardRisksArray: CoveredRisks[] = [];
    let additionalRisksArray: CoveredRisks[] = [];

    separateStandardAndAdditionalRisks(additionalRisksArray, standardRisksArray);
    additionalRisksArray = filteredArray(additionalRisksArray);
    standardRisksArray = filteredArray(standardRisksArray);
    standardRisksArray = removedFakeStandardRisks(standardRisksArray, additionalRisksArray);
    associateRisksFromPolicies(additionalRisksArray);
    associateRisksFromPolicies(standardRisksArray);

    additionalRisks.value = additionalRisksArray;
    standardRisks.value = standardRisksArray;
}

function separateStandardAndAdditionalRisks(
    additionalRisksArray: CoveredRisks[],
    standardRisksArray: CoveredRisks[],
): void {
    props.policies.forEach((plan) => {
        plan.coveragePlans[props.coveragePlanKey].risks.map((risk) => {
            const isNotExcludedRisk = !props.excludedRisks.some((excludedRisk) => excludedRisk === risk.id);
            if (isNotExcludedRisk) {
                if (risk.isAdditional) {
                    additionalRisksArray.push({
                        id: risk.id,
                        risks: [],
                    });
                } else {
                    standardRisksArray.push({
                        id: risk.id,
                        risks: [],
                    });
                }
            }
        });
    });
}

function filteredArray(arrayToFilter: CoveredRisks[]): CoveredRisks[] {
    return Array.from(new Set(arrayToFilter.map((risk) => risk.id))).map(
        (id) => arrayToFilter.find((risk) => risk.id === id)! as CoveredRisks,
    );
}

function removedFakeStandardRisks(risksStandard: CoveredRisks[], risksAdditional: CoveredRisks[]): CoveredRisks[] {
    const additionalRiskIds = new Set(risksAdditional.map((additionalRisk) => additionalRisk.id));

    return risksStandard.filter((standardRisk) => !additionalRiskIds.has(standardRisk.id));
}

function associateRisksFromPolicies(arrayToAssociate: CoveredRisks[]): void {
    arrayToAssociate.forEach((standardRisk) => {
        props.policies.forEach((plan) => {
            const foundRisk = plan.coveragePlans[props.coveragePlanKey].risks.find(
                (risk) => risk.id === standardRisk.id,
            );
            if (foundRisk) {
                standardRisk.risks.push(foundRisk);
            } else {
                standardRisk.risks.push(null);
            }
        });
    });
}

function standardRiskStatusInfo(risk: CoverageRisk | null, planIndex: number): string {
    let result: string = '';
    if (risk !== null && !risk.withoutInsuredSum) {
        result = insuredSumAmount(risk, planIndex);
    } else if (risk !== null) {
        result = `${iconContent(checkmarkIconAttributes())}`;
    } else {
        result = `${iconContent(crossIconAttributes())}`;
    }

    return result;
}

function additionalRiskStatusInfo(risk: CoverageRisk | null): string {
    let result: string;
    if (risk !== null && risk.isAdditional) {
        result = price(risk.price, false) + ' ' + translate('btar_policy_price_text');
    } else if (risk !== null && !risk.isAdditional) {
        result = `${iconContent(checkmarkIconAttributes())}`;
    } else {
        result = `${iconContent(crossIconAttributes())}`;
    }

    return result;
}

function insuredSumAmount(risk: CoverageRisk, policyPlanKey: number): string {
    let result: string;
    if (otherCoverageContainsDifferentAmount(risk, policyPlanKey)) {
        result = coverageAmountsForRisk(risk, policyPlanKey);
    } else {
        result = risk.insuredSum.toString() + '&nbsp;&euro;';
    }

    return result;
}

function otherCoverageContainsDifferentAmount(risk: CoverageRisk, policyPlanKey: number): boolean {
    return !!props.policies[policyPlanKey].coveragePlans
        .map((policyPlan) => policyPlan.risks)
        .flat()
        .find((existingRisk) => existingRisk.id === risk.id && existingRisk.insuredSum !== risk.insuredSum);
}

function coverageAmountsForRisk(risk: CoverageRisk, policyPlanKey: number): string {
    const sums: string[] = [];
    props.policies[policyPlanKey].coveragePlans.forEach((plan) => {
        const matchingRisk = plan.risks.find((value) => value.id === value.id);
        sums.push(`<span class="amount">${price(matchingRisk!.insuredSum, false, 'EUR', true, true)}</span>`);
    });

    return sums.join('');
}

function checkmarkIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon checkmark',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    };
}

function crossIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon',
        src: 'images/one/cross-icon.svg',
        width: 14,
        height: 14,
    };
}

function iconContent(iconAttributes: ImageTagAttributes): string {
    return useHtml().imgTag(iconAttributes);
}

function onSelectedClick(product: string): void {
    emit('selected', [product]);
}
</script>

<template>
    <div class="covered-popup-multiple">
        <div class="coverage-title">{{ translateForType(title, translationType) }}</div>
        <div class="insurance-plans">
            <div v-for="(plan, index) in props.policies" :key="index" class="plan">
                <p class="plan-title">{{ translateForType(plan.id, translationType) }}</p>
                <p class="price">
                    {{
                        translateForType('covered_price_from', 'components', {
                            '%PRICE%': sparse(plan.price, false),
                        })
                    }}
                </p>
                <app-button-with-callback
                    :title="translateForType('covered_select', 'components')"
                    v-bind="buttonParams"
                    @button-callback-click="onSelectedClick(plan.id)"
                >
                </app-button-with-callback>
            </div>
        </div>
        <div class="covered-information">
            <div class="title">{{ translateForType('insurance_coverage', 'components') }}</div>
            <div class="risks">
                <div v-for="(risk, index) in standardRisks" :key="index" class="standard-risk">
                    <app-text-with-tip
                        class="sub-title"
                        :info-icon-side="IconSide.Before"
                        :title="translateForType(risk.id + '_DETAILED', translationType)"
                        :tip-description="translateForType(risk.id + '_DETAILED_TOOLTIP', translationType)"
                    >
                    </app-text-with-tip>
                    <div
                        :id="'covered-block-multiple-' + CoveredPopupAutomationIterator.blockIndexIterate()"
                        class="status"
                    >
                        <div
                            v-for="(riskData, itemIndex) in risk.risks"
                            :id="
                                'covered-block-multiple-item-' +
                                CoveredPopupAutomationIterator.blockIndex() +
                                '-' +
                                itemIndex
                            "
                            :key="itemIndex"
                            class="item"
                            v-html="standardRiskStatusInfo(riskData, itemIndex)"
                        ></div>
                    </div>
                </div>
            </div>
            <div class="title">{{ translateForType('additional_coverage', 'components') }}</div>
            <div class="additional-risk">
                <div v-for="(risk, index) in additionalRisks" :key="index" class="standard-risk">
                    <app-text-with-tip
                        class="sub-title"
                        :info-icon-side="IconSide.Before"
                        :title="translateForType(risk.id + '_DETAILED', translationType)"
                        :tip-description="translateForType(risk.id + '_DETAILED_TOOLTIP', translationType)"
                    >
                    </app-text-with-tip>
                    <div
                        :id="'covered-block-multiple-' + CoveredPopupAutomationIterator.blockIndexIterate()"
                        class="status"
                    >
                        <div
                            v-for="(riskData, itemIndex) in risk.risks"
                            :id="
                                'covered-block-multiple-item-' +
                                CoveredPopupAutomationIterator.blockIndex() +
                                '-' +
                                itemIndex
                            "
                            :key="itemIndex"
                            class="item icon"
                        >
                            <div v-html="additionalRiskStatusInfo(riskData)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.covered-popup-multiple {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .insurance-plans {
        width: 100%;
        min-height: 235px;
        max-height: 235px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
        align-items: flex-end;
        background: var(--background-light);
        padding: var(--size-small);
        gap: var(--size-small);
        border-top-left-radius: var(--size-tiny);
        border-top-right-radius: var(--size-tiny);

        @include respond-above('sm') {
            padding: var(--size-big);
            min-height: 277px;
            gap: var(--size-big);
        }

        .plan {
            max-height: 117px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-self: center;
            justify-content: flex-end;
            align-items: center;

            @include respond-above('sm') {
                margin-bottom: 0;
                justify-content: center;
            }

            .plan-title {
                text-align: center;
                font-size: var(--font-size-small);
                font-weight: 700;
                color: var(--text-color-default);
            }

            .price {
                font-weight: 500;
                text-align: center;
                color: var(--text-color-subtle);
            }

            button {
                width: 100%;
                height: 100%;
                margin-top: var(--size-small);
                min-height: 52px;
            }
        }
    }

    .no-plans {
        min-height: 120px;
        max-height: 120px;
    }

    .covered-information {
        display: grid;
        padding: 0 var(--size-small) var(--size-small);
        width: 100%;
        overflow-y: auto;

        @include respond-above('sm') {
            padding: var(--size-small) var(--size-small) 0 var(--size-big);
        }

        .standard-risk {
            .sub-title {
                margin: 16px 0;

                @include respond-above('sm') {
                    margin: var(--size-small) 0;
                }
            }

            .status {
                border-top: 1px solid var(--black-100);
                border-bottom: 1px solid var(--black-100);
                min-height: 46px;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
                justify-content: center;
                align-items: stretch;

                .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid var(--black-100);
                    color: var(--text-color-subtle);
                    padding: var(--size-nano);
                    gap: var(--size-femto);
                }

                .item:last-child {
                    border-right: none;
                }
            }
        }
    }

    .popup-title {
        position: absolute;
        padding-bottom: var(--size-small);
        border-bottom: 1px solid var(--black-100);
    }

    .coverage-title {
        position: absolute;
        max-width: 225px;
        top: 30px;
        left: 25px;
        font-size: var(--font-size-medium);
        font-weight: 700;

        @include respond-above('sm') {
            top: 50px;
            left: var(--size-big);
            max-width: 600px;
        }
    }

    .title {
        margin: var(--size-small) 0 0 !important;
        padding-bottom: var(--size-small);
        border-bottom: 1px solid var(--black-100);
        font-size: var(--font-size-normal);
        font-weight: 700;
    }

    .sub-title {
        font-size: var(--font-size-tiny);
        font-weight: 600;
    }
}
</style>
