<script lang="ts">
import { useModule } from '@/Composables/Module';
import { computed, defineComponent, ref, Ref, nextTick } from 'vue';
import OneModule from '@/Interfaces/OneModuleInterface';
import Form from '@/Assets/Libraries/Form/Form';
import FormField from '@/Assets/Libraries/Form/FormField';
import Sanitizer from '@/Services/sanitizer.service';
import OneBaseService from '@/Services/OneBaseService';
import DynamicDictionary from '@/Interfaces/dynamic.dictionary.interface';
import { InputOption } from '@/Interfaces/InputOptionInterface';
import Error from '@/Services/error.service';
import { TranslateParams, useTranslate } from '@/Composables/Translate';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import ContractualInstitution from '@/Interfaces/contractual.institution.interface';
import ContractualInstitutionRegion from '@/Interfaces/contractual.institution.region.interface';
import ContractualInstitutionType from '@/Interfaces/contractual.institution.type.interface';
import SearchContractualInstitution from '@/Interfaces/search.contractual.insitution.interface';
import { useDefine } from '@/Composables/Define';
import Url from '@/Enums/UrlEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import Debounce from '@/Services/debounce.service';
import { AxiosResponse } from 'axios';

const { translate }: TranslateParams = useTranslate();

export default defineComponent({
    setup() {
        const error: Error = Error.getInstance();

        const module: OneModule = useModule();
        const request: AxiosParams = useAxios();

        const filterForm: Form = new Form();
        const institutions: Ref<DynamicDictionary[]> = ref([]);
        const regionOptions: Ref<InputOption[]> = ref([]);
        const typeOptions: Ref<InputOption[]> = ref([]);
        const searchValueModel: Ref<string> = ref('');
        const searchValue: Ref<string> = ref('');
        const regionValue: Ref<string> = ref('');
        const typeValue: Ref<string> = ref('');
        const searchDelay: Ref<number> = ref(1000);
        const fetchInProgress: Ref<boolean> = ref(true);
        const fetchEndpoint: Ref<string> = ref('default');

        const endpoints: DynamicDictionary = {
            default: Url.Ajax.fetchContractualInstitutions,
            medical: Url.Ajax.fetchMedicalInstitutions,
        };

        const create = (): void => {
            module.onModuleReady.subscribe((): void => {
                nextTick((): void => {
                    setupFilterForm();
                    fetchInstitutions();
                    module.applyModuleType();
                });
            });
        };

        const search = (): void => {
            if (haveSearchParamsChanged()) {
                fetchInstitutions();
            }
        };

        const regionsEmptyOption = (title: string = '-----'): InputOption => {
            return new InputOptionBuilder().setName(translate(title)).build();
        };

        const typesEmptyOption = (title: string = '-----'): InputOption => {
            return new InputOptionBuilder().setName(translate(title)).build();
        };

        const applyInstitutionParams = (institution: DynamicDictionary): ContractualInstitution => {
            const phone: string[] = institution.phone.split(',');
            return {
                title: institution.title,
                link: institution.link,
                address: institution.address,
                region: institution.region,
                type: institution.type,
                phone: phone[0],
                secondPhone: phone.length === 2 ? phone[1] : '',
            };
        };

        const fieldValue = (name: string): string => {
            return filterForm.exists(name) ? filterForm.field(name).value : '';
        };

        const hasInstitutions: Ref<boolean> = computed(() => {
            return institutions.value.length > 0;
        });

        const showNotFound: Ref<boolean> = computed(() => {
            return !fetchInProgress.value && !hasInstitutions.value;
        });

        const applyRegionOptions = (regionOptionsJson: string): void => {
            const regions: ContractualInstitutionRegion[] = JSON.parse(
                module.transformedVueSafeString(regionOptionsJson),
            );
            regionOptions.value = regions.map((region: ContractualInstitutionRegion): InputOption => {
                return new InputOptionBuilder().setName(region.region).setValue(region.region).build();
            });
        };

        const applyTypeOptions = (typeOptionsJson: string): void => {
            const types = JSON.parse(module.transformedVueSafeString(typeOptionsJson));
            typeOptions.value = types.map((item: ContractualInstitutionType): InputOption => {
                return new InputOptionBuilder().setName(item.type).setValue(item.type).build();
            });
        };

        const applySearchDelay = (delayJson: string): void => {
            searchDelay.value = JSON.parse(module.transformedVueSafeString(delayJson));
        };

        const applyFetchEndpoint = (value: string): void => {
            fetchEndpoint.value = value;
        };

        const setupFilterForm = (): void => {
            filterForm.addField(new FormField('search', '', '', Sanitizer.cleanSearch));
            filterForm.addField(new FormField('region'));
            filterForm.addField(new FormField('type'));
        };

        const searchParams = (): SearchContractualInstitution => {
            return {
                search: fieldValue('search'),
                region: fieldValue('region'),
                type: fieldValue('type'),
            };
        };

        const haveSearchParamsChanged = (): boolean => {
            const params: SearchContractualInstitution = searchParams();
            const isSet = useDefine().isSet;
            let result: boolean = false;
            if (isSet(params.search) && searchValue.value !== params.search) {
                result = true;
            } else if (isSet(params.region) && regionValue.value !== params.region) {
                result = true;
            } else if (isSet(params.type) && typeValue.value !== params.type) {
                result = true;
            }

            return result;
        };

        const fetchInstitutionsDebounce = (): void => {
            const params: SearchContractualInstitution = searchParams();
            searchValue.value = params.search;
            regionValue.value = params.region;
            typeValue.value = params.type;

            request
                .get(endpoints[fetchEndpoint.value], { params })
                .then((response: AxiosResponse): void => {
                    const axiosResponse: DynamicDictionary = response.data.data.body;
                    institutions.value = axiosResponse.institutions.map((element: ContractualInstitution) => {
                        return applyInstitutionParams(element);
                    });
                    fetchInProgress.value = false;
                })
                .catch((reason) => {
                    institutions.value = [];
                    error.show(ErrorType.Error, 'fetchInstitutions', reason);
                });
        };

        const fetchInstitutions: Function = Debounce.getInstance()
            .applyTimeout(500)
            .applyDebounce(() => {
                fetchInstitutionsDebounce();
            });

        const regionText = (text: string): string => {
            return text === '' || text === '-' ? translate('all_regions') : text;
        };

        return {
            ...module,
            ...{
                filterForm,
                institutions,
                regionOptions,
                typeOptions,
                searchValueModel,
                hasInstitutions,
                create,
                search,
                regionsEmptyOption,
                typesEmptyOption,
                applyRegionOptions,
                applyTypeOptions,
                applySearchDelay,
                applyFetchEndpoint,
                searchParams,
                regionText,
                fetchInProgress,
                showNotFound,
            },
        };
    },

    mounted() {
        OneBaseService.getInstance().applyApp(this);
    },
});
</script>
