import { markRaw } from 'vue';
import MovablePropertyOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/MovablePropertyOptionInterface';
import { CoverageRisk } from '@/Interfaces/Resources/MovableProperties/CoverageRiskInterface';
import FormField from '@/Assets/Libraries/Form/FormField';

export default class MovablePropertyOptionBuilder {
    private option: MovablePropertyOption = new (class implements MovablePropertyOption {
        public name: string = '';
        public risk!: CoverageRisk;
        public component: string = 'AppMovablePropertyOption';
        public enabled: FormField<boolean> = markRaw(new FormField('enabled', false));
        public paymentFrequency: string = '';
    })();

    public withName(value: string): MovablePropertyOptionBuilder {
        this.option.name = value;

        return this;
    }

    public withPaymentFrequency(value: string): MovablePropertyOptionBuilder {
        this.option.paymentFrequency = value;

        return this;
    }

    public withCoverageRisk(value: CoverageRisk): MovablePropertyOptionBuilder {
        this.option.risk = value;

        return this;
    }

    public withState(value: boolean): MovablePropertyOptionBuilder {
        this.option.enabled.setValue(value);

        return this;
    }

    public build(): MovablePropertyOption {
        return this.option;
    }
}
