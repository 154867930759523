import { JSLoader } from '@/Assets/Libraries/JsLoader/JsLoader';
import Library from '@/Enums/LibraryEnum';

export class SlickPlugin {
    private static version: string = '1.8.2';

    public static init(): Promise<void> {
        return new JSLoader(Library.Slick.js.map((file: string) => Library.Path + file + '?' + this.version)).load();
    }
}
