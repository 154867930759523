<script setup lang="ts">
import FormField from '@/Assets/Libraries/Form/FormField';
import { computed, ComputedRef, onMounted, PropType, watch } from 'vue';

const props = defineProps({
    formField: {
        type: Object as PropType<FormField<boolean>>,
        default: () => new FormField(''),
    },
    dataStoreDisabled: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
});

const emit = defineEmits(['change', 'click']);

const fieldId: ComputedRef<string> = computed(() => props.formField.name + '-checkbox');

watch(
    () => props.formField.value,
    (after: boolean, before: boolean) => {
        setDefaultValueIfEmpty();
        if (after !== before) {
            emitChange();
        }
    },
);

onMounted((): void => {
    setDefaultValueIfEmpty();
});

function onChange(event: Event): void {
    props.formField.setValue((event.target as HTMLInputElement).checked);
    emitChange();
}

function onClick(): void {
    props.formField.setValue(!props.formField.value);
    props.formField.markAsTouched();
    props.formField.markAsDirty();
    emitClick();
}

function setDefaultValueIfEmpty(): void {
    if (props.formField.isEmpty()) {
        props.formField.setValue(false);
    }
}

function emitChange(): void {
    emit('change', props.formField.value);
}

function emitClick(): void {
    emit('click', props.formField.value);
}
</script>
<template>
    <span
        :id="formField.name"
        class="checkbox"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : `${formField.value}`"
    >
        <input
            :id="fieldId"
            :value="formField.value"
            :checked="formField.value"
            type="checkbox"
            :name="formField.name"
            :disabled="disabled"
            @change="onChange($event)"
            @click="onClick()"
        />
        <label class="title" :for="fieldId">
            <slot></slot>
            <span v-if="required" class="asterisk">&#42;</span>
        </label>
        <slot name="app-tooltipster"></slot>
    </span>
</template>
<style lang="scss" scoped>
.title:empty {
    display: none;
}

.checkbox {
    display: flex;
    flex-direction: row;
    scroll-margin-top: 6em;

    .title {
        span {
            :deep(p) {
                font-size: inherit;
                line-height: inherit;
                font-weight: inherit;
                color: inherit;
            }
        }
    }
}

.asterisk {
    font-size: var(--font-size-medium);
    color: var(--brand-red);
}
</style>
