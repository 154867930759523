<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import OneBase from '@/Interfaces/OneBaseInterface';
import SubmissionFormAccident from '@/Modules/ClaimsSubmissionForm/SubmissionFormAccident.vue';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const claimsAccidentSubmissionFormModule = new VueModule(SubmissionFormAccident).mount();

        return {
            ...btaBase,
            ...{
                claimsAccidentSubmissionFormModule,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.claimsAccidentSubmissionFormModule.create();
        this.claimsAccidentSubmissionFormModule.beforeModuleMounted();
        this.claimsAccidentSubmissionFormModule.moduleMounted();
    },
});
</script>
