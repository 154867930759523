import TwoWayModalContent from '@/Apps/TwoWayCommunication/Interfaces/TwoWayModalContentInterface';
import { Ref, ref } from 'vue';
import OneBaseService from '@/Services/OneBaseService';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import UserCredentials from '@/Interfaces/user.credentials.interface';
import User from '@/Services/user.service';

export default class TwoWayModal {
    private static instance: TwoWayModal;
    private readonly twoWayModalContent?: TwoWayModalContent;
    private readonly twoWayCommunication: TwoWayCommunication = TwoWayCommunication.getInstance();

    public isVisible: Ref<boolean> = ref(false);

    private constructor() {
        const count: number = this.twoWayCommunication.unreadCommunications.value;
        this.twoWayModalContent = new (class implements TwoWayModalContent {
            public name: string = '';
            public messagesCount: number = count;
            public proceedActionCallback: Function = (): void => {};
        })();
    }

    public static getInstance(): TwoWayModal {
        if (!TwoWayModal.instance) {
            TwoWayModal.instance = new TwoWayModal();
        }

        return TwoWayModal.instance;
    }

    public applyProceedActionCallback(proceedActionCallback: Function): this {
        this.twoWayModalContent!.proceedActionCallback = proceedActionCallback;

        return this;
    }

    public content(): TwoWayModalContent {
        return this.twoWayModalContent as TwoWayModalContent;
    }

    public show(): void {
        const currentUsr: UserCredentials = User.getInstance().current;
        this.twoWayModalContent!.name = currentUsr.name;
        this.isVisible.value = true;
        OneBaseService.getInstance().changeBodyVerticalScrollState();
    }

    public hide(): void {
        this.isVisible.value = false;
        OneBaseService.getInstance().changeBodyVerticalScrollState();
        this.twoWayModalContent!.proceedActionCallback();
    }
}
